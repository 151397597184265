import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Column, Row } from '@amxjs/ui';

import {
    Page as MeshPage,
    CardSet as MeshCardSet,
    ResourcesCardSet,
    Resource,
    MeshNode,
    welcomeScreen,
    GridElement,
    CardWithImage as MeshCardWithImage,
    FormLayout as FormLayoutInterface,
    Layout,
    SurveyListTable as SurveyTableInterface,
    AffectedIndividualsListTable,
    Heading,
    tableau,
    UserList as UserListProps,
    UserDetails as UserDetailsProps,
    PatientDetails as PatientDetailsProps,
    ClinicianList as ClinicianListProps,
    ClinicianDetails as ClinicianDetailFormProps,
    PatientList as PatientListProps,
    EncounterDetails as EncounterDetailsProps,
    OrganizationDetails as OrganizationDetailsProps,
    OrganizationList as OrganizationListProps,
    StandardFileValidator as StandardFileValidatorProps,
    MipsExplorer as MipsExplorerProps,
    MipsManageParticipation as MipsManageParticipationProps,
    MipsMonitorPerformance as MipsMonitorPerformanceProps,
    MipsRecord as MipsRecordProps,
    MipsPromoteInteroperabilityDetails as MipsPromoteInteroperabilityDetailsProps,
    MipsAttestToPromotingInteroperability as MipsAttestToPromotingInteroperabilityProps,
    MipsQualityMeasureDetails as MipsQualityMeasureDetailsProps,
    MipsImprovementActivitiesDetails as MipsImprovementActivitiesDetailsProps,
    MipsTINValidation as MipsTinValidationProps,
    MipsTINValidationApproval as MipsTINValidationApprovalProps,
} from '../../generator/generated/MeshInterfaces';
import Store from '../core/Store';
import CardSet from './cards/CardSet';
import DataGraph from './tableau/DataGraph';
import ResourceDetails from './cards/ResourcesCardSet';
import WelcomeScreen from './welcome-screen/WelcomeScreen';
import TableauErrorBoundary from './error/TableauErrorBoundary';
import MeshHeading from './heading/Heading';
import SurveyListTable from '../components/Tables/SurveyListTable';
import AffectedTable from '../components/Tables/AffectedIndividualsTable';
import { openLink } from '../util/LinkUtils';
import CardWithImage from './cards/CardWithImage';
import Textbox, { TextboxProps } from '../components/Inputs/Textbox';
import ResourceCard from './cards/ResourceCard';
import FhirEditorForm, { FhirEditorFormProps } from './fhir/FhirEditorForm';
import FhirEditorPlayground, { FhirEditorPlaygroundProps } from './fhir/FhirEditorPlayground';
import UserList from './users/UserList';
import FormLayout from './form-layout/FormLayout';
import UserDetails from './users/UserDetails';
import FhirList, { FhirListInterfaceProps } from '../components/Tables/FhirList';
import PatientList from './patients/PatientList';
import PatientDetails from './patients/PatientDetail';
import ClinicianList from './clinician/ClinicianList';
import ClinicianDetails from './clinician/ClinicianDetails';
import EncounterDetail from './encounters/EncounterDetail';
import OrganizationList from './organizations/OrganizationList';
import OrganizationDetails from './organizations/OrganizationDetails';
import StandardFileValidator from './data-ingestion/StandardFileValidator';
import MipsExplorer from './mips/MipsExplorer';
import ManageParticipation from './mips/ManageParticipation';
import MonitorPerformance from './mips/MonitorPerformance/MonitorPerformance';
import MipsRecord from './mips/Record/MipsRecord';
import MipsPromoteInteroperabilityDetails from './mips/MipsPromoteInteroperabilityDetails';
import MipsAttestToPromotingInteroperability from './mips/MipsAttestToPromotingInteroperability';
import MipsQualityMeasureDetails from './mips/QualityMeasures/MipsQualityMeasureDetails';
import MipsImprovementActivitiesDetails from './mips/MipsImprovementActivitiesDetails';
import MipsTINValidation from './mips/TINValidation/MipsTINValidation';
import MipsTINValidationApproval from './mips/TINValidation/MipsTINValidationApproval';

interface PageHandlerPropInterface {
    store: Store;
    pageConfig: MeshPage;
    name: string;
}

@observer
class PageHandler extends Component<PageHandlerPropInterface & RouteComponentProps> {
    getComponent(key: string, componentConfig: any) {
        if (key != null && componentConfig != null) {
            // each key in this map corresponds to the mesh schema name and the config
            // will have the same name but may need to be aliased
            const { store } = this.props;
            const componentMap: { [key: string]: JSX.Element } = {
                CardSet: <CardSet store={store} {...(componentConfig as MeshCardSet)} />,
                tableau: (
                    <TableauErrorBoundary store={store}>
                        <DataGraph {...(componentConfig as tableau)} />
                    </TableauErrorBoundary>
                ),
                ResourcesCardSet: (
                    <ResourceDetails store={store} {...(componentConfig as ResourcesCardSet)} />
                ),
                Resource: <ResourceCard {...(componentConfig as Resource)} />,
                welcomeScreen: (
                    <WelcomeScreen
                        store={this.props.store}
                        {...(componentConfig as welcomeScreen)}
                    />
                ),
                CardWithImage: (
                    <CardWithImage
                        {...(componentConfig as MeshCardWithImage)}
                        onClick={() => openLink(componentConfig.pageLink, this.props.history)}
                        link={componentConfig.pageLink && componentConfig.pageLink.route}
                    />
                ),
                SurveyListTable: <SurveyListTable {...(componentConfig as SurveyTableInterface)} />,
                AffectedIndividualsListTable: (
                    <AffectedTable {...(componentConfig as AffectedIndividualsListTable)} />
                ),
                Heading: <MeshHeading {...(componentConfig as Heading)} />,
                Textbox: <Textbox {...(componentConfig as TextboxProps)} />,
                FhirEditorForm: <FhirEditorForm {...(componentConfig as FhirEditorFormProps)} />,
                FhirEditorPlayground: (
                    <FhirEditorPlayground {...(componentConfig as FhirEditorPlaygroundProps)} />
                ),
                FhirList: <FhirList {...(componentConfig as FhirListInterfaceProps)} />,
                UserList: (
                    <UserList store={this.props.store} {...(componentConfig as UserListProps)} />
                ),
                StandardFileValidator: (
                    <StandardFileValidator {...(componentConfig as StandardFileValidatorProps)} />
                ),
                ClinicianList: (
                    <ClinicianList
                        store={this.props.store}
                        {...(componentConfig as ClinicianListProps)}
                    />
                ),
                ClinicianDetails: (
                    <ClinicianDetails
                        store={this.props.store}
                        {...(componentConfig as ClinicianDetailFormProps)}
                    />
                ),
                UserDetails: (
                    <UserDetails
                        store={this.props.store}
                        {...(componentConfig as UserDetailsProps)}
                    />
                ),
                PatientList: <PatientList {...(componentConfig as PatientListProps)} />,
                PatientDetails: (
                    <PatientDetails
                        store={this.props.store}
                        {...(componentConfig as PatientDetailsProps)}
                    />
                ),
                OrganizationDetails: (
                    <OrganizationDetails
                        store={this.props.store}
                        {...(componentConfig as OrganizationDetailsProps)}
                    />
                ),
                OrganizationList: (
                    <OrganizationList {...(componentConfig as OrganizationListProps)} />
                ),
                EncounterDetails: (
                    <EncounterDetail
                        store={this.props.store}
                        {...(componentConfig as EncounterDetailsProps)}
                    />
                ),
                MipsExplorer: (
                    <MipsExplorer
                        store={this.props.store}
                        {...(componentConfig as MipsExplorerProps)}
                    />
                ),
                MipsManageParticipation: (
                    <ManageParticipation
                        store={this.props.store}
                        {...(componentConfig as MipsManageParticipationProps)}
                    />
                ),
                MipsMonitorPerformance: (
                    <MonitorPerformance
                        store={this.props.store}
                        {...(componentConfig as MipsMonitorPerformanceProps)}
                    />
                ),
                MipsRecord: (
                    <MipsRecord
                        store={this.props.store}
                        {...(componentConfig as MipsRecordProps)}
                    />
                ),
                MipsPromoteInteroperabilityDetails: (
                    <MipsPromoteInteroperabilityDetails
                        store={this.props.store}
                        {...(componentConfig as MipsPromoteInteroperabilityDetailsProps)}
                    />
                ),
                MipsAttestToPromotingInteroperability: (
                    <MipsAttestToPromotingInteroperability
                        store={this.props.store}
                        {...(componentConfig as MipsAttestToPromotingInteroperabilityProps)}
                    />
                ),
                MipsQualityMeasureDetails: (
                    <MipsQualityMeasureDetails
                        store={this.props.store}
                        {...(componentConfig as MipsQualityMeasureDetailsProps)}
                    />
                ),
                MipsImprovementActivitiesDetails: (
                    <MipsImprovementActivitiesDetails
                        store={this.props.store}
                        {...(componentConfig as MipsImprovementActivitiesDetailsProps)}
                    />
                ),
                MipsTINValidation: (
                    <MipsTINValidation {...(componentConfig as MipsTinValidationProps)} />
                ),
                MipsTINValidationApproval: (
                    <MipsTINValidationApproval
                        store={this.props.store}
                        {...(componentConfig as MipsTINValidationApprovalProps)}
                    />
                ),
            };
            return componentMap[key];
        }
        return null;
    }

    getElements() {
        if (this.props.pageConfig == null) return null;
        // This is all of the nodes in mesh on the page itself
        const pageEntries = Object.entries(this.props.pageConfig) as [string, MeshNode][];
        if (pageEntries == null) return null;
        // first try to find a layout on the page
        const layout = pageEntries.find(([, elementConfig]: [string, MeshNode]) => {
            if (elementConfig.schema === 'Layout') {
                if ((elementConfig as Layout).display === 'active') {
                    return true;
                }
            }
            return false;
        }) as [string, Layout];
        const formLayout = pageEntries.find(([, elementConfig]: [string, MeshNode]) => {
            if (elementConfig.schema === 'FormLayout') {
                return true;
            }
            return false;
        }) as [string, FormLayoutInterface];
        if (formLayout != null) {
            // render FormLayout
            return <FormLayout store={this.props.store} {...formLayout[1]} />;
        }
        if (layout != null) {
            // use the layout to render the page
            const { layoutElements } = layout[1];
            return layoutElements.reduce((acc: JSX.Element[], gridElement: GridElement) => {
                const { height, width, element } = gridElement;
                const { schema, uuid } = element;
                if (schema == null) return acc;
                // try to render each node defined within the layout according to it's config
                const elementToRender = this.getComponent(schema, element);
                if (elementToRender == null) return acc;
                return [
                    ...acc,
                    <Column
                        style={{ height: height || 'auto' }}
                        xs={
                            parseInt(width || '12', 10) as
                                | 1
                                | 2
                                | 3
                                | 4
                                | 5
                                | 6
                                | 7
                                | 8
                                | 9
                                | 10
                                | 11
                                | 12
                        }
                        key={uuid}
                    >
                        {elementToRender}
                    </Column>,
                ];
            }, []);
        }
        // no layout found, render components however they appear
        return pageEntries.reduce(
            (acc: JSX.Element[], [name, elementConfig]: [string, MeshNode]) => {
                const { schema } = elementConfig;
                if (schema == null) return acc;
                const elementToRender = this.getComponent(schema, elementConfig);
                if (elementToRender == null) return acc;
                // each element takes up a whole column
                return [...acc, <Column key={name}>{elementToRender}</Column>];
            },
            []
        );
    }

    render() {
        if (this.props.store.siteConfiguration != null) {
            if (this.props.pageConfig.fullWidth) {
                return <Row style={{ maxWidth: '100%' }}>{this.getElements()}</Row>;
            }
            // Else render the page container if not specified.
            return (
                <div className="page-container">
                    <Row>{this.getElements()}</Row>
                </div>
            );
        }
        return null;
    }
}

export default withRouter(PageHandler);
