import ReactGA from 'react-ga';

const EXTERNAL_LINK_REGEX = /^https?:\/\//;

export const prependAppBaseName = (link: string | undefined) => {
    if (
        link == null ||
        window.SERVER_CONFIG.REACT_APP_BASE_NAME == null ||
        EXTERNAL_LINK_REGEX.test(link)
    )
        return link;
    return window.SERVER_CONFIG.REACT_APP_BASE_NAME.replace(/\/$/g, '') + link;
};
export const openLink = (link: string | undefined, history: any) => {
    if (link == null) return;
    // for external links, open them in a new tab
    if (EXTERNAL_LINK_REGEX.test(link)) {
        const goToLink = () => {
            if (
                window.SERVER_CONFIG.REACT_APP_ANALYTICS_ENABLED === 'true' &&
                window.SERVER_CONFIG.REACT_APP_ANALYTICS_DEBUG === 'true'
            ) {
                console.info(`Google Analytics hitCallback triggered for external link: ${link}`);
            }
            const a = Object.assign(document.createElement('a'), {
                href: link,
                target: '_blank',
                rel: 'noopener noreferrer',
            });
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        if (window.SERVER_CONFIG.REACT_APP_ANALYTICS_ENABLED === 'true') {
            ReactGA.outboundLink({ label: link }, goToLink);
        } else {
            goToLink();
        }
    } else if (history != null && typeof history.push === 'function') {
        // for internal links, try react-router for navigation
        history.push(link);
    }
};
