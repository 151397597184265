import React, { useEffect, useState } from 'react';
import { RadioGroupField } from '@amxjs/ui';
import { WidgetProps } from 'react-jsonschema-form';

export default function RadioWidget(props: WidgetProps) {
    const [options, setOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any | null>(
        (props.options.selectedOption as any) || null
    );

    useEffect(() => {
        const { enumOptions } = props.options as any;
        const originalOptions: any[] = [];

        enumOptions.forEach((option: any) => {
            originalOptions.push({
                ...option.value,
            });
        });

        setOptions(originalOptions);

        if (props.options.selectedOption) {
            setSelectedOption(props.options.selectedOption);
        } else {
            setSelectedOption(props.value);
        }
    }, [props.options.selectedOption]);

    return (
        <RadioGroupField
            label={props.label || props.schema.title}
            showRequiredIndicator={props.required}
            disabled={props.disabled || props.readonly}
            onChange={(e) => {
                setSelectedOption(e);
                props.onChange(options.filter((option) => option.value === e)[0].value);
            }}
            help={props.options.helpText}
            value={selectedOption}
            options={options}
        />
    );
}
