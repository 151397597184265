import { JSONSchema6 } from 'json-schema';
import { Intent } from '@blueprintjs/core';
import { UiSchema } from 'react-jsonschema-form';
import { TTableEmptyProps } from '@amxjs/ui';
import { RowAction } from '../../components/Tables/List';
import ClinicianModuleConstants from './ClinicianModuleConstants';

export const getAffiliatedOrganizationsUISchema = (
    editMode: boolean,
    createMode: boolean,
    addButtonTitle: string | undefined,
    affiliatedOrganizationTitle: string | undefined,
    showAffiliatedPracticeCallout: boolean,
    rowActions: RowAction[],
    onAddAffiliatedPracticeClick: (event: any) => void,
    columns: any[]
): UiSchema => {
    const showControls = editMode || createMode;
    const properties: any = {};

    columns.forEach((column: any) => {
        properties[column.id] = {
            'ui:widget': 'SimpleTextWidget',
            'ui:options': {
                renderMode:
                    column.id === 'startDate' || column.id === 'endDate' ? 'date' : 'default',
            },
            'ui:orderable': true,
        };
    });

    const noAffiliatedPracticeCallout = {
        intent: Intent.DANGER,
        icon: null,
        title: 'Please add an affiliated practice for this clinician',
    };

    const emptyMessage: TTableEmptyProps = {
        title: `No ${
            affiliatedOrganizationTitle || ClinicianModuleConstants.AddaffiliatedOrganizationTitle
        }`,
        description: `Select '${
            addButtonTitle || ClinicianModuleConstants.AddButtonLabel
        }' to get started`,
    };

    const uiSchema: UiSchema = {
        'ui:field': 'list',
        'ui:hash': 'affiliatedorganizations',
        'ui:options': {
            addButtonLabel: addButtonTitle || ClinicianModuleConstants.AddButtonLabel,
            affiliatedOrganizationTitle:
                affiliatedOrganizationTitle ||
                ClinicianModuleConstants.AddaffiliatedOrganizationTitle,
            addable: showControls,
            removable: showControls,
            editable: showControls,
            onAddItemClick: onAddAffiliatedPracticeClick,
            callout: showAffiliatedPracticeCallout ? noAffiliatedPracticeCallout : null,
            fieldNameForDeleteDialog: 'name',
            rowActions,
            emptyDataListMessage: emptyMessage,
            showDisabledRowActions: true,
        },
        items: {
            ...properties,
        },
    };

    return uiSchema;
};

export const getAffiliatedOrganizationsSchema = (
    affiliatedOrganizationTitle: string | undefined,
    columns: any[]
): JSONSchema6 => {
    const properties: any = {};

    columns.forEach((column: any) => {
        properties[column.id] = {
            type: column.id === 'status' ? 'boolean' : 'string',
            title: column.label,
        };
    });

    const schema: JSONSchema6 = {
        title:
            affiliatedOrganizationTitle || ClinicianModuleConstants.AddaffiliatedOrganizationTitle,
        type: 'array',
        items: {
            type: 'object',
            properties: { ...properties },
        },
    };

    return schema;
};
