import Store from '../core/Store';
import Api from '../core/Api';

class MeshConfigurationService {
    private project: string;

    constructor() {
        this.project = window.SERVER_CONFIG.REACT_APP_MESH_DEFAULT_PROJECT as string;
    }

    loadPageConfiguration(store: Store, project: string = this.project) {
        Api.getMeshConfig(project, store.language)
            .then((result: any) => {
                const mappedNavRoot = result.data;
                store.addSiteConfiguration(project, mappedNavRoot);
                store.setSiteConfiguration(project);
                store.setConnected({ ...store.connected, mesh: true });
            })
            .catch((error) => {
                console.error(error);
                store.setConnected({ ...store.connected, mesh: false });
            });
    }
}

export default MeshConfigurationService;
