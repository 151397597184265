// This common solution of using Regex doesn't work well in Firefox, Chrome, etc.
// It would freeze the browser and eventually would timeout.
// const emailformat = /^\w+([^A-Za-z0-9]?\w+)*@\w+([\.\-]?\w+)*(\.\w{2,4})+$/;
// const emailformat = /^\w+([.!#$%&'*+-/=?^_`{|}~]?\w+)*@\w+([\.\-]?\w+)*(\.\w{2,4})+$/;
// if (!formData.email.match(emailformat)) {

export function validateEmail(email: string) {
    if (!email) return false;
    if (email.indexOf('@') === -1) {
        return false;
    }

    const splits = email.split('@');
    const prefix = splits[0];
    const domain = splits[1];
    const splitsDomain = domain.split('.');
    const tld = splitsDomain[splitsDomain.length - 1];

    if (
        splits.length !== 2 ||
        splitsDomain.length < 2 ||
        prefix.length < 1 ||
        tld.length < 2 ||
        tld.length > 4 ||
        !tld.match(/^[A-Za-z0-9]{2,4}$/)
    ) {
        return false;
    }

    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
    const special = ".!#$%&'*+-/=?^_`{|}~";
    let resultPrefix = '';
    let resultDomain = '';

    prefix.split('').forEach((c) => {
        if (special.includes(c)) {
            resultPrefix += '-';
        } else if (charset.includes(c)) {
            resultPrefix += 'a';
        } else {
            resultPrefix += 'x';
        }
    });

    domain.split('').forEach((c) => {
        if ('.-'.includes(c)) {
            resultDomain += '-';
        } else if (charset.includes(c)) {
            resultDomain += 'a';
        } else {
            resultDomain += 'x';
        }
    });

    if (
        resultPrefix.startsWith('-') ||
        resultPrefix.endsWith('-') ||
        resultPrefix.indexOf('x') >= 0 ||
        resultPrefix.indexOf('--') >= 0 ||
        resultDomain.startsWith('-') ||
        resultDomain.endsWith('-') ||
        resultDomain.indexOf('x') >= 0 ||
        resultDomain.indexOf('--') >= 0
    ) {
        return false;
    }
    return true;
}

export function formFieldsAreEmpty(record: Record<string, any>) {
    let isEmpty = true;
    Object.entries(record).forEach((field) => {
        if (typeof field[1] === 'string') {
            if (field[1].length > 0) isEmpty = false;
        }
    });
    return isEmpty;
}

export const validateUsername = (username?: string) => {
    return username && username.length >= 3 && username.length <= 215;
};

export const validateUsernameForAtSimbol = (username?: string) => {
    return !username?.includes('@');
};

export function validatePhone(phone: string | undefined) {
    if (!phone) return true;

    const regex = new RegExp(/^\(\d{3}\)\d{3}-\d{4}$/, 'g');
    return regex.test(phone);
}

export function validateAddress(address: string | undefined) {
    return address;
}

export function validateCity(city: string | undefined) {
    return city;
}

export function validateState(state: string | undefined) {
    return state;
}

export function validateZipCode(zipCode: string | undefined) {
    if (!zipCode) return false;

    const regex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'g');
    return regex.test(zipCode);
}

export function validateDateRangeStartEndDate(dateRange: any | undefined) {
    if (!dateRange || !dateRange.startDate || !dateRange.endDate) return true;
    return new Date(dateRange.startDate) <= new Date(dateRange.endDate);
}

export function dummyValidate(formData: any, errors: any) {
    if (formData !== null) {
        return errors;
    }
    return errors;
}
