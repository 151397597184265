import { JSONSchema6 } from 'json-schema';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import { cloneDeep, isEqual } from 'lodash';
import { Intent, Position, Toaster } from '@blueprintjs/core';
import { showToast } from '@amxjs/ui';
import {
    OrganizationDetails as OrganizationDetailsInterface,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import FormHeader from '../../components/forms/components/FormHeader';
import Form from '../../components/forms/Form';
import Store from '../../core/Store';
import { getContactsSchema, getContactsUISchema } from './Contacts';
import ConfirmExitDialog from '../ConfirmExitDialog';
import ContactsDialog from './ContactsDialog';
import { getGeneralSchema, getGeneralUISchema } from './GeneralInfo';
import Api from '../../core/Api';
import { SchemaProvider } from '../../mesh/Schema';
import { RowAction } from '../../components/Tables/List';
import {
    validateOrganizationID,
    validateOrganizationName,
    validateTIN,
} from './OrganizationValidation';
import {
    validateAddress,
    validateCity,
    validateState,
    validateZipCode,
} from '../../components/forms/validation/CustomValidation';
import FormFooter from '../../components/forms/components/FormFooter';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { OrganizationRecord } from '../../../server/routes/OrganizationManagement/RecordInterface';
import OrganizationModuleConstants from './OrganizationModuleConstants';

interface OrganizationDetailsProps extends OrganizationDetailsInterface {
    /* eslint-disable-next-line react/no-unused-prop-types */
    store: Store;
}

function OrganizationDetails(props: OrganizationDetailsProps & RouteComponentProps) {
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [contactsDialogOpen, setContactsDialogOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [activeItemId, setActiveItemId] = useState(0);
    const [extraErrors, setExtraErrors] = useState<any>();
    const [savingRecord, setSavingRecord] = useState(false);
    const [key, setKey] = useState<number>(Date.now());
    const [recordInfo, setRecordInfo] = useState({
        id: '',
        name: '',
    });
    const [formData, setFormData] = useState<OrganizationRecord>({
        id: '',
        taxAffiliationId: '',
        General: {
            status: true,
            organizationName: '',
            reportingName: '',
            organizationId: '',
            tin: '',
            address: '',
            buildingSuiteOther: '',
            city: '',
            state: '',
            zipCode: '',
        },
        Contacts: [],
    });
    const [origFormData, setOrigFormData] = useState<OrganizationRecord>({
        id: '',
        taxAffiliationId: '',
        General: {
            status: true,
            organizationName: '',
            reportingName: '',
            organizationId: '',
            tin: '',
            address: '',
            buildingSuiteOther: '',
            city: '',
            state: '',
            zipCode: '',
        },
    });
    const { setBreadcrumbData } = useBreadcrumbContext();

    useEffect(() => {
        (async () => {
            const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

            if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
                props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                    breadcrumbTrail.labels.push(crumb.name);
                    breadcrumbTrail.routes.push(crumb.route || null);
                });
            }

            const { id } = queryString.parse(props.location.search);

            if (id && formData.id === id) {
                setOrigFormData(cloneDeep(formData));

                setRecordInfo({
                    name: `${formData.General.organizationName}`,
                    id: `TIN: ${formData.General.tin}`,
                });

                breadcrumbTrail.labels.push(formData.General.organizationName);
            } else if (id) {
                setCreateMode(false);
                const response = await Api.organizationManagement.getOrganizationDetail(
                    id as string
                );
                const rawData = response.data as OrganizationRecord;

                setOrigFormData(rawData);
                setFormData(cloneDeep(rawData));

                setRecordInfo({
                    name: `${rawData.General.organizationName}`,
                    id: `TIN: ${rawData.General.tin}`,
                });

                breadcrumbTrail.labels.push(rawData.General.organizationName);
            } else {
                breadcrumbTrail.labels.push('Add Practice');
                setCreateMode(true);
            }
            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });
        })();

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [props.ParentPageLink, setBreadcrumbData, props.location.search]);

    const getSidebarElements = () => {
        return [
            { title: 'General Info', hash: 'general' },
            {
                title: props.addContactsTitle || OrganizationModuleConstants.AddContactsTitle,
                hash: 'contacts',
            },
        ];
    };

    const onAddItemClick: (event: any) => void = () => {
        setContactsDialogOpen(!contactsDialogOpen);
    };

    const getSchema = (): JSONSchema6 => {
        return {
            type: 'object',
            properties: {
                General: getGeneralSchema('General Information'),
                Contacts: getContactsSchema(props.addContactsTitle, props.contactList),
            },
        };
    };

    const onDeleteContact = (_e: any, id: number) => {
        const contacts = Object.assign([], formData.Contacts);
        contacts.splice(id, 1);
        setFormData({ ...formData, Contacts: contacts });
        setHasChange(true);
    };

    const onEditContact = (e: any, id: number) => {
        setActiveItem(e);
        setActiveItemId(id);
        setContactsDialogOpen(true);
    };

    const rowActions: RowAction[] = [
        {
            id: `contact-edit`,
            label: '',
            behavior: 'Custom',
            intent: Intent.PRIMARY,
            link: {},
            icon: 'edit',
            callbackOnCustom: onEditContact,
        },
        {
            id: `contact-remove`,
            label: '',
            behavior: 'Delete',
            intent: Intent.DANGER,
            link: {},
            icon: 'remove',
            callbackOnDelete: onDeleteContact,
        },
    ];

    const getUISchema = () => {
        return {
            General: getGeneralUISchema(editMode, createMode),
            Contacts: getContactsUISchema(
                editMode,
                createMode,
                onAddItemClick,
                rowActions,
                props.addContactButtonLabel,
                props.addContactsTitle,
                props.contactList
            ),
        };
    };

    const editToggle = () => {
        const toggleEditMode = () => {
            setEditMode(!editMode);
            setFormData(origFormData);

            console.log('formData', formData);
        };

        if (createMode) {
            if (hasChange) {
                setConfirmationDialogOpen(true);
            } else {
                props.history.goBack();
            }
        } else if (editMode && hasChange) {
            setConfirmationDialogOpen(true);
        } else {
            toggleEditMode();
        }
    };

    const onChange = (e: any) => {
        setHasChange(true);
        setFormData(e.formData);
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };

    const showToastError = (errorTitle: string, errorMessage: string) => {
        showToast({
            message: (
                <div>
                    <p style={{ fontWeight: 'bold' }}>{errorTitle}</p>
                    <p>{errorMessage}</p>
                </div>
            ),
            intent: Intent.DANGER,
            icon: 'error',
        });
    };

    const onSubmit = () => {
        console.log('submitted', formData);
        setSavingRecord(true);
        setExtraErrors(undefined);

        const submitConfig = {
            organizationApi: createMode
                ? Api.organizationManagement.createOrganization
                : Api.organizationManagement.updateOrganization,
            successTitle: createMode
                ? 'New Record Successfully Added'
                : 'Changes successfully saved',
            errorTitle: 'Error Occured!',
            errorMessage: 'Oops! Looks like something went wrong. Please try again.',
            timeout: 5000,
        };

        submitConfig
            .organizationApi(formData)
            .then((response: any) => {
                const toast = Toaster.create({ position: Position.BOTTOM_RIGHT });
                if (response.ok) {
                    if (response.data.noChangesNeeded) {
                        showToast({
                            message: (
                                <div>
                                    <p style={{ fontWeight: 'bold' }}>
                                        {response.data.noChangesMessageTitle}
                                    </p>
                                    <p>{response.data.noChangesMessage}</p>
                                </div>
                            ),
                            intent: Intent.SUCCESS,
                            icon: 'tick-circle',
                        });
                        setHasChange(false);
                        setCreateMode(false);
                        setEditMode(false);
                    } else if (response.data.success) {
                        toast.show({
                            timeout: submitConfig.timeout,
                            message: (
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {submitConfig.successTitle}
                                    </span>
                                </p>
                            ),
                            intent: Intent.SUCCESS,
                            icon: 'tick-circle',
                        });
                        setFormData({
                            ...formData,
                            id: response.data.success.providerId,
                            taxAffiliationId: response.data.success.taxAffiliationId,
                        });

                        setOrigFormData({
                            ...formData,
                            id: response.data.success.providerId,
                            taxAffiliationId: response.data.success.taxAffiliationId,
                        });
                        setHasChange(false);
                        setCreateMode(false);
                        setEditMode(false);
                        if (response.data.success.providerId) {
                            props.history.replace(
                                `${props.history.location.pathname}?id=${encodeURIComponent(
                                    response.data.success.providerId
                                )}`
                            );
                        }
                    } else {
                        // Show generic error message
                        showToastError(submitConfig.errorTitle, submitConfig.errorMessage);
                    }
                } else {
                    // Set duplicate Org ID error content
                    if (
                        response.originalResponseObject &&
                        response.originalResponseObject.status === 409
                    ) {
                        const [errorTitle, errorContent, field] = response.data.split('|');

                        submitConfig.errorTitle = errorTitle;
                        submitConfig.errorMessage = errorContent;

                        if (field === 'id') {
                            setExtraErrors({
                                General: {
                                    organizationId: {
                                        __errors: ['Please enter a different Practice ID'],
                                    },
                                },
                            });
                        }
                    }
                    showToastError(submitConfig.errorTitle, submitConfig.errorMessage);
                }
                setSavingRecord(false);
            })
            .catch((error: any) => {
                console.error(error);
                setSavingRecord(false);
            });
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setKey(Date.now());
        setEditMode(!editMode);
        setFormData(origFormData);
        setHasChange(false);
        setConfirmationDialogOpen(false);
        if (createMode) {
            props.history.goBack();
        }
    };

    const getValidators = () => {
        const validators: any[] = [
            {
                field: 'General.organizationName',
                validator: validateOrganizationName,
                errorMessage: 'This value is required',
            },
            {
                field: 'General.organizationId',
                validator: validateOrganizationID,
                errorMessage: 'This value is required',
            },
            {
                field: 'General.tin',
                validator: validateTIN,
                errorMessage: 'TIN must be 9 digits in the form “XXXXXXXXX”',
            },
            {
                field: 'General.address',
                validator: validateAddress,
                errorMessage: 'This value is required',
            },
            {
                field: 'General.city',
                validator: validateCity,
                errorMessage: 'This value is required',
            },
            {
                field: 'General.state',
                validator: validateState,
                errorMessage: 'Select a state',
            },
            {
                field: 'General.zipCode',
                validator: validateZipCode,
                errorMessage:
                    'Zip code must be 5 or 9 digits in the form of "99999" or "99999-9999"',
            },
        ];

        return validators;
    };

    const handleContactsDialogClose = () => {
        setActiveItem(null);
        setContactsDialogOpen(false);
    };

    const handleContactsDialogOkay = (e: any, editedItem: any) => {
        const contacts = Object.assign([], formData.Contacts) as any;

        if (editedItem) {
            // user is editing existing contact, replace old entry with updated entry
            contacts[activeItemId] = e.formData;
            setHasChange(true);
            setFormData({ ...formData, Contacts: contacts });
        } else {
            // user is adding contact, check for identical contact entry
            const existing = contacts.map((row: any) => {
                if (isEqual(row, e.formData)) {
                    return true;
                }
                return false;
            });

            if (!existing.includes(true)) {
                contacts.push(e.formData);
                setFormData({ ...formData, Contacts: contacts });
                setHasChange(true);
            }
        }

        setActiveItem(null);
        setContactsDialogOpen(false);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            {confirmationDialogOpen && (
                <ConfirmExitDialog
                    isOpen={confirmationDialogOpen}
                    handleClose={handleConfirmationDialogClose}
                    handleOkay={handleConfirmationDialogOkay}
                />
            )}
            {contactsDialogOpen && (
                <ContactsDialog
                    isOpen={contactsDialogOpen}
                    handleClose={handleContactsDialogClose}
                    handleOkay={handleContactsDialogOkay}
                    activeItem={activeItem}
                />
            )}
            <Form
                key={key}
                recordId={recordInfo.id}
                recordName={recordInfo.name}
                sidebarElements={getSidebarElements()}
                schema={getSchema()}
                uiSchema={getUISchema()}
                validators={getValidators()}
                onChange={(e: any) => {
                    onChange(e);
                }}
                formData={editMode || createMode ? formData : origFormData}
                onError={onError}
                onSubmit={onSubmit}
                extraErrors={extraErrors}
            >
                <FormHeader
                    title={createMode ? 'Add Practice Record' : props.name || 'Practice Record'}
                    editMode={editMode || createMode}
                    allowEditing
                    allowCancel={!savingRecord}
                    allowSave={hasChange && !savingRecord}
                    enableEditMode={() => editToggle()}
                    disableEditMode={() => editToggle()}
                />
                {createMode && (
                    <FormFooter
                        title="Add Practice Record"
                        editMode={editMode || createMode}
                        allowEditing
                        allowSave={hasChange && !savingRecord}
                        enableEditMode={() => editToggle()}
                        disableEditMode={() => editToggle()}
                    />
                )}
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(OrganizationDetails);
