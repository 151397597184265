import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getEmergencyContactSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'object',
        title: title || 'Emergency Contact',
        properties: {
            name: { type: 'string', title: 'Full Name' },
            relationship: { type: 'string', title: 'Relationship to Patient' },
            email: { type: 'string', title: 'Email Address' },
            contactNumber: { type: 'string', title: 'Contact Number' },
        },
    };
};

export const getEmergencyContactSectionUISchema = (
    sectionProps: PatientDetailSection
): UiSchema => {
    const emptyListMessage = {
        title: 'No Emergency Contacts Found',
        description: '',
    };

    return {
        'ui:field': 'layout',
        'ui:hash': 'general',
        'ui:options': {
            itemsPerPage: sectionProps.itemsPerPage as number,
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        'ui:layout': [
            {
                name: { sm: 12, md: 6, lg: 4, xl: 3 },
                relationship: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
            {
                email: { sm: 12, md: 6, lg: 4, xl: 3 },
                contactNumber: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
        ],
        name: {
            'ui:widget': 'LabelWidget',
        },
        relationship: {
            'ui:widget': 'LabelWidget',
        },
        email: {
            'ui:widget': 'LabelWidget',
        },
        contactNumber: {
            'ui:widget': 'LabelWidget',
        },
    };
};
