/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { style } from 'typestyle';
import { observer } from 'mobx-react';
import HelpButton from '../components/HelpButton';

const titleWrapper = style({
    marginLeft: '4px',
    paddingLeft: '3px',
    marginBottom: '5px',
});

interface WidgetTitleFieldProps {
    id: string;
    title?: string;
    required: boolean;
    hash?: string;
    help?: React.ReactNode;
}

const helpButtonClassName = style({
    marginLeft: '8px',
    display: 'inline-block',
});

@observer
class WidgetTitleField extends React.Component<WidgetTitleFieldProps> {
    render() {
        const { title, id, required, help } = this.props;
        return (
            <div id={id} className={titleWrapper}>
                <label className="bp3-label" style={{ fontWeight: 600, marginBottom: 0 }}>
                    {required ? `${title} *` : `${title}`}
                    {help && <HelpButton className={helpButtonClassName} help={help} />}
                </label>
            </div>
        );
    }
}

export default WidgetTitleField;
