import React from 'react';
import { useBreadcrumbContext } from './BreadcrumbContext';

export default function withBreadcrumbs(Child: any) {
    const BreadcrumbBar = (props: any) => {
        const { setBreadcrumbData } = useBreadcrumbContext();
        return <Child {...props} setBreadcrumbData={setBreadcrumbData} />;
    };
    return BreadcrumbBar;
}
