export function validateOrganizationName(name: string) {
    if (!name) return false;
    return true;
}

export function validateOrganizationID(id: string) {
    if (!id) return false;
    return true;
}

export function validateTIN(tin: string) {
    if (!tin) return false;
    return tin.length === 9;
}
