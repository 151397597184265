import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { JSONSchema6 } from 'json-schema';
import { JSONPath } from 'jsonpath-plus';
import Heading from '../heading/Heading';
import DialogFormActions from '../../components/forms/components/DialogFormActions';
import DialogForm from '../../components/forms/DialogForm';
import SuggestWidget, { Item } from '../../components/forms/widgets/SuggestWidget';
import DateRangeWidget from '../../components/forms/widgets/DateRangeWidget';
import LabelWidget from '../../components/forms/widgets/LabelWidget';
import { validateAffiliatedPracticeName, validateDateRange } from './ClinicianValidation';
import { OrganizationRecord } from '../../../server/routes/ClinicianManagement/OrganizationApi';

const ORGANIZATION_TIN_DEFINITION = `http://arbormetrix.com/fhir/namingsystem/organization-tin`;

function AffiliatedOrganizationDialog(props: any) {
    const {
        activeAffiliatedPractice,
        isOpen,
        handleClose,
        handleOkay,
        organizations,
        unfilteredOrganizations,
    } = props;
    const defaultFormData = { status: true };
    const initialData = activeAffiliatedPractice
        ? {
              id: activeAffiliatedPractice.id,
              name: activeAffiliatedPractice.name.props.value,
              dateRange: activeAffiliatedPractice.dateRange,
              organizationReference: activeAffiliatedPractice.organizationReference,
              status: activeAffiliatedPractice.status.props.value,
              tin: activeAffiliatedPractice.tin,
          }
        : { ...defaultFormData };
    const [formData, setFormData] = useState<Record<string, any>>(defaultFormData);
    const [hasChange, setHasChange] = useState(false);
    const [organizationItems, setOrganizationItems] = useState<any[]>([]);

    useEffect(() => {
        if (organizations) {
            const items: Item[] = organizations.map((organization: OrganizationRecord) => {
                return {
                    itemName: organization.name,
                    itemID: organization.id,
                };
            });
            if (activeAffiliatedPractice) {
                items.push({
                    itemName: activeAffiliatedPractice.name.props.value,
                    itemID: activeAffiliatedPractice.id,
                });
            }
            setOrganizationItems(items);
        }
        setFormData(initialData);
        setHasChange(false);
    }, [activeAffiliatedPractice, organizations]);

    const resetToBlankState = () => {
        setHasChange(false);
        setFormData(defaultFormData);
    };
    const closeAction = () => {
        resetToBlankState();
        handleClose();
    };

    const onChange = (e: any) => {
        if (!hasChange) {
            setHasChange(JSON.stringify(e.formData) !== JSON.stringify(formData));
        }
        let newOrganizationName = JSONPath({
            path: `$.formData.name.itemName`,
            json: e,
        })[0];
        let newOrganizationID = JSONPath({
            path: `$.formData.name.itemID`,
            json: e,
        })[0];
        if (!newOrganizationName) {
            // eslint-disable-next-line prefer-destructuring
            newOrganizationName = JSONPath({
                path: `$.formData.name`,
                json: e,
            })[0];
        }
        if (!newOrganizationID) {
            // eslint-disable-next-line prefer-destructuring
            newOrganizationID = JSONPath({
                path: `$.formData.organizationReference`,
                json: e,
            })[0];
        }
        const orgInfo = unfilteredOrganizations.filter(
            (org: any) => org.organizationReference === newOrganizationID
        );
        let tin = JSONPath({
            path: `$.[0].BusinessOrganizationList[*].identifier[?(@.system === "${ORGANIZATION_TIN_DEFINITION}")].value`,
            json: orgInfo,
        })[0];
        if (!tin) {
            // todo - Remove temporary code after MIPS-69
            // eslint-disable-next-line prefer-destructuring
            tin = JSONPath({
                path: `$.[0].identifier[?(@.system === "${ORGANIZATION_TIN_DEFINITION}")].value`,
                json: orgInfo,
            })[0];
        }

        const data = {
            ...orgInfo[0],
            ...e.formData,
            organizationReference: newOrganizationID,
            name: newOrganizationName,
            tin,
        };
        setFormData(data);
    };

    const getSchema = () => {
        const schema: JSONSchema6 = {
            type: 'object',
            required: ['name'],
            properties: {
                status: {
                    title: 'Status',
                    type: 'boolean',
                    default: true,
                },
                name: {
                    title: 'Practice Name',
                    type: 'string',
                    enum: organizationItems,
                },
                tin: {
                    title: 'TIN',
                    type: 'string',
                },
                dateRange: { type: 'string', title: 'Date Range' },
            },
        };
        return schema;
    };

    const getUiSchema = () => {
        const uiSchema: any = {
            'ui:field': 'layout',
            'ui:layout': [
                {
                    status: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
                {
                    name: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
                {
                    tin: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
                {
                    dateRange: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
            ],
            status: {
                'ui:widget': 'SwitchWidget',
            },
            name: {
                'ui:widget': 'SuggestWidget',
            },
            tin: {
                'ui:widget': 'LabelWidget',
            },
            dateRange: {
                'ui:widget': 'DateRangeWidget',
                'ui:options': {
                    helpText: 'Enter the clinician’s start and end date at this practice',
                },
            },
        };
        return uiSchema;
    };

    const getValidators = () => {
        const validators = [
            {
                field: 'name',
                validator: validateAffiliatedPracticeName,
                errorMessage: 'Please select a practice',
            },
            {
                field: 'dateRange',
                validator: validateDateRange,
                errorMessage: 'The end date must be greater than or equal to the start date',
            },
        ];

        return validators;
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };

    const onSubmit = () => {
        handleOkay({
            ...formData,
            originalOrganizationReference: initialData && initialData.organizationReference,
        });
        setFormData(defaultFormData);
    };

    return isOpen ? (
        <Dialog
            onClose={() => {
                closeAction();
            }}
            isOpen={isOpen}
            style={{ padding: '40px' }}
        >
            <div>
                <Heading
                    alignment="left"
                    text={
                        activeAffiliatedPractice
                            ? 'Edit Affiliated Practice'
                            : 'Add Affiliated Practice'
                    }
                />
                <DialogForm
                    schema={getSchema()}
                    formData={formData}
                    uiSchema={getUiSchema()}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onError={(e) => onError(e)}
                    widgets={{
                        DateRangeWidget,
                        LabelWidget,
                        SuggestWidget,
                    }}
                    validators={getValidators()}
                    minHeight="132px"
                    minWidth="384px"
                >
                    <DialogFormActions
                        addButtonIcon={activeAffiliatedPractice ? 'tick' : 'add'}
                        onCancel={() => {
                            closeAction();
                        }}
                        formHasChange={hasChange}
                        addButtonText={activeAffiliatedPractice ? 'Update' : 'Add'}
                    />
                </DialogForm>
            </div>
        </Dialog>
    ) : null;
}

export default AffiliatedOrganizationDialog;
