import React from 'react';
import { FieldProps } from 'react-jsonschema-form';

import { Column, Row } from '@amxjs/ui';
import { JSONSchema6 } from 'json-schema';
import { classes, style } from 'typestyle';
import TitleField from './TitleField';

const getRowClasses = (uiOptions: any) => {
    return classes('row', style({ maxWidth: uiOptions?.maxRowWidth || 'none' }));
};

export default class LayoutField extends React.Component<FieldProps> {
    static isFilled = (fieldName: string | number) => (formData: any) =>
        !!(formData[fieldName] && formData[fieldName].length);

    static isTrue = (fieldName: string | number) => (formData: any) => formData[fieldName];

    funcs = {
        isFilled: LayoutField.isFilled,
        isTrue: LayoutField.isTrue,
    };

    onPropertyChange(name: string, addedByAdditionalProperties = false) {
        return (value: string, errorSchema: any) => {
            let v = value;
            if (!value && addedByAdditionalProperties) {
                // Don't set value = undefined for fields added by
                // additionalProperties. Doing so removes them from the
                // formData, which causes them to completely disappear
                // (including the input field for the property name). Unlike
                // fields which are "mandated" by the schema, these fields can
                // be set to undefined by clicking a "delete field" button, so
                // set empty values to the empty string.
                v = '';
            }
            const newFormData = { ...this.props.formData, [name]: v };
            this.props.onChange(
                newFormData,
                errorSchema &&
                    this.props.errorSchema && {
                        ...this.props.errorSchema,
                        [name]: errorSchema,
                    }
            );
        };
    }

    private doShow(doShow: any, formData: any) {
        if (doShow) {
            const [func, fieldName] = doShow.split('|');
            const doShowFn = this.funcs[func as 'isFilled' | 'isTrue'](fieldName);
            if (!doShowFn(formData)) {
                return false;
            }
        }
        return true;
    }

    isRequired(name: string) {
        const { schema } = this.props;
        return Array.isArray(schema.required) && schema.required.indexOf(name) !== -1;
    }

    render() {
        const {
            DescriptionField,
            description,
            title,
            required,
            schema,
            uiSchema,
            idSchema,
            errorSchema,
            formData,
            registry,
            formContext,
        } = this.props;

        const { SchemaField } = registry.fields;

        const layout = uiSchema['ui:layout'];

        const layoutTitle = title || schema.title;

        return (
            <fieldset>
                {layoutTitle ? (
                    <TitleField
                        id={this.props.name}
                        title={layoutTitle}
                        required={required}
                        hash={uiSchema['ui:hash']}
                        options={uiSchema['ui:options']}
                        formData={formData}
                        // formContext={formContext}
                    />
                ) : null}
                {schema.description ? (
                    <DescriptionField
                        id={`${idSchema.$id}__description`}
                        description={description}
                        formContext={formContext}
                    />
                ) : null}
                {layout.map((row: any) => {
                    return (
                        <div
                            className={getRowClasses(uiSchema['ui:options'])}
                            key={Object.keys(row)[0]}
                        >
                            <Row>
                                {Object.keys(row).map((name) => {
                                    const { doShow, ...rowProps } = row[name];
                                    const toShow = this.doShow(doShow, formData);
                                    if (schema.properties && schema.properties[name]) {
                                        return (
                                            <Column {...rowProps} key={name}>
                                                {toShow && (
                                                    <SchemaField
                                                        {...this.props}
                                                        schema={
                                                            schema.properties[name] as JSONSchema6
                                                        }
                                                        uiSchema={uiSchema[name]}
                                                        errorSchema={errorSchema[name]}
                                                        idSchema={idSchema[name]}
                                                        formData={
                                                            formData && formData[name]
                                                                ? formData[name]
                                                                : undefined
                                                        }
                                                        onChange={this.onPropertyChange(name)}
                                                        required={this.isRequired(name)}
                                                    />
                                                )}
                                            </Column>
                                        );
                                    }
                                    return (
                                        <div key={name}>
                                            <h3>Unsupported Field: {name}!</h3>
                                        </div>
                                    );
                                })}
                            </Row>
                        </div>
                    );
                })}
            </fieldset>
        );
    }
}
