export function validateAffiliatedPracticeName(practiceName: string) {
    if (!practiceName) return false;
    return true;
}

// Validates the date range field - if start date or end date is present, ensure
// start date is before the end date
export function validateDateRange(dateRange: Record<string, string>) {
    if (!dateRange) return true; // empty date range is valid
    if (Date.parse(dateRange.endDate) < Date.parse(dateRange.startDate)) return false;
    return true;
}

// Validates the NPI field - It must be 10 digits, and start with a 1 or 2
export function validateNpi(npi: string | undefined) {
    if (!npi) return false;

    // Checks length and that the string starts with 1 or 2
    if (npi.length !== 10 || (npi.slice(0, 1) !== '1' && npi.slice(0, 1) !== '2')) return false;

    // Checks that all 10 characters are numeric
    const regex = new RegExp(/^\d{10}$/, 'g');
    return regex.test(npi);
}

// Validates that there is at least 1 non-deleted affiliated organizations chosen for a clinician
export function validateAffiliatedOrganizations(organizationRecords: any | undefined) {
    if (!organizationRecords || organizationRecords.length === 0) {
        return false;
    }

    let hasNonDeletedOrganization = false;

    organizationRecords.forEach((organizationRecord: any) => {
        if (!organizationRecord.organizationRecordDeleted) {
            hasNonDeletedOrganization = true;
        }
    });

    return hasNonDeletedOrganization;
}

export function validateOrganizationName(organizationName: any | undefined) {
    return organizationName;
}
