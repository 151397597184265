/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Button, Classes, H5, Intent, Popover } from '@blueprintjs/core';

interface DialogFormActionsProps {
    onCancel: () => void;
    addButtonText?: string;
    formHasChange: boolean;
    addButtonIcon?: any;
    disabled?: boolean;
    cancelButtonText?: string;
    actionClassStyle?: boolean;
    isAddButtonDisabled?: boolean;
}

interface DialogFormActionsState {
    isOpen: boolean;
}

class DialogFormActions extends Component<DialogFormActionsProps, DialogFormActionsState> {
    constructor(props: DialogFormActionsProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    shouldOpenPopover() {
        if (this.props.formHasChange) {
            this.setState({
                isOpen: true,
            });
        } else {
            this.props.onCancel();
        }
    }

    handlePopoverCancel() {
        this.setState({
            isOpen: false,
        });
    }

    handleClose() {
        this.setState({
            isOpen: false,
        });

        this.props.onCancel();
    }

    render() {
        return (
            <div
                className={Classes.DIALOG_FOOTER}
                style={
                    // eslint-disable-next-line no-undef
                    this.props.actionClassStyle
                        ? { textAlign: 'right', marginRight: '0px', zIndex: 0 }
                        : { textAlign: 'right', marginRight: '0px', marginTop: '20px', zIndex: 0 }
                }
            >
                <Popover className={Classes.POPOVER} isOpen={this.state.isOpen}>
                    <Button
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                        onClick={() => this.shouldOpenPopover()}
                    >
                        {this.props.cancelButtonText || 'Cancel'}
                    </Button>
                    <div style={{ padding: '15px', backgroundColor: 'white' }}>
                        <H5>Are you sure you want to cancel?</H5>
                        <p>You will lose all the data you have entered.</p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '15px',
                            }}
                        >
                            <Button
                                className={Classes.POPOVER_DISMISS}
                                style={{ marginRight: 10 }}
                                onClick={() => this.handlePopoverCancel()}
                                data-automation-hook="(ActionBar)-dialog-action-cancel-go-back"
                            >
                                No, go back
                            </Button>
                            <Button
                                intent={Intent.DANGER}
                                className={Classes.POPOVER_DISMISS}
                                onClick={() => this.handleClose()}
                                data-automation-hook="(ActionBar)-dialog-action-cancel-confirm"
                            >
                                Yes, cancel
                            </Button>
                        </div>
                    </div>
                </Popover>
                <div style={{ paddingLeft: '12px', display: 'inline-block' }} />
                {this.props.isAddButtonDisabled ? null : (
                    <Button
                        disabled={this.props.disabled}
                        icon={this.props.addButtonIcon}
                        type="submit"
                        intent="primary"
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                    >
                        {this.props.addButtonText}
                    </Button>
                )}
            </div>
        );
    }
}

export default DialogFormActions;
