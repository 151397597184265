import ActionButtonWidget from './ActionButtonWidget';
import ArrayLabelWidget from './ArrayLabelWidget';
import CheckboxWidget from './CheckboxWidget';
import CheckboxesWidget from './CheckboxesWidget';
import DateTimeWidget from './DateTimeWidget';
import DateWidget from './DateWidget';
import EmailWidget from './EmailWidget';
import FileUploadWidget from './FileUploadWidget';
import FractionWidget from './FractionWidget';
import HTMLWidget from './HTMLWidget';
import LabelWidget from './LabelWidget';
import PasswordWidget from './PasswordWidget';
import RadioWidget from './RadioWidget';
// import RangeWidget from './RangeWidget';
import SelectWidget from './SelectWidget';
import TextareaWidget from './TextareaWidget';
import TextWidget from './TextWidget';
import UpDownWidget from './UpDownWidget';
import SwitchWidget from './SwitchWidget';
import MultiSelectWidget from './MultiSelectWidget';
import TagWidget from './TagWidget';
import SimpleTextWidget from './SimpleTextWidget';
import SuggestWidget from './SuggestWidget';
import StateWidget from './StateWidget';
import OptInSelectionWidget from './OptInSelectionWidget';
import URLWidget from './URLWidget';
import ErrorLabelWidget from './ErrorLabelWidget';
import ProgressBarWidget from './ProgressBarWidget';

export default {
    ActionButtonWidget,
    ArrayLabelWidget,
    CheckboxWidget,
    CheckboxesWidget,
    DateTimeWidget,
    DateWidget,
    EmailWidget, // same as TextWidget -- Widget level component to be implemented in amxjs/ui
    FileUploadWidget,
    FractionWidget,
    HTMLWidget,
    LabelWidget,
    ErrorLabelWidget,
    PasswordWidget,
    MultiSelectWidget,
    RadioWidget,
    // RangeWidget, // to be implemented
    SelectWidget,
    SimpleTextWidget,
    StateWidget,
    SuggestWidget,
    SwitchWidget,
    tag: TagWidget,
    TextareaWidget,
    TextWidget,
    UpDownWidget,
    OptInSelectionWidget,
    URLWidget,
    ProgressBarWidget,
};
