import React, { Fragment } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { style } from 'typestyle';
import WidgetTitleField from '../fields/WidgetTitleField';
import formatDate from '../../../util/GeneralUtils';

interface LabelWidgetProps extends WidgetProps {
    label: string;
}

const fieldClassName = (
    skipBottomPadding: boolean,
    skipTopPadding: boolean,
    skipBottomMargin?: boolean
) =>
    style({
        position: 'relative',
        paddingTop: skipTopPadding ? '0' : '4px',
        paddingBottom: skipBottomPadding ? '0' : '18px',
        display: 'block',
        marginBottom: skipBottomMargin ? '-50px' : '',
    });

const labelClassName = (grayText: boolean, overflowBreakWord?: boolean) =>
    style({
        display: 'block',
        margin: '16px 0 15px 4px',
        fontSize: '16px',
        paddingLeft: '3px',
        color: grayText ? '#5C7080' : '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        overflowWrap: overflowBreakWord ? 'break-word' : 'normal',
    });
class LabelWidget extends React.Component<LabelWidgetProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { label, value, required, id, options, schema } = this.props;

        const titleId = `${id}__title`;

        // Change the passed in value for booleans into an 'Active'/'Inactive' string to display as the label
        // Default no value to 'N/A' and set it to gray
        let displayValue;
        let grayText = false;
        if (schema.type === 'boolean') {
            if (value === true) {
                displayValue = options.booleanYesNo ? 'Yes' : 'Active';
            } else {
                displayValue = options.booleanYesNo ? 'No' : 'Inactive';
            }
        } else if (schema.type === 'number') {
            if (value) {
                displayValue = value.toString();
            } else {
                displayValue = 0;
            }
        } else if (options.objectFieldName) {
            if (Array.isArray(value)) {
                displayValue = value
                    .map((val: any) => {
                        return val[options.objectFieldName as string];
                    })
                    .join(', ');
            } else {
                displayValue = value[options.objectFieldName as string];
            }
        } else if (value && value.length > 0) {
            if (options.renderMode === 'date') {
                displayValue = formatDate(value);
            } else {
                displayValue = value;
            }
        } else if (value && typeof value === 'object' && options.inLine) {
            displayValue = value;
        } else {
            displayValue = 'N/A';
            grayText = true;
        }

        return (
            <div
                className={fieldClassName(
                    options.skipBottomPadding as boolean,
                    options.skipTopPadding as boolean,
                    options.inLine as boolean
                )}
            >
                {/* eslint-disable-next-line no-nested-ternary */}
                {options.inLine ? null : label ? (
                    <WidgetTitleField
                        required={required}
                        title={label}
                        id={titleId}
                        help={this.props.options.helpText}
                    />
                ) : null}
                <span
                    className={labelClassName(grayText, options.overflowBreakWord as boolean)}
                    title={displayValue + (options.unit ? ` ${options.unit}` : '')}
                >
                    {typeof displayValue === 'object' && !options.inLine
                        ? displayValue.map((val: string, index: number) => {
                              // eslint-disable-next-line react/no-array-index-key
                              return <div key={index}>{val}</div>;
                          })
                        : displayValue}
                    {options.unit ? <Fragment>&nbsp;{options.unit}</Fragment> : null}
                </span>
            </div>
        );
    }
}

export default LabelWidget;
