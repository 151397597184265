import React, { useEffect, useState, PropsWithChildren } from 'react';
import { FormProps as RjsfFormProps } from 'react-jsonschema-form';
import Sticky from 'react-sticky-el';

import { H6, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import AmxThemedForm from './AmxThemedForm';

interface FormProps<T> extends RjsfFormProps<T> {
    recordName: string | JSX.Element;
    recordText?: string | any[] | JSX.Element;
    recordId: string | JSX.Element;
    sidebarElements: {
        title: string;
        hash: string;
    }[];
    hideSidebar?: boolean;
    validators?: {
        field: string;
        validator: Function;
        errorMessage: string;
        callbackOnFail?: () => void;
    }[];
    extraErrors?: object;
    // If set this will allow the enter key to submitting the form data during normal form input
    // This still allows the submit/save button to be focused via tab and enter to submit the form
    // By default the enter key will now allow the form to be submitted when not focused on the submit button
    allowEnterToSubmit?: boolean;
    primary?: boolean;
}

const headerObserverOptions = {
    root: null,
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
};
const observerOptions = {
    root: null,
    rootMargin: navigator.userAgent.includes('Windows') ? `-59% 0px -40% 0px` : `-49% 0px -50% 0px`,
};
const footerObserverOptions = {
    root: null,
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
};
const { body, documentElement } = document;
const docHeight = Math.max(
    body.scrollHeight,
    documentElement.scrollHeight,
    body.offsetHeight,
    documentElement.offsetHeight,
    body.clientHeight,
    documentElement.clientHeight
);

const Form = <T,>(props: PropsWithChildren<FormProps<T>>) => {
    const { recordName, recordId, sidebarElements, recordText } = props;
    const [formSections, setFormSections] = useState<Element[]>([]);
    const [focusedSection, setFocusedSection] = useState<string>('');
    const [observerDelay, setObserverDelay] = useState(false);
    const [updatedFocus, setUpdatedFocus] = useState('');
    const [isWatchingSections, setIsWatchingSections] = useState(false);
    const [innerWindowHeight, setInnerWindowHeight] = useState<number>(2);
    const [firstSection, setFirstSection] = useState<string>();
    const [lastSection, setLastSection] = useState<string>();
    const [footerIntersection, setFooterIntersection] = useState(0);
    const [headerElement, setHeaderElement] = useState<Element>();
    const [footerElement, setFooterElement] = useState<Element>();
    const [headerObserver] = useState(
        new IntersectionObserver(() => {
            setUpdatedFocus('header');
        }, headerObserverOptions)
    );
    const [observer] = useState(
        new IntersectionObserver((entries: any) => {
            setUpdatedFocus(entries[0].target.attributes.hash.value);
        }, observerOptions)
    );
    const [footerObserver] = useState(
        new IntersectionObserver((entries: any) => {
            setFooterIntersection(entries[0].intersectionRatio);
            setUpdatedFocus('footer');
        }, footerObserverOptions)
    );

    useEffect(() => {
        if (isWatchingSections) {
            formSections.forEach((section: Element) => {
                observer.observe(section);
            });
            if (headerElement) {
                headerObserver.observe(headerElement);
            }
            if (footerElement) {
                footerObserver.observe(footerElement);
            }
        } else {
            setUpdatedFocus('');
            formSections.forEach((section: Element) => {
                observer.unobserve(section);
            });
            if (headerElement) {
                headerObserver.unobserve(headerElement);
            }
            if (footerElement) {
                footerObserver.unobserve(footerElement);
            }
        }
    }, [
        isWatchingSections,
        footerElement,
        footerObserver,
        formSections,
        headerElement,
        headerObserver,
        observer,
    ]);

    useEffect(() => {
        setInnerWindowHeight(window.innerHeight);
        window.addEventListener('resize', () => setInnerWindowHeight(window.innerHeight));
    }, []);

    useEffect(() => {
        setIsWatchingSections((prev) => innerWindowHeight < docHeight - 100 && !prev);
    }, [innerWindowHeight]);

    useEffect(() => {
        if (sidebarElements && sidebarElements.length >= 2) {
            const sections: any[] = [];
            sidebarElements.forEach((section: any, index: any) => {
                const sectionElement = document.querySelector(`#section-name-${section.hash}`);
                if (sectionElement) {
                    sectionElement.setAttribute('hash', section.hash);
                    sections.push(sectionElement);
                    if (index === 0) {
                        setFirstSection(section.hash);
                    }
                    if (index === sidebarElements.length - 1) {
                        setLastSection(section.hash);
                    }
                }
            });
            const header = document.querySelector('#header-menu-group');
            if (header) {
                setHeaderElement(header);
            }
            const footer = document.querySelector('#page-footer');
            if (footer) {
                setFooterElement(footer);
            }
            setFormSections(sections);
        }
    }, [sidebarElements]);

    useEffect(() => {
        if (observerDelay) {
            return;
        }
        if ((updatedFocus === 'header' || updatedFocus === '') && firstSection) {
            setFocusedSection(firstSection);
            return;
        }
        if (updatedFocus === 'footer' && lastSection && footerIntersection > 0.5) {
            setFocusedSection(lastSection);
            return;
        }
        if (
            updatedFocus &&
            updatedFocus !== '' &&
            updatedFocus !== 'footer' &&
            footerIntersection < 0.1
        ) {
            setFocusedSection(updatedFocus);
            return;
        }
        if (updatedFocus === '') {
            setFocusedSection('');
        }
    }, [updatedFocus, firstSection, footerIntersection, lastSection, observerDelay]);

    const getSidebarSectionClass = (menu: Record<string, string>) => {
        return menu.hash === focusedSection && isWatchingSections ? 'sidebar-section-focused' : '';
    };

    return (
        <div style={{ display: 'flex' }}>
            {!props.hideSidebar && (
                <div>
                    <Menu
                        style={{
                            backgroundColor: '#fafcfd',
                            marginLeft: '14px',
                            marginRight: '24px',
                            width: '300px',
                            height: '100%',
                            overflowWrap: 'break-word',
                            boxShadow: '0px 0px 1px #C7D5DF, 1px 0px 1px #D8E1E8',
                        }}
                    >
                        <Sticky className="record-identifier-name">
                            <H6 style={{ fontWeight: 'bold', marginLeft: '5px' }}>{recordName}</H6>
                            <p style={{ marginLeft: '5px' }}>{recordId}</p>
                            {recordText && Array.isArray(recordText) ? (
                                recordText.map((record) => (
                                    <p key={record} style={{ marginLeft: '5px' }}>
                                        {record}
                                    </p>
                                ))
                            ) : (
                                <p style={{ marginLeft: '5px' }}> {recordText}</p>
                            )}
                            {recordName || recordId || recordText ? (
                                <MenuDivider className="record-identifier-divider" />
                            ) : null}
                            {sidebarElements.map((menu) => {
                                return (
                                    <MenuItem
                                        className={getSidebarSectionClass(menu)}
                                        style={{ pointerEvents: observerDelay ? 'none' : 'auto' }}
                                        key={menu.title}
                                        text={menu.title}
                                        href={`#${menu.hash}`}
                                        onClick={() => {
                                            if (!observerDelay && isWatchingSections) {
                                                setFocusedSection(menu.hash);
                                                setObserverDelay(true);
                                                setTimeout(() => {
                                                    setObserverDelay(false);
                                                }, 1000);
                                            }
                                        }}
                                    />
                                );
                            })}
                        </Sticky>
                    </Menu>
                </div>
            )}
            <div
                style={{
                    paddingTop: '10px',
                    width: '100%',
                    height: '100%',
                }}
            >
                <AmxThemedForm
                    {...props}
                    className={props.primary ? 'amx-list-form' : 'amx-form-full-screen'}
                >
                    {props.children}
                </AmxThemedForm>
            </div>
        </div>
    );
};

export default Form;
