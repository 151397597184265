import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getQualityMeasuresSchema = (): JSONSchema6 => ({
    type: 'object',
    properties: {
        measureIsExcluded: {
            type: 'boolean',
            title: 'Included in Submission',
            enum: ['Included', 'Included'],
        },
        measureId: {
            type: 'string',
            title: 'Measure ID',
        },
        ecqmMeasureId: {
            type: 'string',
            title: 'eCQM ID',
        },
        measureTitle: {
            type: 'string',
            title: 'Title',
        },
        description: {
            type: 'string',
            title: 'Description',
        },
        metricType: {
            type: 'string',
            title: 'Metric Type',
        },
        measureType: {
            type: 'string',
            title: 'Measure Type',
        },
        overallAlgorithm: {
            type: 'string',
            title: 'Overall Algorithm Field',
        },
        score: {
            type: 'string',
            title: 'Score',
        },
        decile: {
            type: 'string',
            title: 'Decile',
        },
        startDate: {
            type: 'string',
            title: 'Start Date',
        },
        endDate: {
            type: 'string',
            title: 'End Date',
        },
        dataCompleteness: {
            type: 'string',
            title: 'Data Completeness',
        },
        dataCompletenessNumerator: {
            type: 'number',
            title: 'Reporting Numerator',
        },
        dataCompletenessDenominator: {
            type: 'number',
            title: 'Reporting Denominator',
        },
        performanceRate: {
            type: 'string',
            title: 'Performance Rate',
        },
        performanceRateNumerator: {
            type: 'number',
            title: 'Performance Numerator',
        },
        performanceRateDenominator: {
            type: 'number',
            title: 'Performance Denominator',
        },
        eligiblePopulation: {
            type: 'number',
            title: 'Eligible Population',
        },
        performanceMet: {
            type: 'number',
            title: 'Performance Met',
        },
        performanceNotMet: {
            type: 'number',
            title: 'Performance Not Met',
        },
        eligiblePopulationException: {
            type: 'number',
            title: 'Eligible Population Exception',
        },
        eligiblePopulationExclusion: {
            type: 'number',
            title: 'Eligible Population Exclusion',
        },
    },
});

export const getQualityMeasuresUISchema = (editMode: boolean): UiSchema => {
    const emptyListMessage = {
        title: 'No Quality Measures',
        description: 'Add patient data to see Quality Measures',
    };

    return {
        'ui:field': 'layout',
        'ui:hash': 'qualitymeasurecategoryscores',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        'ui:layout': [
            {
                measureIsExcluded: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                measureId: { sm: 12, md: 6, lg: 4, xl: 3 },
                ecqmMeasureId: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
            {
                measureTitle: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                metricType: { sm: 12, md: 6, lg: 4, xl: 3 },
                measureType: { sm: 12, md: 6, lg: 4, xl: 3 },
                overallAlgorithm: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
            {
                score: { sm: 12, md: 3, lg: 3, xl: 3 },
                decile: { sm: 12, md: 3, lg: 3, xl: 3 },
                startDate: { sm: 12, md: 3, lg: 3, xl: 3 },
                endDate: { sm: 12, md: 3, lg: 3, xl: 3 },
            },
            {
                dataCompleteness: { sm: 12, md: 4, lg: 4, xl: 4 },
                dataCompletenessNumerator: { sm: 12, md: 4, lg: 4, xl: 4 },
                dataCompletenessDenominator: { sm: 12, md: 4, lg: 4, xl: 4 },
            },
            {
                performanceRate: { sm: 12, md: 4, lg: 4, xl: 4 },
                performanceRateNumerator: { sm: 12, md: 4, lg: 4, xl: 4 },
                performanceRateDenominator: { sm: 12, md: 4, lg: 4, xl: 4 },
            },
            {
                eligiblePopulation: { sm: 12, md: 4, lg: 4, xl: 4 },
                performanceMet: { sm: 12, md: 4, lg: 4, xl: 4 },
                performanceNotMet: { sm: 12, md: 4, lg: 4, xl: 4 },
            },
            {
                eligiblePopulationException: { sm: 12, md: 6, lg: 4, xl: 3 },
                eligiblePopulationExclusion: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
        ],
        measureIsExcluded: {
            'ui:widget': 'SwitchWidget',
            'ui:readonly': !editMode,
        },

        measureId: {
            'ui:widget': 'LabelWidget',
        },
        ecqmMeasureId: {
            'ui:widget': 'LabelWidget',
        },
        measureTitle: {
            'ui:widget': 'LabelWidget',
        },
        description: {
            'ui:widget': 'LabelWidget',
        },
        metricType: {
            'ui:widget': 'LabelWidget',
        },
        measureType: {
            'ui:widget': 'LabelWidget',
        },
        overallAlgorithm: {
            'ui:widget': 'LabelWidget',
        },
        score: {
            'ui:widget': 'LabelWidget',
        },
        decile: {
            'ui:widget': 'LabelWidget',
        },
        startDate: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        endDate: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        dataCompleteness: {
            'ui:widget': 'LabelWidget',
        },
        dataCompletenessNumerator: {
            'ui:widget': 'LabelWidget',
        },
        dataCompletenessDenominator: {
            'ui:widget': 'LabelWidget',
        },
        performanceRate: {
            'ui:widget': 'LabelWidget',
        },
        performanceRateNumerator: {
            'ui:widget': 'LabelWidget',
        },
        performanceRateDenominator: {
            'ui:widget': 'LabelWidget',
        },
        eligiblePopulation: {
            'ui:widget': 'LabelWidget',
        },
        performanceMet: {
            'ui:widget': 'LabelWidget',
        },
        performanceNotMet: {
            'ui:widget': 'LabelWidget',
        },
        eligiblePopulationException: {
            'ui:widget': 'LabelWidget',
        },
        eligiblePopulationExclusion: {
            'ui:widget': 'LabelWidget',
        },
    };
};
