import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getMedicationsSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Medications',
        items: {
            type: 'object',
            properties: {
                status: {
                    type: 'string',
                    title: 'Status',
                },
                name: {
                    type: 'string',
                    title: 'Name',
                },
                dose: {
                    type: 'string',
                    title: 'Dose',
                },
                schedule: {
                    type: 'string',
                    title: 'Schedule',
                },
                startDate: {
                    type: 'string',
                    title: 'Start Date',
                },
                endDate: {
                    type: 'string',
                    title: 'End Date',
                },
            },
        },
    };
};

export const getMedicationsSectionUISchema = (sectionProps: PatientDetailSection): UiSchema => {
    const emptyListMessage = {
        title: 'No Medications Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'medications',
        'ui:options': {
            itemsPerPage: sectionProps.itemsPerPage as number,
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items: {
            status: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            name: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            dose: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            schedule: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            startDate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            endDate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
        },
    };
};
