import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import Store from '../core/Store';
import Pages from './Pages';
import Header from './header/Header';
import Footer from './footer/Footer';
import { SiteInfo } from '../../generator/generated/MeshInterfaces';
import ScrollToTop from './utils/ScrollToTop';
import { BreadcrumbContextProvider } from '../core/BreadcrumbContext';

interface MainPropsInterface extends RouteComponentProps<any> {
    store: Store;
    keycloak: any;
}

@observer
class Main extends Component<MainPropsInterface> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { keycloak, store } = this.props;
        if (store.siteConfiguration == null) return null;
        // These folders to be hard-coded into the root directory of the project
        const headerConfig = store.siteConfiguration.HeaderConfig;
        const footerConfig = store.siteConfiguration.FooterConfig;

        const siteInfoConfig: SiteInfo = get(
            this.props,
            'store.siteConfiguration.Configuration.siteInfo'
        );

        return (
            <div className="page">
                {siteInfoConfig && (
                    <Helmet>
                        <title>{siteInfoConfig.htmlTitleText}</title>
                        <link
                            rel="shortcut icon"
                            href={siteInfoConfig.favicon && siteInfoConfig.favicon.path}
                        />
                    </Helmet>
                )}
                {headerConfig && headerConfig.Header && (
                    <Header store={store} keycloak={keycloak} {...headerConfig} />
                )}
                <ScrollToTop />
                <BreadcrumbContextProvider>
                    <Pages store={store} />
                </BreadcrumbContextProvider>
                {footerConfig && footerConfig.Footer && (
                    <Footer store={store} {...footerConfig.Footer} />
                )}
            </div>
        );
    }
}

export default withRouter(Main);
