export function validateParticipationOption(option: any) {
    if (!option) return false;
    return true;
}

// This ID is optional, but if added it needs to have a length of 15
export function validateCehrtId(cehrtId: string) {
    if (!cehrtId) return true;
    const maskless = cehrtId.replace('_', '');
    if (maskless.length !== 15) return false;
    return true;
}
