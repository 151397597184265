export default function getPersonas(realmAccess: any) {
    if (realmAccess && realmAccess.roles) {
        const { roles } = realmAccess;
        if (Array.isArray(roles) && roles.length > 0) {
            return (roles as string[])
                .filter((role: string) => {
                    return role.startsWith('persona/');
                })
                .map((role: string) => {
                    return role.split('/')[1];
                });
        }
    }
    return [];
}
