/* eslint-disable react/prefer-stateless-function */
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { remove } from 'lodash';

import { PatientDetailSection, ControlledField } from '../../../generator/generated/MeshInterfaces';

export const getGeneralSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'object',
        title,
        properties: {
            mrn: { type: 'string', title: 'MRN' },
            firstName: { type: 'string', title: 'First Name' },
            middleName: { type: 'string', title: 'Middle Name' },
            lastName: { type: 'string', title: 'Last Name' },
            primaryEmail: { type: 'string', title: 'Primary Email Address' },
            secondaryEmail: { type: 'string', title: 'Secondary Email Address' },
            dateOfBirth: { type: 'string', title: 'Date of Birth' },
            sex: { type: 'string', title: 'Sex' },
            height: { type: 'string', title: 'Height' },
            weight: { type: 'number', title: 'Weight' },
            allergies: { type: 'string', title: 'Any Allergies to Medications or Food' },
            race: { type: 'string', title: 'Race' },
            ethnicity: { type: 'string', title: 'Hispanic Ethnicity' },
            city: { type: 'string', title: 'City' },
            state: { type: 'string', title: 'State' },
            zipCode: { type: 'string', title: 'Zip Code' },
            practice: { type: 'string', title: 'Practice' },
            primaryPayerType: { type: 'string', title: 'Primary Payer Type' },
        },
    };
};

export const getGeneralSectionUISchema = (
    sectionProps: PatientDetailSection,
    units: Record<string, string>
): UiSchema => {
    const visibleFields: Record<string, Record<string, any>> = {};
    const defaultLayout: Record<string, Record<string, number>>[] = [
        {
            mrn: { sm: 12, md: 12, lg: 12, xl: 12 },
        },
        {
            firstName: { sm: 12, md: 6, lg: 4, xl: 3 },
            middleName: { sm: 12, md: 6, lg: 4, xl: 3 },
            lastName: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            primaryEmail: { sm: 12, md: 6, lg: 4, xl: 3 },
            secondaryEmail: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            dateOfBirth: { sm: 12, md: 6, lg: 4, xl: 3 },
            sex: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            height: { sm: 12, md: 6, lg: 4, xl: 3 },
            weight: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            allergies: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            race: { sm: 12, md: 6, lg: 4, xl: 3 },
            ethnicity: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            city: { sm: 12, md: 6, lg: 4, xl: 3 },
            state: { sm: 12, md: 6, lg: 4, xl: 3 },
            zipCode: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
            practice: { sm: 12, md: 6, lg: 4, xl: 3 },
            primaryPayerType: { sm: 12, md: 6, lg: 4, xl: 3 },
        },
    ];

    if (sectionProps.controlledFields) {
        sectionProps.controlledFields.forEach((field: ControlledField) => {
            if (field.showField) {
                visibleFields[field.name] = {
                    'ui:widget': 'LabelWidget',
                    'ui:options': {
                        unit: units[field.name] ? units[field.name] : null,
                        renderMode: field.name === 'dateOfBirth' ? 'date' : 'default',
                    },
                };
            }
        });
    }

    const filteredLayout = defaultLayout.map((row) => {
        const filteredRow: Record<string, Record<string, number>> = {};
        Object.keys(row).forEach((field) => {
            if (visibleFields[field]) {
                filteredRow[field] = row[field];
            }
            return null;
        });
        return filteredRow;
    });

    remove(filteredLayout, (row) => {
        return Object.keys(row).length === 0;
    });

    return {
        'ui:field': 'layout',
        'ui:hash': 'general',
        'ui:layout': filteredLayout,
        ...visibleFields,
    };
};
