import React, { FC } from 'react';
import { style } from 'typestyle';

import { IconNames } from '@blueprintjs/icons';
import {
    AnchorButton,
    Colors,
    Icon,
    Intent,
    Text,
    ProgressBar as ProgressBarBJs,
    ProgressBarProps,
} from '@blueprintjs/core';

interface ExtendedProgressBarProps extends ProgressBarProps {
    description?: string;
    statusUploadDescription?: string;
    showRemovalButton?: boolean;
    showProgressBar?: boolean;
    actionRemovalButtonCallback?: any;
}

const baseStyle = {
    flex: 1,
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    transition: 'border-color .24s ease-in-out',
    lineHeight: 1.5,
    backgroundColor: 'rgba(138, 155, 168, 0.15)',
    borderRadius: '3px',
    marginBottom: '15px',
};

const buttonsLine = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    marginBottom: '15px',
};

const ProgressBar: FC<ExtendedProgressBarProps> = (props: ExtendedProgressBarProps) => {
    const {
        value,
        description,
        statusUploadDescription,
        showRemovalButton,
        showProgressBar,
        actionRemovalButtonCallback,
    } = props;

    const calculatePercentage = (val: number): string => `${val * 100} %`;

    return showProgressBar ? (
        <div className={style(baseStyle)}>
            <div className={style(buttonsLine)}>
                <div>
                    <Icon icon={IconNames.UPLOAD} intent={Intent.PRIMARY} size={25} />
                    {description && <span style={{ marginLeft: '15px' }}>{`${description}`}</span>}
                </div>
                {showRemovalButton && (
                    <AnchorButton
                        intent={Intent.DANGER}
                        minimal
                        type="button"
                        onClick={() => actionRemovalButtonCallback()}
                    >
                        <Icon icon={IconNames.REMOVE} intent={Intent.DANGER} size={25} />
                    </AnchorButton>
                )}
            </div>
            {value !== undefined && (
                <>
                    <ProgressBarBJs value={value} intent={Intent.PRIMARY} animate={value < 1} />
                    <Text>{calculatePercentage(value)}</Text>
                </>
            )}
            {statusUploadDescription && (
                <Text className={style({ color: Colors.GRAY1 })}>{statusUploadDescription}</Text>
            )}
        </div>
    ) : null;
};

ProgressBar.defaultProps = {
    description: undefined,
    statusUploadDescription: undefined,
    showRemovalButton: undefined,
    showProgressBar: undefined,
    actionRemovalButtonCallback: undefined,
};

export default ProgressBar;
