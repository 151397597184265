import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CardWithImage from './CardWithImage';

import { Resource } from '../../../generator/generated/MeshInterfaces';

@observer
class ResourceCard extends Component<Resource> {
    constructor(props: Readonly<Resource>) {
        super(props);
    }

    render() {
        const { description, link, title, imageUrl, tags } = this.props;

        return (
            <CardWithImage
                description={description || ''}
                imageSource={imageUrl || ''}
                link={link}
                title={title || ''}
                key={title || ''}
                tags={tags}
            />
        );
    }
}

export default ResourceCard;
