/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Row, Column } from '@amxjs/ui';

// import { ObjectFieldTemplateProps } from '@rjsf/core';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';
import TitleField from './TitleField';

const ObjectFieldTemplate = ({
    DescriptionField,
    description,
    title,
    properties,
    required,
    uiSchema,
    idSchema,
}: ObjectFieldTemplateProps) => {
    return (
        <>
            {(uiSchema['ui:title'] || title) && (
                <TitleField
                    id={`${idSchema.$id}-title`}
                    title={title}
                    required={required}
                    hash={uiSchema['ui:hash']}
                />
            )}
            {description && (
                <DescriptionField id={`${idSchema.$id}-description`} description={description} />
            )}
            <Row>
                {properties.map((element: any, index: number) => (
                    <Column xs={12} key={index} style={{ marginBottom: '10px' }}>
                        {element.content}
                    </Column>
                ))}
            </Row>
        </>
    );
};

export default ObjectFieldTemplate;
