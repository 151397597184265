import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import {
    mipsRecordCost,
    mipsRecordimprovementActivities,
    mipsRecordpromoteInteroperability,
    mipsRecordQualitymeasures,
    mipsRecordscoringDetails,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import { MipsManageRecordListOutput } from '../../core/Store';

// export const isEncounterSection = (p: any): p is PatientDetailEncounters => !!p.encounterPageLink;

export const getSchemaForSection = (
    section:
        | mipsRecordimprovementActivities
        | mipsRecordpromoteInteroperability
        | mipsRecordQualitymeasures
        | mipsRecordscoringDetails
        | mipsRecordCost
): JSONSchema6 => {
    const properties: Record<string, JSONSchema6> = {};

    // eslint-disable-next-line prettier/prettier
  section.columns?.forEach((column: any) => {
        properties[column.id] = { type: 'string', title: column.label };
    });

    const sectionSchema: JSONSchema6 = {
        type: 'array',
        title: section.title,
        items: {
            type: 'object',
            properties,
        },
    };

    return sectionSchema;
};

export const getUISchemaForSection = (
    section:
        | mipsRecordimprovementActivities
        | mipsRecordpromoteInteroperability
        | mipsRecordQualitymeasures
        | mipsRecordscoringDetails
        | mipsRecordCost
): UiSchema => {
    const items: Record<string, UiSchema> = {};
    const emptyListMessage = {
        title: `No ${section.title} Found`,
        description: '',
    };

    // eslint-disable-next-line prettier/prettier
  section?.columns?.forEach((column: any) => {
        const dateFields = ['startDate', 'endDate', 'date'];
        items[column.id] = {
            'ui:widget': 'SimpleTextWidget',
            'ui:options': {
                orderable: !(!column.sortable || column.sortable === 'OFF'),
                renderMode: dateFields.includes(column.id) ? 'date' : 'default',
            },
        };
    });

    const schema: UiSchema = {
        'ui:field': 'list',
        'ui:hash': section.sectionType.toLowerCase(),
        'ui:options': {
            //   itemsPerPage: section.itemsPerPage || 5,
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items,
    };

    //   if (isEncounterSection(section) && schema['ui:options']) {
    //     schema['ui:options'].editable = true;
    //     schema['ui:options'].rowActions = [
    //       {
    //         id: 'encounter-view',
    //         label: 'View',
    //         behavior: 'Navigate',
    //         intent: 'primary',
    //         link: section.encounterPageLink,
    //         idName: 'id',
    //       },
    //     ];
    //   }

    return schema;
};

// This is not sustainable, another solution to building the labels and routes on breadcrumbs is needed
export function buildBreadcrumbTrail(
    trail?: (Page | undefined)[],
    record?: MipsManageRecordListOutput
): Record<string, any[]> {
    const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
    if (trail) {
        trail.forEach((crumb: Page | undefined) => {
            if (crumb) {
                if (crumb.name.toLowerCase() === 'MIPSMonitorPerformance'.toLowerCase()) {
                    breadcrumbTrail.labels.push(record?.preferences.tin || crumb.name);
                } else if (crumb.name.toLowerCase() === 'MipsRecord'.toLowerCase()) {
                    // Group Record
                    // Individual Record
                    breadcrumbTrail.labels.push(
                        record?.preferences.npi ? 'Individual Record' : 'Group Record'
                    );
                } else {
                    breadcrumbTrail.labels.push(crumb.name);
                }
                if (crumb.name.toLowerCase() === 'MIPS Explorer'.toLowerCase()) {
                    if (record?.reportingYear) {
                        breadcrumbTrail.routes.push(
                            `${crumb.route}?year=${record.reportingYear}` || null
                        );
                    } else {
                        breadcrumbTrail.routes.push(crumb.route || null);
                    }
                } else if (record?.reportingYear && record?.organizationFhirId) {
                    if (crumb.name.toLowerCase() === 'MipsRecord'.toLowerCase()) {
                        breadcrumbTrail.routes.push(
                            `${crumb.route}?id=${record.organizationFhirId}&type=${
                                record?.preferences.npi ? 'Individual' : 'Group'
                            }${
                                record?.providerPractitionerFhirId
                                    ? `&providerPractitionerFhirId=${
                                          record?.providerPractitionerFhirId || ''
                                      }`
                                    : ''
                            }&year=${record.reportingYear}` || null
                        );
                    } else {
                        breadcrumbTrail.routes.push(
                            `${crumb.route}?id=${record.organizationFhirId}&year=${record.reportingYear}` ||
                                null
                        );
                    }
                } else if (record?.reportingYear) {
                    breadcrumbTrail.routes.push(
                        `${crumb.route}?year=${record.reportingYear}` || null
                    );
                } else {
                    breadcrumbTrail.routes.push(crumb.route || null);
                }
                // If we have nested trails surface them all here
                if (crumb.breadcrumbTrail) {
                    const recursiveValue = buildBreadcrumbTrail(crumb.breadcrumbTrail, record);
                    if (recursiveValue.labels) {
                        breadcrumbTrail.labels.push(...recursiveValue.labels);
                    }
                    if (recursiveValue.routes) {
                        breadcrumbTrail.routes.push(...recursiveValue.routes);
                    }
                }
            }
        });
    }
    return breadcrumbTrail;
}
