import { JSONSchema6 } from 'json-schema';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Toaster, Position, Intent } from '@blueprintjs/core';
import queryString from 'query-string';
import { cloneDeep, isEqual, isEmpty, pick } from 'lodash';
import {
    Page,
    UserDetails as UserDetailsInterface,
} from '../../../generator/generated/MeshInterfaces';
import FormHeader from '../../components/forms/components/FormHeader';
import Form from '../../components/forms/Form';
import Store from '../../core/Store';
import { getAccessSchema, getAccessUISchema } from './AccessControl';
import ConfirmExitDialog from '../ConfirmExitDialog';
import { getGeneralSchema, getGeneralUISchema } from './GeneralInfo';
import Api from '../../core/Api';
import { UserRecord, UserRecordAccessControl } from '../../../server/routes/UserManagement/UserApi';
import { SchemaProvider } from '../../mesh/Schema';
import {
    validateEmail,
    validatePhone,
    validateUsername,
    validateUsernameForAtSimbol,
} from '../../components/forms/validation/CustomValidation';
import AccessControlDialog from './AccessControlDialog';
import { RowAction } from '../../components/Tables/List';
import FormFooter from '../../components/forms/components/FormFooter';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import UserModuleConstants from './UserModuleConstants';

interface UserDetailsProps extends UserDetailsInterface {
    /* eslint-disable-next-line react/no-unused-prop-types */
    store: Store;
}

function UserDetails(props: UserDetailsProps & RouteComponentProps) {
    // const [updateMode, setUpdateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [accessControlDialogOpen, setAccessControlDialogOpen] = useState(false);
    const [showAccessControlCallout, setShowAccessControlCallout] = useState(false);
    const [extraErrors, setExtraErrors] = useState<any>();
    const [savingRecord, setSavingRecord] = useState(false);
    const [activeAccess, setActiveAccess] = useState<UserRecordAccessControl | null>(null);
    const [key, setKey] = useState<number>(Date.now());
    const [recordInfo, setRecordInfo] = useState({
        id: '',
        name: '',
    });
    const [formData, setFormData] = useState<UserRecord>({
        id: '',
        General: {
            firstName: undefined,
            lastName: undefined,
            middleName: '',
            suffix: '',
            primaryEmail: undefined,
            userStatus: true,
            createdDate: '',
            updatedDate: '',
        },
        AccessControl: [],
    });
    const [origFormData, setOrigFormData] = useState<UserRecord>({
        id: '',
        General: {
            firstName: '',
            lastName: '',
            middleName: '',
            suffix: '',
            primaryEmail: '',
            userStatus: true,
            createdDate: '',
            updatedDate: '',
        },
    });
    const [accessControlLists, setAccessControlLists] = useState({
        personas: [{}],
        organizations: [{}],
        additionalProperties: [{}],
        capabilities: [{}],
    });
    const [
        isLoggedInUserHasAccessToAllManagingEntities,
        setIsLoggedInUserHasAccessToAllManagingEntities,
    ] = useState<boolean>(false);
    const { setBreadcrumbData } = useBreadcrumbContext();

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        Api.userManagement.getPersonasAndOrganizations().then((lists) => {
            if (lists && lists.data && lists.data.personaList && lists.data.organizationList) {
                setAccessControlLists({
                    personas: lists.data.personaList as any[],
                    organizations: lists.data.organizationList as any[],
                    additionalProperties: lists.data.additionalProperties as any[],
                    capabilities: lists.data.capabilities as any[],
                });
            }
        });

        const { id } = queryString.parse(props.location.search);

        if (id) {
            setCreateMode(false);
            Api.userManagement.getUserDetail(id as string).then((response) => {
                const rawData = response.data as UserRecord;

                setOrigFormData(rawData);
                setFormData(cloneDeep(rawData));

                setRecordInfo({
                    id,
                    name: `${rawData.General.lastName}, ${rawData.General.firstName}`,
                });

                breadcrumbTrail.labels.push(
                    `${rawData.General.lastName}, ${rawData.General.firstName}`
                );
                setBreadcrumbData({
                    crumbLabels: breadcrumbTrail.labels,
                    crumbRoutes: breadcrumbTrail.routes,
                });
                setIsLoggedInUserHasAccessToAllManagingEntities(
                    !!rawData.General.loggedInUserHasAccessToAllManagingEntities
                );
            });
        } else {
            setCreateMode(true);

            breadcrumbTrail.labels.push('Add User');
            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });
        }

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    const getSidebarElements = () => {
        return [
            { title: 'General Info', hash: 'general' },
            {
                title: props.addAccessTitle || UserModuleConstants.AddAccessTitle,
                hash: 'accesscontrol',
            },
        ];
    };

    const getSchema = (): JSONSchema6 => {
        return {
            type: 'object',
            properties: {
                General: getGeneralSchema('General Information'),
                AccessControl: getAccessSchema(
                    props.addAccessTitle || UserModuleConstants.AddAccessTitle
                ),
            },
        };
    };

    const onAddAccessControlClick: (event: any) => void = () => {
        setAccessControlDialogOpen(!accessControlDialogOpen);
    };

    const onDeleteAccessControl = (e: any) => {
        const accessControl = [...(formData.AccessControl || [])];
        const accessList = e.dataAccess.map((entry: any) => {
            return { id: entry.props.id, name: entry.props.value };
        });

        const accessControlIndexToRemove = accessControl.findIndex((entry) => {
            return (
                entry &&
                entry.persona.id === e.persona.props.id &&
                entry.persona.name === e.persona.props.value &&
                isEqual(pick(entry.dataAccess, ['id']), pick(accessList, ['id']))
            );
        });

        accessControl.splice(accessControlIndexToRemove, 1);

        setFormData({ ...formData, AccessControl: accessControl });
        setHasChange(true);
    };

    const onEditAccessControl = (renderedFormData: any) => {
        // The event is the object composed of the React Components for each cell (a consequence of how to render custom cells)
        // We need to pull out the raw data before sending it to Dialog
        const rawData = {
            persona: {
                id: renderedFormData.persona.props.id,
                name: renderedFormData.persona.props.value,
            },
            dataAccess: renderedFormData.dataAccess
                .filter((dataAccess: any) => {
                    return dataAccess.props;
                })
                .map((dataAccess: any) => {
                    return {
                        id: dataAccess.props.id,
                        name: dataAccess.props.value,
                    };
                }),
        };
        setActiveAccess(rawData);
        setAccessControlDialogOpen(true);
    };

    const rowActions: RowAction[] = [
        {
            id: `accessControl-edit`,
            label: '',
            behavior: 'Custom',
            intent: Intent.PRIMARY,
            link: {},
            icon: 'edit',
            callbackOnCustom: onEditAccessControl,
        },
        {
            id: `accessControl-remove`,
            label: '',
            behavior: 'Delete',
            intent: Intent.DANGER,
            link: {},
            icon: 'remove',
            callbackOnDelete: onDeleteAccessControl,
        },
    ];

    const getUISchema = () => {
        return {
            General: getGeneralUISchema(
                editMode,
                createMode,
                isLoggedInUserHasAccessToAllManagingEntities
            ),
            AccessControl: getAccessUISchema(
                editMode,
                createMode,
                onAddAccessControlClick,
                showAccessControlCallout,
                rowActions,
                props.addPersonaButtonLabel,
                props.addAccessTitle
            ),
        };
    };

    const editToggle = () => {
        const toggleEditMode = () => {
            setEditMode(!editMode);
            setFormData(origFormData);
        };

        if (createMode) {
            if (hasChange) {
                setConfirmationDialogOpen(true);
            } else {
                props.history.goBack();
            }
        } else if (editMode && hasChange) {
            setConfirmationDialogOpen(true);
        } else {
            toggleEditMode();
        }
    };

    const onChange = (e: any) => {
        setHasChange(true);
        setFormData(e.formData);
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };

    const onSubmit = () => {
        console.log('submitted', formData);
        setSavingRecord(true);
        setExtraErrors(undefined);

        if (isEmpty(formData.AccessControl)) {
            setShowAccessControlCallout(true);
            setSavingRecord(false);
            return;
        }
        setShowAccessControlCallout(false);

        const submitConfig = {
            userApi: createMode ? Api.userManagement.createUser : Api.userManagement.updateUser,
            successTitle: createMode
                ? 'New Record Successfully Added!'
                : 'Changes successfully saved!',
            successMessage: createMode
                ? 'User will receive an email with instructions on how to set up their account'
                : 'The section has been saved',
            errorTitle: 'Error Occured!',
            errorMessage:
                'Oops! Looks like something went wrong and the record could not be saved. Please try again.',
            duplicateTitle: 'User Already Exists!',
            duplicateEmailMessage: `This user could not be ${
                createMode ? 'added' : 'updated'
            } because the email address entered is associated with another user in your organization.`,
            duplicateUsernameMessage: `This user could not be ${
                createMode ? 'added' : 'updated'
            } because the username entered is associated with another user in your organization.`,
            duplicateDefaultMessage: `This user could not be ${
                createMode ? 'added' : 'updated'
            } because the information entered is associated with another user in your organization.`,
            timeout: 5000,
        };

        submitConfig
            .userApi(formData)
            .then((response) => {
                const toast = Toaster.create({ position: Position.BOTTOM_RIGHT });

                if (response.ok) {
                    if (response.data.success) {
                        toast.show({
                            timeout: submitConfig.timeout,
                            message: (
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {submitConfig.successTitle}
                                    </span>
                                    <br />
                                    <br />
                                    <span>{submitConfig.successMessage}</span>
                                </p>
                            ),
                            intent: Intent.SUCCESS,
                            icon: 'tick-circle',
                        });

                        setOrigFormData(formData);
                        setHasChange(false);
                        setCreateMode(false);
                        setEditMode(false);
                    } else {
                        const getDuplicateMessage = (message: string | undefined) => {
                            if (!message) {
                                return submitConfig.duplicateDefaultMessage;
                            }
                            const messageType =
                                (message.includes('searchParams.user-email-identifier') &&
                                    'email') ||
                                (message.includes('searchParams.username') && 'username');

                            switch (messageType) {
                                case 'email':
                                    setExtraErrors({
                                        General: {
                                            primaryEmail: {
                                                __errors: [
                                                    'Please enter a different email address',
                                                ],
                                            },
                                        },
                                    });
                                    return submitConfig.duplicateEmailMessage;
                                case 'username':
                                    setExtraErrors({
                                        General: {
                                            userName: {
                                                __errors: ['Please enter a different username'],
                                            },
                                        },
                                    });
                                    return submitConfig.duplicateUsernameMessage;
                                default:
                                    return submitConfig.duplicateDefaultMessage;
                            }
                        };

                        toast.show({
                            timeout: submitConfig.timeout,
                            message: (
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {submitConfig.duplicateTitle}
                                    </span>
                                    <br />
                                    <br />
                                    <span>{getDuplicateMessage(response.data.diagnostics)}</span>
                                </p>
                            ),
                            intent: Intent.DANGER,
                            icon: 'error',
                        });
                    }
                } else {
                    toast.show({
                        timeout: submitConfig.timeout,
                        message: (
                            <p>
                                <span style={{ fontWeight: 'bold' }}>
                                    {submitConfig.errorTitle}
                                </span>
                                <br />
                                <br />
                                <span>{submitConfig.errorMessage}</span>
                            </p>
                        ),
                        intent: Intent.DANGER,
                        icon: 'error',
                    });
                }
                setSavingRecord(false);
            })
            .catch((error: any) => {
                console.error(error);
                setSavingRecord(false);
            });
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setKey(Date.now());
        setEditMode(!editMode);
        setFormData(origFormData);
        setHasChange(false);
        setConfirmationDialogOpen(false);
        if (createMode) {
            props.history.goBack();
        }
    };

    const getValidators = () => {
        const validators = [
            {
                field: 'General.userName',
                validator: validateUsername,
                errorMessage: 'Username must be between 3 and 215 characters',
            },
            {
                field: 'General.primaryEmail',
                validator: validateEmail,
                errorMessage: 'Please specify a valid email address in the form of user@webaddress',
            },
            {
                field: 'General.phone',
                validator: validatePhone,
                errorMessage: 'Please specify a valid phone number in the form of (999)999-9999',
            },
            ...(editMode
                ? []
                : [
                      {
                          field: 'General.userName',
                          validator: validateUsernameForAtSimbol,
                          errorMessage: 'Username can not contain @',
                      },
                  ]),
        ];

        return validators;
    };

    const handleAccessControlDialogClose = () => {
        setActiveAccess(null);
        setAccessControlDialogOpen(false);
    };

    const handleAccessControlDialogOkay = (e: any, editedAccess: any) => {
        const accessControl = Object.assign([], formData.AccessControl) as any;
        const accessList = editedAccess ? editedAccess.dataAccess : e.formData.dataAccess;

        if (editedAccess) {
            // user is editing existing access control entry, replace old entry with updated entry
            const editedAccessControl = accessControl.map((row: any) => {
                if (
                    isEqual(row.persona.id, editedAccess.persona.id) &&
                    isEqual(row.persona.name, editedAccess.persona.name) &&
                    isEqual(pick(row.dataAccess, ['id']), pick(accessList, ['id']))
                ) {
                    setHasChange(true);
                    return e.formData;
                }
                return row;
            });
            setFormData({ ...formData, AccessControl: editedAccessControl });
        } else {
            // user is adding new access control entry, check for identical existing access control entry
            const existing = accessControl.map((row: any) => {
                if (
                    isEqual(row.persona, e.formData.persona) &&
                    isEqual(pick(row.dataAccess, ['id']), pick(accessList, ['id']))
                ) {
                    return true;
                }
                return false;
            });

            if (!existing.includes(true)) {
                accessControl.push(e.formData);
                setFormData({ ...formData, AccessControl: accessControl });
                setHasChange(true);
            }
        }

        setActiveAccess(null);
        setAccessControlDialogOpen(false);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={handleConfirmationDialogClose}
                handleOkay={handleConfirmationDialogOkay}
            />
            <AccessControlDialog
                isOpen={accessControlDialogOpen}
                handleClose={handleAccessControlDialogClose}
                handleOkay={handleAccessControlDialogOkay}
                personas={accessControlLists.personas}
                organizations={accessControlLists.organizations}
                activeAccess={activeAccess}
                additionalProperties={accessControlLists.additionalProperties}
                capabilities={accessControlLists.capabilities}
            />
            <Form
                key={key}
                recordId={recordInfo.id}
                recordName={recordInfo.name}
                sidebarElements={getSidebarElements()}
                schema={getSchema()}
                uiSchema={getUISchema()}
                validators={getValidators()}
                onChange={(e: any) => {
                    onChange(e);
                }}
                formData={editMode || createMode ? formData : origFormData}
                onError={onError}
                onSubmit={onSubmit}
                extraErrors={extraErrors}
            >
                <FormHeader
                    title={createMode ? 'Add User Record' : props.name || 'User Record'}
                    editMode={editMode || createMode}
                    allowEditing
                    allowCancel={!savingRecord}
                    allowSave={hasChange && !savingRecord}
                    enableEditMode={() => editToggle()}
                    disableEditMode={() => editToggle()}
                />
                {createMode && (
                    <FormFooter
                        title="Add User Record"
                        editMode={editMode || createMode}
                        allowEditing
                        allowSave={hasChange && !savingRecord}
                        enableEditMode={() => editToggle()}
                        disableEditMode={() => editToggle()}
                    />
                )}
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(UserDetails);
