import { NumeratorDenominator } from './Mips';

export enum MipsPIAttestationMeasureType {
    PROPORTION = 'proportion',
    BOOLEAN = 'boolean',
}

export enum MipsPIAttestationReportingCategory {
    BONUS = 'bonus',
    EXCLUSION = 'exclusion',
    REQUIRED = 'required',
}

interface MipsPIAttestationMeasureBase {
    id: string;
    measureId: string;
    shortMeasureId: string;
    reportingCategory: MipsPIAttestationReportingCategory | null;
    title: string;
    objective?: string;
    exclusion?: string[];
}

interface MipsPIAttestationMeasureEx extends MipsPIAttestationMeasureBase {
    measureType: MipsPIAttestationMeasureType;
    measureReportId?: string;
    hasChanged?: boolean;
    recordIsFromMonet?: boolean;
    disabledActions?: string[];
}

export interface MipsPIAttestationMeasure extends MipsPIAttestationMeasureEx {
    attested: boolean;
    startDate?: Date;
    endDate?: Date;
    performanceRate?: NumeratorDenominator;
}

interface MipsPIAttestationMeasureProp {
    disabled: boolean;
    hidden: boolean;
    hideErrors: boolean;
}

export interface MipsPIAttestationMeasureWithProps extends MipsPIAttestationMeasureEx {
    hasChanged?: boolean;
    measureReportId?: string;
    measureType: MipsPIAttestationMeasureType;
    attested: { value: boolean; props: { disabled: boolean } };
    startDate?: {
        value?: Date;
        props: MipsPIAttestationMeasureProp;
    };
    endDate?: { value?: Date; props: MipsPIAttestationMeasureProp };
    performanceRate?: {
        value?: NumeratorDenominator;
        props: MipsPIAttestationMeasureProp;
    };
}

export interface MipsPIAttestation<T> {
    tin?: string;
    organizationName?: string;
    npi?: string;
    measures?: T[];
}

export interface PerformanceRateTC {
    numerator: number;
    denominator: number;
}

export interface PeriodTC {
    start: string;
    end: string;
}

export interface MipsPIAttestationMeasureResponse extends MipsPIAttestationMeasureBase {
    attested: boolean;
    metricType: MipsPIAttestationMeasureType;
    measureReport: {
        id: string;
        period?: PeriodTC;
        performanceRate?: PerformanceRateTC;
    } | null;
}
export interface MipsPIAttestationListResponse {
    MipsPIAttestationList: {
        returnData: {
            tin: string;
            organizationId: string;
            npi?: string;
            providerOrganizationName: string;
            measures: null | MipsPIAttestationMeasureResponse[];
        }[];
    };
}
