import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { MultiSelectWidgetEnum } from '../../components/forms/widgets/MultiSelectWidget';

export const getImprovementActivitiesSchema = (
    measureDefinitions: { itemName: string; itemID: string }[] | undefined,
    copyImprovementActivityList: MultiSelectWidgetEnum[] | undefined
): JSONSchema6 => ({
    type: 'object',
    properties: {
        measureId: {
            type: 'string',
            title: 'Measure ID',
            enum: measureDefinitions || [],
        },
        measureTitle: {
            type: 'string',
            title: 'Title',
        },
        subcategory: {
            type: 'string',
            title: 'Category',
        },
        weight: {
            type: 'string',
            title: 'Weight',
        },
        score: {
            type: 'string',
            title: 'Score',
        },
        startDate: {
            type: 'string',
            title: 'Start Date',
        },
        endDate: {
            type: 'string',
            title: 'End Date',
        },
        description: {
            type: 'string',
            title: 'Description',
        },
        copyImprovementActivities: {
            type: 'string',
            title: 'Copy IAs',
            enum: copyImprovementActivityList,
        },
    },
});

export const getImprovementActivitiesDetailsUISchema = (
    editMode: boolean,
    addMode: boolean
): UiSchema => {
    const emptyListMessage = {
        title: 'No Improvement Activities',
        description: '',
    };

    return {
        'ui:field': 'layout',
        'ui:hash': 'qualitymeasurecategoryscores',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        'ui:layout': [
            {
                measureId: {
                    sm: 12,
                    // Make a larger box when it's a different widget for the add logic
                    md: addMode ? 8 : 6,
                    lg: addMode ? 8 : 4,
                    xl: addMode ? 8 : 3,
                },
            },
            {
                measureTitle: { sm: 12, md: 12, lg: 12, xl: 12 },
            },

            {
                subcategory: { sm: 12, md: 6, lg: 4, xl: 3 },
                weight: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
            {
                score: { sm: 12, md: 3, lg: 3, xl: 3 },
            },
            {
                startDate: { sm: 12, md: 3, lg: 3, xl: 3 },
                endDate: { sm: 12, md: 3, lg: 3, xl: 3 },
            },
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                copyImprovementActivities: { sm: 8, md: 8, lg: 8, xl: 8 },
            },
        ],
        measureId: {
            'ui:widget': addMode ? 'SuggestWidget' : 'LabelWidget',
            'ui:options': {},
        },
        measureTitle: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        description: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        subcategory: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        score: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        weight: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        startDate: {
            'ui:widget': editMode ? 'DateWidget' : 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        endDate: {
            'ui:widget': editMode ? 'DateWidget' : 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        copyImprovementActivities: {
            'ui:widget': 'MultiSelectWidget',
            'ui:options': {
                skipBottomPadding: true,
                renderMode: editMode ? undefined : 'none',
            },
        },
    };
};
