import React from 'react';

// import { AddButtonProps } from '@rjsf/core';
import { AddButtonProps } from 'react-jsonschema-form';

import { Button } from '@blueprintjs/core';

// TODO: Add label property on type definition
class AddButton extends React.Component<AddButtonProps & { label?: string }> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { label } = this.props;

        return <Button icon="add">{label || 'Add Item'}</Button>;
    }
}

export default AddButton;
