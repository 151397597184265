import React, {
    createContext,
    SetStateAction,
    useContext,
    useState,
    ReactNode,
    Dispatch,
} from 'react';

interface BreadcrumbProviderProps {
    children: ReactNode;
}

interface BreadcrumbContext {
    breadcrumbData: any;
    setBreadcrumbData: Dispatch<SetStateAction<any>>;
}

const initialBreadcrumbPatient: BreadcrumbContext = {
    breadcrumbData: {},
    setBreadcrumbData: () => {},
};

const BreadcrumbContext = createContext<BreadcrumbContext>(initialBreadcrumbPatient);

export const BreadcrumbContextProvider = (props: BreadcrumbProviderProps) => {
    const [breadcrumbData, setBreadcrumbData] = useState({
        crumbLabels: null,
        crumbRoutes: null,
    });

    const breadcrumbContextValue: BreadcrumbContext = {
        breadcrumbData,
        setBreadcrumbData,
    };

    return (
        <BreadcrumbContext.Provider value={breadcrumbContextValue}>
            {props.children}
        </BreadcrumbContext.Provider>
    );
};

export const useBreadcrumbContext = () => {
    const { breadcrumbData, setBreadcrumbData } = useContext(BreadcrumbContext);
    if (breadcrumbData === undefined || setBreadcrumbData === undefined) {
        throw new Error(`useBreadcrumbContext: must be used within BreadcrumbContext.Provider`);
    }
    return { breadcrumbData, setBreadcrumbData };
};
