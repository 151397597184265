import React from 'react';
import { Button, Icon, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, ItemPredicate, MultiSelect } from '@blueprintjs/select';

const TagMultiSelect = MultiSelect.ofType<string>();

interface TagSearchBoxState {
    items: string[];
}

interface TagSearchBoxProps {
    items: string[];
    addToFilteredTag: any;
    filterTags: string[];
    onClear: any;
    removeTag: any;
}

const renderTag: ItemRenderer<string> = (tag, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={tag}
            onClick={handleClick}
            text={tag}
        />
    );
};

const filterTag: ItemPredicate<string> = (query, tag, _index) => {
    const normalizedName = tag.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    return normalizedName.includes(normalizedQuery);
};

const tagSelectProps = {
    itemPredicate: filterTag,
    itemRenderer: renderTag,
};

export default class TagSearchBox extends React.PureComponent<
    TagSearchBoxProps,
    TagSearchBoxState
> {
    public state: TagSearchBoxState = {
        items: this.props.items,
    };

    private renderTagNames = (tag: string) => tag;

    public render() {
        const { items } = this.state;
        const { addToFilteredTag, filterTags, onClear, removeTag } = this.props;

        const clearButton =
            filterTags.length > 0 ? <Button icon="cross" minimal onClick={onClear} /> : undefined;

        const displayItems = items.filter((i) => !filterTags.includes(i));

        const leftIcon = <Icon style={{ padding: '7px' }} icon="search" />;

        return (
            <TagMultiSelect
                {...tagSelectProps}
                itemRenderer={renderTag}
                resetOnSelect
                items={displayItems}
                noResults={<MenuItem disabled text="No results." />}
                onItemSelect={(x: string) => addToFilteredTag(x)}
                placeholder="Search resource tags.."
                selectedItems={filterTags}
                tagRenderer={this.renderTagNames}
                tagInputProps={{
                    rightElement: clearButton,
                    leftIcon,
                    onRemove: removeTag,
                    className: 'tag-search bp3-round',
                }}
            />
        );
    }
}
