import React, { PropsWithChildren, useEffect, useState } from 'react';
import { AjvError, FormProps as RjsfFormProps, withTheme } from 'react-jsonschema-form';
import get from 'lodash/get';

import { Button, Intent } from '@blueprintjs/core';
import { showToast } from '@amxjs/ui';

import Widgets from './widgets';
import Fields from './fields';
import FieldTemplate from './fields/FieldTemplate';
import ArrayFieldTemplate from './fields/ArrayFieldTemplate';
import ObjectFieldTemplate from './fields/ObjectFieldTemplate';
import ErrorList from './components/ErrorList';
import { validateEmail } from './validation/CustomValidation';

const DefaultChildren = () => (
    <Button type="submit" color="primary">
        Submit
    </Button>
);

const theme = {
    children: <DefaultChildren />,
    ArrayFieldTemplate,
    widgets: Widgets,
    FieldTemplate,
    ObjectFieldTemplate,
    ErrorList,
    fields: Fields,
};

interface Validator {
    field: string;
    validator: Function;
    errorMessage: string;
    callbackOnFail?: () => void;
}

interface FormProps<T> extends RjsfFormProps<T> {
    validators?: Validator[];
    extraErrors?: object;
    // If set this will allow the enter key to submitting the form data during normal form input
    // This still allows the submit/save button to be focused via tab and enter to submit the form
    // By default the enter key will now allow the form to be submitted when not focused on the submit button
    allowEnterToSubmit?: boolean;
}

const ThemedForm = withTheme(theme);

function preventEnterFromSubmitting(e: any) {
    if (e && e.keyCode === 13) {
        e.preventDefault();
    }
}

// partial application
// Reference: https://www.freecodecamp.org/news/how-to-use-partial-application-to-improve-your-javascript-code-5af9ad877833/
const partial = (fn: any, firstArg: any) => {
    return (...lastArgs: any[]) => {
        return fn(firstArg, ...lastArgs);
    };
};

interface FormState {
    id?: string;
}

const AmxThemedForm = <T,>(props: PropsWithChildren<FormProps<T> & FormState>) => {
    const [id, setId] = useState<string>();
    const [formContext, setFormContext] = useState();

    useEffect(() => {
        setId((props.id ? props.id : Math.random()) as string);
    }, [props.id]);

    // use partial application to let it carry the onError from outside
    const onError = (onerror: any, errors: any) => {
        showToast({
            message: (
                <div>
                    <p style={{ fontWeight: 'bold' }}>Save Failed!</p>
                    <p>Please check that all the fields are entered correctly!</p>
                </div>
            ),
            intent: Intent.DANGER,
            icon: 'error',
        });

        onerror?.(errors);
    };

    const transformErrors = (errors: AjvError[], formCtx: any): AjvError[] => {
        // remove default required error on fields with custom error messages
        return errors
            .filter((error) => {
                const matchingValidatorsForProperty = formCtx?.validators?.filter(
                    (validator: any) => error.property === `.${validator.field}`
                );
                return error.name === 'required' && matchingValidatorsForProperty?.length;
            })
            .map((error) => {
                const e = error;
                console.log('ERROR: ', error.name);

                if (error.name === 'required') {
                    e.message = 'This value is required';
                }
                return e;
            });
    };

    // use partial application to let it carry the context
    const validate = (formCtx: any, formData: any, errors: any) => {
        const validationErrors = errors;

        formCtx?.validators?.forEach((entry: any) => {
            const { field, errorMessage, validator } = entry;
            if (!validator(get(formData, field))) {
                get(validationErrors, field).addError(errorMessage);
                if (entry.callbackOnFail) {
                    entry.callbackOnFail();
                }
            }
        });

        if (formData.pass1 && formData.pass2 && formData.pass1 !== formData.pass2) {
            validationErrors.pass2.addError("Passwords don't match");
        }

        if (
            formCtx?.emailValidation &&
            Array.isArray(formCtx.emailValidation) &&
            formCtx.emailValidation.length
        ) {
            const emailFields: string[] = formCtx.emailValidation;
            emailFields.map((field) => {
                if (formData[field]) {
                    if (!validateEmail(formData[field])) {
                        validationErrors[field].addError(
                            'Please specify a valid email address in the form of user@webaddress'
                        );
                    }
                }
            });
        }
        return validationErrors;
    };

    const addDefaultEmailValidation = (formCtx: any): any => {
        const defaultEmailFields = ['email1', 'email2', 'primaryEmail'];
        if (!formCtx) {
            return { emailValidation: defaultEmailFields };
        }
        if (formCtx.emailValidation) {
            return formCtx;
        }
        return { ...formCtx, emailValidation: defaultEmailFields };
    };

    useEffect(() => {
        if (document && !props.allowEnterToSubmit && id) {
            const form = document.getElementById(id);
            if (form) {
                form.onkeypress = preventEnterFromSubmitting;
            }
        }
    }, [id, props.allowEnterToSubmit]);

    useEffect(() => {
        const auxFormContext = addDefaultEmailValidation(props.formContext);
        auxFormContext.validators = props.validators;
        auxFormContext.errors = props.extraErrors;
        if (auxFormContext) {
            setFormContext(auxFormContext);
        }
    }, [props.extraErrors, props.formContext, props.validators]);

    return (
        <ThemedForm
            transformErrors={(errors: AjvError[]) => transformErrors(errors, formContext)}
            onError={partial(onError, props.onError)}
            validate={partial(validate, formContext)}
            {...props}
            id={`Form-${id}`}
            formContext={formContext}
        >
            {props.children}
        </ThemedForm>
    );
};

AmxThemedForm.defaultProps = {
    id: undefined,
    children: null,
    validators: undefined,
    extraErrors: undefined,
    allowEnterToSubmit: undefined,
};

export default AmxThemedForm;
