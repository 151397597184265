import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { JSONSchema6 } from 'json-schema';
import queryString from 'query-string';
import { UiSchema } from 'react-jsonschema-form';

import { Button, Colors, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { showToast } from '@amxjs/ui';

import { MipsManageRecordWriteInput } from '../../../../server/routes/Mips/ManageRecordWriteInterface';
import { SubmissionErrorDialogInterface } from '../../../../server/routes/Mips/MipsApi';
import {
    Page,
    MipsRecord as MipsRecordInterface,
    mipsRecorddetailsSection,
} from '../../../../generator/generated/MeshInterfaces';
import { PromotingInteroperabilityScore } from '../../../../models/Mips';
import Store, {
    Preferences,
    ImprovementActivityScore,
    QualityMeasureScore,
    MipsManageRecordListOutput,
} from '../../../core/Store';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../../util/DataDictionary';
import Api from '../../../core/Api';
import { formatDecimalPlaces, getRoutes } from '../../../util/GeneralUtils';
import { useBreadcrumbContext } from '../../../core/BreadcrumbContext';
import { SchemaProvider } from '../../../mesh/Schema';
import FormHeader from '../../../components/forms/components/FormHeader';
import Form from '../../../components/forms/Form';
import ConfirmExitDialog from '../../ConfirmExitDialog';
import MipsModuleConstants from '../MipsModuleConstants';
import { getSchemaForSection } from '../utils';
import { getCostSchema, getCostUISchema } from '../Cost';
import SubmissionErrorDialog from '../SubmissionErrorDialog';
import { validateCehrtId } from '../MipsValidation';
import { getPreferenceSchema, getPreferenceUISchema } from './Preference';
import {
    getImprovementActivitiesSchema,
    getImprovementActivitiesUISchema,
    updateImprovementActivities,
} from './ImprovementActivities';
import {
    getPromoteInteroperabilitySchema,
    getPromoteInteroperabilityUISchema,
} from './PromoteInteroperability';
import { getQualityMeasuresSchema, getQualityMeasuresUISchema } from './QualityMeasures';
import { getScoringDetailsSchema, getScoringDetailsUISchema } from './ScoringDetails';

interface MipsRecordProps extends MipsRecordInterface {
    store: Store;
}
interface SubmissionErrorMessageDialogInterface extends SubmissionErrorDialogInterface {
    messages?: any;
    clinicianName: string;
    errors: any[];
    error: any;
}
const isMipsRecordDetailsSection = (p: any): p is mipsRecorddetailsSection => !p.columns;
const preferences: Preferences = {
    tin: '',
    tinName: '',
    npi: '',
    clinicianName: '',
    participationOption: '',
    participatingInQualityMeasures: false,
    participatingInImprovementActivities: false,
    participatingInPromotingInteroperability: false,
    optInStatus: '',
    optInDecision: '',
    cehrtId: '',
    emailAddress: '',
    consentGiven: false,
    performanceCategoryParticipation: '',
};

const qualityMeasureScore: QualityMeasureScore = {
    scoreMeasureReportFhirId: '',
    measureId: '',
    measureTitle: '',
    dataCompleteness: '',
    performanceRate: '',
    score: '',
    decile: '',
    startDate: '',
    endDate: '',
    measureIsExcluded: false,
    ecqmMeasureId: '',
    endToEndFlag: false,
    metricType: '',
    measureType: '',
    inverse: false,
    dataCompletenessNumerator: 0,
    dataCompletenessDenominator: 0,
    performanceRateNumerator: 0,
    performanceRateDenominator: 0,
    eligiblePopulation: 0,
    performanceMet: 0,
    performanceNotMet: 0,
    eligiblePopulationException: 0,
    eligiblePopulationExclusion: 0,
    view: '',

    observationInstances: 0,
    description: '',
    overallAlgorithm: '', // Only for multi performance rates & registry multi performance rates
    stratum: [],
};
const improvementActivityScore: ImprovementActivityScore = {
    scoreMeasureReportFhirId: '',
    measureId: '',
    measureTitle: '',
    subcategory: '',
    weight: '',
    score: '',
    startDate: '',
    endDate: '',
    metricType: '',
    measureType: '',
    description: '',
    view: '',
};
const promotingInteroperabilityScore: PromotingInteroperabilityScore = {
    scoreMeasureReportFhirId: '',
    measureId: '',
    rawMeasureId: '',
    measureTitle: '',
    performanceRate: '',
    score: '',
    decile: '',
    startDate: '',
    endDate: '',
    metricType: '',
    measureType: '',
    performanceRateNumerator: 0,
    performanceRateDenominator: 0,
    description: '',
    objective: '',
    view: '',
};

const MipsRecord = (props: MipsRecordProps & RouteComponentProps & InjectedDataDictionaryProps) => {
    const [editMode, setEditMode] = useState(false);
    const [extraErrors, setExtraErrors] = useState<any>();
    const [tin, setTin] = useState('');
    const [tinName, setTinName] = useState('');
    const [clinicianName, setClinicianName] = useState('');
    const [npi, setNpi] = useState<any>('');
    const [reportingYear, setReportingYear] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const [optInStatus, setOptInStatus] = useState('');
    const [participationOption, setParticipationOption] = useState<any>('');
    const [performanceCategoryParticipationOptions, setPerformanceCategoryParticipationOptions] =
        useState(['']);
    const { setBreadcrumbData } = useBreadcrumbContext();
    const { sections, labels } = props;
    const [formData, setFormData] = useState<MipsManageRecordListOutput>({
        organizationFhirId: '',
        reportingYear: '',
        organizationAffiliationFhirId: '',
        providerPractitionerFhirId: '',
        mipsPractitionerRoleFhirId: '',
        consentRecordFhirId: '',
        preferences,
        qualityMeasureCategoryScores: [qualityMeasureScore],
        improvementActivityScores: [improvementActivityScore],
        promotingInteroperabilityScores: [promotingInteroperabilityScore],
    });
    const [origFormData, setOrigFormData] = useState<MipsManageRecordListOutput>({
        organizationFhirId: '',
        reportingYear: '',
        organizationAffiliationFhirId: '',
        providerPractitionerFhirId: '',
        mipsPractitionerRoleFhirId: '',
        consentRecordFhirId: '',
        preferences,
        qualityMeasureCategoryScores: [qualityMeasureScore],
        improvementActivityScores: [improvementActivityScore],
        promotingInteroperabilityScores: [promotingInteroperabilityScore],
    });
    const [viewType, setViewType] = useState<string>('');
    const [submissionErrorDialogOpen, setSubmissionErrorDialogOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState<
        SubmissionErrorMessageDialogInterface[]
    >([]);
    const [loader, setLoader] = useState(false);
    const [saving, setSaving] = useState(false);
    const showErrorDialog = () => {
        setSubmissionErrorDialogOpen(true);
    };
    const handleSubmissionErrorDialogOkay = () => {
        setSubmissionErrorDialogOpen(false);
    };

    const handleSubmissionErrorDialogClose = () => {
        setSubmissionErrorDialogOpen(false);
    };

    const getProviderOrEmpty = (providerPractitionerFhirId: string | undefined) => {
        return providerPractitionerFhirId
            ? `&providerPractitionerFhirId=${providerPractitionerFhirId}`
            : '';
    };

    const pushHistoryItem = useCallback(
        (route: any, item: string) => {
            if (route) {
                props.history.push(item);
            }
        },
        [props.history]
    );

    const onViewClick = useCallback(
        (_e: any) => {
            const { id, type, providerPractitionerFhirId, year } = queryString.parse(
                props.location.search
            );
            const { route } = props.ViewPageLink as Page;
            pushHistoryItem(
                route,
                `${route}?id=${id}&type=${type}${getProviderOrEmpty(
                    providerPractitionerFhirId
                )}&year=${year}&measureId=${_e.measureId}&editState=${editMode ? 'Edit' : 'View'}`
            );
        },
        [props.ViewPageLink, props.location.search, pushHistoryItem, editMode]
    );

    const onAttestationViewClick = () => {
        const { id, year } = queryString.parse(props.location.search);
        const { route } = props.ViewPageLinkPIAttestation as Page;
        pushHistoryItem(
            route,
            `${route}?orgId=${id}${getProviderOrEmpty(formData.providerPractitionerFhirId)}${
                formData.mipsPractitionerRoleFhirId
                    ? `&practitionerRoleFhirId=${formData.mipsPractitionerRoleFhirId}`
                    : ''
            }&year=${year}`
        );
    };

    const onInterOperabilityViewClick = useCallback(
        (_e: any) => {
            const { id, type, providerPractitionerFhirId, year } = queryString.parse(
                props.location.search
            );
            const { route } = props.ViewPageLinkPI as Page;
            pushHistoryItem(
                route,
                `${route}?id=${id}&type=${type}${getProviderOrEmpty(
                    providerPractitionerFhirId
                )}&year=${year}&measureId=${_e.rawMeasureId}`
            );
        },
        [props.ViewPageLinkPI, props.location.search, pushHistoryItem]
    );

    const onImprovementActivitiesViewClick = useCallback(
        (_e: any) => {
            const { id, type, providerPractitionerFhirId, year } = queryString.parse(
                props.location.search
            );
            const { route } = props.ViewPageLinkIA as Page;
            pushHistoryItem(
                route,
                `${route}?id=${id}&type=${type}${getProviderOrEmpty(
                    providerPractitionerFhirId
                )}&year=${year}&measureId=${_e.measureId}&editState=${editMode ? 'Edit' : 'View'}`
            );
        },
        [props.ViewPageLinkIA, props.location.search, editMode, pushHistoryItem]
    );

    const getMipsRecordDetails = useCallback(async () => {
        const { id, type, providerPractitionerFhirId, year } = queryString.parse(
            props.location.search
        );
        setViewType(type);
        if (id) {
            const breadcrumbTrail: Record<string, any[]> = getRoutes(
                props.ParentPageLink?.breadcrumbTrail,
                year
            );
            setLoader(true);
            const response = await Api.mips.readForRecordDetails({
                organizationFhirId: id,
                providerPractitionerFhirId,
                reportingYear:
                    year || props.labels.submissionYear || MipsModuleConstants.SubmissionYear,
            });

            if (!response.ok) {
                console.error('error');
                return;
            }

            const { data: rawData } = response as { data: MipsManageRecordListOutput };
            props.store.setMipsManageRecordList(rawData);

            // Set the `setSubmissionErrors` once when the data is being loaded in. There was an issue
            // where this was being set everytime on click of the button and causing data to get dropped
            // from the dialog box.
            const error: any = rawData.scoringDetails?.errors || {};
            error.clinician = error.clinicianName;
            error.errors = error?.errors?.length
                ? error.errors.map((errorData: { errorMessage: string }) => errorData.errorMessage)
                : undefined;

            setSubmissionErrors([error]);

            const cookedData: MipsManageRecordListOutput = {
                ...rawData,
                ...(rawData?.scoringDetails
                    ? {
                          scoringDetails: {
                              ...rawData.scoringDetails,
                              details: [
                                  {
                                      detailsType: 'Category Score',
                                      qualityMeasures: `${rawData.scoringDetails.qualityMeasureCategoryScore}/${rawData.scoringDetails.qualityMeasureCategoryScoreDenominator}`,
                                      improvementActivities: `${rawData.scoringDetails.improvementActivityCategoryScore}/40`,
                                      promotingInteroperability: `${rawData.scoringDetails.promotingInteroperabilityCategoryScore}/100`,
                                      // See MIPS-354 for why this is a dash
                                      bonus: '-',
                                      total: '-',
                                  },
                              ],
                              ...(() => {
                                  switch (rawData.scoringDetails?.submissionStatus) {
                                      case 'Submitted':
                                          return {
                                              submissionStatus: (
                                                  <div>
                                                      <span style={{ fontWeight: 600 }}>
                                                          Submission: &nbsp;
                                                      </span>
                                                      <span>
                                                          <Icon
                                                              htmlTitle={
                                                                  rawData.scoringDetails
                                                                      .lastSubmissionDate
                                                              }
                                                              color={Colors.GREEN3}
                                                              icon={IconNames.TICK_CIRCLE}
                                                              style={{ paddingRight: '5px' }}
                                                          />
                                                          {rawData.scoringDetails.submissionStatus}
                                                      </span>
                                                  </div>
                                              ),
                                          };
                                      case '-':
                                          return {
                                              submissionStatus: (
                                                  <div>
                                                      <span style={{ fontWeight: 600 }}>
                                                          Submission: &nbsp;
                                                      </span>
                                                      <span
                                                          title={
                                                              rawData.scoringDetails
                                                                  .lastSubmissionDate
                                                          }
                                                      >
                                                          <Icon
                                                              htmlTitle={
                                                                  rawData.scoringDetails
                                                                      .lastSubmissionDate
                                                              }
                                                              color={Colors.RED3}
                                                              icon={IconNames.TICK_CIRCLE}
                                                              style={{ paddingRight: '5px' }}
                                                          />
                                                          Prescore failed
                                                      </span>
                                                  </div>
                                              ),
                                          };
                                      default:
                                          return {
                                              submissionStatus: (
                                                  <div>
                                                      <span style={{ fontWeight: 600 }}>
                                                          Submission: &nbsp;
                                                      </span>
                                                      <span
                                                          style={{
                                                              color: '#137CBD',
                                                              cursor: 'Pointer',
                                                          }}
                                                          onClick={() => showErrorDialog()}
                                                          aria-hidden="true"
                                                      >
                                                          <span
                                                              title={
                                                                  rawData.scoringDetails
                                                                      .lastSubmissionDate
                                                              }
                                                          >
                                                              <Icon
                                                                  htmlTitle={
                                                                      rawData.scoringDetails
                                                                          .lastSubmissionDate
                                                                  }
                                                                  color={Colors.RED3}
                                                                  icon={IconNames.TICK_CIRCLE}
                                                                  style={{
                                                                      paddingRight: '5px',
                                                                  }}
                                                              />
                                                              {
                                                                  rawData.scoringDetails
                                                                      .submissionStatus
                                                              }
                                                          </span>
                                                      </span>
                                                  </div>
                                              ),
                                          };
                                  }
                              })(),
                          },
                      }
                    : {}),
                ...(rawData.preferences
                    ? {
                          preferences: {
                              ...rawData.preferences,
                              ...(rawData.preferences.optInDecision
                                  ? {
                                        optInDecision: rawData.preferences.optInDecision
                                            .split('-')
                                            .map(
                                                (value) =>
                                                    value[0].toUpperCase() +
                                                    value.slice(1, value.length)
                                            )
                                            .join(' '),
                                    }
                                  : {}),
                              performanceCategoryParticipation: [
                                  rawData.preferences.participatingInPromotingInteroperability
                                      ? 'Promoting Interoperability'
                                      : null,
                                  rawData.preferences.participatingInImprovementActivities
                                      ? 'Improvement Activities'
                                      : null,
                                  rawData.preferences.participatingInQualityMeasures
                                      ? 'Quality Measures'
                                      : null,
                              ].filter((i) => i !== null),
                              ...(() => {
                                  switch (rawData.preferences.participationOption) {
                                      case 'participation-both':
                                          return { participationOption: 'Group + Individual' };
                                      case 'participation-group':
                                          return { participationOption: 'Group' };
                                      case 'participation-individual':
                                          return { participationOption: 'Individual' };
                                      default:
                                          return {};
                                  }
                              })(),
                          },
                      }
                    : null),
                qualityMeasureCategoryScores: rawData.qualityMeasureCategoryScores?.map(
                    (qms: QualityMeasureScore) => ({
                        ...qms,
                        view: (
                            <button
                                type="button"
                                className="bp3-button bp3-intent-primary bp3-minimal"
                                onClick={() => onViewClick(qms)}
                            >
                                {editMode ? 'Edit' : 'View'}
                            </button>
                        ),
                        dataCompleteness: `${formatDecimalPlaces(qms.dataCompleteness)}%`,
                        performanceRate: `${formatDecimalPlaces(qms.performanceRate)}%`,
                        ...(qms.measureIsExcluded
                            ? {
                                  score: (
                                      <Icon color={Colors.DARK_GRAY1} icon={IconNames.DISABLE} />
                                  ),
                                  decile: (
                                      <Icon color={Colors.DARK_GRAY1} icon={IconNames.DISABLE} />
                                  ),
                              }
                            : null),
                    })
                ),
                improvementActivityScores: rawData.improvementActivityScores?.map(
                    (ias: ImprovementActivityScore) => ({
                        ...ias,
                        view: (
                            <button
                                type="button"
                                className="bp3-button bp3-intent-primary bp3-minimal"
                                onClick={() => onImprovementActivitiesViewClick(ias)}
                            >
                                {editMode ? 'Edit' : 'View'}
                            </button>
                        ),
                    })
                ),
                promotingInteroperabilityScores: rawData.promotingInteroperabilityScores?.map(
                    (pis: PromotingInteroperabilityScore) => ({
                        ...pis,
                        view: (
                            <button
                                type="button"
                                className="bp3-button bp3-intent-primary bp3-minimal"
                                onClick={() => onInterOperabilityViewClick(pis)}
                            >
                                View
                            </button>
                        ),
                    })
                ),
            };

            if (cookedData?.preferences) {
                setOptInStatus(cookedData.preferences.optInStatus || '');
                setPerformanceCategoryParticipationOptions(
                    cookedData.preferences.performanceCategoryParticipation
                );
                setParticipationOption(cookedData.preferences.participationOption);
            }

            setTin(cookedData.preferences.tin);
            setNpi(cookedData.preferences.npi);
            setClinicianName(cookedData.preferences.clinicianName || '');
            setTinName(cookedData.preferences.tinName);
            setReportingYear(cookedData.reportingYear);

            const breadCrumbRoute = props.ParentPageLink && props.ParentPageLink.route;
            breadcrumbTrail.labels.push(cookedData.preferences.tin);
            breadcrumbTrail.routes.push(
                `${breadCrumbRoute}?id=${id}&year=${cookedData.reportingYear}`
            );
            breadcrumbTrail.labels.push(`${type} Record`);
            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });

            setFormData(cookedData);
            setOrigFormData(cookedData);
            setLoader(false);
        }
    }, [
        editMode,
        onImprovementActivitiesViewClick,
        onInterOperabilityViewClick,
        onViewClick,
        props.ParentPageLink,
        props.labels.submissionYear,
        props.location.search,
        props.store,
        setBreadcrumbData,
    ]);

    useEffect(() => {
        getMipsRecordDetails();
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [getMipsRecordDetails, setBreadcrumbData]);

    const getSidebarElements = () =>
        sections?.map((section) => ({
            title: section.title || section.sectionType,
            hash: section.sectionType.toLowerCase(),
        }));

    const getSchemaGenerator = (
        sectionType: string
    ): ((title: string, labels: any, description?: string) => JSONSchema6) => {
        const generators: Record<
            string,
            (title: string, labels: any, description?: string) => JSONSchema6
        > = {
            preferences: getPreferenceSchema,
            improvementActivityScores: getImprovementActivitiesSchema,
            promotingInteroperabilityScores: getPromoteInteroperabilitySchema,
            qualityMeasureCategoryScores: getQualityMeasuresSchema,
            scoringDetails: getScoringDetailsSchema,
            Cost: getCostSchema,
        };
        return generators[sectionType] || null;
    };

    const getUISchemaGenerator = (
        sectionType: string
    ): ((
        editMode: boolean,
        formSection: any,
        optInReadOnly: boolean,
        participationOption: string,
        performanceCategoryParticipationOptions: any,
        viewType?: string
    ) => UiSchema) => {
        const setFormDataBySection = (sectionFormData: any) => {
            setFormData({ ...formData, [sectionType]: sectionFormData });
            setHasChange(true);
        };
        const { route } = props.ViewPageLinkIA?.route ? props.ViewPageLinkIA : { route: null };
        const { id, type, providerPractitionerFhirId, year } = queryString.parse(
            props.location.search
        );
        let addIaLink: string | undefined;
        if (route) {
            const routePath = route;
            addIaLink = `${routePath}?id=${id}&type=${type}${getProviderOrEmpty(
                providerPractitionerFhirId
            )}&year=${year}&editState=Add`;
        }
        const generators: Record<
            string,
            (
                editMode: boolean,
                formSection: any,
                optInReadOnly: boolean,
                participationOption: string,
                performanceCategoryParticipationOptions: any,
                viewType?: string
            ) => UiSchema
        > = {
            preferences: getPreferenceUISchema,
            improvementActivityScores: getImprovementActivitiesUISchema(
                formData.improvementActivityScores,
                setFormDataBySection,
                addIaLink,
                props
            ),
            promotingInteroperabilityScores: getPromoteInteroperabilityUISchema(
                null,
                onAttestationViewClick
            ),
            qualityMeasureCategoryScores: getQualityMeasuresUISchema,
            scoringDetails: getScoringDetailsUISchema,
            Cost: getCostUISchema,
        };
        return generators[sectionType] || null;
    };

    const getSchema = (): JSONSchema6 => {
        const properties: Record<string, JSONSchema6> = {};
        let generator: (title: string, labels: any, description?: string) => JSONSchema6;

        sections.map((formSection) => {
            if (isMipsRecordDetailsSection(formSection)) {
                generator = getSchemaGenerator(formSection.sectionType);
                properties[formSection.sectionType] = generator(
                    formSection.title || '',
                    labels,
                    formSection.description
                );
            } else {
                properties[formSection.sectionType] = getSchemaForSection(formSection);
            }
        });
        return {
            type: 'object',
            properties,
        };
    };

    const getUISchema = (): UiSchema => {
        const properties: Record<string, UiSchema> = {};
        let generator: (
            editMode: boolean,
            formSection: any,
            optInReadOnly: boolean,
            participationOption: string,
            performanceCategoryParticipationOptions: any,
            viewType?: string
        ) => UiSchema;

        sections.map((formSection) => {
            if (isMipsRecordDetailsSection(formSection)) {
                generator = getUISchemaGenerator(formSection.sectionType);
                properties[formSection.sectionType] = generator(
                    editMode,
                    formSection,
                    optInStatus !== 'eligible',
                    participationOption,
                    performanceCategoryParticipationOptions,
                    viewType
                );
            }
        });
        return properties;
    };

    const getValidators = () => [
        {
            field: 'preferences.cehrtId',
            validator: validateCehrtId,
            errorMessage: MipsModuleConstants.CehrtIdError,
        },
    ];

    const onError = (e: any) => {
        console.log('errored', e);
    };

    const onSubmit = async () => {
        setExtraErrors(undefined);
        const partOption = (() => {
            switch (formData.preferences.participationOption) {
                case 'Group + Individual':
                    return 'participation-both';
                case 'Group':
                    return 'participation-group';
                case 'Individual':
                    return 'participation-individual';
                default:
                    return formData.preferences.participationOption;
            }
        })();

        const decision = formData?.preferences?.optInDecision
            ?.split(' ')
            .map((option) => option.toLocaleLowerCase())
            .join('-');

        const updatedFormData: MipsManageRecordListOutput = {
            ...formData,
            preferences: {
                ...formData.preferences,
                ...(Array.isArray(formData.preferences.performanceCategoryParticipation)
                    ? {
                          participatingInPromotingInteroperability:
                              formData.preferences.performanceCategoryParticipation.some(
                                  (opt) => opt === 'Promoting Interoperability'
                              ),
                          participatingInImprovementActivities:
                              formData.preferences.performanceCategoryParticipation.some(
                                  (opt) => opt === 'Improvement Activities'
                              ),
                          participatingInQualityMeasures:
                              formData.preferences.performanceCategoryParticipation.some(
                                  (opt) => opt === 'Quality Measures'
                              ),
                      }
                    : {
                          participatingInPromotingInteroperability: false,
                          participatingInImprovementActivities: false,
                          participatingInQualityMeasures: false,
                      }),
            },
        };
        const {
            preferences: {
                participatingInQualityMeasures,
                participatingInImprovementActivities,
                participatingInPromotingInteroperability,
            },
        } = updatedFormData;
        const baseRecord: Omit<MipsManageRecordWriteInput, 'recordType'> = {
            organizationFhirId: formData.organizationFhirId,
            reportingYear: formData.reportingYear,
            preferences: {
                participatingInQualityMeasures,
                participatingInImprovementActivities,
                participatingInPromotingInteroperability,
                cehrtId: formData.preferences.cehrtId,
                optInDecision: decision,
                consentGiven: formData.preferences.consentGiven,
            },
        };
        const record: MipsManageRecordWriteInput = {
            ...baseRecord,
            ...(viewType === 'Group'
                ? {
                      recordType: 'group',
                      organizationAffiliationFhirId: formData.organizationAffiliationFhirId,
                      preferences: {
                          ...baseRecord.preferences,
                          participationOption: partOption,
                      },
                  }
                : {
                      recordType: 'individual',
                      mipsPractitionerRoleFhirId: formData.mipsPractitionerRoleFhirId,
                      preferences: {
                          participationOption: formData.preferences.participationOption,
                          ...baseRecord.preferences,
                      },
                  }),
        };
        try {
            const response = await Promise.all([
                ...updateImprovementActivities(updatedFormData, origFormData),
                Api.mips.saveRecordDetails(record),
            ]);
            if (response.every((r) => r?.ok && r.data?.successful)) {
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Success</span>
                            <br />
                            <br />
                            <span>Save Successful!</span>
                        </p>
                    ),
                    intent: Intent.SUCCESS,
                    icon: 'tick-circle',
                });
                setFormData(formData);
                setOrigFormData(formData);
            } else {
                setFormData(origFormData);
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Error Occurred!</span>
                            <br />
                            <br />
                            <span>
                                Oops! Looks like something went wrong and your changes could not be
                                saved. Please try again later.
                            </span>
                        </p>
                    ),
                    intent: Intent.DANGER,
                    icon: 'error',
                });
            }
        } catch (e) {
            setFormData(origFormData);
            showToast({
                message: (
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Error Occurred!</span>
                        <br />
                        <br />
                        <span>
                            Oops! Looks like something went wrong and your changes could not be
                            saved. Please try again later.
                        </span>
                    </p>
                ),
                intent: Intent.DANGER,
                icon: 'error',
            });
        } finally {
            setEditMode(false);
            setHasChange(false);
            // Disable the save button until changes are made to the page
            setSaving(true);
        }
    };

    const onChange = (e: any) => {
        if (editMode) {
            setHasChange(true);
        }
        setFormData(e.formData);
    };

    const getEditViewMode = (rawData: MipsManageRecordListOutput): MipsManageRecordListOutput => ({
        ...rawData,
        ...(rawData.qualityMeasureCategoryScores?.length
            ? {
                  qualityMeasureCategoryScores: rawData.qualityMeasureCategoryScores.map(
                      (qm: QualityMeasureScore) => ({
                          ...qm,
                          view: (
                              <div
                                  style={{ color: '#137CBD' }}
                                  onClick={() => onViewClick(qm)}
                                  aria-hidden="true"
                              >
                                  {!editMode ? (
                                      <Button
                                          intent="primary"
                                          className="bp3-minimal"
                                          icon="edit"
                                      ></Button>
                                  ) : (
                                      'View'
                                  )}
                              </div>
                          ),
                          ...(qm.measureIsExcluded
                              ? {
                                    score: (
                                        <Icon color={Colors.DARK_GRAY1} icon={IconNames.DISABLE} />
                                    ),
                                    decile: (
                                        <Icon color={Colors.DARK_GRAY1} icon={IconNames.DISABLE} />
                                    ),
                                }
                              : {}),
                      })
                  ),
              }
            : {}),
        ...(rawData.improvementActivityScores?.length
            ? {
                  improvementActivityScores: rawData.improvementActivityScores.map(
                      (ia: ImprovementActivityScore) => ({
                          ...ia,
                          view: (
                              <div
                                  style={{ color: '#137CBD' }}
                                  onClick={() => onImprovementActivitiesViewClick(ia)}
                                  aria-hidden="true"
                              >
                                  {!editMode ? (
                                      <Button
                                          intent="primary"
                                          className="bp3-minimal"
                                          icon="edit"
                                      />
                                  ) : (
                                      'View'
                                  )}
                              </div>
                          ),
                      })
                  ),
              }
            : {}),
        ...(rawData.promotingInteroperabilityScores?.length
            ? {
                  promotingInteroperabilityScores: rawData.promotingInteroperabilityScores.map(
                      (pi: PromotingInteroperabilityScore) => ({
                          ...pi,
                          view: (
                              <button
                                  type="button"
                                  className="bp3-button bp3-intent-primary bp3-minimal"
                                  onClick={() => onInterOperabilityViewClick(pi)}
                              >
                                  View
                              </button>
                          ),
                      })
                  ),
              }
            : {}),
    });

    const editToggle = () => {
        const toggleEditMode = () => {
            setEditMode(!editMode);
            const newRawData = getEditViewMode(origFormData);
            setFormData(newRawData);
            setHasChange(false);
        };

        // toggleEditMode();
        if (editMode && hasChange) {
            setConfirmationDialogOpen(true);
        } else {
            toggleEditMode();
        }
    };
    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setEditMode(!editMode);
        setFormData(origFormData);
        setHasChange(false);
        setConfirmationDialogOpen(false);
    };
    const groupCategorization = viewType === 'Individual' ? `TIN: ${tin}` : '';
    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={handleConfirmationDialogClose}
                handleOkay={handleConfirmationDialogOkay}
            />
            <SubmissionErrorDialog
                isOpen={submissionErrorDialogOpen}
                handleClose={handleSubmissionErrorDialogClose}
                handleOkay={handleSubmissionErrorDialogOkay}
                errors={submissionErrors}
            />
            <Form
                recordId={viewType === 'Group' ? `${tinName}` : `${clinicianName}`}
                recordName={viewType === 'Group' ? `TIN: ${tin}` : `NPI: ${npi}`}
                recordText={[groupCategorization, reportingYear]}
                sidebarElements={getSidebarElements()}
                schema={getSchema()}
                uiSchema={getUISchema()}
                validators={getValidators()}
                onChange={(e: any) => onChange(e)}
                formData={editMode ? formData : origFormData}
                onError={onError}
                onSubmit={onSubmit}
                extraErrors={extraErrors}
            >
                <FormHeader
                    title={`${viewType} Record` || 'Mips Record'}
                    editMode={editMode}
                    allowEditing={!loader}
                    allowCancel={!saving}
                    allowSave={hasChange && !saving}
                    enableEditMode={() => editToggle()}
                    disableEditMode={() => editToggle()}
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(withDataDictionary(MipsRecord));
