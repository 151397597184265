import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getGeneralUISchema = (editMode: boolean, createMode: boolean): UiSchema => {
    const readOnlyFields = !(editMode || createMode);

    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:hash': 'general',
        'ui:layout': [
            {
                organizationName: { sm: 12, md: 6, lg: 4, xl: 4 },
                reportingName: { sm: 12, md: 6, lg: 4, xl: 4 },
            },
            {
                organizationId: { sm: 12, md: 6, lg: 4, xl: 4 },
                tin: { sm: 12, md: 6, lg: 4, xl: 4 },
            },
            {
                address: { sm: 12, md: 6, lg: 4, xl: 4 },
                buildingSuiteOther: { sm: 12, md: 6, lg: 4, xl: 4 },
            },
            {
                city: { sm: 12, md: 6, lg: 4, xl: 4 },
                state: { sm: 12, md: 6, lg: 3, xl: 3 },
                zipCode: { sm: 12, md: 4, lg: 2, xl: 2 },
            },
        ],
        organizationName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        reportingName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
            'ui:options': {
                helpText: 'Enter a shortened practice name to display in reports.',
            },
        },
        organizationId: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        tin: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
            'ui:options': {
                helpText: 'Taxpayer Identification Number',
            },
        },
        address: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        buildingSuiteOther: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        city: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        state: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : 'StateWidget',
        },
        zipCode: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
    };

    if (!createMode) {
        uiSchema['ui:layout'] = [
            {
                status: { md: 4 },
            },
            ...uiSchema['ui:layout'],
        ];

        uiSchema.status = {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'SwitchWidget',
        };
    }

    return uiSchema;
};

export const getGeneralSchema = (title: string): JSONSchema6 => {
    const schema: JSONSchema6 = {
        title,
        type: 'object',
        required: [
            'organizationName',
            'organizationId',
            'tin',
            'address',
            'city',
            'state',
            'zipCode',
        ],
        properties: {
            status: { type: 'boolean', title: 'Practice Status', default: true },
            id: { type: 'string', title: 'ID' },
            organizationName: { type: 'string', title: 'Practice Name' },
            reportingName: { type: 'string', title: 'Reporting Name' },
            organizationId: { type: 'string', title: 'Practice ID' },
            tin: { type: 'string', title: 'TIN' },
            address: { type: 'string', title: 'Address' },
            buildingSuiteOther: { type: 'string', title: 'Building/Suite/Other' },
            city: { type: 'string', title: 'City' },
            state: { type: 'string', title: 'State' },
            zipCode: { type: 'string', title: 'Zip Code' },
        },
    };

    return schema;
};
