import React, { FC } from 'react';
import { style } from 'typestyle';
import { Button, Classes, Dialog, H5, Intent } from '@blueprintjs/core';
import { TextField } from '@amxjs/ui';

interface DenyDialogProps {
    onClose: (event?: React.SyntheticEvent<HTMLElement>) => void;
    onSubmit: (event?: React.SyntheticEvent<HTMLElement>) => void;
    handleMessageChange: any;
    isOpen: boolean;
    title: string;
    description: string;
    cancelButtonDescription: string;
    submitButtonDescription: string;
    message: string;
}

const containerClassName = style({ padding: '15px', backgroundColor: 'white', width: '360px' });
const contentClassName = style({ display: 'flex', justifyContent: 'flex', marginTop: '15px' });

const TinValidationDenyDialog: FC<DenyDialogProps> = (props: DenyDialogProps) => {
    return props.isOpen ? (
        <Dialog
            onClose={props.onClose}
            isOpen={props.isOpen}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
        >
            <div className={containerClassName}>
                <H5>{props.title}</H5>
                <p>{props.description}</p>
                <H5>Reason for deny</H5>
                <TextField
                    value={props.message}
                    multiLine
                    label=""
                    onChange={props.handleMessageChange}
                />
                <div className={contentClassName}>
                    <Button
                        className={Classes.POPOVER_DISMISS}
                        style={{ marginRight: 10 }}
                        onClick={props.onClose}
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                    >
                        {props.cancelButtonDescription}
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={props.onSubmit}
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                    >
                        {props.submitButtonDescription}
                    </Button>
                </div>
            </div>
        </Dialog>
    ) : null;
};

export default TinValidationDenyDialog;
