import { createClient } from '@amxjs/api/client';
import { Routes } from '../../server/routes';

// Use Routes type from server for type-checking
const SERVER_API_URL = (window.SERVER_CONFIG.REACT_APP_SERVER_API_URL as string) || '';

const Api = createClient<Routes>(SERVER_API_URL, {
    getAffectedIndividuals: null,
    getSurveyList: null,
    downloadSurvey: null,
    getVizUrl: null,
    queryFhirResource: null,
    getResources: null,
    clinicianManagement: {
        updateClinician: null,
        queryForClinician: null,
        queryForOrganization: null,
    },
    userManagement: {
        getUserDetail: null,
        createUser: null,
        updateUser: null,
        getPersonasAndOrganizations: null,
    },
    organizationManagement: {
        getOrganizationDetail: null,
        createOrganization: null,
        updateOrganization: null,
    },
    encounter: {
        getEncounterDetail: null,
    },
    patient: {
        getPatientDetail: null,
    },
    mips: {
        updateTin: null,
        getMipsParticipationRecordDetails: null,
        getMipsPerformanceRecordDetails: null,
        updateMipsParticipation: null,
        submitMips: null,
        submitOptInElection: null,
        prescoreAll: null,
        readForRecordDetails: null,
        saveRecordDetails: null,
        editImprovementActivities: null,
        deleteImprovementActivity: null,
        editQualityMeasure: null,
        tinValidation: {
            getDocuments: null,
            getDocument: null,
            approveDocument: null,
            rejectDocument: null,
            submitDocument: null,
            deleteDocument: null,
        },
        pIAttestation: {
            getPIAttestationCombined: null,
            createPIAttestation: null,
            updatePIAttestation: null,
            removeMeasureReport: null,
            finalizeAttestation: null,
        },
    },
    getMeshConfig: null,
    dataIngestion: {
        getDataSpecifications: null,
        getReviewResult: null,
    },
});

export default Api;
