import React from 'react';
import { ErrorPage } from '@amxjs/ui';
import { IconName, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

const DatabaseError = () => (
    <ErrorPage
        fullScreen
        title=":( Error"
        description={[
            'Looks like there is an error loading the application.',
            'Please try again later.',
        ]}
        footerImage="https://www.hemophilia.org/sites/default/files/MyBDC-Logo.png"
    />
);

const GenericError = () => (
    <ErrorPage
        fullScreen
        title=":( Error"
        description={['Something went wrong, but rest assured.', 'Our best people are on it!']}
    />
);

const KeycloakError = () => (
    <ErrorPage
        fullScreen
        title="We had trouble authenticating you."
        titleIcon={'blocked-person' as IconName}
        titleIntent={'danger' as Intent}
        description={['Wait a few minutes and try again.']}
    />
);

const MeshError = () => (
    <ErrorPage
        fullScreen
        title=":( Error"
        description={[
            'Looks like there is an error with loading the application.',
            'Please try again later.',
        ]}
    />
);

const MissingPageError = () => (
    <div
        style={{
            fontSize: '64px',
            height: '100%',
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
            width: '100%',
        }}
    >
        <ErrorPage title="This page doesn't exist or has been disabled">
            <Link key="home" style={{ fontSize: '48px', marginTop: '16px' }} to="/">
                Back to Home
            </Link>
        </ErrorPage>
    </div>
);

const TableauError = () => (
    <ErrorPage
        title="Connection error."
        titleIcon={'error' as IconName}
        titleIntent={'danger' as Intent}
        description={[
            'We are having trouble loading the health reports visualization at the moment.',
            'Please try again later.',
        ]}
    />
);

export { DatabaseError, GenericError, KeycloakError, MeshError, MissingPageError, TableauError };
