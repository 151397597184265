/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { JSONPath } from 'jsonpath-plus';
import { TTableEmptyProps } from '@amxjs/ui';
import { cloneDeep, get } from 'lodash';
import {
    FhirList as FhirListMeshProps,
    FhirListColumn,
    FhirListRowAction,
    FhirListTableAction,
} from '../../../generator/generated/MeshInterfaces';
import Api from '../../core/Api';
import Store from '../../core/Store';
import List from './List';

export interface FhirListColumnWithRenderMode extends FhirListColumn {
    renderMode?:
        | 'default'
        | 'tag'
        | 'active'
        | 'date'
        | 'dash'
        | 'checkbox'
        | 'select'
        | 'iconAndText';
    conditionalJsonPath?: Record<string, any>;
    valueSet?: Record<string, string>;
    callbackToDetermineIconAndText?: (jsonData: any) => void; // If the renderMode is of type 'iconAndText' this needs to be passed in
}

export interface FhirListTableActionWithCallBacks extends FhirListTableAction {
    callbackOnTriggerAction?: () => void;
}

export interface FhirListInterfaceProps extends FhirListMeshProps {
    store: Store;
    onFilterStringChange?: (filterString: string) => void;
    showSearchBar?: boolean;
    searchPlaceholderText?: string;
    unsearchableColumns?: string[];
    columns: FhirListColumnWithRenderMode[];
    description?: JSX.Element;
    // Maps the name of a schema to a react component class. ClinicianDetails.tsx is an example of this.
    // Used to prevent circular dependencies from MeshComponentMapper.ts
    schemaToMeshType?: Map<string, any>;
    emptyDataListMessage?: TTableEmptyProps; // Messages to be shown on the empty list when there are no rows of data
    enableDropdownActionMenu?: boolean;
    dropdownActionMenuParent?: Record<string, any>;
    onDataReceived?: (data: any) => void;
    dataFilter?: Record<string, any>;
    updatedRow?: any;
    onLoadMore?: () => void;
    onSortChanged?: (value: string, columnId: string) => void;
    appendResults?: boolean;
    useServerSideSearch?: boolean;
    readyToReadData?: boolean;
    tableActions?: FhirListTableActionWithCallBacks[];
    skipTotalCount?: boolean;
    separateCallForTotalCount?: boolean;
    graphQLQueryForTotalCount?: string;
}

function FhirList(props: FhirListInterfaceProps & RouteComponentProps) {
    const [rowData, setRowData] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingTotalCount, setLoadingTotalCount] = useState(true);

    useEffect(() => {
        // An optional boolean to be passed in, if undefined or set to true this will read data as expected
        // If the boolean is not undefined and it is not ready to read for data then this means the page might still be
        // loading some initial state and needs a bit more time before we should attempt any API calls to get data on the list.
        if (props.readyToReadData !== undefined && !props.readyToReadData) {
            return;
        }
        if (!props.appendResults) {
            setLoading(true);
            setLoadingTotalCount(true);
        }
        if (props.graphQLQuery !== '') {
            Api.getResources(props.graphQLQuery)
                .then((response) => {
                    let listData: any = [];
                    const rawData = response.data.result;

                    if (rawData) {
                        if (props.appendResults) {
                            const existingData: any = cloneDeep(rowData);
                            listData = existingData.concat(rawData.returnData);
                            if (!props.skipTotalCount && !props.separateCallForTotalCount) {
                                setTotalRows(rawData.meta.totalCount);
                                setLoadingTotalCount(false);
                            }
                        } else {
                            listData = rawData.returnData;
                            if (
                                rawData.meta.totalCount &&
                                !props.skipTotalCount &&
                                !props.separateCallForTotalCount
                            ) {
                                setTotalRows(rawData.meta.totalCount);
                                setLoadingTotalCount(false);
                            }
                        }

                        if (props.dataFilter) {
                            let filteredRowCount = 0;
                            const data = listData.filter((rawDataForFilter: any) => {
                                const field = JSONPath({
                                    path: props.dataFilter && props.dataFilter.path,
                                    json: rawDataForFilter,
                                })[0];

                                if (
                                    (field &&
                                        props.dataFilter &&
                                        !props.dataFilter.values.includes(field)) ||
                                    (props.dataFilter &&
                                        props.dataFilter.displayUnsetValues &&
                                        !field)
                                ) {
                                    return rawDataForFilter;
                                }
                                filteredRowCount++;
                            });

                            setTotalRows(
                                rawData.meta.totalCount
                                    ? rawData.meta.totalCount - filteredRowCount
                                    : data.length
                            );
                            listData = cloneDeep(data);
                        }

                        setLoading(false);

                        if (props.onDataReceived) {
                            if (response.data) {
                                props.onDataReceived(
                                    {
                                        valueSet: response.data.ValueSet,
                                        results: cloneDeep(listData),
                                    } || []
                                );
                            }
                        }
                        setRowData(cloneDeep(listData));
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });

            if (props.separateCallForTotalCount && props.graphQLQueryForTotalCount) {
                Api.getResources(props.graphQLQueryForTotalCount)
                    .then((response) => {
                        const rawData = response.data.result;

                        if (rawData) {
                            if (rawData.meta.totalCount && !props.skipTotalCount) {
                                setTotalRows(rawData.meta.totalCount);
                            }
                        }
                    })
                    .finally(() => {
                        setLoadingTotalCount(false);
                    });
            }
        }
    }, [props.graphQLQuery]);

    useEffect(() => {
        if (props.updatedRow && props.updatedRow.rowIdPath && props.updatedRow.rowJson) {
            const { rowIdPath, rowJson } = props.updatedRow;
            const rowDataToUpdate = cloneDeep(rowData);

            const updatedRowId = JSONPath({
                path: rowIdPath,
                json: rowJson,
            })[0];
            let rowWasUpdated = false;

            const updatedRowData: any = rowDataToUpdate.map((row: any) => {
                const existingRowId = JSONPath({
                    path: rowIdPath,
                    json: row,
                })[0];

                if (updatedRowId === existingRowId) {
                    rowWasUpdated = true;
                    return rowJson;
                }
                return row;
            });

            if (rowWasUpdated) {
                setRowData(cloneDeep(updatedRowData));
            }
        }
    }, [props.updatedRow]);

    const formatSingleRecord = (rawRecord: any) => {
        const record: { [key: string]: any } = {};
        props.columns.map((column) => {
            if (column.jsonPath) {
                let dataFound = false;
                // jsonPath is an array to allow for multiple paths for where the data for a column can come from
                // This is used in cases where data might be in one spot on some records, but if not filled in should have a
                // fallback value used instead. Another example use case is if the data model has changed and where data
                // is coming from also changed. We can check that it is available in the new location and then fall-back if
                // it does not exist.
                column.jsonPath.forEach((jsonPath: string) => {
                    const result = JSONPath({ path: jsonPath, json: rawRecord });
                    if (result.length && !dataFound) {
                        dataFound = true;
                        record[column.id] = result.length === 1 ? result[0] : result;
                    }
                    if (!record[column.id]) {
                        record[column.id] = undefined;
                    }
                });
            }
            if (column.conditionalJsonPath) {
                const condition = JSONPath({
                    path: column.conditionalJsonPath.path,
                    json: rawRecord,
                })[0];
                // eslint-disable-next-line prefer-destructuring
                record[column.id] = JSONPath({
                    path:
                        condition === column.conditionalJsonPath.condition
                            ? column.conditionalJsonPath.fulfilled
                            : column.conditionalJsonPath.unfulfilled,
                    json: rawRecord,
                })[0];

                if (!record[column.id]) {
                    record[column.id] = undefined;
                }
            }
            // The display value is intended to be filled in in the List.tsx component via a callback that the user of this renderMode should provide
            if (column.renderMode === 'iconAndText') {
                record[column.id] = rawRecord;
            }
        });
        return record;
    };

    const formatRawDataMany = (rawData: any[]) => {
        const parsedRecords: any[] = [];

        if (rawData) {
            const rowActions: FhirListRowAction[] = [];

            if (props.rowActions) {
                props.rowActions.map((action) => {
                    if (action.behavior === 'Navigate') {
                        rowActions.push(action);
                    }
                });
            }

            rawData.map((dataRow) => {
                let path = null;
                const disabledActions: string[] = [];
                rowActions.map((rowAction) => {
                    path = rowAction && rowAction.idJsonPath ? rowAction.idJsonPath : '';
                    const dependencyPath = get(rowAction, 'dependency.fieldJsonPath', null);
                    const dependencyModes = get(rowAction, 'dependency.modes', null);

                    if (dependencyPath && dependencyModes) {
                        const jsonPath = JSONPath({ path: dependencyPath, json: dataRow });
                        if (Boolean(jsonPath?.[0]) !== dependencyModes.disabled) {
                            disabledActions.push(rowAction.id);
                        }
                    }
                });
                parsedRecords.push({
                    ...formatSingleRecord(dataRow),
                    // Used to determine what to create for the url for the ?id={Data}
                    idFieldForLink: path ? JSONPath({ path, json: dataRow })[0] : '',
                    disabledActions,
                });
            });
        }

        return parsedRecords;
    };

    const getRowActions = () => {
        if (props.rowActions) {
            const actions: any[] = [];

            props.rowActions.map((action) => {
                actions.push({
                    ...action,
                    idName: 'idFieldForLink',
                });
            });
            return actions;
        }
    };

    const onSortChanged = (sortValue: string, columnId: string) => {
        setRowData([]);
        setTotalRows(undefined);
        setLoading(true);
        setLoadingTotalCount(true);
        if (props.onSortChanged) {
            props.onSortChanged(sortValue, columnId);
        }
    };

    const onFilterStringChange = (filterString: string) => {
        setRowData([]);
        setTotalRows(undefined);
        setLoading(true);
        setLoadingTotalCount(true);
        if (props.onFilterStringChange) {
            props.onFilterStringChange(filterString);
        }
    };

    return (
        <List
            {...props}
            onSortChanged={onSortChanged}
            onFilterStringChange={onFilterStringChange}
            loading={loading}
            totalCountLoading={loadingTotalCount}
            rowActions={getRowActions()}
            rowsOfData={formatRawDataMany(rowData)}
            totalRows={totalRows}
            editing
        />
    );
}

export default withRouter(FhirList);
