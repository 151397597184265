import React, { useState, useEffect } from 'react';
import { InformationOverlay } from '@amxjs/ui';
import { IconName, Intent } from '@blueprintjs/core';
import { welcomeScreen } from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';

interface WelcomeScreenProps extends welcomeScreen {
    store: Store;
}

function WelcomeScreen(props: WelcomeScreenProps) {
    const { listItems } = props;
    const [listItemsWithCasts, setListItemsWithCasts] = useState([]);
    const [overlayOpen, setOverlayOpen] = useState(true);

    useEffect(() => {
        if (listItems) {
            const items: any = listItems.map((listItem) => ({
                ...listItem,
                icon: listItem.icon as IconName,
                intent: listItem.intent as Intent,
            }));
            setListItemsWithCasts(items);
        }
    }, [listItems]);

    const handleCloseWelcomeScreen = (dismissedFlag: boolean) => {
        localStorage.setItem('welcomeScreenDismissedSessionId', props.store.keycloakSessionId);
        if (dismissedFlag) {
            localStorage.setItem('welcomeScreenDismissed', 'yes');
        }
        setOverlayOpen(false);
    };

    return localStorage.getItem('welcomeScreenDismissedSessionId') !==
        props.store.keycloakSessionId &&
        localStorage.getItem('welcomeScreenDismissed') !== 'yes' &&
        props.store.siteConfiguration &&
        overlayOpen ? (
        <InformationOverlay
            action={handleCloseWelcomeScreen}
            isOpen
            canOutsideClickClose={false}
            canEscapeKeyClose={false}
            autoFocus={false}
            {...props}
            listItems={listItemsWithCasts}
        />
    ) : null;
}

export default WelcomeScreen;
