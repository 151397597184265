import React, { useEffect } from 'react';
import { Column, Row } from '@amxjs/ui';
import { withRouter, RouteComponentProps } from 'react-router';
import TableauCard from './TableauCard';
import { CardSet as MeshCardSet, Card, Page } from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';
import TableauErrorBoundary from '../error/TableauErrorBoundary';
import SurveyListCard from './SurveyListCard';
import ResourceListCard from './ResourceListCard';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';

interface CardSetProps extends MeshCardSet {
    store: Store;
}

function CardSet(props: CardSetProps & RouteComponentProps) {
    const { setBreadcrumbData } = useBreadcrumbContext();

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.name);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    const getCards = () => {
        if (props.cards == null) return [];
        return props.cards.map((card: Card) => {
            const cardMap: { [key: string]: JSX.Element } = {
                Survey: <SurveyListCard {...card} />,
                Graph: (
                    <TableauErrorBoundary store={props.store} isCard>
                        <TableauCard {...card} />
                    </TableauErrorBoundary>
                ),
                Resource: <ResourceListCard {...card} store={props.store} />,
                General: <div key="General">I am a card.</div>,
            };
            return (
                <Column key={card.title || 'General'} md={4}>
                    {cardMap[card.type || 'General']}
                </Column>
            );
        });
    };

    const getAlignment = () => {
        switch (props.cardAlignment) {
            case 'left':
                return 'flex-start';
            case 'center':
                return 'center';
            case 'right':
                return 'flex-end';
            default:
                return 'flex-start';
        }
    };

    return <Row justifyContent={getAlignment()}>{getCards()}</Row>;
}

export default withRouter(CardSet);
