import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getProceduresSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Procedures',
        items: {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                    title: 'Name',
                },
                status: {
                    type: 'string',
                    title: 'Status',
                },
                date: {
                    type: 'string',
                    title: 'Procedure Date',
                },
                product: {
                    type: 'string',
                    title: 'Product Used',
                },
            },
        },
    };
};

export const getProceduresSectionUISchema = (sectionProps: PatientDetailSection): UiSchema => {
    const emptyListMessage = {
        title: 'No Procedures Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'procedures',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
            itemsPerPage: sectionProps.itemsPerPage as number,
        },
        items: {
            name: {
                'ui:widget': 'SimpleTextWidget',
            },
            status: {
                'ui:widget': 'SimpleTextWidget',
            },
            date: {
                'ui:readonly': true,
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    renderMode: 'date',
                },
            },
            product: {
                'ui:widget': 'SimpleTextWidget',
            },
        },
    };
};
