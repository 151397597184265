import React from 'react';

import { TextField } from '@amxjs/ui';

import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';

@observer
class EmailWidget extends React.Component<WidgetProps> {
    enumOptions: any;

    constructor(props: any) {
        super(props);

        const { options } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;
    }

    /* eslint-disable class-methods-use-this */
    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: props.options,
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,

            schema: props.schema,

            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
        };
    }

    render() {
        const props = this.convertProps(this.props);

        return (
            <TextField
                className={
                    this.props.rawErrors && this.props.rawErrors.length > 0 ? 'error-border' : ''
                }
                label={props.label || props.schema.title}
                autoFocus={props.autofocus}
                showRequiredIndicator={props.required}
                disabled={props.disabled || props.readonly}
                placeholder={props.readonly ? 'N/A' : ' '}
                // type="email" // to be implemented by amx/ui
                value={props.value}
                onChange={(v) => {
                    props.onChange(v === '' ? this.props.options.emptyValue : v);
                }}
            />
        );
    }
}

export default EmailWidget;
