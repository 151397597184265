import { Path } from 'history';

export enum Status {
    ACTIVE = 'Active',
    DEPRECATED = 'Deprecated',
    DRAFT = 'Draft',
    SUPPORTED = 'Supported',
    RETIRED = 'Retired',
    UNKNOWN = 'Unknown',
}

export enum StatusUploadDescription {
    PROCESSING = 'Processing',
    UPLOADED = 'Uploaded',
    UPLOAD_FAILED = 'Upload Failed. Please try again.',
}

export enum ValidationStatus {
    CORRUPTED_FILE = 'Corrupted File',
    FILE_CONTAINS_ERROR = 'File Contains Error',
    NO_ERROR = 'No errors',
}

export enum DialogMode {
    RESET_DATA = 1,
    RESET_WITH_NEW_DATA = 2,
}

export interface DataSpecification {
    id: string;
    url: string;
    specType: string;
    releaseDate: string;
    status: Status;
    description: string;
    dataSpecPDFLink?: Path;
}

export interface ErrorDetail {
    fileName?: string;
    validationStatus?: ValidationStatus;
    rowNumber?: number;
    columnName?: string;
    errorDescription?: string;
    errorResolution?: string;
}

export interface ReviewResult {
    fileUploaded?: string;
    fileStatus?: string;
    uploadTime?: string;
    specType?: string;
    totalFilesIncluded?: string;
    corruptedFiles?: string;
    filesWithErrors?: string;
    filesWithNoErrors?: string;
    errorDetails?: ErrorDetail[];
}

export const reviewResultError: ReviewResult = {
    fileUploaded: '-',
    fileStatus: StatusUploadDescription.UPLOAD_FAILED,
    uploadTime: '-',
    specType: '-',
    totalFilesIncluded: '-',
    corruptedFiles: '-',
    filesWithErrors: '-',
    filesWithNoErrors: '-',
    errorDetails: [],
};
