import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { TTableEmptyProps } from '@amxjs/ui';

import {
    sfvReviewDataSpecificationsColumn,
    sfvReviewDataSpecifications,
    sfvAssembleData,
    sfvUploadData,
    sfvReviewResults,
    sfvNextSteps,
} from '../../../generator/generated/MeshInterfaces';

const UNSET = 'UNSET';
const ALLOWED_FILE_SIZE = 1048576;

export const getSFVReviewDataSpecificationsSchema = ({
    title,
    columns,
}: sfvReviewDataSpecifications): JSONSchema6 => {
    const properties: { [k: string]: JSONSchema6Definition } = {};
    if (columns) {
        columns.forEach((column: sfvReviewDataSpecificationsColumn) => {
            if (column.id === 'actionLink') {
                properties[column.id] = {
                    type: 'object',
                    title: ' ',
                    properties: {
                        text: {
                            type: 'string',
                        },
                        url: {
                            type: 'string',
                        },
                    },
                };
            } else {
                properties[column.id] = {
                    type: 'string',
                    title: column.label,
                };
            }
        });
    }
    return {
        title,
        type: 'object',
        required: [],
        properties: {
            description: {
                type: 'string',
            },
            specs: {
                type: 'array',
                items: {
                    type: 'object',
                    properties,
                },
            },
        },
    };
};

export const getSFVReviewDataSpecificationsUISchema = ({
    itemsPerPage,
    columns,
    actionLinkClassName,
    emptyDataListMessage,
}: sfvReviewDataSpecifications & {
    actionLinkClassName: string;
    emptyDataListMessage?: TTableEmptyProps;
}): UiSchema => {
    const emptyListMessage = {
        title: 'No Data Specifications',
        description: '',
    };
    const getSortOrder = (columnId: string) => {
        if (columns && columns.length > 0) {
            const col = columns.find((column) => column.id === columnId);
            return col ? col.sortable : UNSET;
        }
        return UNSET;
    };
    return {
        'ui:field': 'layout',
        'ui:hash': 'reviewDataSpecifications',
        'ui:layout': [
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                specs: { sm: 12 },
            },
        ],
        description: {
            'ui:readonly': true,
            'ui:widget': 'HTMLWidget',
            'ui:options': {},
        },
        specs: {
            'ui:field': 'list',
            'ui:options': {
                emptyListMessage,
                emptyDataListMessage,
                itemsPerPage,
            },
            items: {
                specType: {
                    'ui:options': {
                        orderable: true,
                        initialSort: getSortOrder('specType'),
                    },
                },
                releaseDate: {
                    'ui:readonly': true,
                    'ui:widget': 'DateWidget',
                    'ui:options': {
                        orderable: true,
                        initialSort: getSortOrder('releaseDate'),
                        renderMode: 'date',
                    },
                },
                status: {
                    'ui:options': {
                        orderable: true,
                        initialSort: getSortOrder('status'),
                    },
                },
                description: {
                    'ui:options': {},
                },
                actionLink: {
                    'ui:widget': 'URLWidget',
                    'ui:options': {
                        className: actionLinkClassName,
                    },
                },
            },
        },
    };
};

export const getSFVAssembleDataSchema = ({ title }: sfvAssembleData): JSONSchema6 => {
    return {
        title,
        type: 'object',
        required: [],
        properties: {
            description: {
                type: 'string',
            },
        },
    };
};

export const getSFVAssembleDataUISchema = (): UiSchema => {
    return {
        'ui:field': 'layout',
        'ui:hash': 'assembleData',
        'ui:layout': [
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        description: {
            'ui:readonly': true,
            'ui:widget': 'HTMLWidget',
            'ui:options': {},
        },
    };
};

export const getSFVUploadDataSchema = ({
    title,
    specTypes,
}: {
    specTypes: string[];
} & sfvUploadData): JSONSchema6 => {
    return {
        title,
        type: 'object',
        required: [],
        properties: {
            description: {
                type: 'string',
            },
            specType: {
                title: 'Spec Type',
                type: 'string',
                enum: specTypes,
            },
            progress: {
                type: 'string',
            },
            file: {
                type: 'string',
            },
        },
    };
};

export const getSFVUploadDataUISchema = ({
    actionRemovalButtonCallback,
}: sfvUploadData & { actionRemovalButtonCallback: any }): UiSchema => {
    return {
        'ui:field': 'layout',
        'ui:hash': 'uploadData',
        'ui:layout': [
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                specType: { sm: 7, md: 6, lg: 4, xl: 3 },
            },
            {
                progress: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                file: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        description: {
            'ui:readonly': true,
            'ui:widget': 'HTMLWidget',
            'ui:options': {},
        },
        specType: {
            'ui:readonly': false,
            'ui:widget': 'SelectWidget',
            'ui:options': {
                objectFieldName: 'name',
            },
        },
        progress: {
            'ui:readonly': false,
            'ui:widget': 'ProgressBarWidget',
            'ui:options': {
                actionRemovalButtonCallback,
            },
        },
        file: {
            'ui:readonly': false,
            'ui:widget': 'FileUploadWidget',
            'ui:options': {
                accept: {
                    'text/csv': ['.csv'],
                    'text/plain': ['.txt'],
                    'application/vnd.ms-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/zip': ['.zip'],
                    'application/x-zip-compressed': ['.zip'],
                },
                acceptedFilesText:
                    'Accepted File Types: Excel.xlsx, ZIP of CSV, ZIP of pipe-delimited.txt',
                maxFileSize: ALLOWED_FILE_SIZE,
            },
        },
    };
};

export const getSFVReviewResultsSchema = ({
    title,
    hide,
}: sfvReviewResults & { hide?: boolean }): JSONSchema6 => {
    return hide
        ? {}
        : {
              title,
              type: 'object',
              required: [],
              properties: {
                  description: {
                      type: 'string',
                  },
                  fileUploaded: {
                      type: 'string',
                      title: 'File Uploaded',
                  },
                  fileStatus: {
                      type: 'string',
                      title: 'File Status',
                  },
                  uploadTime: {
                      type: 'string',
                      title: 'Upload Time',
                  },
                  specType: {
                      type: 'string',
                      title: 'Spec Type',
                  },
                  totalFilesIncluded: {
                      type: 'string',
                      title: 'Total Files included',
                  },
                  corruptedFiles: {
                      type: 'string',
                      title: 'Corrupted Files',
                  },
                  filesWithErrors: {
                      type: 'string',
                      title: 'Files with Errors',
                  },
                  filesWithNoErrors: {
                      type: 'string',
                      title: 'Files with No Errors',
                  },
                  errorDetails: {
                      type: 'array',
                      items: {
                          type: 'object',
                          properties: {
                              fileName: {
                                  type: 'string',
                                  title: 'File Name',
                              },
                              rowNumber: {
                                  type: 'string',
                                  title: 'Row Number',
                              },
                              columnName: {
                                  type: 'string',
                                  title: 'Column Name',
                              },
                              errorDescription: {
                                  type: 'string',
                                  title: 'Error Description',
                              },
                              errorResolution: {
                                  type: 'string',
                                  title: 'Error Resolution',
                              },
                          },
                      },
                  },
              },
          };
};

export const getSFVReviewResultsUISchema = ({
    itemsPerPage,
    corruptedFilesHelperText,
    filesWithErrorsHelperText,
    filesWithNoErrorsHelperText,
    enableActionButton,
    determineIconAndTextForValidationStatus,
    exportDataToCsv,
    disabledButton,
}: sfvReviewResults & {
    determineIconAndTextForValidationStatus?: any;
    exportDataToCsv?: any;
    disabledButton: boolean;
}): UiSchema => {
    return {
        'ui:field': 'layout',
        'ui:hash': 'reviewResults',
        'ui:options': {
            disabledButton,
            enableActionButton,
            actionButtonCallback: (formData?: any) => {
                exportDataToCsv(formData);
            },
        },
        'ui:layout': [
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                fileUploaded: { lg: 3 },
                fileStatus: { lg: 3 },
                uploadTime: { lg: 3 },
                specType: { lg: 3 },
                totalFilesIncluded: { lg: 3 },
                corruptedFiles: { lg: 3 },
                filesWithErrors: { lg: 3 },
                filesWithNoErrors: { lg: 3 },
            },
            {
                errorDetails: { sm: 12 },
            },
        ],
        description: {
            'ui:readonly': true,
            'ui:widget': 'HTMLWidget',
            'ui:options': {},
        },
        fileUploaded: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        fileStatus: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        uploadTime: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        specType: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        totalFilesIncluded: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        corruptedFiles: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                helpText: corruptedFilesHelperText,
            },
        },
        filesWithErrors: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                helpText: filesWithErrorsHelperText,
            },
        },
        filesWithNoErrors: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                helpText: filesWithNoErrorsHelperText,
            },
        },
        errorDetails: {
            'ui:field': 'list',
            'ui:options': {
                showSearchBar: true,
                itemsPerPage,
            },
            items: {
                fileName: {
                    'ui:options': {},
                },
                validationStatus: {
                    'ui:options': {
                        renderMode: 'iconAndText',
                        callbackToDetermineIconAndText: determineIconAndTextForValidationStatus,
                    },
                },
                rowNumber: {
                    'ui:options': {},
                },
                columnName: {
                    'ui:options': {},
                },
                errorDescription: {
                    'ui:options': {},
                },
                errorResolution: {
                    'ui:options': {},
                },
            },
        },
    };
};

export const getSFVNextStepsSchema = ({
    title,
    hide,
}: sfvNextSteps & { hide?: boolean }): JSONSchema6 => {
    return hide
        ? {}
        : {
              title,
              type: 'object',
              required: [],
              properties: {
                  description: {
                      type: 'string',
                  },
              },
          };
};

export const getSFVNextStepsUISchema = (): UiSchema => {
    return {
        'ui:field': 'layout',
        'ui:hash': 'nextSteps',
        'ui:layout': [
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        description: {
            'ui:readonly': true,
            'ui:widget': 'HTMLWidget',
            'ui:options': {},
        },
    };
};
