import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { JSONPath } from 'jsonpath-plus';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import { style } from 'typestyle';

import { Button, ButtonGroup, IconName, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { showToast, TTableEmptyProps } from '@amxjs/ui';
import * as Commons from '@amxjs/fhir-commons';

import {
    FhirListColumn,
    MipsExplorer as MipsExplorerInterface,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import { MipsTinRecord } from '../../../server/routes/Mips/RecordInterface';
import { setQueryParamsInUrl } from '../../../util/utils';
import FhirList, {
    FhirListColumnWithRenderMode,
    FhirListTableActionWithCallBacks,
} from '../../components/Tables/FhirList';
import Store from '../../core/Store';
import { SchemaProvider } from '../../mesh/Schema';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../util/DataDictionary';
import Api from '../../core/Api';
import { getSortingValues, sortValues } from '../utils/filterAndSort';
import ConfirmExitDialog from '../ConfirmExitDialog';
import MipsModuleConstants from './MipsModuleConstants';
import SetUpTinDialog from './SetUpTinDialog';
import { FilterButton } from './models/MipsExplorerModel';

interface MipsExplorerProps extends MipsExplorerInterface {
    store: Store;
}
const marginTop = style({ marginTop: '18px' });

function MipsExplorer(
    props: MipsExplorerProps & RouteComponentProps & InjectedDataDictionaryProps
) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [editMode, setEditMode] = useState(false);
    const [activeTin, setActiveTin] = useState(null);
    const [orgMap, setOrgMap] = useState({} as any);
    const [participationValueSet, setParticipationValueSet] = useState({} as any);
    const [setupTINDialogOpen, setSetupTINDialogOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [editTinUpdatedRow, setEditTinUpdatedRow] = useState({} as any);
    const itemsPerPage = props.itemsPerPage || 25;
    const [paginationOffset, setPaginationOffset] = useState(0);
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const [updatedRow, setUpdatedRow] = useState();
    const [columnSorting, setColumnSorting] = useState<Record<string, string>>(
        getSortingValues(props.columns)
    );
    const [pageInitialized, setPageInitialized] = useState(false);
    const [appendResults, setAppendResults] = useState(false);
    const selectedYearRef = useRef<HTMLSelectElement>(null);
    const [filterString, setFilterString] = useState('');
    const [filterParticipationOption, setFilterParticipationOption] = useState(true);
    const [graphQLQuery, setGraphQLQuery] = useState<string>('');

    const { labels, Years } = props;
    const [selectedYear, setSelectedYear] = useState<any>(Years && Years[0]);
    const participationInlineDescriptions: Record<string, string> = {
        'participation-group': labels.groupMethodInfo || MipsModuleConstants.IndividualOptionInfo,
        'participation-individual':
            labels.individualMethodInfo || MipsModuleConstants.IndividualOptionInfo,
        'participation-both':
            labels.groupPlusIndividualInfo || MipsModuleConstants.GroupPlusIndividualOptionInfo,
    };
    const [activeButtonStyle, setActiveButtonStyle] = useState<FilterButton>(
        FilterButton.PARTICIPATION_ONLY
    );

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        const { year } = queryString.parse(props.location.search);
        if (year) {
            setSelectedYear(year);
        }
        // Set this page initialized boolean to make sure that the pages initial columns states have been fully loaded
        // before attempting to read any data in order to prevent multiple calls for data.
        setPageInitialized(true);
        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [props.ParentPageLink, props.location.search, props.title, setBreadcrumbData]);

    const resetPagination = () => {
        setAppendResults(false);
        setPagesLoaded(1);
        setPaginationOffset(0);
    };

    const onSortChanged = (sortValue: string, columnId: string) => {
        // When sorting changes:
        // 1. the pagination resets (handled here)
        // 2. the list is cleared (handled in FhirList)
        // 3. the list is populated with the new first data set (handled in FhirList)
        resetPagination();
        setColumnSorting({ ...columnSorting, [columnId]: sortValues[sortValue] });
    };

    // Business Rules:
    // If participation Option is not set for the tin, display dash.
    // If any of the reporting entities for the TIN have a Prescore OR Submission Error, display Failed with red alert icon.
    // If at least one reporting entity has NOT been submitted successfully and none are in error, display In Progress.
    // If ALL of the reporting entities for the TIN have been submitted successfully, display Successful with Green tick icon.
    // If there participation option is set and none are submitted and none are in error, display none with a gray indicator.
    const determineIconAndTextForSubmissionColumn = (
        jsonData: any
    ): { iconName?: IconName; iconColor?: string; tooltipText?: string; displayText?: string } => {
        const { submissionStatus } = jsonData;

        if (submissionStatus === '-') {
            return {
                displayText: '-',
            };
        }
        if (submissionStatus === 'Failed') {
            return {
                iconName: IconNames.ERROR,
                iconColor: '#A82A2A',
                displayText: 'Failed',
            };
        }
        if (submissionStatus === 'Successful') {
            return {
                iconName: IconNames.TICK_CIRCLE,
                iconColor: '#0F9960',
                displayText: 'Successful',
            };
        }
        if (submissionStatus === 'In Progress') {
            return {
                iconName: IconNames.WARNING_SIGN,
                iconColor: '#D9822B',
                displayText: 'In Progress',
            };
        }
        return { iconName: IconNames.WARNING_SIGN, iconColor: '#5C7080', displayText: 'None' };
    };

    const getBaseColumns = (): FhirListColumn[] => {
        const jsonPaths: any = {
            tin: ['$.tin'],
            participationOption: ['$.participationOption'],
            tinName: ['$.providerOrganizationName'],
            tinStatus: ['$.tinStatus'],
        };

        return props.columns.map((col) => {
            const fhirColumn: FhirListColumnWithRenderMode = {
                ...col,
                jsonPath: jsonPaths[col.id],
            };

            if (fhirColumn.id === 'participationOption') {
                fhirColumn.renderMode = 'dash';
                fhirColumn.valueSet = participationValueSet;
            } else if (fhirColumn.id === 'submission') {
                fhirColumn.renderMode = 'iconAndText';
                fhirColumn.callbackToDetermineIconAndText = determineIconAndTextForSubmissionColumn;
            } else {
                fhirColumn.renderMode = 'default';
            }

            return fhirColumn;
        });
    };

    const onEditTin = (e: any) => {
        setEditTinUpdatedRow(e);
        setSetupTINDialogOpen(true);
    };

    const setQueryParametersOnNavigate = (routePath: string) => {
        const queryParamData: Record<string, string> = {
            year: selectedYearRef?.current ? selectedYearRef.current.value : selectedYear,
        };
        return setQueryParamsInUrl(routePath, queryParamData);
    };

    useEffect(() => {
        if (orgMap) {
            setActiveTin(
                cloneDeep({
                    ...editTinUpdatedRow,
                    activeOrg: orgMap[editTinUpdatedRow.idFieldForLink as string],
                })
            );
        }
    }, [editTinUpdatedRow, orgMap]);

    const getRowActions = (): any[] => {
        return [
            {
                id: 'set-up-tin',
                label: 'Set Up TIN',
                behavior: 'Custom',
                intent: Intent.PRIMARY,
                idJsonPath: '$.businessOrganizationId',
                link: {},
                callbackOnCustom: onEditTin,
            },
            {
                id: 'tin-validation',
                label: 'TIN Validation',
                behavior: 'Navigate',
                intent: 'primary',
                link: props.TINValidationLink,
                selectedYear,
                idJsonPath: '$.businessOrganizationId',
                setQueryParametersOnNavigate,
            },
            {
                id: 'manage-participation',
                label: 'Manage Participation',
                behavior: 'Navigate',
                intent: 'primary',
                link: props.ManageParticipationLink,
                selectedYear,
                idJsonPath: '$.businessOrganizationId',
                dependency: {
                    fieldJsonPath: '$.participationOption',
                    modes: { disabled: true },
                },
                setQueryParametersOnNavigate,
            },
            {
                id: 'monitor-performance',
                label: 'Monitor Performance & Submit',
                behavior: 'Navigate',
                intent: 'primary',
                link: props.MonitorPerformanceLink,
                selectedYear,
                idJsonPath: '$.businessOrganizationId',
                dependency: {
                    fieldJsonPath: '$.participationOption',
                    modes: { disabled: true },
                },
                setQueryParametersOnNavigate,
            },
        ];
    };

    useEffect(() => {
        setGraphQLQuery(`
    MipsSubmissionOverviewList(searchString: "${filterString}", pageCount: ${itemsPerPage}, offset:${paginationOffset}, year: ${
            selectedYear || MipsModuleConstants.SubmissionYear
        }, organizationId: "") {
        meta {
            totalCount
        }
        returnData {
            tin (includeInSearchString: true sortDirection: "${columnSorting?.tin || ''}")
            cehrtId (includeInSearchString: true)
            participationOption (
                includeInSearchString: true
                sortDirection: "${columnSorting?.participationOption || ''}"
                ${filterParticipationOption ? 'excludeNullValues: true' : ''}
            )
            providerOrganizationName (includeInSearchString: true sortDirection: "${
                columnSorting?.tinName || ''
            }")
            submissionStatus (includeInSearchString: true sortDirection: "${
                columnSorting?.submission || ''
            }")
            tinStatus (includeInSearchString: true sortDirection: "${
                columnSorting?.tinStatus || ''
            }")
            businessOrganizationId
            mipsOrganizationAffiliationId
            providerPractitionerIds
        }
    }
    ValueSet(url: "${Commons.ValueSets.MIPS_PARTICIPATION_VALUE_SET}") {
        compose {
            include {
                concept {
                    display
                    code
                }
            }
        }
    }
    `);
    }, [
        filterString,
        filterParticipationOption,
        itemsPerPage,
        paginationOffset,
        selectedYear,
        columnSorting,
    ]);

    const emptyMessage: TTableEmptyProps = {
        title: MipsModuleConstants.ExplorerEmptyListTitle,
        description: MipsModuleConstants.ExplorerEmptyListDescription,
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setEditMode(!editMode);
        setConfirmationDialogOpen(false);
    };

    const handleSetUpTinDialogClose = () => {
        setActiveTin(null);
        setSetupTINDialogOpen(false);
    };

    const handleSetUpTinDialogOkay = (
        updatedRowId: any,
        existingOrg: any,
        updatedRecord: MipsTinRecord
    ) => {
        setSetupTINDialogOpen(false);
        if (updatedRowId) {
            const orgMapToUpdate = cloneDeep(orgMap);
            const orgMapToUpdateArray = Object.values(orgMapToUpdate);

            const updates = orgMapToUpdateArray.filter(
                (org: any) => org.businessOrganizationId === updatedRowId
            );

            if (updates.length) {
                const updatedOrg = cloneDeep(existingOrg);
                updatedOrg.participationOption = updatedRecord.participationOption;
                updatedOrg.cehrtId = updatedRecord.cehrtId;

                const clonedUpdatedOrg = cloneDeep(updatedOrg);
                setUpdatedRow(clonedUpdatedOrg);
                const updatedMap: any = {};
                orgMapToUpdateArray.forEach((org: any) => {
                    if (org.businessOrganizationId === updatedRecord.businessOrganizationId) {
                        updatedMap[org.businessOrganizationId] = clonedUpdatedOrg;
                    } else {
                        updatedMap[org.businessOrganizationId] = org;
                    }
                });
                setOrgMap(cloneDeep(updatedMap));
            }
        }
    };

    const buildSearch = (filter: string) => {
        resetPagination();
        setFilterString(filter);
    };

    const handleButtonFilter = (filterButton: FilterButton) => {
        resetPagination();
        switch (filterButton) {
            case FilterButton.ALL:
                setFilterParticipationOption(false);
                setActiveButtonStyle(FilterButton.ALL);
                break;
            default:
                setFilterParticipationOption(true);
                setActiveButtonStyle(FilterButton.PARTICIPATION_ONLY);
                break;
        }
    };

    const buildListDescription = () => {
        return (
            <div className={style({ display: 'flex', flexDirection: 'column' })}>
                <span>
                    {/* {'test' || props.labels.submissionYear || MipsModuleConstants.SubmissionYear} */}
                    <select
                        title="descriptionList"
                        ref={selectedYearRef}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.currentTarget.value)}
                    >
                        {props.Years.map((year) => (
                            <option value={year} key={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </span>
                <span className={marginTop}>
                    {props.labels.explorerDescription || MipsModuleConstants.ExplorerDescription}
                </span>
                <ButtonGroup className={marginTop}>
                    <Button
                        intent={activeButtonStyle === FilterButton.ALL ? 'primary' : 'none'}
                        onClick={() => {
                            handleButtonFilter(FilterButton.ALL);
                        }}
                    >
                        All
                    </Button>
                    <Button
                        intent={
                            activeButtonStyle === FilterButton.PARTICIPATION_ONLY
                                ? 'primary'
                                : 'none'
                        }
                        onClick={() => {
                            handleButtonFilter(FilterButton.PARTICIPATION_ONLY);
                        }}
                    >
                        Participating TINs Only
                    </Button>
                </ButtonGroup>
            </div>
        );
    };

    const onDataReceived = (data: any) => {
        const { results, valueSet } = data;

        const updatedMap: any = {};
        results.forEach((org: any) => {
            updatedMap[org.businessOrganizationId] = org;
        });

        if (valueSet) {
            const participationVS = JSONPath({
                path: `$.compose.include[0].concept`,
                json: valueSet,
            })[0];

            const formattedValueSet = participationVS.map(
                (participation: Record<string, string>) => {
                    return {
                        value: participation.code,
                        label: participation.display,
                        inlineInfo: participationInlineDescriptions[participation.code],
                    };
                }
            );
            setParticipationValueSet(formattedValueSet);
        }
        setOrgMap(cloneDeep(updatedMap));
    };

    const onLoadMore = () => {
        setAppendResults(true);
        setPaginationOffset(itemsPerPage * pagesLoaded);
        setPagesLoaded(pagesLoaded + 1);
    };

    const prescoreAll = () => {
        showToast({
            message: `Prescoring all data`,
            intent: Intent.WARNING,
            icon: 'refresh',
        });
        Api.mips
            .prescoreAll(selectedYear || MipsModuleConstants.SubmissionYear)
            .then((response) => {
                if (response.ok && response.data.successful) {
                    showToast({
                        message: `All data has been prescored`,
                        intent: Intent.SUCCESS,
                        icon: 'tick',
                    });
                } else {
                    showToast({
                        message: `Data has failed to be prescored`,
                        intent: Intent.DANGER,
                        icon: 'error',
                    });
                }
            });
    };

    const getTableActions = (): FhirListTableActionWithCallBacks[] => {
        return [
            {
                id: 'prescoreAll',
                label: 'Prescore All',
                behavior: 'TriggerAction',
                intent: 'primary',
                link: {},
                icon: 'full-stacked-chart',
                callbackOnTriggerAction: () => prescoreAll(),
            },
        ];
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={handleConfirmationDialogClose}
                handleOkay={handleConfirmationDialogOkay}
            />
            <SetUpTinDialog
                isOpen={setupTINDialogOpen}
                handleClose={handleSetUpTinDialogClose}
                handleOkay={handleSetUpTinDialogOkay}
                participationOptions={participationValueSet}
                activeTin={activeTin}
                selectedYear={selectedYear}
            />
            <FhirList
                store={props.store}
                allowExport={props.allowExport}
                itemsPerPage={itemsPerPage}
                onLoadMore={onLoadMore}
                appendResults={appendResults}
                title={props.title}
                showSearchBar
                onFilterStringChange={buildSearch}
                description={buildListDescription()}
                graphQLQuery={graphQLQuery}
                columns={getBaseColumns()}
                rowActions={getRowActions()}
                tableActions={getTableActions()}
                schemaId={props.schemaId}
                emptyDataListMessage={emptyMessage}
                onSortChanged={onSortChanged}
                readyToReadData={pageInitialized}
                useServerSideSearch
                enableDropdownActionMenu
                dropdownActionMenuParent={{
                    label: 'Actions',
                    rightIcon: 'caret-down',
                    id: 'manage-actions',
                }}
                onDataReceived={onDataReceived}
                updatedRow={{
                    rowIdPath: 'businessOrganizationId',
                    rowJson: updatedRow,
                }}
            />
        </SchemaProvider>
    );
}

export default withRouter(withDataDictionary(MipsExplorer));
