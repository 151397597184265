import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { style } from 'typestyle';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { getCurrentTimeFromBrowser } from '../../../../util/utils';
import {
    MipsTINValidationApproval as MipsTINValidationApprovalInterface,
    Page,
} from '../../../../generator/generated/MeshInterfaces';
import { TINValidationApprovalData, TINValidationDocument } from '../../../../models/TinDocument';
import Store from '../../../core/Store';
import { useBreadcrumbContext } from '../../../core/BreadcrumbContext';
import { SchemaProvider } from '../../../mesh/Schema';
import { downloadFile } from '../../../components/utils/FileUtils';
import Form from '../../../components/forms/Form';
import FormHeader from '../../../components/forms/components/FormHeader';
import Api from '../../../core/Api';
import { showSuccessfulToast, showToastError } from '../../utils/CommonToasts';
import {
    getMipsTINValidationApprovalDocumentsSchema,
    getMipsTINValidationApprovalDocumentsUISchema,
} from './MipsTINValidationSchemas';
import TinValidationApproveDialog from './TinValidationApproveDialog';
import TinValidationDenyDialog from './TinValidationDenyDialog';

const titleDescriptionStyle = style({
    marginBottom: '40px',
});

interface MipsTINValidationApprovalProps extends MipsTINValidationApprovalInterface {
    store: Store;
}
const MipsTINValidationApproval: FC<MipsTINValidationApprovalProps & RouteComponentProps> = (
    props: MipsTINValidationApprovalProps & RouteComponentProps
) => {
    const [selectedYear, setSelectedYear] = useState<number>();
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [key] = useState<number>(Date.now());
    const [schema, setSchema] = useState<JSONSchema6>({});
    const [uiSchema, setUISchema] = useState<UiSchema>({});
    const [formData, setFormData] = useState<TINValidationApprovalData[]>([]);
    const [reportingYears, setReportingYears] = useState<number[]>();
    const [message, setMessage] = useState<string>('');
    const [approveDialogAttributes, setApproveDialogAttributes] = useState({
        open: false,
        title: '',
        docId: '',
    });
    const [denyDialogAttributes, setDenyDialogAttributes] = useState({
        open: false,
        title: '',
        docId: '',
    });

    useEffect(() => {
        const auxUISchema: Record<string, UiSchema> = {};
        const auxSchema: Record<string, JSONSchema6> = {};
        auxSchema.documents = getMipsTINValidationApprovalDocumentsSchema(props);
        auxUISchema.documents = getMipsTINValidationApprovalDocumentsUISchema({
            ...props,
        });
        setUISchema(auxUISchema);
        setSchema({
            type: 'object',
            properties: auxSchema,
        });
    }, [props]);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }
        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [props.title, props.ParentPageLink, setBreadcrumbData]);

    const mipsYears = useMemo(() => props.store.MipsYearList, [props.store.MipsYearList]);
    useEffect(() => {
        if (mipsYears.length) {
            setReportingYears(mipsYears);
            setSelectedYear(mipsYears[0]);
        }
    }, [mipsYears]);

    const viewDocument = async (doc: TINValidationDocument) => {
        const result = await Api.mips.tinValidation.getDocument(doc.id);
        if (result.ok) {
            const bytes = new Uint8Array(result.data.data.length);
            for (let i = 0; i < bytes.length; i += 1) {
                bytes[i] = result.data.data[i];
            }
            const blob = new Blob([bytes], { type: 'application/octet-stream' });
            downloadFile(blob, doc.documentName, 'application/octet-stream');
        } else {
            // TODO: sad path
        }
    };

    const approveDocument = async (doc: TINValidationDocument) => {
        setApproveDialogAttributes({
            open: true,
            title: `Are you sure you want to approve ${doc.documentName}?`,
            docId: doc.id,
        });
    };

    const rejectDocument = async (doc: TINValidationDocument) => {
        // TODO: ask the user for a note when rejecting
        setDenyDialogAttributes({
            open: true,
            title: `Are you sure you want to deny ${doc.documentName}`,
            docId: doc.id,
        });
    };

    const handleMessageChange = (textReceived: string) => {
        if (textReceived.length <= 500) {
            setMessage(textReceived);
        }
    };

    useEffect(() => {
        const retrieve = async () => {
            if (selectedYear) {
                const docs = await Api.mips.tinValidation.getDocuments({
                    year: selectedYear,
                    pendingDocumentsOnly: true,
                });
                if (docs.ok) {
                    const { data } = docs;
                    setFormData(
                        data.flatMap(
                            ({ documents, tin, organizationName }) =>
                                documents?.map((doc) => ({
                                    ...doc,
                                    tin,
                                    organizationName,
                                    actions: [
                                        {
                                            label: 'View',
                                            intent: Intent.PRIMARY,
                                            onClick: () => viewDocument(doc),
                                            disabled: false,
                                            minimal: true,
                                        },
                                        {
                                            label: 'Approve',
                                            intent: Intent.PRIMARY,
                                            onClick: () => approveDocument(doc),
                                            disabled: false,
                                            minimal: true,
                                        },
                                        {
                                            label: 'Deny',
                                            intent: Intent.DANGER,
                                            onClick: () => rejectDocument(doc),
                                            disabled: false,
                                            minimal: true,
                                        },
                                    ],
                                })) || []
                        )
                    );
                }
            }
        };
        retrieve();
    }, [selectedYear]);

    const exportDocumentsList = () => {
        const columnsToDisplay = props.columns
            .filter((col) => col.id !== 'actions')
            .map((col) => col.label)
            .join(',');

        const formatter = (value: number | string) => {
            const strValue = value.toString();
            if (strValue.includes('"')) {
                const aux = [...strValue]
                    .map((e) => (e === '"' ? '""' : e))
                    .join('')
                    .replaceAll(/[\n]/g, '');
                return `"${aux}"`;
            }
            return `"${value}"`.replaceAll(/[\n]/g, '');
        };

        const rowsToDisplay = formData
            ?.map(({ reportingYear, tin, practice, documentName, dateUploaded, approvalStatus }) =>
                Object.values({
                    a: reportingYear,
                    b: tin,
                    c: practice,
                    d: documentName,
                    e: dateUploaded,
                    f: approvalStatus,
                }).map((val) => (val ? formatter(val) : '-'))
            )
            .join('\n');
        const content = [columnsToDisplay, rowsToDisplay].join('\n');
        const fileName = `Documents_${getCurrentTimeFromBrowser()}.csv`;
        downloadFile(content, fileName, 'text/csv');
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <TinValidationApproveDialog
                isOpen={approveDialogAttributes.open}
                title={approveDialogAttributes.title}
                description="If you continue, you will not be able to change TIN Validation status of this document."
                cancelButtonDescription="Cancel"
                submitButtonDescription="Yes, approve"
                onClose={() => {
                    setApproveDialogAttributes({
                        open: false,
                        title: '',
                        docId: '',
                    });
                }}
                onSubmit={async () => {
                    const result = await Api.mips.tinValidation.approveDocument(
                        approveDialogAttributes.docId
                    );
                    if (result.ok) {
                        setFormData(
                            formData?.filter((doc) => approveDialogAttributes.docId !== doc.id)
                        );
                        showSuccessfulToast('File Action.', 'Document successfully approved');
                        setApproveDialogAttributes({
                            open: false,
                            title: '',
                            docId: '',
                        });
                    } else {
                        showToastError(
                            'Error to Approve Document!',
                            'Looks like something went wrong and the document could not be approved. Please try again later.'
                        );
                    }
                }}
            />
            <TinValidationDenyDialog
                isOpen={denyDialogAttributes.open}
                title={denyDialogAttributes.title}
                description="If you continue, you will not be able to change TIN Validation status of this document."
                cancelButtonDescription="Cancel"
                submitButtonDescription="Yes, deny"
                message={message}
                handleMessageChange={handleMessageChange}
                onClose={() => {
                    setDenyDialogAttributes({
                        open: false,
                        title: '',
                        docId: '',
                    });
                }}
                onSubmit={async () => {
                    if (message.length > 2) {
                        const result = await Api.mips.tinValidation.rejectDocument(
                            denyDialogAttributes.docId,
                            { note: message }
                        );
                        if (result.ok) {
                            setFormData(
                                formData?.filter((doc) => denyDialogAttributes.docId !== doc.id)
                            );
                            setDenyDialogAttributes({
                                open: false,
                                title: '',
                                docId: '',
                            });
                            showSuccessfulToast('File action.', 'Document Successfully Rejected');
                        } else {
                            showToastError(
                                'Error Denying Document!',
                                'Looks like something went wrong and the document could not be approved. Please try again later.'
                            );
                        }
                    } else {
                        showToastError(
                            'Error Denying Document!',
                            'Description to deny a document is required.'
                        );
                    }
                }}
            />
            <Form
                key={key}
                recordId="tin-validation-approval"
                recordName="tinValidationApproval"
                sidebarElements={[]}
                hideSidebar
                primary
                schema={schema}
                uiSchema={uiSchema}
                validators={[]}
                formData={{ documents: formData }}
            >
                <FormHeader
                    disableSticky
                    title={props.title}
                    description={
                        <div className={titleDescriptionStyle}>
                            <p>{props.description}</p>
                            <select
                                title="yearList"
                                value={selectedYear}
                                onChange={async (e) => {
                                    setSelectedYear(parseInt(e.currentTarget.value, 10));
                                }}
                            >
                                {reportingYears?.map((y) => (
                                    <option value={y} key={y}>
                                        {y}
                                    </option>
                                ))}
                            </select>
                        </div>
                    }
                    editMode={false}
                    primaryListStyle
                    customButton={{
                        label: 'Export List',
                        intent: Intent.NONE,
                        icon: IconNames.EXPORT,
                        onClick: () => exportDocumentsList(),
                        disabled: false,
                        minimal: false,
                    }}
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(MipsTINValidationApproval);
