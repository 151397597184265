import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export function GetGeneralInfoSchema(title: string): JSONSchema6 {
    const schema: JSONSchema6 = {
        title,
        type: 'object',
        properties: {
            encounterId: { type: 'string', title: 'Encounter ID' },
            provider: { type: 'string', title: 'Provider' },
            startDate: { type: 'string', title: 'Start Date' },
            endDate: { type: 'string', title: 'End Date' },
        },
    };

    return schema;
}

export function GetGeneralInfoUISchema(): UiSchema {
    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:hash': 'general',
        'ui:layout': [
            {
                encounterId: { sm: 12, md: 6, lg: 3, xl: 3 },
                provider: { sm: 12, md: 6, lg: 3, xl: 3 },
                startDate: { sm: 12, md: 6, lg: 2, xl: 2 },
                endDate: { sm: 12, md: 6, lg: 2, xl: 2 },
            },
        ],
        encounterId: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
        },
        provider: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
        },
        startDate: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        endDate: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
    };

    return uiSchema;
}
