import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { MipsQualityMeasureDetails as MipsQualityMeasureDetailsInterface } from '../../../../generator/generated/MeshInterfaces';
import { EditQualityMeasureOutput } from '../../../../server/routes/Mips/EditQualityMeasureApi';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../../util/DataDictionary';
import Store, { MipsManageRecordListOutput } from '../../../core/Store';
import { useBreadcrumbContext } from '../../../core/BreadcrumbContext';
import { SchemaProvider } from '../../../mesh/Schema';
import Form from '../../../components/forms/Form';
import FormHeader from '../../../components/forms/components/FormHeader';
import Api from '../../../core/Api';
import ConfirmExitDialog from '../../ConfirmExitDialog';
import {
    showNoChangesToast,
    showSuccessfulToast,
    showToastError,
    showToastErrors,
} from '../../utils/CommonToasts';
import { buildBreadcrumbTrail } from '../utils';
import MipsModuleConstants from '../MipsModuleConstants';
import {
    getQualityMeasuresSchema,
    getQualityMeasuresUISchema,
} from './MipsQualityMeasureDetailsSchema';
import {
    getQualityMeasuresStratumSchema,
    getQualityMeasuresStratumUISchema,
} from './QualityMeasuresStratumDetails';

interface MipsQualityMeasureDetailsProps extends MipsQualityMeasureDetailsInterface {
    store: Store;
}

function MipsQualityMeasureDetails(
    props: MipsQualityMeasureDetailsProps & RouteComponentProps & InjectedDataDictionaryProps
) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
    const [preferences, setPreferences] = useState(props.store?.MipsManageRecordList?.preferences);
    const [reportingYear, setReportingYear] = useState(
        props.store?.MipsManageRecordList?.reportingYear
    );
    const { measureId, editState } = queryString.parse(props.location.search);
    const [editMode, setEditMode] = useState(editState === 'Edit');
    const [formData, setFormData] = useState(
        props.store?.MipsManageRecordList?.qualityMeasureCategoryScores?.filter(
            (row) =>
                row.measureId &&
                measureId &&
                row.measureId.toLowerCase() === measureId.toLowerCase()
        )[0]
    );
    const [originalFormData, setOriginalFormData] = useState(
        props.store?.MipsManageRecordList?.qualityMeasureCategoryScores?.filter(
            (row) =>
                row.measureId &&
                measureId &&
                row.measureId.toLowerCase() === measureId.toLowerCase()
        )[0]
    );
    const [saving, setSaving] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    function buildTrailData(record: MipsManageRecordListOutput | undefined) {
        breadcrumbTrail.labels = [];
        breadcrumbTrail.routes = [];
        setBreadcrumbData({
            crumbLabels: [],
            crumbRoutes: [],
        });
        const newTrail = buildBreadcrumbTrail([props.ParentPageLink], record);
        breadcrumbTrail.labels = newTrail.labels;
        breadcrumbTrail.routes = newTrail.routes;
        if (breadcrumbTrail.labels) breadcrumbTrail.labels.reverse();
        if (breadcrumbTrail.routes) breadcrumbTrail.routes.reverse();
        breadcrumbTrail.labels.push(`Quality Measure Details`);

        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
    }

    useEffect(() => {
        buildTrailData(props.store?.MipsManageRecordList);
        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);
    const getSidebarElements = () => {
        return [
            {
                title: 'Quality Measure Details',
                hash: 'MipsQualityMeasureDetails',
            },
        ];
    };
    const groupCategorization = preferences?.npi ? `TIN: ${preferences?.tin}` : '';

    // If the store was not already loaded up with the required data to load this page then go ahead and make the API
    // call to get data. This is used in cases where the user reloads the page or they start at the measure details
    // page without having gone to the main record page.
    useEffect(() => {
        const { id, providerPractitionerFhirId, year } = queryString.parse(props.location.search);
        if (
            !props.store.mipsManageRecordList ||
            props.store.mipsManageRecordList.organizationFhirId !== id ||
            (!providerPractitionerFhirId &&
                props.store.mipsManageRecordList.providerPractitionerFhirId) ||
            (providerPractitionerFhirId &&
                props.store.mipsManageRecordList.providerPractitionerFhirId !==
                    providerPractitionerFhirId)
        ) {
            Api.mips
                .readForRecordDetails({
                    organizationFhirId: id,
                    providerPractitionerFhirId,
                    reportingYear:
                        year || props.labels.submissionYear || MipsModuleConstants.SubmissionYear,
                })
                .then(async (response) => {
                    const rawData = response.data as MipsManageRecordListOutput;
                    props.store.setMipsManageRecordList(rawData);
                    setPreferences(props.store?.MipsManageRecordList?.preferences);
                    setReportingYear(props.store?.MipsManageRecordList?.reportingYear);
                    const newFormData =
                        props.store?.MipsManageRecordList?.qualityMeasureCategoryScores?.filter(
                            (row) =>
                                row.measureId &&
                                measureId &&
                                row.measureId.toLowerCase() === measureId.toLowerCase()
                        )[0];
                    setFormData(newFormData);
                    setOriginalFormData(newFormData);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    buildTrailData(rawData);
                });
        }
    }, []);

    const onChange = (e: any) => {
        // console.log(`on change: ${JSON.stringify(e.formData)}`);
        setFormData({
            ...e.formData.qualityMeasures,
            // The value from the page means 'This measure is to be included', however this field really means the opposite of that.
            measureIsExcluded: !e.formData.qualityMeasures.measureIsExcluded,
            stratum: e.formData.stratumDetails ? [...e.formData.stratumDetails] : undefined,
            stratumDetails: undefined,
        });
        setHasChange(true);
    };

    // Since we are storing some stateful information in the store we need to make sure that we go back and update
    // the store with the new state of the application.
    const updateStoreWithNewExclusionIndicator = (newMeasureScore: ReactElement | string) => {
        if (
            props.store.MipsManageRecordList &&
            props.store.MipsManageRecordList.qualityMeasureCategoryScores &&
            formData
        ) {
            props.store.setMipsManageRecordList({
                ...props.store.MipsManageRecordList,
                qualityMeasureCategoryScores:
                    props.store.MipsManageRecordList.qualityMeasureCategoryScores.map(
                        (qualityMeasureScore) => {
                            if (
                                measureId &&
                                qualityMeasureScore.measureId.toLowerCase() ===
                                    measureId.toLowerCase()
                            ) {
                                return {
                                    ...qualityMeasureScore,
                                    measureIsExcluded: formData.measureIsExcluded,
                                    score: formData.measureIsExcluded ? '-' : newMeasureScore,
                                };
                            }
                            return qualityMeasureScore;
                        }
                    ),
            });
        }
    };

    const onSubmit = () => {
        if (props.store && props.store.MipsManageRecordList && formData && !saving) {
            Api.mips
                .editQualityMeasure({
                    organizationFhirId: props.store.MipsManageRecordList.organizationFhirId,
                    reportingYear: props.store.MipsManageRecordList.reportingYear,
                    practitionerToApplyChangeTo: props.store.MipsManageRecordList
                        .providerPractitionerFhirId
                        ? `Practitioner/${props.store.MipsManageRecordList.providerPractitionerFhirId}`
                        : undefined,
                    isEcqmMeasure: formData.measureId.includes('(ECQM)'),
                    measureId: formData.measureId.replace(' (ECQM)', ''),
                    isMeasureExcluded: formData.measureIsExcluded,
                })
                .then((response) => {
                    setSaving(false);
                    if (response.ok && response.data) {
                        setEditMode(!editMode);
                        setHasChange(false);
                        if (response.data.noChangesNeeded) {
                            showNoChangesToast();
                            updateStoreWithNewExclusionIndicator(formData.score);
                            setOriginalFormData(formData);
                        } else {
                            showSuccessfulToast(undefined, 'Save Successful!');
                            updateStoreWithNewExclusionIndicator(
                                response.data.newMeasureScore || formData.score
                            );
                            // Set the form data with the new measure score
                            if (response.data.newMeasureScore) {
                                setFormData({ ...formData, score: response.data.newMeasureScore });
                                setOriginalFormData({
                                    ...formData,
                                    score: response.data.newMeasureScore,
                                });
                            } else {
                                setOriginalFormData(formData);
                            }
                        }
                        // If we got any errors back try to show that specific error or a generic toast.
                    } else if (
                        response.data &&
                        (response.data as EditQualityMeasureOutput).errors
                    ) {
                        showToastErrors(
                            (response.data as EditQualityMeasureOutput).errors,
                            'Error saving Quality Measure Details'
                        );
                    } else {
                        showToastError();
                    }
                });
        }
        // Disable the save button until changes are made to the page
        setSaving(true);
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };
    const getSchema = (isStratum: boolean): JSONSchema6 => {
        return !isStratum
            ? {
                  type: 'object',
                  properties: {
                      qualityMeasures: getQualityMeasuresSchema(),
                      stratumDetails: getQualityMeasuresStratumSchema('Stratum Details'),
                  },
              }
            : {
                  type: 'object',
                  properties: {
                      qualityMeasures: getQualityMeasuresSchema(),
                  },
              };
    };
    const getUISchema = (isStratum: boolean): UiSchema => {
        return !isStratum
            ? {
                  qualityMeasures: getQualityMeasuresUISchema(editMode),
                  stratumDetails: getQualityMeasuresStratumUISchema(),
              }
            : {
                  qualityMeasures: getQualityMeasuresUISchema(editMode),
              };
    };
    const editToggle = () => {
        if (editMode && hasChange) {
            setConfirmationDialogOpen(true);
        } else {
            setEditMode(!editMode);
            setHasChange(false);
        }
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setEditMode(!editMode);
        setFormData(originalFormData);

        setHasChange(false);
        setConfirmationDialogOpen(false);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={() => handleConfirmationDialogClose()}
                handleOkay={() => handleConfirmationDialogOkay()}
            />
            <Form
                recordId={
                    preferences?.npi ? `${preferences?.clinicianName}` : `${preferences?.tinName}`
                }
                recordName={
                    preferences?.npi ? `NPI: ${preferences?.npi}` : `TIN: ${preferences?.tin}`
                }
                recordText={[groupCategorization, reportingYear]}
                sidebarElements={getSidebarElements()}
                schema={getSchema(!(formData && formData?.stratum?.length))}
                uiSchema={getUISchema(!(formData && formData?.stratum?.length))}
                formData={{
                    qualityMeasures: formData && {
                        ...formData,
                        measureIsExcluded: !formData.measureIsExcluded,
                        stratum: undefined,
                    },
                    stratumDetails: formData && formData.stratum && [...formData.stratum],
                }}
                onChange={(e: any) => onChange(e)}
                onSubmit={onSubmit}
                onError={onError}
                noValidate
            >
                <FormHeader
                    title="Quality Measure Details"
                    editMode={editMode}
                    allowEditing
                    // Disable save and cancel button while we are saving the page and waiting for the API call to return
                    allowCancel={!saving}
                    allowSave={hasChange && !saving}
                    allowPrinting={!editMode}
                    editButtonText="Edit Measure"
                    exportButtonLabel="Export Measure"
                    buttonOrder
                    enableEditMode={() => editToggle()}
                    disableEditMode={() => editToggle()}
                />
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(withDataDictionary(MipsQualityMeasureDetails));
