/* eslint-disable no-underscore-dangle */
import React, { Component, Fragment } from 'react';
import IdleTimer from 'react-idle-timer';
import { observer } from 'mobx-react';

import { InactivityWarning } from '@amxjs/ui';
import { observable } from 'mobx';
import { get } from 'lodash';
import { InactivityTimeout } from '../../../generator/generated/MeshInterfaces';

interface SessionTimerPropsInterface {
    keycloak: any;
    store: any;
}

@observer
class SessionTimer extends Component<SessionTimerPropsInterface> {
    idleTimer: any;

    @observable time: number = 0;

    @observable shouldShowWarning: boolean = false;

    interval: NodeJS.Timer | undefined;

    timeout: number = Number(window.SERVER_CONFIG.REACT_APP_SESSION_TIMEOUT);

    timeoutWarning: number = Number(window.SERVER_CONFIG.REACT_APP_SESSION_TIMEOUT_WARNING);

    componentDidMount() {
        // TODO: Lets not include this async func unless it is necessary.
        this.interval = setInterval(() => {
            if (this.idleTimer) {
                this.time = Math.floor(this.idleTimer.getRemainingTime() / 1000);
                const timeSinceLastActive = this.idleTimer.getLastActiveTime();

                if (this.time < this.timeoutWarning / 1000) {
                    this.showWarning();
                }
                if (Date.now() - timeSinceLastActive > this.timeout) {
                    this.logout();
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    showWarning() {
        this.shouldShowWarning = true;
        this.idleTimer._unbindEvents();
    }

    confirmActivity() {
        this.shouldShowWarning = false;
        this.idleTimer._bindEvents();
        this.idleTimer.reset();
    }

    logout() {
        if (this.props.keycloak) {
            this.props.keycloak.logout();
        }
    }

    render() {
        let inactivityConfig: InactivityTimeout = {};
        const inactivityConfigFolder = get(this.props, 'store.siteConfiguration.Configuration');
        if (inactivityConfigFolder) {
            const foundConfig = Object.entries(inactivityConfigFolder).filter(
                ([, value]: [string, any]) => value.schema === 'InactivityTimeout'
            );
            if (foundConfig.length) {
                const inactivityObject = foundConfig[0][1] as any;
                if (inactivityObject) {
                    inactivityConfig = inactivityObject;
                }
            }
        }
        if (this.props.keycloak) {
            return (
                <Fragment>
                    <IdleTimer
                        ref={(ref) => {
                            this.idleTimer = ref;
                        }}
                        onIdle={() => this.logout()}
                        stopOnIdle
                        startOnMount
                        debounce={250}
                        timeout={this.timeout}
                    />
                    <InactivityWarning
                        buttonText={inactivityConfig.buttonText}
                        isOpen={this.shouldShowWarning}
                        onConfirm={() => this.confirmActivity()}
                        text={inactivityConfig.description}
                        timeLeft={this.time}
                        timeUnits={this.time > 59 ? 'minutes' : 'seconds'}
                        title={inactivityConfig.title}
                    />
                </Fragment>
            );
        }
        return <Fragment />;
    }
}

export default SessionTimer;
