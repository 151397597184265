import React, { useEffect, useMemo, useState } from 'react';
import { JSONSchema6 } from 'json-schema';
import { Dialog } from '@blueprintjs/core';

import DialogFormActions from '../../components/forms/components/DialogFormActions';
import DialogForm from '../../components/forms/DialogForm';
import TextWidget from '../../components/forms/widgets/TextWidget';
import SelectWidget from '../../components/forms/widgets/SelectWidget';
import MultiSelectWidget from '../../components/forms/widgets/MultiSelectWidget';
import withDataDictionary from '../../util/DataDictionary';
import Heading from '../heading/Heading';
import { validatePersona, validateDataAccess } from './UserValidation';
import UserModuleConstants from './UserModuleConstants';

interface Capability {
    capability: string;
    personaExclusions: string[];
}
const unrestrictedDataAccess = {
    id: 'unrestricted-access',
    name: 'Unrestricted Access',
};

function AccessControlDialog(props: any) {
    const {
        description,
        target,
        link,
        activeAccess,
        isOpen,
        handleClose,
        handleOkay,
        additionalProperties,
        capabilities,
        labels,
    } = props;

    const getAccessControlSelectRenderMode = (
        personaForCheck: string
    ): 'default' | 'business-entity' => {
        switch (personaForCheck) {
            case 'mips-organization-admin':
                return 'business-entity';
            default:
                return 'default';
        }
    };

    const initialData = useMemo(
        () => ({
            persona: { id: '', name: '' },
            dataAccess: [],
        }),
        []
    );

    const [formData, setFormData] = useState(activeAccess || initialData);
    const [hasChange, setHasChange] = useState(false);
    const [readOnlyDataAccess, setReadOnlyDataAccess] = useState(false);
    const [accessControlSelectRenderMode, setAccessControlSelectRenderMode] = useState<
        'default' | 'business-entity'
    >(getAccessControlSelectRenderMode(activeAccess?.persona.id));

    useEffect(() => {
        setFormData(activeAccess || initialData);
        setHasChange(false);
        setReadOnlyDataAccess(activeAccess?.dataAccess[0]?.id === 'unrestricted-access');
        setAccessControlSelectRenderMode(
            getAccessControlSelectRenderMode(activeAccess?.persona.id)
        );
    }, [activeAccess, initialData]);

    const onChange = (e: any) => {
        if (!hasChange) {
            setHasChange(JSON.stringify(e.formData) !== JSON.stringify(formData));
        }

        const assignPersonaDanglingCapability: Capability = Array.isArray(additionalProperties)
            ? capabilities.find(
                  (capability: Capability) =>
                      capability.capability === 'Capability/assign-persona-dangling'
              )
            : undefined;
        const newPersona = (e.formData && e.formData.persona && e.formData.persona.id) || undefined;
        const newPersonaAdditionalProperties =
            (Array.isArray(additionalProperties) &&
                additionalProperties.find(
                    (additionalProperty) => additionalProperty.persona === newPersona
                )) ||
            undefined;

        const newRenderMode = getAccessControlSelectRenderMode(newPersona);

        if (
            newPersona &&
            assignPersonaDanglingCapability &&
            (!assignPersonaDanglingCapability.personaExclusions ||
                !assignPersonaDanglingCapability.personaExclusions.find(
                    (exclusion: string) => exclusion.split('|')[1] === newPersona
                )) &&
            newPersonaAdditionalProperties &&
            newPersonaAdditionalProperties.danglingAllowed === 'true'
        ) {
            e.formData.dataAccess = [unrestrictedDataAccess];
            setReadOnlyDataAccess(true);
        } else {
            const oldPersonaAdditionalProperties =
                (Array.isArray(additionalProperties) &&
                    additionalProperties.find(
                        (additionalProperty) => additionalProperty.persona === formData.persona.id
                    )) ||
                undefined;
            // If this is swapping away from a unrestricted persona clear the dataAccess array
            if (
                oldPersonaAdditionalProperties?.danglingAllowed === 'true' ||
                newRenderMode !== accessControlSelectRenderMode
            ) {
                e.formData.dataAccess = [];
            }
            setReadOnlyDataAccess(false);
        }

        setFormData(e.formData);
        setAccessControlSelectRenderMode(newRenderMode);
    };

    const jsonSchema = useMemo(() => {
        const enumItems = props.organizations.map((item: any) => {
            let mappedItem: { name: string; id: string; subText?: string };

            switch (accessControlSelectRenderMode) {
                case 'business-entity':
                    mappedItem = {
                        name: item.tin,
                        // TODO: Switch to Business Entity ID once it is supported: SWE-404/SWE-405
                        // id: item.businessEntityId,
                        id: item.businessEntityId,
                        subText: item.name,
                    };
                    break;
                default:
                    mappedItem = {
                        name: item.name,
                        id: item.id,
                        subText: item.providerId,
                    };
                    break;
            }
            return mappedItem;
        });
        const schema: JSONSchema6 = {
            type: 'object',
            required: ['persona', 'dataAccess'],
            properties: {
                persona: {
                    title: labels.persona || 'Role',
                    enum: props.personas,
                },
                dataAccess: {
                    title: labels.dataAccess || 'Data Access',
                    enum: enumItems,
                },
            },
        };
        return schema;
    }, [
        accessControlSelectRenderMode,
        labels.dataAccess,
        labels.persona,
        props.organizations,
        props.personas,
    ]);

    const uiJsonSchema = useMemo(() => {
        const uiSchema: any = {
            'ui:field': 'layout',
            'ui:layout': [
                {
                    persona: { sm: 12, md: 4, lg: 4, xl: 4 },
                    dataAccess: { sm: 12, md: 8, lg: 8, xl: 8 },
                },
            ],
            persona: {
                'ui:widget': 'SelectWidget',
            },
            dataAccess: {
                'ui:widget': readOnlyDataAccess ? 'LabelWidget' : 'MultiSelectWidget',
                'ui:options': {
                    skipBottomPadding: true,
                    objectFieldName: 'name',
                },
            },
        };
        return uiSchema;
    }, [readOnlyDataAccess]);

    const getValidators = () => {
        const validators = [
            {
                field: 'persona',
                validator: validatePersona,
                errorMessage: labels.personaError || UserModuleConstants.PersonaError,
            },
            {
                field: 'dataAccess',
                validator: validateDataAccess,
                errorMessage: labels.dataAccessError || UserModuleConstants.DataAccessError,
            },
        ];

        return validators;
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };

    const onSubmit = (e: any) => {
        setReadOnlyDataAccess(false);
        setFormData(initialData);
        handleOkay(e, activeAccess);
    };

    const onDialogCancel = () => {
        setFormData(initialData);
        handleClose();
    };

    const formatDescription = (body: string, phrase: string, docLink: string) => {
        if (!phrase) {
            return body;
        }
        return (
            <span>
                {body.split(phrase).reduce((prev: any, current, i) => {
                    if (!i) {
                        return [current];
                    }
                    return prev.concat(
                        <a key="doc-link" href={docLink} target="_blank" rel="noreferrer">
                            {phrase}
                        </a>,
                        current
                    );
                }, [])}
            </span>
        );
    };

    return isOpen ? (
        <Dialog onClose={handleClose} isOpen={isOpen} style={{ padding: '40px' }}>
            <div>
                <Heading
                    alignment="left"
                    text={activeAccess ? 'Edit Access Control' : 'Add Access Control'}
                />
                <span
                    style={{
                        display: 'inline-block',
                        marginBottom: '32px',
                        maxWidth: '624px',
                    }}
                >
                    {formatDescription(description, target, link)}
                </span>
                <DialogForm
                    schema={jsonSchema}
                    formData={formData}
                    uiSchema={uiJsonSchema}
                    onChange={(e: any) => {
                        onChange(e);
                    }}
                    onSubmit={(e) => {
                        onSubmit(e);
                    }}
                    onError={(e) => onError(e)}
                    widgets={{
                        TextWidget,
                        SelectWidget,
                        MultiSelectWidget,
                    }}
                    validators={getValidators()}
                    minHeight="132px"
                    minWidth="624px"
                >
                    <DialogFormActions
                        addButtonIcon={activeAccess ? 'tick' : 'add'}
                        onCancel={onDialogCancel}
                        formHasChange={hasChange}
                        addButtonText={activeAccess ? 'Update' : 'Add'}
                    />
                </DialogForm>
            </div>
        </Dialog>
    ) : null;
}

export default withDataDictionary(AccessControlDialog);
