import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';

import { BreadcrumbBar } from '@amxjs/ui';
import { get } from 'lodash';
import Store from '../core/Store';
import { Page, SiteInfo } from '../../generator/generated/MeshInterfaces';
import PageHandler from './PageHandler';
import { MissingPageError } from './error/Errors';
import { useBreadcrumbContext } from '../core/BreadcrumbContext';

interface PagesPropInterface extends RouteComponentProps {
    store: Store;
}

function Pages(props: PagesPropInterface) {
    const [routes, setRoutes] = useState<any>();
    const {
        breadcrumbData: { crumbLabels, crumbRoutes },
    } = useBreadcrumbContext();

    const getPage = useCallback(
        (key: string, store: Store) => {
            const { siteConfiguration } = props.store;
            if (siteConfiguration == null) return null;
            return (
                <PageHandler store={store} name={key} pageConfig={siteConfiguration.Pages[key]} />
            );
        },
        [props.store]
    );

    const getRoutes = useCallback(() => {
        if (props.store.siteConfiguration == null || props.store.siteConfiguration.Pages == null)
            return null;

        const siteInfoConfig: SiteInfo = get(
            props,
            'store.siteConfiguration.Configuration.siteInfo'
        );

        const homeBreadcrumb = (siteInfoConfig && siteInfoConfig.homeBreadcrumbName) || 'home';

        const pages: [string, Page][] = Object.entries(props.store.siteConfiguration.Pages);
        if (!pages.length) return null;
        const pageRoutes: JSX.Element[] = [];
        const breadcrumbRoutes: JSX.Element[] = [];

        const createBreadcrumbTrail = (page: Page) => {
            let trail: Record<any, any> = {};
            if (props.store.siteConfiguration && page.schema) {
                trail = {
                    label: crumbLabels,
                    route: crumbRoutes,
                };
            }
            if (trail.label) {
                return trail.label.map((crumb: string, index: number) => {
                    return {
                        name: crumb,
                        onClick: () =>
                            trail.route[index] && props.history.push(`${trail.route[index]}`),
                    };
                });
            }
            return [];
        };

        pages
            .filter(
                ([, page]: [string, any]) => page.schema === 'Page' && page.route && page.active
            )
            .map(([name, page]: [string, Page]) => {
                pageRoutes.push(
                    <Route
                        key={name}
                        path={page.route}
                        exact
                        render={() => getPage(name, props.store)}
                    />
                );
                if (page.route !== '/')
                    breadcrumbRoutes.push(
                        <Route
                            key={name}
                            path={page.route}
                            exact
                            render={(routeProps) => (
                                <div
                                    style={{
                                        backgroundColor: '#f5f8fa',
                                        border: '1px solid #e0e8ed',
                                    }}
                                    className="breadcrumbbar"
                                >
                                    <BreadcrumbBar
                                        breadcrumbs={[
                                            {
                                                name: homeBreadcrumb,
                                                onClick: () => routeProps.history.push('/'),
                                            },
                                            ...createBreadcrumbTrail(page),
                                        ]}
                                    />
                                </div>
                            )}
                        />
                    );
            });
        return { pageRoutes, breadcrumbRoutes };
    }, [getPage, props, crumbLabels, crumbRoutes]);

    useEffect(() => {
        const pageRoutes = getRoutes();
        if (pageRoutes !== null) {
            setRoutes(pageRoutes);
        }
    }, [getRoutes]);

    useEffect(() => {
        const pageRoutes = getRoutes();
        if (pageRoutes !== null) {
            setRoutes(pageRoutes);
        }
    }, [getRoutes]);

    return (
        <Fragment>
            {routes && routes.breadcrumbRoutes}
            <Switch>
                {routes && routes.pageRoutes}
                <Route component={MissingPageError} />
            </Switch>
        </Fragment>
    );
}

export default withRouter(Pages);
