import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { JSONSchema6 } from 'json-schema';
import { UiSchema, Widget } from 'react-jsonschema-form';
import DialogForm from './DialogForm';
import DialogFormActions from './components/DialogFormActions';
import Heading from '../../pages/heading/Heading';

interface GenericDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    handleOkay: () => void;
    schema: JSONSchema6;
    uiSchema: UiSchema;
    headingTitle: string;
    cancelButtonText: string;
    addButtonText: string;
    addButtonDisabled: boolean;
    disabled: boolean;
    widgets: { [name: string]: Widget } | undefined;
    formData: any;
}

export default function GenericDialog(props: GenericDialogProps) {
    const [formData, setFormData] = useState(props.formData);

    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);

    return props.isOpen ? (
        <Dialog onClose={props.handleClose} isOpen={props.isOpen} style={{ padding: '40px' }}>
            <div>
                <Heading alignment="left" text={props.headingTitle} />
                <DialogForm
                    schema={props.schema}
                    formData={formData}
                    uiSchema={props.uiSchema}
                    onSubmit={props.handleOkay}
                    widgets={props.widgets}
                    minHeight="204px"
                    minWidth="360px"
                >
                    <DialogFormActions
                        onCancel={props.handleClose}
                        cancelButtonText={props.cancelButtonText}
                        formHasChange={false}
                        disabled={props.disabled}
                        isAddButtonDisabled={props.addButtonDisabled}
                        addButtonText={props.addButtonText}
                        actionClassStyle
                    />
                </DialogForm>
            </div>
        </Dialog>
    ) : null;
}
