import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { isEmpty } from 'lodash';
import { style } from 'typestyle';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { getCurrentTimeFromBrowser, getUriFromFile } from '../../../../util/utils';
import {
    MipsTINValidation as MipsTINValidationInterface,
    Page,
} from '../../../../generator/generated/MeshInterfaces';
import { TINValidationData, TINValidationDocument } from '../../../../models/TinDocument';
import { useBreadcrumbContext } from '../../../core/BreadcrumbContext';
import { SchemaProvider } from '../../../mesh/Schema';
import { downloadFile } from '../../../components/utils/FileUtils';
import Form from '../../../components/forms/Form';
import FormHeader from '../../../components/forms/components/FormHeader';
import Api from '../../../core/Api';
import { showSuccessfulToast, showToastError } from '../../utils/CommonToasts';
import DeleteDialog from '../../DeleteDialog';
import {
    getMipsTINValidationDocumentsSchema,
    getMipsTINValidationDocumentsUISchema,
    getMipsTINValidationUploadDocumentDataSchema,
    getMipsTINValidationUploadDocumentUISchema,
} from './MipsTINValidationSchemas';

const submitDocument = async ({
    fileUri,
    fileName,
    organizationId,
    year,
    organizationName,
}: {
    fileUri: string;
    fileName: string;
    organizationId: string;
    year: string;
    organizationName?: string;
}): Promise<TINValidationDocument | undefined> => {
    const result = await Api.mips.tinValidation.submitDocument(
        fileUri,
        fileName,
        organizationId,
        year,
        organizationName
    );
    if (result.ok) {
        return result.data;
    }
    return undefined;
};

const titleDescriptionStyle = style({
    marginBottom: '40px',
});

const MipsTINValidation: FC<MipsTINValidationInterface & RouteComponentProps> = (
    props: MipsTINValidationInterface & RouteComponentProps
) => {
    const { id, year }: { id: string; year: string } = queryString.parse(props.location.search);
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [key] = useState<number>(Date.now());
    const [schema, setSchema] = useState<JSONSchema6>({});
    const [uiSchema, setUISchema] = useState<UiSchema>({});
    const [formData, setFormData] = useState<TINValidationData>();
    const [deleteDialogAttributes, setDeleteDialogAttributes] = useState({
        open: false,
        docName: '',
        docId: '',
    });

    useEffect(() => {
        const auxUISchema: Record<string, UiSchema> = {};
        const auxSchema: Record<string, JSONSchema6> = {};
        auxSchema.uploadDocument = getMipsTINValidationUploadDocumentDataSchema();
        auxSchema.documents = getMipsTINValidationDocumentsSchema(props);
        auxUISchema.uploadDocument = getMipsTINValidationUploadDocumentUISchema();
        auxUISchema.documents = getMipsTINValidationDocumentsUISchema({
            ...props,
        });
        setUISchema(auxUISchema);
        setSchema({
            type: 'object',
            properties: auxSchema,
        });
    }, [props]);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }
        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [props.title, props.ParentPageLink, setBreadcrumbData]);

    const viewDocument = async (doc: TINValidationDocument) => {
        const result = await Api.mips.tinValidation.getDocument(doc.id);
        if (result.ok) {
            const bytes = new Uint8Array(result.data.data.length);
            for (let i = 0; i < bytes.length; i += 1) {
                bytes[i] = result.data.data[i];
            }
            const blob = new Blob([bytes], { type: 'application/octet-stream' });
            downloadFile(blob, doc.documentName, 'application/octet-stream');
        } else {
            // TODO: sad path
        }
    };

    const removeDocument = async (documentToRemove: TINValidationDocument) => {
        setDeleteDialogAttributes({
            open: true,
            docName: documentToRemove.documentName,
            docId: documentToRemove.id,
        });
    };

    const addActionsToDocuments = useCallback((doc: TINValidationDocument) => {
        return [
            {
                label: 'View',
                intent: Intent.PRIMARY,
                onClick: () => viewDocument(doc),
                disabled: false,
                minimal: true,
            },
            ...(doc.approvalStatus.toLowerCase() === 'pending'
                ? [
                      {
                          label: 'Delete',
                          intent: Intent.DANGER,
                          onClick: () => removeDocument(doc),
                          disabled: false,
                          minimal: true,
                      },
                  ]
                : []),
        ];
    }, []);

    useEffect(() => {
        const init = async () => {
            const docs = await Api.mips.tinValidation.getDocuments({
                organizationId: `Organization/${id}`,
                year: parseInt(year, 10),
            });
            if (docs.ok) {
                const {
                    data: [
                        { tin, organizationName, documents } = {
                            tin: undefined,
                            organizationName: undefined,
                            documents: [],
                        },
                    ],
                } = docs;
                if (tin && organizationName) {
                    setFormData({
                        tin,
                        organizationName,
                        documents:
                            documents?.map((doc) => ({
                                ...doc,
                                actions: addActionsToDocuments(doc),
                            })) || [],
                    });
                } else {
                    showToastError('Error Retrieving Documents', 'Please try again later');
                }
            }
        };
        if (!isEmpty(id) && !isEmpty(year)) {
            init();
        }
    }, [id, year, addActionsToDocuments]);

    const uploadDocument = async (file: File) => {
        if (file) {
            const fileUri = await getUriFromFile(file);
            const result: TINValidationDocument | undefined = await submitDocument({
                fileUri,
                fileName: file.name,
                organizationId: id,
                year,
                organizationName: formData?.organizationName,
            });
            if (result) {
                const documentsMerged: TINValidationDocument[] = [
                    ...(formData?.documents || []),
                    ...[result],
                ];
                setFormData((prev) => ({
                    ...prev,
                    documents:
                        documentsMerged?.map((doc) => ({
                            ...doc,
                            actions: addActionsToDocuments(doc),
                        })) || [],
                }));
                showSuccessfulToast('Success', 'File Uploaded Successfully');
            }
        }
    };

    const onChange = async (e: any) => {
        if (e.formData?.uploadDocument?.file) {
            setFormData((prev) => ({
                ...prev,
                uploadDocument: {
                    ...prev?.uploadDocument,
                    file: e.formData.uploadDocument.file,
                },
            }));
            await uploadDocument(e.formData.uploadDocument.file);
        }
    };

    const closeAndResetDeleteDialog = () => {
        setDeleteDialogAttributes({
            open: false,
            docName: '',
            docId: '',
        });
    };

    const exportDocumentsList = () => {
        const columnsToDisplay = props.columns
            .filter((col) => col.id !== 'actions')
            .map((col) => col.label)
            .join(',');

        const formatter = (value: number | string) => {
            const strValue = value.toString();
            if (strValue.includes('"')) {
                const aux = [...strValue]
                    .map((e) => (e === '"' ? '""' : e))
                    .join('')
                    .replaceAll(/[\n]/g, '');
                return `"${aux}"`;
            }
            return `"${value}"`.replaceAll(/[\n]/g, '');
        };
        const rowsToDisplay = formData?.documents
            ?.map((doc) =>
                Object.values({
                    a: doc.documentName,
                    b: doc.practice,
                    c: doc.approvedBy,
                    d: doc.dateUploaded,
                    e: doc.approvalStatus,
                    f: doc.note,
                }).map((val) => (val ? formatter(val) : '-'))
            )
            .join('\n');
        const content = [columnsToDisplay, rowsToDisplay].join('\n');
        const fileName = `Documents_${getCurrentTimeFromBrowser()}_${formData?.tin || ''}.csv`;
        downloadFile(content, fileName, 'text/csv');
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <DeleteDialog
                isOpen={deleteDialogAttributes.open}
                onClose={() => {
                    closeAndResetDeleteDialog();
                }}
                onSubmit={async () => {
                    closeAndResetDeleteDialog();
                    const result = await Api.mips.tinValidation.deleteDocument(
                        deleteDialogAttributes.docId
                    );
                    if (result.ok) {
                        setFormData((prev) => ({
                            ...prev,
                            documents: prev?.documents?.filter(
                                (doc) => deleteDialogAttributes.docId !== doc.id
                            ),
                        }));
                        showSuccessfulToast('Success', 'Document Successfully Deleted');
                    } else {
                        closeAndResetDeleteDialog();
                        showToastError(
                            'Error Deleting Document.',
                            'Looks like something went wrong and the document could not be deleted. Please try again later.'
                        );
                    }
                }}
                recordNameBeingDelete={deleteDialogAttributes.docName}
            />
            <Form
                key={key}
                recordId="tin-validation"
                recordName={formData?.organizationName || ''}
                sidebarElements={[]}
                hideSidebar
                primary
                schema={schema}
                uiSchema={uiSchema}
                validators={[]}
                onChange={(e: any) => {
                    onChange(e);
                }}
                formData={formData}
            >
                <FormHeader
                    disableSticky
                    title={props.title}
                    description={
                        <div className={titleDescriptionStyle}>
                            <p>
                                TIN: {formData?.tin || '-'} | {formData?.organizationName || '-'} |{' '}
                                {year}
                            </p>
                            <p>{props.description}</p>
                        </div>
                    }
                    editMode={false}
                    primaryListStyle
                    customButton={{
                        label: 'Export List',
                        intent: Intent.NONE,
                        icon: IconNames.EXPORT,
                        onClick: () => exportDocumentsList(),
                        disabled: false,
                        minimal: false,
                    }}
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(MipsTINValidation);
