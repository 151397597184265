import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';

import { MipsPromoteInteroperabilityDetails as MipsPromoteInteroperabilityDetailsInterface } from '../../../generator/generated/MeshInterfaces';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../util/DataDictionary';
import Store, { MipsManageRecordListOutput } from '../../core/Store';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { SchemaProvider } from '../../mesh/Schema';
import Form from '../../components/forms/Form';
import FormHeader from '../../components/forms/components/FormHeader';
import Api from '../../core/Api';
import {
    getPromoteInteroperabilityDetailsSchema,
    getPromoteInteroperabilityDetailsUISchema,
} from './MipsPromoteInteroperoperabilityDetailsSchema';
import { buildBreadcrumbTrail } from './utils';
import MipsModuleConstants from './MipsModuleConstants';

interface MipsPromoteInteroperabilityDetailsProps
    extends MipsPromoteInteroperabilityDetailsInterface {
    // eslint-disable-next-line react/no-unused-prop-types
    store: Store;
}

function MipsPromoteInteroperabilityDetails(
    props: MipsPromoteInteroperabilityDetailsProps &
        RouteComponentProps &
        InjectedDataDictionaryProps
) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [preferences, setPreferences] = useState(props.store?.MipsManageRecordList?.preferences);
    const [reportingYear, setReportingYear] = useState(
        props.store?.MipsManageRecordList?.reportingYear
    );
    const { measureId } = queryString.parse(props.location.search);
    const [formData, setFormData] = useState(
        props.store?.MipsManageRecordList?.promotingInteroperabilityScores?.filter(
            (row) =>
                row.measureId &&
                measureId &&
                row.measureId.toLowerCase() === measureId.toLowerCase()
        )[0]
    );
    const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
    function buildTrailData(record: MipsManageRecordListOutput | undefined) {
        breadcrumbTrail.labels = [];
        breadcrumbTrail.routes = [];
        setBreadcrumbData({
            crumbLabels: [],
            crumbRoutes: [],
        });
        const newTrail = buildBreadcrumbTrail([props.ParentPageLink], record);
        breadcrumbTrail.labels = newTrail.labels;
        breadcrumbTrail.routes = newTrail.routes;
        if (breadcrumbTrail.labels) breadcrumbTrail.labels.reverse();
        if (breadcrumbTrail.routes) breadcrumbTrail.routes.reverse();
        breadcrumbTrail.labels.push(`Promoting Interoperability Details`);

        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
    }
    // If the store was not already loaded up with the required data to load this page then go ahead and make the API
    // call to get data. This is used in cases where the user reloads the page or they start at the measure details
    // page without having gone to the main record page.
    useEffect(() => {
        const { id, providerPractitionerFhirId, year } = queryString.parse(props.location.search);
        if (
            !props.store.mipsManageRecordList ||
            props.store.mipsManageRecordList.organizationFhirId !== id ||
            (!providerPractitionerFhirId &&
                props.store.mipsManageRecordList.providerPractitionerFhirId) ||
            (providerPractitionerFhirId &&
                props.store.mipsManageRecordList.providerPractitionerFhirId !==
                    providerPractitionerFhirId)
        ) {
            Api.mips
                .readForRecordDetails({
                    organizationFhirId: id,
                    providerPractitionerFhirId,
                    reportingYear:
                        year || props.labels.submissionYear || MipsModuleConstants.SubmissionYear,
                })
                .then(async (response) => {
                    const rawData = response.data as MipsManageRecordListOutput;
                    props.store.setMipsManageRecordList(rawData);
                    setPreferences(props.store?.MipsManageRecordList?.preferences);
                    setReportingYear(props.store?.MipsManageRecordList?.reportingYear);

                    setFormData(
                        props.store?.MipsManageRecordList?.promotingInteroperabilityScores?.filter(
                            (row) => row.measureId === measureId
                        )[0]
                    );
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    buildTrailData(rawData);
                });
        }
    }, []);
    useEffect(() => {
        buildTrailData(props.store?.MipsManageRecordList);
        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);
    const getSidebarElements = () => {
        return [
            {
                title: 'Promote Interoperability Details',
                hash: 'PromoteInteroperabilityDetails',
            },
        ];
    };
    const groupCategorization = preferences?.npi ? `TIN: ${preferences?.tin}` : '';
    return (
        <SchemaProvider schemaId={props.ParentPageLink?.route}>
            <Form
                recordId={
                    preferences?.npi ? `${preferences?.clinicianName}` : `${preferences?.tinName}`
                }
                recordName={
                    preferences?.npi ? `NPI: ${preferences?.npi}` : `TIN: ${preferences?.tin}`
                }
                recordText={[groupCategorization, reportingYear]}
                sidebarElements={getSidebarElements()}
                schema={getPromoteInteroperabilityDetailsSchema('')}
                uiSchema={getPromoteInteroperabilityDetailsUISchema('')}
                formData={formData}
            >
                <FormHeader
                    title="Promote Interoperability Details"
                    editMode={false}
                    allowEditing={false}
                    allowCancel={false}
                    allowSave={false}
                />
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(withDataDictionary(MipsPromoteInteroperabilityDetails));
