import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getClinicalIdsSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Patient Identifiers',
        items: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                    title: 'Type',
                },
                value: {
                    type: 'string',
                    title: 'Value',
                },
            },
        },
    };
};

export const getClinicalIdsSectionUISchema = (sectionProps: PatientDetailSection): UiSchema => {
    const emptyListMessage = {
        title: 'No Patient Identifiers Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'clinical-id-results',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
            editable: false,
            itemsPerPage: sectionProps.itemsPerPage as number,
        },
        items: {
            type: {
                'ui:options': {
                    orderable: true,
                },
            },
            value: {
                'ui:options': {
                    orderable: true,
                },
            },
        },
    };
};
