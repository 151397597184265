import React from 'react';

import { TextField } from '@amxjs/ui';

import { runInAction, observable } from 'mobx';
import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';

@observer
class PasswordWidget extends React.Component<WidgetProps> {
    @observable
    $value: any;

    enumOptions: any;

    constructor(props: any) {
        super(props);

        const { options, value } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        this.$value = value === '' ? options.emptyValue : value;
    }

    /* eslint-disable class-methods-use-this */
    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: props.options,
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,

            schema: props.schema,

            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
        };
    }

    render() {
        const props = this.convertProps(this.props);

        return (
            <TextField
                label={props.label || props.schema.title}
                autoFocus={props.autofocus}
                showRequiredIndicator={props.required}
                disabled={props.disabled || props.readonly}
                type="password"
                value={this.$value}
                onChange={(v) => {
                    runInAction(() => {
                        this.$value = v;
                        props.onChange(v === '' ? this.props.options.emptyValue : v);
                    });
                }}
            />
        );
    }
}

export default PasswordWidget;
