import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

interface ConfirmMipsSubmissionProps {
    isOpen: boolean;
    handleClose: () => void;
    handleOkay: () => void;
    numOfGroups: number;
    numOfIndividuals: number;
    isDisabled: boolean;
}

const ConfirmMipsSubmissionDialog = (props: ConfirmMipsSubmissionProps) =>
    props.isOpen ? (
        <Dialog
            onClose={props.handleClose}
            isOpen={props.isOpen}
            title="Are you sure you want to submit data?"
        >
            <div className={Classes.DIALOG_BODY}>
                <p>{`You are submitting data for ${props.numOfGroups} ${
                    props.numOfGroups === 1 ? 'Group' : 'Groups'
                } and ${props.numOfIndividuals} ${
                    props.numOfIndividuals === 1 ? 'Individual' : 'Individuals'
                } to CMS.`}</p>
                <p>Review the monitor and performance screen for updated scores and errors</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        text="No, go back"
                        onClick={props.handleClose}
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                    />
                    <Button
                        text="Yes, continue"
                        intent={Intent.PRIMARY}
                        onClick={props.handleOkay}
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                        disabled={props.isDisabled}
                    />
                </div>
            </div>
        </Dialog>
    ) : null;

export default ConfirmMipsSubmissionDialog;
