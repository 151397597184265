import React from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import SelectWidget from './SelectWidget';
import SimpleTextWidget from './SimpleTextWidget';

function OptInSelectionWidget(props: WidgetProps): JSX.Element {
    return props.value === '-' || props.readonly ? (
        <SimpleTextWidget {...props} />
    ) : (
        <SelectWidget {...props} />
    );
}

export default OptInSelectionWidget;
