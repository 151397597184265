/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import { JSONSchema6 } from 'json-schema';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UiSchema } from 'react-jsonschema-form';
import { get } from 'lodash';
import {
    PatientDetails as PatientDetailsInterface,
    PatientDetailSection,
    ControlledField,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';
import Form from '../../components/forms/Form';
import { PatientDetailInterface } from '../../../server/routes/Patient/PatientInterface';
import Api from '../../core/Api';
import FormHeader from '../../components/forms/components/FormHeader';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { getGeneralSectionSchema, getGeneralSectionUISchema } from './GeneralSection';
import { getEncountersSectionSchema, getEncountersSectionUISchema } from './EncounterSection';
import { getConditionsSectionSchema, getConditionsSectionUISchema } from './ConditionSection';
import { getMedicationsSectionSchema, getMedicationsSectionUISchema } from './MedicationSection';
import {
    getLaboratoryResultsSectionSchema,
    getLaboratoryResultsSectionUISchema,
} from './LaboratoryResultsSection';
import {
    getEmergencyContactSectionSchema,
    getEmergencyContactSectionUISchema,
} from './EmergencyContactSection';
import { getPharmaciesSectionSchema, getPharmaciesSectionUISchema } from './PharmacySection';
import { getProceduresSectionSchema, getProceduresSectionUISchema } from './ProcedureSection';
import { getProvidersSectionSchema, getProvidersSectionUISchema } from './ProviderSection';
import { getClinicalIdsSectionSchema, getClinicalIdsSectionUISchema } from './ClinicalIdSection';
import { getSchemaForSection, getUISchemaForSection } from './utils';

interface RecordInfoType {
    id: string | JSX.Element;
    name: string;
}

interface PatientDetailsProps extends PatientDetailsInterface, RouteComponentProps {
    /* eslint-disable-next-line react/no-unused-prop-types */
    store: Store;
}

const isPatientDetailSection = (p: any): p is PatientDetailSection => !p.columns;

function PatientDetails(props: PatientDetailsProps) {
    const { sections } = props;
    const [sectionFields, setSectionFields] = useState<Record<string, ControlledField[]>>({});
    const [formData, setFormData] = useState<PatientDetailInterface>({});
    const { id } = queryString.parse(props.location.search);
    const [recordInfo, setRecordInfo] = useState<RecordInfoType>({
        id: '',
        name: '',
    });
    const [units, setUnits] = useState({});
    const { setBreadcrumbData } = useBreadcrumbContext();

    const getSections = () => {
        const formSections = Object.assign([], sectionFields);
        return [
            ...new Set(
                sections.map((section) => {
                    if (isPatientDetailSection(section)) {
                        formSections[section.sectionType] = section.controlledFields || [];
                    }

                    setSectionFields(formSections);
                    return section.sectionType;
                })
            ),
        ];
    };

    const getSidebarElements = () => {
        return sections.map((section) => {
            return {
                title: section.title || section.sectionType,
                hash: section.sectionType.toLowerCase(),
            };
        });
    };

    useEffect(() => {
        Api.patient.getPatientDetail(id, getSections()).then((response) => {
            if (response.ok) {
                // setRecordInfo({ id, name: `${rawData.lastName}, ${rawData.firstName}` });
                const result = response.data as PatientDetailInterface;
                const lastName = get(result, 'General.lastName');
                const firstName = get(result, 'General.firstName');
                const dob = get(result, 'General.dateOfBirth');
                const secondaryInformation = get(props, 'secondaryInformation');
                setUnits({
                    weight: get(result, 'General.weightUnit'),
                });

                setRecordInfo({
                    name: `${lastName}, ${firstName}`,
                    id: (
                        <div>
                            {secondaryInformation && secondaryInformation.includes('DOB')}DOB: {dob}
                        </div>
                    ),
                });
                setFormData(result);

                const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

                if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
                    props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                        breadcrumbTrail.labels.push(crumb.name);
                        breadcrumbTrail.routes.push(crumb.route || null);
                    });
                }

                breadcrumbTrail.labels.push(`${lastName}, ${firstName}`);

                setBreadcrumbData({
                    crumbLabels: breadcrumbTrail.labels,
                    crumbRoutes: breadcrumbTrail.routes,
                });
            }
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    // The generator functions are for getting PatientDetailSection schemas.
    // This allows backwards compatibility for customers while switching to the
    // new configurable patient detail sections
    const getSchemaGenerator = (sectionType: string): ((title: string) => JSONSchema6) => {
        const generators: Record<string, (title: string) => JSONSchema6> = {
            General: getGeneralSectionSchema,
            ClinicalIds: getClinicalIdsSectionSchema,
            Encounters: getEncountersSectionSchema,
            Conditions: getConditionsSectionSchema,
            Medications: getMedicationsSectionSchema,
            LaboratoryResults: getLaboratoryResultsSectionSchema,
            EmergencyContact: getEmergencyContactSectionSchema,
            Pharmacies: getPharmaciesSectionSchema,
            Procedures: getProceduresSectionSchema,
            Providers: getProvidersSectionSchema,
        };
        return generators[sectionType] || null;
    };

    const getUISchemaGenerator = (
        sectionType: string
    ): ((sectionProps: PatientDetailSection, units: Record<string, string>) => UiSchema) => {
        const generators: Record<
            string,
            (sectionProps: PatientDetailSection, units: Record<string, string>) => UiSchema
        > = {
            General: getGeneralSectionUISchema,
            ClinicalIds: getClinicalIdsSectionUISchema,
            Encounters: getEncountersSectionUISchema,
            Conditions: getConditionsSectionUISchema,
            Medications: getMedicationsSectionUISchema,
            LaboratoryResults: getLaboratoryResultsSectionUISchema,
            EmergencyContact: getEmergencyContactSectionUISchema,
            Pharmacies: getPharmaciesSectionUISchema,
            Procedures: getProceduresSectionUISchema,
            Providers: getProvidersSectionUISchema,
        };
        return generators[sectionType] || null;
    };

    const getSchema = (): JSONSchema6 => {
        const properties: Record<string, JSONSchema6> = {};
        let generator: (title: string) => JSONSchema6;

        sections.map((formSection) => {
            if (isPatientDetailSection(formSection)) {
                generator = getSchemaGenerator(formSection.sectionType);
                properties[formSection.sectionType] = generator(formSection.title || '');
            } else {
                properties[formSection.sectionType] = getSchemaForSection(formSection);
            }
        });

        return {
            type: 'object',
            properties,
        };
    };

    const getUISchema = (): UiSchema => {
        const properties: Record<string, UiSchema> = {};
        let generator: (
            sectionProps: PatientDetailSection,
            units: Record<string, string>
        ) => UiSchema;

        sections.map((formSection) => {
            if (isPatientDetailSection(formSection)) {
                generator = getUISchemaGenerator(formSection.sectionType);
                properties[formSection.sectionType] = generator(formSection, units);
            } else {
                properties[formSection.sectionType] = getUISchemaForSection(formSection);
            }
        });

        return properties;
    };

    return (
        <div>
            <Form
                recordId={recordInfo.id}
                recordName={recordInfo.name}
                sidebarElements={getSidebarElements()}
                schema={getSchema()}
                uiSchema={getUISchema()}
                formData={formData}
            >
                <FormHeader
                    title={props.name || 'Practice Record'}
                    editMode={false}
                    allowEditing={false}
                    allowSave={false}
                    allowPrinting
                    exportButtonLabel={props.exportButtonLabel}
                />
            </Form>
            <div className="amx-disclaimer">{props.disclaimerText}</div>
        </div>
    );
}

export default withRouter(PatientDetails);
