import React, { Component, ErrorInfo } from 'react';
import { Card } from '@blueprintjs/core';

import Store from '../../core/Store';
import { TableauError } from './Errors';

interface ErrorProps {
    store: Store;
    children: JSX.Element;
    isCard?: boolean;
}
class TableauErrorBoundary extends Component<ErrorProps> {
    state: { hasError: boolean } = { hasError: false };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.props.store.setConnected({ ...this.props.store.connected, tableau: false });
        console.error(error);
        console.info(info);
    }

    render() {
        const { tableau } = this.props.store.connected;
        if ((!tableau || this.state.hasError) && !this.props.isCard) {
            return <TableauError />;
        }
        if ((!tableau || this.state.hasError) && this.props.isCard) {
            return (
                <Card style={{ height: 'calc(100% - 24px)' }} interactive>
                    <TableauError />
                </Card>
            );
        }
        return this.props.children;
    }
}

export default TableauErrorBoundary;
