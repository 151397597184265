import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { TTableEmptyProps } from '@amxjs/ui';
import { RowAction } from '../../components/Tables/List';
import {
    OrganizationContactListColumn,
    OrganizationContactList,
} from '../../../generator/generated/MeshInterfaces';
import OrganizationModuleConstants from './OrganizationModuleConstants';

const getConfigurableItems = (columns: OrganizationContactListColumn[]) => {
    const items: Record<string, UiSchema> = {};

    columns.forEach((column) => {
        items[column.id] = {
            'ui:widget': 'SimpleTextWidget',
            'ui:options': {
                orderable: column.sortable || false,
            },
        };
    });

    return items;
};

export const getContactsUISchema = (
    editMode: boolean,
    createMode: boolean,
    onAddItemClick: (event: any) => void,
    rowActions: RowAction[],
    addContactButtonLabel: string | undefined,
    addContactsTitle: string | undefined,
    contactList?: OrganizationContactList
): UiSchema => {
    const showControls = editMode || createMode;
    const emptyMessage: TTableEmptyProps = {
        title: `No ${addContactsTitle || OrganizationModuleConstants.AddContactsTitle}`,
        description: showControls
            ? `Select '${
                  addContactButtonLabel || OrganizationModuleConstants.AddContactButtonLabel
              }' to get started`
            : `Select "Edit Record" to add a contact`,
    };
    return {
        'ui:field': 'list',
        'ui:hash': 'contacts',
        'ui:options': {
            addButtonLabel:
                addContactButtonLabel || OrganizationModuleConstants.AddContactButtonLabel,
            addContactsTitle: addContactsTitle || OrganizationModuleConstants.AddContactsTitle,
            addable: showControls,
            removable: showControls,
            editable: showControls,
            onAddItemClick,
            emptyListMessage: emptyMessage,
            emptyDataListMessage: emptyMessage,
            fieldNameForDeleteDialog: 'firstName',
            rowActions,
        },
        items: contactList
            ? getConfigurableItems(contactList.columns)
            : {
                  email: {
                      'ui:widget': 'SimpleTextWidget',
                      'ui:options': {
                          orderable: true,
                      },
                  },
                  firstName: {
                      'ui:widget': 'SimpleTextWidget',
                      'ui:options': {
                          orderable: true,
                      },
                  },
                  lastName: {
                      'ui:widget': 'SimpleTextWidget',
                      'ui:options': {
                          orderable: true,
                      },
                  },
                  phone: {
                      'ui:widget': 'SimpleTextWidget',
                      'ui:options': {
                          orderable: true,
                      },
                  },
                  status: {
                      'ui:widget': 'SimpleTextWidget',
                      'ui:options': {
                          orderable: true,
                      },
                  },
              },
    };
};

const getConfigurableProperties = (columns: OrganizationContactListColumn[]) => {
    const properties: { [k: string]: JSONSchema6Definition } = {};

    columns.forEach((column: { id: string; label: string }) => {
        properties[column.id] = {
            type: column.id === 'status' ? 'boolean' : 'string',
            title: column.label,
        };
    });

    return properties;
};

export const getContactsSchema = (
    addContactsTitle: string | undefined,
    contactList?: OrganizationContactList
): JSONSchema6 => {
    return {
        title: addContactsTitle || OrganizationModuleConstants.AddContactsTitle,
        type: 'array',
        items: {
            type: 'object',
            properties: contactList
                ? getConfigurableProperties(contactList.columns)
                : {
                      email: {
                          type: 'string',
                          title: 'Email Address',
                      },
                      firstName: {
                          type: 'string',
                          title: 'First Name',
                      },
                      lastName: {
                          type: 'string',
                          title: 'Last Name',
                      },
                      phone: {
                          type: 'string',
                          title: 'Phone Number',
                      },
                      status: {
                          type: 'boolean',
                          title: 'Status',
                      },
                  },
        },
    };
};
