import React from 'react';

import { TextField } from '@amxjs/ui';

import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';

@observer
class TextWidget extends React.Component<WidgetProps> {
    enumOptions: any;

    constructor(props: any) {
        super(props);

        const { options } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        // const emptyValue = multiple ? [] : '';
    }

    render() {
        // NOTE: we should actually use TextField instead of TextInput!
        // TODO: However, current TextField implemtation would ALWAYS show the label - if no label, it will default to empty string
        //  This behavior will mess up with the ArrayFieldTemplate - the buttons are off a bit. Maybe the solution would be to more adaptive TextField,
        //  or a more adaptive ArrayFieldTemplate to show the aligned buttons.
        let notes;

        if (this.props.options.notes)
            notes = Array.isArray(this.props.options.notes)
                ? this.props.options.notes
                : [this.props.options.notes];

        return (
            <div>
                <TextField
                    className={
                        this.props.rawErrors && this.props.rawErrors.length > 0
                            ? 'error-border'
                            : ''
                    }
                    mask={this.props.options.mask as string}
                    help={this.props.options.helpText}
                    notes={notes}
                    label={this.props.label || this.props.schema.title}
                    autoFocus={this.props.autofocus}
                    showRequiredIndicator={this.props.required}
                    disabled={this.props.disabled || this.props.readonly}
                    readOnly={this.props.readonly}
                    placeholder={this.props.readonly ? 'N/A' : ' '}
                    type="text"
                    value={this.props.value}
                    onChange={(v) => {
                        this.props.onChange(v === '' ? this.props.options.emptyValue : v);
                    }}
                />
            </div>
        );
    }
}

export default TextWidget;
