import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { isUndefined, omitBy } from 'lodash';
import Heading from '../heading/Heading';
import DialogFormActions from '../../components/forms/components/DialogFormActions';
import DialogForm from '../../components/forms/DialogForm';
import { validateEmail, validatePhone } from '../../components/forms/validation/CustomValidation';

function ContactsDialog(props: any) {
    const { activeItem } = props;
    const [formData, setFormData] = useState<Record<string, any>>({ status: true });
    const [hasChange, setHasChange] = useState(false);

    useEffect(() => {
        if (activeItem) {
            const activeContact: Record<string, any> = { status: true };
            Object.entries(activeItem).forEach((field: Record<string, any>) => {
                activeContact[field[0]] =
                    field[1] && field[1].props ? field[1].props.value : field[1];
            });
            setFormData(activeContact);
        }
        setHasChange(false);
    }, [activeItem]);

    const onChange = (e: any) => {
        if (!hasChange) {
            setHasChange(JSON.stringify(e.formData) !== JSON.stringify(formData));
        }

        setFormData(e.formData);
    };

    const getSchema = () => {
        const schema: JSONSchema6 = {
            type: 'object',
            required: ['firstName', 'lastName', 'email'],
            properties: {
                status: { type: 'boolean', title: 'Status', default: true },
                firstName: {
                    title: 'First Name',
                    type: 'string',
                },
                lastName: {
                    title: 'Last Name',
                    type: 'string',
                },
                email: {
                    title: 'Email Address',
                    type: 'string',
                },
                phone: {
                    title: 'Phone Number',
                    type: 'string',
                    maxLength: 13,
                    minLength: 13,
                },
            },
        };
        return schema;
    };

    const getUiSchema = () => {
        const uiSchema: UiSchema = {
            'ui:field': 'layout',
            'ui:layout': [
                {
                    status: { md: 4 },
                },
                {
                    firstName: { sm: 12, md: 6, lg: 6, xl: 6 },
                    lastName: { sm: 12, md: 6, lg: 6, xl: 6 },
                },
                {
                    email: { sm: 12, md: 6, lg: 6, xl: 6 },
                    phone: { sm: 12, md: 6, lg: 6, xl: 6 },
                },
            ],
            status: {
                'ui:widget': 'SwitchWidget',
            },
            phone: {
                'ui:options': {
                    mask: '(999)999-9999',
                    note: 'Enter in (123)456-7890 format',
                },
            },
        };
        return uiSchema;
    };

    const getValidators = () => {
        const validators = [
            {
                field: 'email',
                validator: validateEmail,
                errorMessage: 'Please specify a valid email address in the form of user@webaddress',
            },
            {
                field: 'phone',
                validator: validatePhone,
                errorMessage: 'Please enter a valid phone number in the form of (123)456-7890',
            },
        ];

        return validators;
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };

    return (
        <Dialog onClose={props.handleClose} isOpen={props.isOpen} style={{ padding: '40px' }}>
            <div>
                <Heading alignment="left" text={activeItem ? 'Edit Contact' : 'Add Contact'} />
                <DialogForm
                    schema={getSchema()}
                    formData={formData}
                    uiSchema={getUiSchema()}
                    onChange={(e: any) => {
                        onChange(e);
                    }}
                    onSubmit={(e) => {
                        e.formData = omitBy(e.formData, isUndefined);
                        props.handleOkay(e, activeItem);
                    }}
                    onError={(e) => onError(e)}
                    validators={getValidators()}
                    minHeight="132px"
                    minWidth="624px"
                >
                    <DialogFormActions
                        addButtonIcon={activeItem ? 'tick' : 'add'}
                        onCancel={props.handleClose}
                        formHasChange={hasChange}
                        addButtonText={activeItem ? 'Update' : 'Add'}
                    />
                </DialogForm>
            </div>
        </Dialog>
    );
}

export default ContactsDialog;
