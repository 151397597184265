import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getPharmaciesSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Pharmacies',
        items: {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                    title: 'Name',
                },
                type: {
                    type: 'string',
                    title: 'Type',
                },
                contactName: {
                    type: 'string',
                    title: 'Contact Person',
                },
                address: {
                    type: 'string',
                    title: 'Address',
                },
                contactNumber: {
                    type: 'string',
                    title: 'Contact Number',
                },
                contactFaxNumber: {
                    type: 'string',
                    title: 'Fax Number',
                },
            },
        },
    };
};

export const getPharmaciesSectionUISchema = (sectionProps: PatientDetailSection): UiSchema => {
    const emptyListMessage = {
        title: 'No Pharmacies Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'pharmacies',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
            itemsPerPage: sectionProps.itemsPerPage as number,
        },
        items: {
            name: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    filterable: false,
                },
            },
            type: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    filterable: false,
                },
            },
            contactName: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    filterable: false,
                },
            },
            address: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    filterable: false,
                },
            },
            contactNumber: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    filterable: false,
                },
            },
            contactFaxNumber: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    filterable: false,
                },
            },
        },
    };
};
