/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { style } from 'typestyle';
import { observer } from 'mobx-react';
import { Button, H4 } from '@blueprintjs/core';

interface TitleFieldProps {
    id: string;
    title?: string;
    required: boolean;
    hash?: string;
    options?: any;
    formData?: any;
}

const titleButton = style({
    display: 'flex',
    justifyContent: 'space-between',
});

const hashStyle = style({
    position: 'absolute',
    top: '-50px',
});

@observer
class TitleField extends React.Component<TitleFieldProps> {
    render() {
        const { title, hash, options, formData } = this.props;
        return (
            <H4>
                {hash ? <span id={hash} className={hashStyle} /> : null}
                <div className={titleButton}>
                    {title}
                    {options?.enableActionButton ? (
                        <Button
                            icon="export"
                            disabled={options?.disabledButton}
                            onClick={() => options?.actionButtonCallback(formData)}
                        >
                            Export Results
                        </Button>
                    ) : null}
                </div>
            </H4>
        );
    }
}

export default TitleField;
