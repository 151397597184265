/* eslint-disable react/no-array-index-key */

import React from 'react';
import { classes, style } from 'typestyle';
import { important } from 'csx';

// import { FieldTemplateProps } from '@rjsf/core';
import { FieldTemplateProps } from 'react-jsonschema-form';
import { IconNames } from '@blueprintjs/icons';
import { Icon } from '@blueprintjs/core';

const baseTagClassName = classes(
    'bp3-tag',
    'bp3-minimal',
    style({
        display: important('block'),
        margin: '5px 5px 5px 0px',
        background: important('none'),
        color: important('#DB3737'),
        padding: important('0px'),
    })
);

const FieldTemplate = (props: FieldTemplateProps) => {
    const {
        children,
        displayLabel,
        rawErrors = [],
        rawHelp,
        rawDescription,
        id,
        formContext,
        uiSchema,
    } = props;

    const errors = rawErrors || [];
    if (formContext.errors) {
        const idPath = id.split('_');
        const extraErrors = idPath.reduce((accum, currentValue) => {
            if (currentValue === 'root') return accum;

            return (accum && accum[currentValue]) || null;
        }, formContext.errors);

        // eslint-disable-next-line no-underscore-dangle
        if (extraErrors && extraErrors.__errors) {
            // eslint-disable-next-line no-underscore-dangle
            extraErrors.__errors.forEach((error: string) => {
                if (!errors.includes(error)) {
                    errors.push(error);
                }
            });
        }
    }

    return (
        <div
            id={`section-name-${id.toLowerCase().replace('root_', '')}`}
            className={errors.length ? 'extra-error-border' : ''}
            data-automation-hook={`(Question)-field-name-${id.replace('root_', '')}`}
            style={{ marginBottom: '20px' }}
        >
            {children}
            {displayLabel && rawDescription ? (
                <h6 color="textSecondary">{rawDescription}</h6>
            ) : null}
            {rawErrors.length > 0 && (
                <ul>
                    {errors.map((error, i: number) => {
                        return (
                            <div key={i} className={classes(baseTagClassName)}>
                                <Icon icon={IconNames.ERROR} />
                                &nbsp;
                                {error}
                            </div>
                        );
                    })}
                </ul>
            )}
            {uiSchema['ui:options'] && uiSchema['ui:options'].note}
            {rawHelp && <span>{rawHelp}</span>}
        </div>
    );
};

export default FieldTemplate;
