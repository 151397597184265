import React, { Component } from 'react';
import { H6, Menu } from '@blueprintjs/core';
import MeshComponentMapper from '../../mesh/MeshComponentMapper';
import {
    FormLayout as FormLayoutInterface,
    MeshNode,
    SidebarElement,
} from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';
import ConfirmExitDialog from '../ConfirmExitDialog';

const sideBarStyle = {
    backgroundColor: '#f4f8fb',
    height: '200vh',
    marginLeft: '10px',
    marginRight: '10px',
};

interface FormLayoutProps extends FormLayoutInterface {
    store: Store;

    // Maps the name of a schema to a react component class. ClinicianDetails.tsx is an example of this.
    // Used to prevent circular dependencies from MeshComponentMapper.ts
    schemaToMeshType?: Map<string, any>;
}

interface FormLayoutState {
    recordName: string;
    recordId: string;
    activeElement?: MeshNode;
    isOpen: boolean;
    warnOnExit: boolean;
    targetSchema?: MeshNode;
}

class FormLayout extends Component<FormLayoutProps, FormLayoutState> {
    // meshMapper: MeshComponentMapper;

    dialogNavFunc?: () => void;

    constructor(props: FormLayoutProps) {
        super(props);

        // TODO: Refactor default to be a part of the sidebar list, or just default it to the first in the list.
        if (props.defaultElement) {
            const activeElement = props.defaultElement.element;

            this.state = {
                activeElement,
                recordName: '',
                recordId: '',
                isOpen: false,
                warnOnExit: false,
            };
        }

        this.setRecordName = this.setRecordName.bind(this);
        // this.meshMapper = new MeshComponentMapper();
    }

    setActiveElement(element: MeshNode) {
        this.dialogNavFunc = () => {
            this.setState((prevState) => {
                const targetElement = prevState.targetSchema;
                return { activeElement: targetElement };
            });
        };

        if (this.state.warnOnExit) {
            this.setState({
                isOpen: true,
                targetSchema: element,
            });
        } else {
            this.setState({ activeElement: element, targetSchema: element });
        }
    }

    setRecordName(recordName: string, recordId: string) {
        this.setState({
            recordName,
            recordId,
        });
    }

    handleWarnOnExit = (show: boolean = true) => {
        this.setState({ warnOnExit: show });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    showWarning = (callback?: () => void) => {
        if (callback) {
            this.dialogNavFunc = callback;
        }

        this.setState({ isOpen: true });
    };

    handleOkay = () => {
        if (this.dialogNavFunc) this.dialogNavFunc();
        this.setState({ isOpen: false, warnOnExit: false });
    };

    renderComponent(element: MeshNode) {
        if (!element.schema) {
            throw new Error('Element does not have a schema');
        }

        const componentType = this.props.schemaToMeshType
            ? this.props.schemaToMeshType.get(element.schema)
            : MeshComponentMapper.getComponent(element.schema);

        return React.createElement(componentType, {
            ...element,
            ...this.props,
            store: this.props.store,
            onNameChange: this.setRecordName,
            handleWarnOnExit: this.handleWarnOnExit,
            showWarning: this.showWarning,
        });
    }

    render() {
        const { recordName, recordId } = this.state;
        return (
            <div style={{ display: 'flex' }}>
                <ConfirmExitDialog
                    isOpen={this.state.isOpen}
                    handleClose={this.handleClose}
                    handleOkay={this.handleOkay}
                />
                <div>
                    <Menu style={sideBarStyle}>
                        <H6 style={{ fontWeight: 'bold', marginLeft: '5px' }}>{recordName}</H6>
                        <p style={{ marginLeft: '5px' }}>ID: {recordId}</p>
                        <Menu.Divider />
                        {this.props.sidebarElements.map((menu: SidebarElement) => {
                            return (
                                <Menu.Item
                                    key={menu.title}
                                    text={menu.title}
                                    onClick={() => this.setActiveElement(menu.element)}
                                />
                            );
                        })}
                    </Menu>
                </div>
                <div
                    style={{
                        paddingTop: '10px',
                        paddingRight: '20px',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {this.state.activeElement && this.renderComponent(this.state.activeElement)}
                </div>
            </div>
        );
    }
}

export default FormLayout;
