import React from 'react';

import { DateTimeField } from '@amxjs/ui';
import { runInAction, observable } from 'mobx';
import uuid from 'uuid';

import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';
import { style } from 'typestyle';

interface DateRangeProps extends WidgetProps {
    rightLabel?: string;
}

const dateFieldLeft = style({
    paddingRight: '5px',
});

const dateFieldRight = style({
    paddingLeft: '5px',
});

@observer
class DateRangeWidget extends React.Component<DateRangeProps> {
    @observable
    $startDateValue: Date | null;

    @observable
    $endDateValue: Date | null;

    id: any;

    enumOptions: any;

    constructor(props: any) {
        super(props);

        const { options, value } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        this.$startDateValue =
            !value || !value.startDate || typeof value.startDate === 'undefined'
                ? null
                : new Date(value.startDate);
        this.$endDateValue =
            !value || !value.endDate || typeof value.endDate === 'undefined'
                ? null
                : new Date(value.endDate);

        this.id = uuid.v4();
    }

    /* eslint-disable class-methods-use-this */
    convertProps(props: DateRangeProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: props.options,
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,
            schema: props.schema,
            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
            rightLabel: props.options.endDateLabel,
            help: props.options.helpText,
        };
    }

    render() {
        const props = this.convertProps(this.props);

        return (
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div className={dateFieldLeft}>
                    <DateTimeField
                        className={
                            this.props.rawErrors && this.props.rawErrors.length > 0
                                ? 'error-border'
                                : ''
                        }
                        help={props.help}
                        label={props.label || props.schema.title}
                        showRequiredIndicator={props.required}
                        disabled={props.disabled || props.readonly}
                        readOnly={props.readonly}
                        value={this.$startDateValue}
                        onChange={(v) =>
                            runInAction(() => {
                                this.$startDateValue = v;

                                let startDate;
                                if (this.$startDateValue) {
                                    startDate = this.$startDateValue.toLocaleDateString('en-US');
                                }

                                let endDate;
                                if (this.$endDateValue) {
                                    endDate = this.$endDateValue.toLocaleDateString('en-US');
                                    if (endDate === 'Invalid Date') {
                                        endDate = '';
                                    }
                                }

                                props.onChange({ startDate, endDate });
                            })
                        }
                        showPicker
                        showTimeInput={false}
                    />
                </div>
                <div className={dateFieldRight}>
                    <DateTimeField
                        label={props.rightLabel}
                        showRequiredIndicator={props.required}
                        disabled={props.disabled || props.readonly}
                        readOnly={props.readonly}
                        value={this.$endDateValue}
                        onChange={(v) =>
                            runInAction(() => {
                                this.$endDateValue = v;

                                let startDate;
                                if (this.$startDateValue) {
                                    startDate = this.$startDateValue.toLocaleDateString('en-US');
                                    if (startDate === 'Invalid Date') {
                                        startDate = '';
                                    }
                                }

                                let endDate;
                                if (this.$endDateValue) {
                                    endDate = this.$endDateValue.toLocaleDateString('en-US');
                                }

                                props.onChange({ startDate, endDate });
                            })
                        }
                        showPicker
                        showTimeInput={false}
                    />
                </div>
            </div>
        );
    }
}

export default DateRangeWidget;
