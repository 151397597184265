import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import { style } from 'typestyle';
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
    MipsPIAttestation,
    MipsPIAttestationMeasure,
    MipsPIAttestationMeasureWithProps,
    MipsPIAttestationMeasureType,
    MipsPIAttestationReportingCategory,
} from '../../../models/PIAttestation';
import { MipsAttestToPromotingInteroperability as MipsAttestToPromotingInteroperabilityInterface } from '../../../generator/generated/MeshInterfaces';
import { SchemaProvider } from '../../mesh/Schema';
import Form from '../../components/forms/Form';
import FormHeader from '../../components/forms/components/FormHeader';
import Store, { MipsManageRecordListOutput } from '../../core/Store';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { RowAction } from '../../components/Tables/List';
import Api from '../../core/Api';
import { showSuccessfulToast, showToastError } from '../utils/CommonToasts';
import { buildBreadcrumbTrail } from './utils';
import {
    getMipsAttestToPromotingInteroperabilitySchema,
    getAttestToPromotingInteroperabilityUISchema,
} from './MipsAttestToPromotingInteroperabilitySchemas';

interface MipsAttestToPromotingInteroperabilityProps
    extends MipsAttestToPromotingInteroperabilityInterface {
    store: Store;
}

const MipsAttestToPromotingInteroperability: FC<
    MipsAttestToPromotingInteroperabilityProps & RouteComponentProps
> = (props: MipsAttestToPromotingInteroperabilityProps & RouteComponentProps) => {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const {
        orgId,
        practitionerRoleFhirId,
        year,
        providerPractitionerFhirId,
    }: {
        orgId: string;
        practitionerRoleFhirId: string;
        year: string;
        providerPractitionerFhirId: string;
    } = queryString.parse(props.location.search);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [schema, setSchema] = useState<JSONSchema6>({});
    const [uiSchema, setUISchema] = useState<UiSchema>({});
    /**
     * rawData --[map]--> formData
     * rawData is used to communicate with the backend.
     * formData is used to render the table.
     */
    const [rawData, setRawData] = useState<MipsPIAttestation<MipsPIAttestationMeasure>>();
    const [formData, setFormData] =
        useState<MipsPIAttestation<MipsPIAttestationMeasureWithProps>>();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    // Because it's not possible to keep creating new callbacks and passing them to the List,
    // useRef is being used to keep a mutable object with the current state.
    // dataRef will always have the current data state
    // then "fixed" callbacks can refer to this object whenever
    // they need the current value.
    // Note: the callbacks will not be reactive - they will not re-run the instant state changes,
    // but they *will* see the current value whenever they do run
    const dataRef = useRef<MipsPIAttestation<MipsPIAttestationMeasure>>();
    dataRef.current = rawData;

    useEffect(() => {
        const init = async () => {
            const resp = await Api.mips.pIAttestation.getPIAttestationCombined(
                orgId,
                Number.parseInt(year, 10),
                practitionerRoleFhirId,
                providerPractitionerFhirId
            );
            if (resp.ok) {
                setRawData(resp.data);
            } else {
                showToastError(
                    'Error Retrieving Measures!',
                    'Could not retrieve measures. Please try again later.'
                );
            }
            setTableDataLoading(false);
        };
        init();
    }, [orgId, year, practitionerRoleFhirId, providerPractitionerFhirId]);

    const createAttestations = async (dataToCreate: MipsPIAttestationMeasure[]) => {
        if (dataToCreate.length > 0) {
            const defaultError = () =>
                showToastError(
                    'Changes Not Saved',
                    'Error while saving attestation of one or more measures. Please try again later.'
                );
            try {
                const response = await Api.mips.pIAttestation.createPIAttestation(
                    dataToCreate,
                    orgId,
                    practitionerRoleFhirId
                );
                if (response.ok) {
                    const [created, errored] = response.data;
                    // Updates attestations for created MeasureReports
                    created.forEach((piAttestation) => {
                        setRawData((prev) => ({
                            ...prev,
                            measures: prev?.measures?.map((d) =>
                                d.id === piAttestation.id
                                    ? {
                                          ...d,
                                          measureReportId: piAttestation.measureReportId,
                                          hasChanged: false,
                                      }
                                    : d
                            ),
                        }));
                    });
                    if (created.length > 0) {
                        showSuccessfulToast(
                            'Success',
                            `Measure attestations successfully created for measure${
                                created.length > 1 ? 's' : ''
                            }: ${created.map(({ shortMeasureId }) => shortMeasureId).join(', ')}`
                        );
                    }
                    if (errored.length > 0) {
                        showToastError(
                            'Changes Not Saved',
                            `Error while creating measure attestation for measure${
                                errored.length > 1 ? 's' : ''
                            }: ${errored
                                .map((e: MipsPIAttestationMeasure) => e.shortMeasureId)
                                .join(', ')}. Please try again later.`
                        );
                    }
                } else {
                    defaultError();
                }
            } catch {
                defaultError();
            }
        }
    };

    const updateAttestations = async (dataToSave: MipsPIAttestationMeasure[]) => {
        if (dataToSave.length > 0) {
            const defaultError = () =>
                showToastError(
                    'Changes Not Updated',
                    'Error while updating attestation of one or more measures. Please try again later.'
                );
            try {
                const response = await Api.mips.pIAttestation.updatePIAttestation(dataToSave);
                if (response.ok) {
                    const [updated, errored] = response.data;
                    if (updated.length > 0) {
                        const changed = updated.map(({ id, shortMeasureId }) => [
                            id,
                            shortMeasureId,
                        ]);
                        // Updates attestations for updated MeasureReports
                        setRawData((prev) => ({
                            ...prev,
                            measures: prev?.measures?.map((m) => ({
                                ...m,
                                ...(changed.flatMap((n) => n[0]).includes(m.id)
                                    ? { hasChanged: false }
                                    : null),
                            })),
                        }));
                        showSuccessfulToast(
                            'Success',
                            `Measure attestations successfully updated for measure${
                                updated.length > 1 ? 's' : ''
                            }: ${changed.flatMap((m) => m[1]).join(', ')}`
                        );
                    }
                    if (errored.length > 0) {
                        showToastError(
                            'Changes Not Saved',
                            `Error while updating measure attestation for measure${
                                errored.length > 1 ? 's' : ''
                            }: ${errored
                                .map((e: MipsPIAttestationMeasure) => e.shortMeasureId)
                                .join(', ')}. Please try again later.`
                        );
                    }
                } else {
                    defaultError();
                }
            } catch {
                defaultError();
            }
        }
    };

    const removeAttestations = async (dataToRemove: MipsPIAttestationMeasure[]) => {
        if (dataToRemove.length > 0) {
            const defaultError = () =>
                showToastError(
                    'Changes Not Saved',
                    'Error while removing measure attestation. Please try again later.'
                );
            try {
                const result = await Api.mips.pIAttestation.removeMeasureReport(dataToRemove);
                if (result.ok) {
                    const [removed, errored] = result.data;
                    // Updates attestations for removed MeasureReports
                    removed.forEach((piAttestation) => {
                        setRawData((prev) => ({
                            ...prev,
                            measures: prev?.measures?.map((d) =>
                                d.id === piAttestation.id
                                    ? {
                                          ...d,
                                          attested: false,
                                          measureReportId: undefined,
                                          startDate: undefined,
                                          endDate: undefined,
                                          hasChanged: false,
                                      }
                                    : d
                            ),
                        }));
                    });
                    if (removed.length > 0) {
                        showSuccessfulToast(
                            'Success',
                            `Measure attestation successfully removed for measure${
                                removed.length > 1 ? 's' : ''
                            }: ${removed
                                .map((r: MipsPIAttestationMeasure) => r.shortMeasureId)
                                .join(', ')}`
                        );
                    }
                    if (errored.length > 0) {
                        showToastError(
                            'Changes Not Saved',
                            `Error while removing measure attestation for measure${
                                errored.length > 1 ? 's' : ''
                            }: ${errored
                                .map((e: MipsPIAttestationMeasure) => e.shortMeasureId)
                                .join(', ')}. Please try again later.`
                        );
                    }
                } else {
                    defaultError();
                }
            } catch {
                defaultError();
            }
        }
    };

    const finalizeAttestations = async () => {
        setLoading(true);
        const defaultError = () =>
            showToastError(
                'Prescore Not successful',
                'Error while prescoring. Please try again later.'
            );
        try {
            const result = await Api.mips.pIAttestation.finalizeAttestation(
                year,
                orgId,
                providerPractitionerFhirId
            );

            if (!result.ok) {
                defaultError();
            }
        } catch {
            defaultError();
        }
    };

    const saveData = async () => {
        setLoading(true);
        const modifiedData: MipsPIAttestationMeasure[] = (
            dataRef.current?.measures?.filter((piAttestation) => piAttestation.hasChanged) || []
        ).map((piAttestation: MipsPIAttestationMeasure) => ({
            ...piAttestation,
            // If it's an exclusion the user won't be able to set a period, but CDR requires that.
            ...(piAttestation.reportingCategory?.toLowerCase() ===
            MipsPIAttestationReportingCategory.EXCLUSION
                ? { startDate: new Date(`${year}-01-01`), endDate: new Date(`${year}-12-31`) }
                : null),
        }));
        const attestedData: MipsPIAttestationMeasure[] =
            modifiedData.filter((piAttestation) => piAttestation.attested === true) || [];
        const dataToSave: MipsPIAttestationMeasure[] = attestedData.filter(
            (piAttestation) =>
                piAttestation.measureType === MipsPIAttestationMeasureType.BOOLEAN ||
                (piAttestation.performanceRate?.numerator &&
                    piAttestation.performanceRate.denominator)
        );
        const dataToUpdate: MipsPIAttestationMeasure[] = dataToSave.filter(
            (piAttestation) => piAttestation.measureReportId
        );
        const dataToCreate: MipsPIAttestationMeasure[] = dataToSave.filter(
            (piAttestation) => !piAttestation.measureReportId
        );
        const dataToRemove: MipsPIAttestationMeasure[] =
            modifiedData.filter((piAttestation) => piAttestation.attested === false) || [];
        await Promise.all([
            createAttestations(dataToCreate),
            updateAttestations(dataToUpdate),
            removeAttestations(dataToRemove),
        ])
            .then(finalizeAttestations)
            .finally(() => {
                setLoading(false);
            });
    };

    // Disable Save button if the form is loading or there's no pending changes
    useEffect(() => {
        setSaveButtonDisabled(loading || !rawData?.measures?.some((m) => m.hasChanged));
    }, [rawData?.measures, loading]);

    // Breadcrumb handling
    useEffect(() => {
        const setTrailData = (recordList: MipsManageRecordListOutput) => {
            const { labels, routes } = buildBreadcrumbTrail([props.ParentPageLink], recordList);
            const breadcrumbTrail: Record<string, any[]> = {
                labels: labels?.reverse() || [],
                routes: routes?.reverse() || [],
            };
            breadcrumbTrail.labels.push(props.title);
            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });
        };
        const retrieveTrailData = async () => {
            if (props.store.MipsManageRecordList) {
                setTrailData(props.store.MipsManageRecordList);
            } else {
                const response = await Api.mips.readForRecordDetails({
                    organizationFhirId: orgId,
                    providerPractitionerFhirId,
                    reportingYear: year,
                });
                const recordList = response.data as MipsManageRecordListOutput;
                props.store.setMipsManageRecordList(recordList);
                setTrailData(recordList);
            }
        };
        retrieveTrailData();
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
        // Directly tracking if props.ParentPageLink.uuid changes.
        // If not, it will trigger the effect twice.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        orgId,
        year,
        providerPractitionerFhirId,
        props.ParentPageLink?.uuid,
        props.title,
        props.store,
        setBreadcrumbData,
    ]);

    const updateData = (id: string, field: string, newValue: any) => {
        setRawData((prev) => ({
            ...prev,
            measures: prev?.measures?.map((measure) =>
                measure.id !== id
                    ? measure
                    : {
                          ...measure,
                          // Unchecking the attested checkbox, removes the start and end date for the row as well.
                          ...(field === 'attested' && newValue === false && !measure.measureReportId
                              ? {
                                    startDate: undefined,
                                    endDate: undefined,
                                    hasChanged: false,
                                }
                              : { hasChanged: true }),
                          [field]: newValue,
                      }
            ),
        }));
    };

    const getMeasureFlags = useCallback(
        (measure: MipsPIAttestationMeasure) => {
            const isParent = Boolean(measure.exclusion?.length);
            const parent = rawData?.measures?.find((cm) =>
                cm.exclusion?.includes(measure.shortMeasureId)
            );
            const isChild = parent !== undefined;
            const children = rawData?.measures?.filter((cm) =>
                measure?.exclusion?.includes(cm.shortMeasureId)
            );
            const attestDisabled = Boolean(
                isChild ? parent?.attested : isParent && children?.some((cm) => cm.attested)
            );
            const isExclusion =
                measure.reportingCategory?.toLowerCase() ===
                MipsPIAttestationReportingCategory.EXCLUSION;
            const isBoolean = measure.measureType === MipsPIAttestationMeasureType.BOOLEAN;
            const disableDate = !measure.attested || attestDisabled;
            return { attestDisabled, disableDate, isExclusion, isBoolean };
        },
        [rawData?.measures]
    );

    useEffect(() => {
        const dataForRows: MipsPIAttestationMeasureWithProps[] | undefined = rawData?.measures?.map(
            (m) => {
                const { attestDisabled, disableDate, isExclusion, isBoolean } = getMeasureFlags(m);
                return {
                    ...m,
                    shortMeasureId: m.recordIsFromMonet
                        ? `${m.shortMeasureId} (QRDAIII)`
                        : m.shortMeasureId,
                    attested: {
                        value: m.attested,
                        props: {
                            disabled: attestDisabled,
                            onChange: (newValue: Date | null) =>
                                updateData(m.id, 'attested', newValue),
                        },
                    },
                    startDate: {
                        value: m.startDate && new Date(m.startDate),
                        props: {
                            disabled: disableDate,
                            hidden: isExclusion,
                            hideErrors: disableDate || isExclusion,
                            onChange: (newValue: Date | null) =>
                                updateData(m.id, 'startDate', newValue),
                        },
                    },
                    endDate: {
                        value: m.endDate && new Date(m.endDate),
                        props: {
                            disabled: disableDate,
                            hidden: isExclusion,
                            hideErrors: disableDate || isExclusion,
                            onChange: (newValue: Date | null) =>
                                updateData(m.id, 'endDate', newValue),
                        },
                    },
                    performanceRate: {
                        value: {
                            numerator: m.performanceRate?.numerator,
                            denominator: m.performanceRate?.denominator,
                        },
                        props: {
                            disabled: !m.attested,
                            hidden: isBoolean,
                            hideErrors: !m.attested || isBoolean,
                            onChange: (newValue: Date | null) =>
                                updateData(m.id, 'performanceRate', newValue),
                        },
                    },
                };
            }
        );
        if (dataForRows) {
            setFormData({ ...rawData, measures: dataForRows });
        }
    }, [rawData, getMeasureFlags]);

    const [rowActions] = useState<RowAction[]>([
        {
            id: 'remove',
            label: '',
            behavior: 'Delete',
            icon: IconNames.REMOVE,
            intent: Intent.DANGER,
            callbackOnDelete: async (rowData) => {
                const piAttestationMeasure = dataRef.current?.measures?.find(
                    (m) => m.measureReportId === rowData.measureReportId
                );
                if (piAttestationMeasure?.measureReportId) {
                    await removeAttestations([piAttestationMeasure]);
                }
            },
            link: {},
        },
    ]);

    const onChangeList = useCallback(
        (
            value: MipsPIAttestationMeasure[],
            field: keyof MipsPIAttestationMeasure,
            rowNumber: number
        ) => {
            const measure = value[rowNumber];
            updateData(measure.id, field, measure[field]);
        },
        []
    );

    useEffect(() => {
        const auxSchema: Record<string, JSONSchema6> = {};
        const auxUISchema: Record<string, UiSchema> = {};
        auxSchema.data = getMipsAttestToPromotingInteroperabilitySchema(props);
        auxUISchema.data = getAttestToPromotingInteroperabilityUISchema(
            rowActions,
            rawData?.measures?.length || 0,
            props.itemsPerPage,
            tableDataLoading,
            onChangeList
        );
        setSchema({
            type: 'object',
            properties: auxSchema,
        });
        setUISchema(auxUISchema);
        return () => {
            setUISchema({});
            setSchema({});
        };
    }, [props, tableDataLoading, rawData?.measures?.length, rowActions, onChangeList]);

    // Note: "errors" should be of type "FormValidation".
    // However the typings for that property are not correct.
    const validate = ({ data }: { data?: MipsPIAttestationMeasureWithProps[] }, errors: any) => {
        // Only Validate fields that are attested, and not hidden or disable
        const rowsToValidate: number[] | undefined = data?.reduce((acc: number[], curr, index) => {
            if (
                curr.attested?.value &&
                !(
                    curr.performanceRate?.props?.hideErrors &&
                    curr.startDate?.props?.hideErrors &&
                    curr.endDate?.props?.hideErrors
                )
            ) {
                acc.push(index);
            }
            return acc;
        }, []);

        if (data && 'data' in errors) {
            rowsToValidate?.forEach((val: number) => {
                const stringKey = val.toString();
                if (stringKey.length && stringKey in errors.data) {
                    const field = errors.data[stringKey];

                    const measureWithProps = data[val];
                    const measure = rawData?.measures?.find(
                        (rd) => rd.measureId === measureWithProps.measureId
                    );
                    if (measure) {
                        const { disableDate, isExclusion, isBoolean } = getMeasureFlags(measure);
                        if (!isBoolean) {
                            if (measureWithProps.performanceRate?.value?.numerator === undefined) {
                                field.performanceRate.value.numerator.addError(
                                    'Numerator is required'
                                );
                            } else if (measureWithProps.performanceRate.value.numerator < 0) {
                                field.performanceRate.value.numerator.addError(
                                    'Numerator should be greater or equal to 0'
                                );
                            }
                            if (
                                measureWithProps.performanceRate?.value?.denominator === undefined
                            ) {
                                field.performanceRate.value.denominator.addError(
                                    'Denominator is required'
                                );
                            } else if (measureWithProps.performanceRate.value.denominator <= 0) {
                                field.performanceRate.value.denominator.addError(
                                    'Denominator should be greater than 0'
                                );
                            }
                        }
                        if (!(disableDate || isExclusion)) {
                            const startDate = measureWithProps.startDate?.value;
                            const endDate = measureWithProps.endDate?.value;
                            if (startDate !== undefined && endDate !== undefined) {
                                if (startDate > endDate) {
                                    field.endDate.value.addError(
                                        'End date must be after start date'
                                    );
                                }
                                const startYear = startDate.getUTCFullYear();
                                const endYear = endDate.getUTCFullYear();
                                const selectedYear = Number.parseInt(year, 10);
                                if (startYear !== selectedYear) {
                                    field.startDate.value.addError(
                                        'Start date must be within current period'
                                    );
                                }
                                if (endYear !== selectedYear) {
                                    field.endDate.value.addError(
                                        'End date must be within current period'
                                    );
                                }
                            } else {
                                if (startDate === undefined) {
                                    field.startDate.value.addError('Start Date is required');
                                }
                                if (endDate === undefined) {
                                    field.endDate.value.addError('End Date is required');
                                }
                            }
                        }
                    }
                }
            });
        }
        return errors;
    };

    const memoizedTitle = useMemo(() => {
        const tin = formData?.tin || '-';
        const npiOrOrganizationName = formData?.npi || formData?.organizationName || '-';
        return `TIN: ${tin} | ${npiOrOrganizationName} | ${year}`;
    }, [formData?.npi, formData?.organizationName, formData?.tin, year]);

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <Form
                recordId="promoting-interoperability-attestations"
                recordName="promotingInteroperabilityAttestations"
                sidebarElements={[]}
                hideSidebar
                primary
                schema={schema}
                uiSchema={uiSchema}
                formData={{ data: formData?.measures }}
                onSubmit={() => {
                    saveData();
                }}
                transformErrors={(errors) => errors}
                validate={validate}
            >
                <FormHeader
                    disableSticky
                    title={props.title}
                    description={
                        <div className={style({ marginTop: '10px' })}>
                            <p>{memoizedTitle}</p>
                            {props.description && <p>{props.description}</p>}
                        </div>
                    }
                    editMode
                    allowEditing
                    allowSave={!saveButtonDisabled}
                    hideCancel
                    primaryListStyle
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(MipsAttestToPromotingInteroperability);
