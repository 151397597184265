import React, { Component, Fragment } from 'react';
import { Button, IconName, Intent, MaybeElement } from '@blueprintjs/core';

export interface CustomButton {
    label: string;
    intent: Intent;
    onClick: () => void;
    icon?: IconName | MaybeElement;
    disabled: boolean;
    minimal: boolean;
    tooltip?: string;
}
interface FormActionsProps {
    editMode: boolean;
    allowEditing?: boolean;
    allowCancel?: boolean;
    allowPrinting?: boolean;
    allowSave?: boolean;
    hideCancel?: boolean;
    buttonOrder?: boolean;
    editButtonText?: string;
    exportButtonLabel?: string;
    customButton?: CustomButton;
    enableEditMode?: () => void;
    disableEditMode?: () => void;
}

class FormActions extends Component<FormActionsProps> {
    static print() {
        window.print();
    }

    renderEditButtons() {
        const editText = this.props.editButtonText || 'Edit Record';
        const saveText = 'Save';

        return (
            <Fragment>
                {this.props.editMode ? (
                    <Fragment>
                        {this.props.hideCancel ? null : (
                            <Button
                                icon="remove"
                                onClick={this.props.disableEditMode}
                                style={{ marginRight: '10px' }}
                                disabled={!this.props.allowCancel}
                                data-automation-hook="(ActionBar)-action-cancel"
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            icon="floppy-disk"
                            type="submit"
                            intent="primary"
                            disabled={!this.props.allowSave}
                            data-automation-hook="(ActionBar)-action-submit"
                        >
                            {saveText}
                        </Button>
                    </Fragment>
                ) : (
                    <>
                        <Button
                            onClick={this.props.enableEditMode}
                            icon="edit"
                            intent="primary"
                            data-automation-hook="(ActionBar)-action-edit"
                        >
                            {editText}
                        </Button>
                    </>
                )}
            </Fragment>
        );
    }

    renderCustomButton() {
        const {
            customButton,
            customButton: { tooltip, icon, label, onClick, intent, disabled } = {},
        } = this.props;
        return (
            customButton && (
                <Button
                    icon={icon}
                    onClick={onClick}
                    intent={intent}
                    disabled={disabled}
                    text={label}
                    title={tooltip}
                />
            )
        );
    }

    render() {
        return (
            <div className="form-action-group">
                {this.props.buttonOrder ? (
                    <>
                        {this.props.allowPrinting ? (
                            <Button
                                icon="export"
                                onClick={FormActions.print}
                                style={{ marginRight: '10px' }}
                                data-automation-hook="(ActionBar)-action-print"
                            >
                                {this.props.exportButtonLabel || 'Export Record'}
                            </Button>
                        ) : null}
                        {this.props.allowEditing ? this.renderEditButtons() : null}
                    </>
                ) : (
                    <>
                        {this.props.allowEditing ? this.renderEditButtons() : null}
                        {this.props.allowPrinting ? (
                            <Button
                                icon="export"
                                onClick={FormActions.print}
                                style={{ marginRight: '10px' }}
                                data-automation-hook="(ActionBar)-action-print"
                            >
                                {this.props.exportButtonLabel || 'Export Record'}
                            </Button>
                        ) : null}
                    </>
                )}

                {this.props.customButton ? this.renderCustomButton() : null}
            </div>
        );
    }
}

export default FormActions;
