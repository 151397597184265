import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getPromoteInteroperabilityDetailsSchema = (title: string): JSONSchema6 => {
    return {
        type: 'object',
        title: title || '',

        properties: {
            measureId: {
                type: 'string',
                title: 'Measure ID',
            },
            measureTitle: {
                type: 'string',
                title: 'Title',
            },
            description: {
                type: 'string',
                title: 'Description',
            },
            metricType: {
                type: 'string',
                title: 'Metric Type',
            },
            measureType: {
                type: 'string',
                title: 'Measure Type',
            },
            score: {
                type: 'string',
                title: 'Score',
            },
            decile: {
                type: 'string',
                title: 'Decile',
            },
            startDate: {
                type: 'string',
                title: 'Start Date',
            },
            endDate: {
                type: 'string',
                title: 'End Date',
            },

            performanceRate: {
                type: 'string',
                title: 'Performance Rate',
            },
            performanceRateNumerator: {
                type: 'number',
                title: 'Performance Numerator',
            },
            performanceRateDenominator: {
                type: 'number',
                title: 'Performance Denominator',
            },
        },
    };
};

export const getPromoteInteroperabilityDetailsUISchema = (labels: any): UiSchema => {
    const emptyListMessage = {
        title: labels?.PromoteInteroperability?.emptyListMessage || 'No Data Available',
    };

    return {
        'ui:field': 'layout',
        'ui:hash': 'PromoteInteroperabilityDetails',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        'ui:layout': [
            {
                measureId: { sm: 12, md: 6, lg: 4, xl: 3 },
                measureTitle: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
            {
                description: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                metricType: { sm: 12, md: 6, lg: 4, xl: 3 },
                measureType: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
            {
                score: { sm: 12, md: 3, lg: 3, xl: 3 },
                decile: { sm: 12, md: 3, lg: 3, xl: 3 },
                startDate: { sm: 12, md: 3, lg: 3, xl: 3 },
                endDate: { sm: 12, md: 3, lg: 3, xl: 3 },
            },

            {
                performanceRate: { sm: 12, md: 4, lg: 4, xl: 4 },
                performanceRateNumerator: { sm: 12, md: 4, lg: 4, xl: 4 },
                performanceRateDenominator: { sm: 12, md: 4, lg: 4, xl: 4 },
            },
        ],
        measureId: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        measureTitle: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        description: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        metricType: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },

        measureType: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        score: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        decile: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        startDate: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        endDate: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                renderMode: 'date',
            },
        },
        performanceRate: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        performanceRateNumerator: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
        performanceRateDenominator: {
            'ui:widget': 'LabelWidget',
            'ui:options': {},
        },
    };
};
