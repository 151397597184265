import React from 'react';
import { important } from 'csx';
import { classes, style } from 'typestyle';
import { Icon, Popover } from '@blueprintjs/core';

interface TProps {
    help: React.ReactNode;
    className?: string;
}

const contentClassName = style({
    maxHeight: '300px',
    padding: '15px',
    overflowY: 'auto',
});

const popoverClassName = classes(
    'bp3-popover-content-sizing',
    style({
        $nest: { '.bp3-popover-content': { padding: important(0) } },
    })
);

const HelpButton = (props: TProps) => {
    return (
        <span className={props.className}>
            <Popover
                lazy
                popoverClassName={popoverClassName}
                target={<Icon icon="help" style={{ cursor: 'pointer' }} />}
                content={<div className={contentClassName}>{props.help}</div>}
            />
        </span>
    );
};

HelpButton.defaultProps = { className: undefined };

export default HelpButton;
