import React, { FC, useEffect, useState } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import ProgressBar from '../components/ProgressBar';

export interface CustomProgressBarProps extends Omit<WidgetProps, 'value'> {
    value: {
        value?: number;
        description?: string;
        statusUploadDescription?: string;
        showRemovalButton?: boolean;
        showProgressBar?: boolean;
    };
}

const ProgressBarWidget: FC<CustomProgressBarProps> = (props: CustomProgressBarProps) => {
    const { actionRemovalButtonCallback } = props.options;
    const { value } = props;
    const [description, setDescription] = useState<string>();
    const [statusUploadDescription, setStatusUploadDescription] = useState<string>();
    const [progressValue, setProgressValue] = useState<number>();
    const [showRemovalButton, setShowRemovalButton] = useState<boolean>(false);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);

    useEffect(() => {
        if (value) {
            if (value.value !== undefined) {
                setProgressValue(value.value);
            }
            if (value.description) {
                setDescription(value.description);
            }
            if (value.statusUploadDescription) {
                setStatusUploadDescription(value.statusUploadDescription);
            }
            if (typeof value?.showRemovalButton === 'boolean') {
                setShowRemovalButton(value.showRemovalButton);
            }
            if (typeof value?.showProgressBar === 'boolean') {
                setShowProgressBar(value.showProgressBar);
            }
        }
    }, [value]);

    return (
        <ProgressBar
            value={progressValue}
            description={description}
            statusUploadDescription={statusUploadDescription}
            showRemovalButton={showRemovalButton}
            showProgressBar={showProgressBar}
            actionRemovalButtonCallback={actionRemovalButtonCallback}
        />
    );
};

export default ProgressBarWidget;
