import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import MipsModuleConstants from './MipsModuleConstants';

export const getCostUISchema = (): UiSchema => {
    const readOnlyFields = true;

    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:hash': 'cost',
        'ui:layout': [
            {
                cost: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        cost: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
    };

    return uiSchema;
};

export const getCostSchema = (
    title: string,
    _labels: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _description?: string
): JSONSchema6 => {
    const schema: JSONSchema6 = {
        title,
        type: 'object',
        required: [],
        properties: {
            cost: {
                type: 'null',
                title: '',
                description: _description || MipsModuleConstants.CostDescription,
            },
        },
    };

    return schema;
};
