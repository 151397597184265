import { Page } from '../../generator/generated/MeshInterfaces';

export default function formatDate(date: string) {
    if (!date) {
        return undefined;
    }
    if (date === '-') {
        return date;
    }
    const formattedDate = new Date(date);
    const month = formattedDate.getUTCMonth() + 1;
    const day = formattedDate.getUTCDate();
    const year = formattedDate.getUTCFullYear();
    return `${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}/${year}`;
}

interface LabelsAndRoutes extends Record<string, any[]> {
    labels: string[];
    routes: (string | null)[];
}

interface LabelAndRoute {
    label: string;
    route: string | null;
}

const initialLablesAndRoutes: LabelsAndRoutes = { labels: [], routes: [] };

export const getRoutes = (
    parentPageBreadcrumbTrail: Page[] | undefined,
    year: number
): LabelsAndRoutes =>
    parentPageBreadcrumbTrail
        ?.map(
            (crumb: Page): LabelAndRoute => ({
                label: crumb.name,
                route: year ? `${crumb.route}?year=${year}` || null : crumb.route || null,
            })
        )
        ?.reduce(
            (prev: LabelsAndRoutes, curr: LabelAndRoute) => ({
                labels: [...prev.labels, curr.label],
                routes: [...prev.routes, curr.route],
            }),
            initialLablesAndRoutes
        ) || initialLablesAndRoutes;

export const formatDecimalPlaces = (numberToFormat: string, decimalPlaces = 2) => {
    const parsed = Number.parseFloat(numberToFormat);
    if (Number.isNaN(parsed)) {
        return numberToFormat;
    }
    return parsed.toFixed(decimalPlaces);
};

export const swapDisplayValues = (
    data: any,
    targetField: string,
    valueSet: Record<string, string>
) =>
    data.map((row: any) => ({
        ...row,
        ...(valueSet[row[targetField]]
            ? {
                  [targetField]: valueSet[row[targetField]],
              }
            : null),
    }));
