import React, { useEffect } from 'react';
import { H3 } from '@blueprintjs/core';
import { Heading, Page } from '../../../generator/generated/MeshInterfaces';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';

function HeadingPage(props: Heading) {
    const { setBreadcrumbData } = useBreadcrumbContext();

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        if (props.name) {
            breadcrumbTrail.labels.push(props.name);
            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });
        }

        return function cleanup() {
            if (props.name) {
                setBreadcrumbData({
                    crumbLabels: [],
                    crumbRoutes: [],
                });
            }
        };
    }, [setBreadcrumbData, props.ParentPageLink, props.name]);

    return (
        <H3 style={{ textAlign: props.alignment || 'left', marginBottom: '24px' }}>{props.text}</H3>
    );
}
export default HeadingPage;
