/* eslint-disable */
import React from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { style } from 'typestyle';

interface LabelWidgetProps extends WidgetProps {
  label: string;
}

const labelClassName = style({
  display: 'block',
  margin: '0px 0 5px 10px',
  fontSize: '16px',
  paddingLeft: '3px',
})

class ArrayLabelWidget extends React.Component<LabelWidgetProps> {
  constructor(props: any) {
    super(props);
    
    this.state = {};
  }

  render() {
    const { value } = this.props;

    return (
      <div>
        <span className={labelClassName}>{value ? value : '-'}</span>
      </div>
    )
  }
}

export default ArrayLabelWidget;
