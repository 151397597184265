import {
    ClinicianListColumn,
    MipsExplorerColumn,
    OrganizationListColumn,
    PatientListColumn,
    UserListColumn,
} from '../../../generator/generated/MeshInterfaces';

export const sortValues: Record<string, string> = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
};

export function setSortingValues(
    sorting: Record<string, string>
): (
    value:
        | UserListColumn
        | PatientListColumn
        | ClinicianListColumn
        | OrganizationListColumn
        | MipsExplorerColumn,
    index: number,
    array: (
        | UserListColumn
        | PatientListColumn
        | ClinicianListColumn
        | OrganizationListColumn
        | MipsExplorerColumn
    )[]
) => void {
    return (
        col:
            | UserListColumn
            | PatientListColumn
            | ClinicianListColumn
            | OrganizationListColumn
            | MipsExplorerColumn
    ) => {
        const sort = sorting;
        if (col.sortable && (col.sortable === 'ASCENDING' || col.sortable === 'DESCENDING')) {
            sort[col.id] = sortValues[col.sortable];
        } else {
            sort[col.id] = '';
        }
    };
}

export const getSortingValues = (columns: MipsExplorerColumn[]): Record<string, string> =>
    columns
        .map((c) => ({
            ...c,
            ...(c.sortable && (c.sortable === 'ASCENDING' || c.sortable === 'DESCENDING')
                ? { [c.id]: sortValues[c.sortable] }
                : { [c.id]: '' }),
        }))
        .reduce((p, c) => ({ ...p, ...c }), {});

export default function filterAndSort(columnSorting: string) {
    return `includeInSearchString:true${
        columnSorting ? `, sortDirection: "${columnSorting}"` : ''
    }`;
}

export function columnSort(columnSorting: string) {
    if (!columnSorting) return '';
    return `(sortDirection: "${columnSorting}")`;
}
