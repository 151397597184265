import React from 'react';

import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

const ConfirmExitDialog = (props: any) =>
    props.isOpen ? (
        <Dialog
            onClose={props.handleClose}
            isOpen={props.isOpen}
            title="Are you sure you want to cancel?"
        >
            <div className={Classes.DIALOG_BODY}>
                <p>If you continue, you will lose all your unsaved changes</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        text="No, go back"
                        onClick={props.handleClose}
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                    />
                    <Button
                        text="Yes, cancel"
                        intent={Intent.DANGER}
                        onClick={props.handleOkay}
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                    />
                </div>
            </div>
        </Dialog>
    ) : null;

export default ConfirmExitDialog;
