import { RouteComponentProps } from 'react-router';
import { UiSchema } from 'react-jsonschema-form';
import { JSONSchema6 } from 'json-schema';

import { Intent } from '@blueprintjs/core';
import { TClientResult } from '@amxjs/api/client';

import { EditOutput } from '../../../../server/routes/Mips/EditImprovementActivityInterface';
import { MipsRecord as MipsRecordInterface } from '../../../../generator/generated/MeshInterfaces';
import { ImprovementActivityScore } from '../../../../server/routes/Mips/ManageRecordReadInterface';
import { MipsManageRecordListOutput } from '../../../core/Store';
import { InjectedDataDictionaryProps } from '../../../util/DataDictionary';
import { RowAction } from '../../../components/Tables/List';
import Api from '../../../core/Api';

export const getImprovementActivitiesSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'ImprovementActivities',
        items: {
            type: 'object',
            properties: {
                measureId: {
                    type: 'string',
                    title: 'Measure ID',
                },
                measureTitle: {
                    type: 'string',
                    title: 'Title',
                },
                subcategory: {
                    type: 'string',
                    title: 'Subcategory',
                },
                weight: {
                    type: 'string',
                    title: 'Weight',
                },
                score: {
                    type: 'string',
                    title: 'Score',
                },
                startDate: {
                    type: 'string',
                    title: 'Start Date',
                },
                endDate: {
                    type: 'string',
                    title: 'End Date',
                },
                view: {
                    type: 'string',
                    title: ' ',
                },
            },
        },
    };
};

export const updateImprovementActivities = (
    formData: MipsManageRecordListOutput,
    origFormData: MipsManageRecordListOutput
): Promise<TClientResult<EditOutput | null>>[] => {
    const currentActivityFhirIds: Record<string, boolean> | undefined =
        formData.improvementActivityScores
            ?.map((elem) => ({ [elem.scoreMeasureReportFhirId]: true }))
            .reduce((prev, curr) => ({ ...prev, ...curr }), {});
    const promises =
        origFormData.improvementActivityScores
            ?.filter((elem) => !currentActivityFhirIds?.[elem.scoreMeasureReportFhirId])
            .map((elem) =>
                Api.mips.deleteImprovementActivity({
                    organizationFhirId: formData.organizationFhirId,
                    reportingYear: formData.reportingYear,
                    practitionerToApplyChangeTo: formData.providerPractitionerFhirId,
                    measureId: elem.measureId,
                })
            ) || [];
    return promises;
};

const onDeleteImprovementActivity =
    (sectionFormData: ImprovementActivityScore[] | undefined, setSectionFormData: Function) =>
    (e: ImprovementActivityScore) => {
        const activitiesRemaining = sectionFormData?.filter((activity) => {
            return activity.scoreMeasureReportFhirId !== e.scoreMeasureReportFhirId;
        });

        setSectionFormData(activitiesRemaining);
    };

const getRowActions = (
    sectionFormData: ImprovementActivityScore[] | undefined,
    setSectionFormData: Function
): RowAction[] => [
    {
        id: `improvement-activity-remove`,
        label: '',
        behavior: 'Delete',
        intent: Intent.DANGER,
        link: {},
        icon: 'remove',
        callbackOnDelete: onDeleteImprovementActivity(sectionFormData, setSectionFormData),
    },
];

// This is called when adding a new IA. We need to be taken to the page in 'Add' mode
export const navCallback = (
    linkToAddIa: string | undefined,
    props: MipsRecordInterface & RouteComponentProps & InjectedDataDictionaryProps
) => {
    if (linkToAddIa) {
        props.history.push(linkToAddIa);
    } else {
        console.error('Linked Component is not a Page to route to.');
    }
};

export const getImprovementActivitiesUISchema =
    (
        sectionFormData: ImprovementActivityScore[] | undefined,
        setSectionFormData: Function,
        linkToAddIa: string | undefined,
        props: MipsRecordInterface & RouteComponentProps & InjectedDataDictionaryProps
    ) =>
    (editMode: boolean): UiSchema => {
        const emptyListMessage = {
            title: 'No Improvement Activities',
            description: 'Select “Edit TIN” to add improvement activities',
        };

        return {
            'ui:field': 'list',
            'ui:hash': 'improvementactivityscores',
            'ui:options': {
                emptyListMessage,
                emptyDataListMessage: emptyListMessage,
                removable: editMode,
                fieldNameForDeleteDialog: 'measureId',
                rowActions: getRowActions(sectionFormData, setSectionFormData),
                addable: editMode,
                addButtonLabel: 'Add Improvement Activity',
                onAddItemClick: () => navCallback(linkToAddIa, props),
            },
            items: {
                measureId: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                measureTitle: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                subcategory: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                score: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                startDate: {
                    'ui:readonly': true,
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                endDate: {
                    'ui:readonly': true,
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                weight: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
            },
            view: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: false,
                },
            },
        };
    };
