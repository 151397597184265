import React from 'react';

import { CardClickableList } from '@amxjs/ui';
// import { ErrorListProps } from '@rjsf/core';
import { ErrorListProps } from 'react-jsonschema-form';

const ErrorList = ({ errors, formContext }: ErrorListProps) => {
    if (formContext && formContext.showErrorList) {
        return (
            <CardClickableList
                title="Errors"
                description=""
                linkedPageText="Read more"
                listItemsToDisplay={4}
                listItems={errors.map((error: any) => {
                    return {
                        listItemIcon: 'error',
                        listItemIconIntent: 'none',
                        listItemHeading: '',
                        listItemText: error.stack,
                    };
                })}
            />
        );
    }
    return <div />;
};

export default ErrorList;
