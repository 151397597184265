import React, { Component } from 'react';
import { observer } from 'mobx-react';
// import { JSONSchema7 } from 'json-schema';
// import Form from '@rjsf/core';
import { JSONSchema6 } from 'json-schema';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import AmxThemedForm from '../../components/forms/AmxThemedForm';
import Store from '../../core/Store';
import { FhirEditorForm as FhirEditorFormInterface } from '../../../generator/generated/MeshInterfaces';
import Api from '../../core/Api';

export interface FhirEditorFormProps extends FhirEditorFormInterface {
    store: Store;
}

interface FhirEditorFormState {
    formData: any;
}

@observer
class FhirEditorForm extends Component<
    FhirEditorFormProps & RouteComponentProps,
    FhirEditorFormState
> {
    updateMode = false;

    constructor(props: any) {
        super(props);

        this.state = { formData: {} };
    }

    componentDidMount() {
        const { id } = queryString.parse(this.props.location.search);

        if (id) {
            this.updateMode = true;
            Api.queryFhirResource(
                this.props.primaryResource,
                id as string,
                this.props.graphQLQuery || ''
            ).then((response) => {
                const rawData = response.data;

                this.setState({ formData: rawData });
            });
        }
    }

    static onChange(formData: any, e: any) {
        console.log('changed', formData, e);
    }

    static onError(errors: any) {
        console.log('errored', errors);
    }

    static onSubmit(formData: any) {
        console.log('submitted', formData);
    }

    getSchema(): JSONSchema6 {
        return JSON.parse(this.props.jsonSchema);
    }

    getUiSchema(): JSONSchema6 {
        return JSON.parse(this.props.uiSchema ? this.props.uiSchema : '{}');
    }

    render() {
        return (
            <AmxThemedForm
                schema={this.getSchema()}
                formData={this.state.formData}
                uiSchema={this.getUiSchema()}
                onChange={FhirEditorForm.onChange}
                onSubmit={(submitEvent: any) => FhirEditorForm.onSubmit(submitEvent)}
                onError={FhirEditorForm.onError}
            />
        );
    }
}

export default withRouter(FhirEditorForm);
