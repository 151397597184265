/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Button, Classes, Dialog, H5, Intent } from '@blueprintjs/core';

interface DeleteHandlerProps {
    isOpen: boolean;
    onClose: (event?: React.SyntheticEvent<HTMLElement>) => void;
    onSubmit: (event?: React.SyntheticEvent<HTMLElement>) => void;
    recordNameBeingDelete: string;
}

class DeleteDialog extends Component<DeleteHandlerProps> {
    constructor(props: DeleteHandlerProps) {
        super(props);
    }

    render() {
        const removeTitleText = `Are you sure you want to remove ${this.props.recordNameBeingDelete}?`;
        const removeSubText = 'If you save the record, you will not be able to undo your changes.';
        const cancelText = 'No, go back';
        const submitText = 'Yes, remove';
        return this.props.isOpen ? (
            <Dialog
                onClose={this.props.onClose}
                isOpen={this.props.isOpen}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
            >
                <div style={{ padding: '15px', backgroundColor: 'white', width: '360px' }}>
                    <H5>{removeTitleText}</H5>
                    <p>{removeSubText}</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                        <Button
                            className={Classes.POPOVER_DISMISS}
                            style={{ marginRight: 10 }}
                            onClick={this.props.onClose}
                            data-automation-hook="(ActionBar)-dialog-action-cancel"
                        >
                            {cancelText}
                        </Button>
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={this.props.onSubmit}
                            data-automation-hook="(ActionBar)-dialog-action-submit"
                        >
                            {submitText}
                        </Button>
                    </div>
                </div>
            </Dialog>
        ) : null;
    }
}

export default DeleteDialog;
