import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getGeneralUISchema = (
    editing: boolean,
    selectedItem: string | undefined,
    userHasAccessToAllAffiliatedPractices: boolean
): UiSchema => {
    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:hash': 'general',
        'ui:layout': [
            {
                firstName: { sm: 4 },
                middleName: { sm: 2.5 },
                lastName: { sm: 4 },
                suffix: { sm: 1.5 },
            },
            {
                npi: { sm: 4 },
            },
            {
                primaryEmail: { sm: 4 },
                phoneNumber: { sm: 2.5 },
            },
            {
                clinicianType: { sm: 4 },
            },
            {
                clinicianStatus: { sm: 4 },
            },
        ],
        clinicianStatus: {
            'ui:readonly': !editing || !userHasAccessToAllAffiliatedPractices,
            'ui:widget': editing ? 'SwitchWidget' : 'LabelWidget',
            'ui:options':
                editing && !userHasAccessToAllAffiliatedPractices
                    ? {
                          helpText:
                              "You don't have access to all of the organizations this clinician is associated with. Please reach out to your Registry Administrator to resolve.",
                      }
                    : undefined,
        },
        firstName: {
            'ui:readonly': !editing,
            'ui:widget': editing ? undefined : 'LabelWidget',
        },
        middleName: {
            'ui:readonly': !editing,
            'ui:widget': editing ? undefined : 'LabelWidget',
        },
        lastName: {
            'ui:readonly': !editing,
            'ui:widget': editing ? undefined : 'LabelWidget',
        },
        suffix: {
            'ui:readonly': !editing,
            'ui:widget': editing ? undefined : 'LabelWidget',
        },
        npi: {
            'ui:readonly': !editing,
            'ui:widget': editing ? undefined : 'LabelWidget',
            'ui:options': editing
                ? {
                      mask: '9999999999',
                      helpText:
                          'A 10 digit number healthcare providers acquire to uniquely identify themselves in a standardized way.',
                  }
                : undefined,
        },
        primaryEmail: {
            'ui:readonly': !editing,
            'ui:widget': editing ? 'email' : 'LabelWidget',
        },
        phoneNumber: {
            'ui:readonly': !editing,
            'ui:widget': editing ? undefined : 'LabelWidget',
            'ui:options': {
                mask: '(999)999-9999',
            },
        },
        clinicianType: {
            'ui:readonly': !editing,
            'ui:widget': editing ? 'SelectWidget' : 'LabelWidget',
            'ui:options': editing
                ? {
                      selectedItem,
                  }
                : undefined,
        },
    };

    return uiSchema;
};

export const getGeneralSchema = (title: string, clinicianTypes: string[]): JSONSchema6 => {
    const schema: JSONSchema6 = {
        title,
        type: 'object',
        required: ['firstName', 'lastName', 'primaryEmail', 'npi'],
        properties: {
            clinicianStatus: { type: 'boolean', title: 'Status', default: true },
            firstName: { type: 'string', title: 'First Name' },
            middleName: { type: 'string', title: 'Middle Name' },
            lastName: { type: 'string', title: 'Last Name' },
            suffix: { type: 'string', title: 'Suffix' },
            npi: { type: 'string', title: 'NPI' },
            primaryEmail: { type: 'string', title: 'Email Address' },
            phoneNumber: { type: 'string', title: 'Phone Number' },
            clinicianType: {
                default: 'None',
                title: 'Clinician Type',
                type: 'string',
                enum: clinicianTypes,
            },
        },
    };

    return schema;
};
