/* eslint-disable class-methods-use-this */
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Dialog } from '@blueprintjs/core';
import { SortedFilteredTable, TTableRowActionProps } from '@amxjs/ui';
import {
    AffectedIndividualsListTable,
    Page,
    SurveyListTable as SurveyTableInterface,
} from '../../../generator/generated/MeshInterfaces';
import SurveyListTable, { SurveyListTableWithPersonId } from './SurveyListTable';
import Api from '../../core/Api';
import { normalizedColumns } from './TableUtils';
import withBreadcrumbs from '../../core/BreadcrumbHoc';

interface BreadCrumbChildProps {
    setBreadcrumbData: (data: Record<string, (string | undefined)[]>) => {};
}

@observer
class AffectedTable extends React.Component<AffectedIndividualsListTable & BreadCrumbChildProps> {
    @observable
    rows: any[] = [];

    @observable dialogOpen: boolean = false;

    @observable surveyDetailsProps: SurveyListTableWithPersonId | null = null;

    rowActions: TTableRowActionProps[] = [
        {
            id: 'popup',
            label: 'Details',
            rightIcon: 'chevron-right',
            intent: 'primary',
            showInline: true,
            maxSelection: 1,
            showInSelectionView: true,
            showLabelInButton: true,
            onTrigger: async (_rowIds: string[], row: any) => {
                this.openDialog(row[0].id);
            },
        },
    ];

    componentDidMount() {
        this.setBreadcrumb();

        Api.getAffectedIndividuals().then((response) => {
            this.rows = response.data.map((r: any) => ({
                id: r.userId,
                cells: {
                    UserId: r.userId,
                    EmailAddress: r.email,
                    Gender: r.gender,
                    DateOfBirth: r.dateOfBirth,
                    PrimaryDiagnosis: r.primaryDiagnosis,
                    SecondaryDiagnosis: r.secondaryDiagnosis,
                    Persona: r.personaType,
                },
            }));
        });
    }

    componentWillUnmount() {
        this.clearBreadcrumb();
    }

    prepareRows = (rows: any[]) => {
        return rows.map((row) => {
            return { ...row, actionIds: ['popup'], cells: { ...row.cells } };
        });
    };

    setBreadcrumb = () => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (this.props.ParentPageLink && this.props.ParentPageLink.breadcrumbTrail) {
            this.props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(this.props.title);
        this.props.setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
    };

    clearBreadcrumb = () => {
        this.props.setBreadcrumbData({
            crumbLabels: [],
            crumbRoutes: [],
        });
    };

    openDialog = (personId: string) => {
        if (this.props.popOutTable != null) {
            this.surveyDetailsProps = this.props.popOutTable;
            this.surveyDetailsProps.personId = personId;
            this.dialogOpen = true;
        }
    };

    closeDialog = () => {
        this.dialogOpen = false;
    };

    export(columns: any[], rows: any[], fileName: string) {
        const columnsToDisplay = columns.map((column) => column.label);
        const columnsToMap = columns.map((column) => column.id);
        const rowsToDispaly = rows
            .map((row: any) =>
                columnsToMap
                    .map((column) => {
                        if (column === 'Actions' && row.cells.Actions.props != null)
                            return `"=HYPERLINK(""${row.cells.Actions.props.href}"", ""Download All"")"`;
                        return `"${row.cells[column]}"`;
                    })
                    .join(',')
            )
            .join('\n');
        const content = `${columnsToDisplay}\n${rowsToDispaly}\nShowing 1-${rows.length} of ${rows.length}`;
        const a = document.createElement('a');
        if (navigator.msSaveBlob) {
            // IE10
            navigator.msSaveBlob(
                new Blob([content], {
                    type: 'text/csv;encoding:utf-8',
                }),
                fileName
            );
        } else if (URL && 'download' in a) {
            // html5 A[download]
            a.href = URL.createObjectURL(
                new Blob([content], {
                    type: 'text/csv;encoding:utf-8',
                })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            window.location.href = `data:application/octet-stream,${encodeURIComponent(content)}`; // only this mime type is supported
        }
    }

    render() {
        const tableActions = [];
        if (this.props.allowExport) {
            tableActions.push({
                label: 'Export',
                onTrigger: (columns: any[], rows: any[]) => {
                    this.export(
                        columns,
                        rows,
                        `${this.props.title}-${new Date().toLocaleDateString('en-us', {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                        })}.csv`
                    );
                },
            });
        }

        return (
            <Fragment>
                <Dialog className="popup-table" onClose={this.closeDialog} isOpen={this.dialogOpen}>
                    <SurveyListTable {...(this.surveyDetailsProps as SurveyTableInterface)} />,
                </Dialog>
                <SortedFilteredTable
                    totalRows={this.rows.length}
                    rowActions={this.rowActions}
                    tableActions={tableActions}
                    columns={normalizedColumns(this.props.columns)}
                    rows={this.prepareRows(this.rows.slice())}
                    title={this.props.title}
                    itemsPerPage={this.props.itemsPerPage}
                    verticallyCenterRowContents
                    enableStickyHeader
                    showPagination
                    showFooter
                    disableColumnHoverIndicators
                    emptyList={{
                        title: 'No results',
                        description: 'Update the filter',
                        iconName: 'th-list',
                    }}
                />
            </Fragment>
        );
    }
}

export default withBreadcrumbs(AffectedTable);
