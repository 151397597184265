import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { mipsRecorddetailsSection } from '../../../../generator/generated/MeshInterfaces';
import MipsModuleConstants from '../MipsModuleConstants';

export const getPreferenceUISchema = (
    editMode: boolean,
    formSection: mipsRecorddetailsSection,
    optInReadOnly: boolean,
    participationOption: string,
    performanceCategoryParticipation: any,
    type?: string
): UiSchema => {
    const readOnlyFields = !editMode;
    const groupSchema = editMode
        ? [
              {
                  preferenceDescription: { sm: 12, md: 12, lg: 12, xl: 12 },
              },

              {
                  tin: { sm: 12, md: 6, lg: 4, xl: 3 },
                  tinName: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  participationOption: { sm: 12, md: 6, lg: 6, xl: 6 },
              },
              { optInDecision: { sm: 12, md: 6, lg: 6, xl: 6 } },
              {
                  performanceCategoryParticipation: { sm: 12, md: 6, lg: 6, xl: 6 },
              },
              { cehrtId: { sm: 12, md: 6, lg: 4, xl: 3 } },
              {
                  consentGiven: { sm: 12, md: 2, lg: 2, xl: 2 },
              },
          ]
        : [
              {
                  preferenceDescription: { sm: 12, md: 12, lg: 12, xl: 12 },
              },

              {
                  tin: { sm: 12, md: 6, lg: 4, xl: 3 },
                  tinName: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  participationOption: { sm: 12, md: 6, lg: 4, xl: 3 },
                  optInDecision: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  performanceCategoryParticipation: { sm: 12, md: 6, lg: 4, xl: 3 },
                  cehrtId: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  consentGiven: { sm: 12, md: 2, lg: 2, xl: 2 },
              },
          ];
    const individualSchema = editMode
        ? [
              {
                  preferenceDescription: { sm: 12, md: 12, lg: 12, xl: 12 },
              },
              {
                  clinicianName: { sm: 12, md: 6, lg: 4, xl: 3 },
                  npi: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  tin: { sm: 12, md: 6, lg: 4, xl: 3 },
                  tinName: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  optInDecision: { sm: 12, md: 6, lg: 6, xl: 6 },
              },
              {
                  performanceCategoryParticipation: { sm: 12, md: 6, lg: 6, xl: 6 },
              },
              {
                  cehrtId: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  emailAddress: { sm: 12, md: 6, lg: 4, xl: 3 },
                  consentGiven: { sm: 6, md: 6, lg: 4, xl: 3 },
              },
          ]
        : [
              {
                  preferenceDescription: { sm: 12, md: 12, lg: 12, xl: 12 },
              },
              {
                  clinicianName: { sm: 12, md: 6, lg: 4, xl: 3 },
                  npi: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  tin: { sm: 12, md: 6, lg: 4, xl: 3 },
                  tinName: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  optInDecision: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  performanceCategoryParticipation: { sm: 12, md: 6, lg: 4, xl: 3 },
                  cehrtId: { sm: 12, md: 6, lg: 4, xl: 3 },
              },
              {
                  emailAddress: { sm: 12, md: 6, lg: 4, xl: 3 },
                  consentGiven: { sm: 6, md: 6, lg: 4, xl: 3 },
              },
          ];
    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:hash': 'preferences',
        'ui:layout': type === 'Group' ? groupSchema : individualSchema,
        preferenceDescription: {
            'ui:readonly': true,
            'ui:widget': 'LabelWidget',
        },
        clinicianName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'LabelWidget',
        },
        npi: {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'LabelWidget',
        },
        tin: {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'LabelWidget',
        },
        tinName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'LabelWidget',
        },
        participationOption: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : 'RadioWidget',
            'ui:options': {
                selectedOption: participationOption,
            },
        },
        optInDecision: {
            'ui:readonly': readOnlyFields || optInReadOnly,
            'ui:widget': readOnlyFields || optInReadOnly ? 'LabelWidget' : 'RadioWidget',
            'ui:options': {
                helpText:
                    formSection.optInHelpText ||
                    'Opt-In Eligible providers can elect to opt-in to MIPS, voluntarily report, or not report. Field will be disabled if the provider is not Opt-In eligible or election has be sent to CMS for the reporting year.',
            },
        },

        performanceCategoryParticipation: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : 'CheckboxesWidget',
            'ui:options': {
                selectedOption: performanceCategoryParticipation,
            },
        },
        cehrtId: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : 'TextWidget',
            'ui:options': {
                mask: '***************',
                helpText:
                    'Certified Electronic Health Record Technology ID: A unique identifier based on your EHR vendor, product, and version that is required by CMS if submitting for the Promoting Interoperability category.',
            },
        },
        consentGiven: {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'LabelWidget',
            'ui:options': {
                booleanYesNo: true,
            },
        },
        emailAddress: {
            'ui:readonly': readOnlyFields,
            'ui:widget': 'LabelWidget',
        },
    };

    return uiSchema;
};

export const getPreferenceSchema = (
    title: string,
    labels: any,
    _description?: string
): JSONSchema6 => {
    const optInOptions = [
        {
            value: 'Fully Participating',
            id: 'fully-participating',
            aliasedValues: ['fully-participating'],
            label: 'Fully Participating',
            helpText: '',
            inlineInfo:
                labels.OptInFullyParticipatingDescription ||
                MipsModuleConstants.OptInFullyParticipatingDescription,
        },
        {
            value: 'Voluntarily Reporting',
            id: 'voluntarily-participating',
            aliasedValues: ['voluntary-reporting'],
            label: 'Voluntarily Reporting',
            helpText: '',
            inlineInfo:
                labels.OptInVoluntarilyReportingDescription ||
                MipsModuleConstants.OptInVoluntarilyReportingDescription,
        },
        {
            value: 'Not Participating',
            id: 'not-participating',
            aliasedValues: ['not-participating'],
            label: 'Not Participating',
            helpText: '',
            inlineInfo: '',
        },
    ];
    const performanceCategoryParticipationOptions = [
        {
            value: 'Quality Measures',
            id: 'Quality Measures',
            aliasedValues: ['Quality Measures'],
            label: 'Quality Measures (QM)',
            helpText: '',
            inlineInfo:
                labels.QualityMeasuresDescription || MipsModuleConstants.QualityMeasuresDescription,
        },
        {
            value: 'Improvement Activities',
            id: 'Improvement Activities',
            aliasedValues: ['Improvement Activities'],
            label: 'Improvement Activities (IA)',
            helpText: '',
            inlineInfo:
                labels.ImprovementActivitiesDescription ||
                MipsModuleConstants.ImprovementActivitiesDescription,
        },

        {
            value: 'Promoting Interoperability',
            id: 'Promoting Interoperability',
            aliasedValues: ['Promoting Interoperability'],
            label: 'Promoting Interoperability (PI)',
            helpText: '',
            inlineInfo:
                labels.PromotingInteroperabilityDescription ||
                MipsModuleConstants.PromotingInteroperabilityDescription,
        },
    ];
    const participationOptions = [
        {
            value: 'Group',
            id: 'participation-group',
            aliasedValues: ['participation-group', 'Group'],
            label: 'Group',
            helpText: '',
            inlineInfo:
                labels.ParticipationGroupDescription ||
                MipsModuleConstants.ParticipationGroupDescription,
        },
        {
            value: 'Individual',
            id: 'participation-individual',
            aliasedValues: ['participation-individual', 'Individual'],
            label: 'Individual',
            helpText: '',
            inlineInfo:
                labels.ParticipationIndividualDescription ||
                MipsModuleConstants.ParticipationIndividualDescription,
        },
        {
            value: 'Group + Individual',
            id: 'participation-both',
            aliasedValues: ['participation-both', 'Group + Individual'],
            label: 'Group + Individual',
            helpText: '',
            inlineInfo:
                labels.ParticipationBothDescription ||
                MipsModuleConstants.ParticipationBothDescription,
        },
    ];
    const schema: JSONSchema6 = {
        title,
        type: 'object',
        required: [],
        properties: {
            preferenceDescription: {
                type: 'null',
                title: '',
                description: _description || MipsModuleConstants.PreferenceDescription,
            },
            clinicianName: { type: 'string', title: 'Clinician' },
            npi: { type: 'string', title: 'NPI' },
            tin: { type: 'string', title: 'TIN' },
            tinName: { type: 'string', title: 'TIN Name' },
            participationOption: {
                type: 'string',
                title: 'Participation Option',
                enum: participationOptions,
            },
            optInDecision: { type: 'string', title: 'Opt-In Election', enum: optInOptions },
            performanceCategoryParticipation: {
                type: 'string',
                title: 'Performance Category Participation',
                enum: performanceCategoryParticipationOptions,
            },
            cehrtId: { type: 'string', title: 'CEHRT ID' },
            consentGiven: { type: 'boolean', title: 'Consent' },
            emailAddress: { type: 'string', title: 'Email' },
        },
    };

    return schema;
};
