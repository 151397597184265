import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { CardClickableList } from '@amxjs/ui';
import { IconName, Intent } from '@blueprintjs/core';
import { Card } from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';
import { prependAppBaseName, openLink } from '../../util/LinkUtils';

export interface ListItem {
    listItemHeading: string;
    listItemIcon: IconName;
    listItemIconIntent: Intent;
    listItemLink?: string;
    listItemAction?: (event: any) => void;
    listItemText: string;
}

export interface CardClickableListCardProps extends Card {
    listItems?: ListItem[];
    store?: Store;
}

interface CardClickableListCardState {
    listItems: ListItem[];
}
@observer
class CardClickableListCard extends Component<
    CardClickableListCardProps & RouteComponentProps,
    CardClickableListCardState
> {
    constructor(props: CardClickableListCardProps & RouteComponentProps) {
        super(props);

        this.state = { listItems: props.listItems || [] };
    }

    render() {
        const { description, history, title, linkedPageText, linkedPage, listItemsToDisplay } =
            this.props;
        const pageLink = linkedPage && linkedPage.route;
        return (
            <CardClickableList
                key={title || ''}
                description={description || ''}
                linkedPageText={linkedPageText}
                listItemsToDisplay={listItemsToDisplay || 2}
                listItems={this.state.listItems}
                pageLink={prependAppBaseName(pageLink)}
                onClick={() => openLink(pageLink, history)}
                title={title || ''}
            />
        );
    }
}

export default CardClickableListCard;
