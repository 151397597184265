import React, { useState, useEffect, FC } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { ToggleField } from '@amxjs/ui';

export interface CheckboxWidgetProps extends WidgetProps {
    hideFieldMessageArea?: boolean;
}

const CheckboxWidget: FC<CheckboxWidgetProps> = (props: CheckboxWidgetProps) => {
    const { label, required, readonly, disabled, hideFieldMessageArea } = props;
    const [checked, setChecked] = useState<boolean>(props.value);

    useEffect(() => {
        setChecked(props.value);
    }, [props]);

    return (
        <ToggleField
            hideFieldMessageArea={hideFieldMessageArea || false}
            key={`${props.id}_${Math.random()}`}
            onChange={(e) => {
                setChecked(e);
                props.onChange?.(e);
            }}
            label={label}
            indicator="checkbox"
            showRequiredIndicator={required}
            disabled={disabled || readonly}
            readOnly={readonly}
            value={checked}
        />
    );
};

CheckboxWidget.defaultProps = {
    hideFieldMessageArea: false,
};

export default CheckboxWidget;
