const normalizedColumns = (columns: any[]) => {
    return columns.map((column: any) => {
        let { maxWidth } = column;
        if (maxWidth) {
            // In the case of only digits, send it to the table as pixels
            if (maxWidth.toString().match(/^[0-9]+$/)) {
                maxWidth += 'px';
            }
        } else {
            // Use 1fr as a reasonable default
            maxWidth = '1fr';
        }
        return {
            ...column,
            // minWidth is a number, always pass in as pixels. use 96px as a reasonable default
            minWidth: column.minWidth ? `${column.minWidth}px` : '96px',
            maxWidth,
        };
    });
};

export { normalizedColumns };
