import React from 'react';

import { CheckboxGroupField } from '@amxjs/ui';
import { runInAction, observable } from 'mobx';
import uuid from 'uuid';

import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';

@observer
class CheckboxesWidget extends React.Component<WidgetProps> {
    @observable
    $value: any;

    id: any;

    enumOptions: any;

    constructor(props: any) {
        super(props);

        // const { options, multiple, value } = this.props;  // 2.0.0+
        const { options, value } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        // const emptyValue = multiple ? [] : '';  // 2.0.0+
        const emptyValue = '';

        this.$value = typeof value === 'undefined' ? emptyValue : value;

        this.id = uuid.v4();
    }

    convertOptions = (iOptions: any) => {
        return iOptions.map((option: any) => {
            const item = option.value;
            return {
                value: item.value,
                label: item.label,
                inlineInfo: item.inlineInfo,
            };
        });
    };

    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: this.convertOptions(props.options.enumOptions),
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,

            schema: props.schema,
            selectedOption: props.options.selectedOption,
            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
        };
    }

    render() {
        const props = this.convertProps(this.props);

        return (
            <CheckboxGroupField
                label={props.label}
                showRequiredIndicator={props.required}
                disabled={props.disabled || props.readonly}
                readOnly={props.readonly}
                value={props.value}
                options={props.options}
                onChange={(v) =>
                    runInAction(() => {
                        this.$value = v;
                        props.onChange(v);
                    })
                }
            />
        );
    }
}

export default CheckboxesWidget;
