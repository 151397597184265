import { TTableActionProps } from '@amxjs/ui';
import Api from '../../core/Api';
import { downloadFile } from '../utils/FileUtils';

const exportToCsv = (columns: any, rows: any, fileName: string, totalRowCount: number) => {
    const columnsToDisplay = columns.map((column: any) => column.label);
    const columnsToMap = columns.map((column: any) => column.id);
    const rowsToDisplay = rows
        .map((row: any) =>
            columnsToMap
                .map((column: any) => {
                    // For the actions column, replace with an excel style hyperlink
                    if (column === 'Actions') return `"=HYPERLINK("""", ""Download All"")"`;
                    // For columns with special display values, replace with the base value
                    if (row.cells[column] && row.cells[column].value)
                        return row.cells[column].value;
                    // Fow columns that are arrays of values, prints them in a comma (', ') delimited list
                    if (Array.isArray(row.cells[column])) {
                        const stringValues: string[] = [];
                        row.cells[column].forEach((rowData: any) => {
                            if (rowData && rowData.key) {
                                stringValues.push(rowData.key);
                            }
                        });
                        // Because this is a CSV and we want to display this with a comma it needs to be wrapped in double quotes
                        return `"${stringValues.join(', ')}"`;
                    }
                    // Check to see if this is an HTML type in the cell. This is needed in order to grab what to display
                    // for cases where we have extra icons or other elements displayed within the cell. In particular on
                    // the MIPS Explorer list where the Submission column contains an icon noting the submission status.
                    if (
                        row.cells[column] &&
                        row.cells[column].type === 'div' &&
                        row.cells[column].props &&
                        row.cells[column].props.children &&
                        row.cells[column].props.children.length
                    ) {
                        const dataToReturn: any[] = [];
                        row.cells[column].props.children.forEach(
                            (child: { type: string; props: { children: any } }) => {
                                if (
                                    child &&
                                    child.type === 'div' &&
                                    child.props &&
                                    child.props.children
                                ) {
                                    dataToReturn.push(child.props.children);
                                }
                            }
                        );
                        return dataToReturn;
                    }
                    // If the cell contains .props.content, and it is a string then use that value to display in the CSV
                    // This is because it will contain the display value of the string rather than any database/stored value.
                    if (
                        row.cells[column] &&
                        row.cells[column].props &&
                        row.cells[column].props.content &&
                        typeof row.cells[column].props.content === 'string'
                    )
                        return row.cells[column].props.content;
                    // For objects that have a key return that value for the excel file
                    // This can happen in cases where there is a single value for a field that is a tag
                    // See method - buildTagValue in List. for why this has a .key property
                    if (
                        row.cells[column] &&
                        typeof row.cells[column] === 'object' &&
                        row.cells[column].key
                    )
                        return row.cells[column].key;
                    // For objects without value, return an empty string instead of printing [object Object]
                    if (row.cells[column] && typeof row.cells[column] === 'object') return '';
                    return row.cells[column];
                })
                // Wrap all values in quotes that contain a comma because otherwise the CSV will treat it as a new column
                .map((value: any) => {
                    if (value && value.includes(',')) {
                        return `"${value}"`;
                    }
                    return value;
                })
                .join(',')
        )
        .join('\n');
    const content = `${columnsToDisplay}\n${rowsToDisplay}\nShowing 1-${rows.length} of ${totalRowCount}`;

    downloadFile(content, fileName, 'text/csv');
};

const exportToCsvAction: TTableActionProps = {
    label: 'Export List',
    iconName: 'export',
    onTrigger(columns: any[], rows: any[], optionalContent: { totalRowCount: number }) {
        exportToCsv(
            columns,
            rows,
            `${new Date().toLocaleDateString('en-us', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            })}.csv`,
            optionalContent && optionalContent.totalRowCount
                ? optionalContent.totalRowCount
                : rows.length
        );
    },
};

const downloadAllAction = (personId: string | undefined, PDF_URL: string, format = 'pdf') => {
    return {
        label: 'Download All',
        onTrigger(_unusedColumns: any[], rows: any[]) {
            rows.forEach((row) => {
                const surveyId = parseInt(row.cells.SurveyId, 10);

                const responseId = parseInt(row.cells.ResponseId, 10);
                if (surveyId && responseId) {
                    Api.downloadSurvey(surveyId, responseId, personId, format).then(
                        async (downloadResponse: any) => {
                            if (!downloadResponse.ok) {
                                console.error(downloadResponse.data);
                                return;
                            }
                            const url = `${PDF_URL}/${downloadResponse.data.resourceId}`;
                            const response = await fetch(url);
                            const blob = await response.blob();
                            downloadFile(
                                blob,
                                `Survey Response ${downloadResponse.data.resourceId}.pdf`,
                                'application/pdf'
                            );
                        }
                    );
                }
            });
        },
    };
};

export { exportToCsvAction, downloadAllAction };
