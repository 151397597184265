import React from 'react';
import uuid from 'uuid';
import { runInAction } from 'mobx';
import { WidgetProps } from 'react-jsonschema-form';
import { style } from 'typestyle';

import { DateTimeField } from '@amxjs/ui';

class DateWidget extends React.Component<WidgetProps> {
    id: any;

    constructor(props: any) {
        super(props);
        this.id = uuid.v4();
    }

    /* eslint-disable class-methods-use-this */
    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: props.options,
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            hidden: props.hidden,
            readonly: props.readonly,
            autofocus: props.autofocus,
            schema: props.schema,
            errors: props.rawErrors,
            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
        };
    }

    render() {
        const props = this.convertProps(this.props);
        let dateValue;
        if (typeof props.value === 'undefined') {
            dateValue = null;
        } else {
            // Adjust the date based on the users timezone offset in order to display the correct day
            const date = new Date(props.value);
            const userTimezone = date.getTimezoneOffset() * 60000;
            dateValue = new Date(date.getTime() + userTimezone);
        }

        return props.hidden ? null : (
            <div
                className={style({
                    maxWidth: '400px',
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                })}
            >
                <DateTimeField
                    label={props.label || props.schema?.title}
                    showRequiredIndicator={props.required}
                    disabled={props.disabled || props.readonly}
                    hideLabel={Boolean(props.options?.hideLabel)}
                    readOnly={props.readonly}
                    value={dateValue}
                    onChange={(v) =>
                        runInAction(() => {
                            if (v !== null) {
                                // Return the date back to the onChange method in UTC format adjusted based on the timezone offset.
                                const date = new Date(v);
                                const userTimezone = date.getTimezoneOffset() * 60000;
                                dateValue = new Date(date.getTime() - userTimezone);
                                props.onChange?.(dateValue);
                            } else {
                                props.onChange?.(v);
                            }
                        })
                    }
                    showPicker
                    showTimeInput={false}
                    errors={props.errors}
                />
            </div>
        );
    }
}

export default DateWidget;
