import React, { Fragment } from 'react';
import { Icon, Intent, Position, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TTableRowProps, SortedFilteredTable, TTableRowActionProps } from '@amxjs/ui';
import { TCellProps } from '@amxjs/ui/lib/components/table/model';
import { Page, SurveyListTable } from '../../../generator/generated/MeshInterfaces';
import Api from '../../core/Api';
import { exportToCsvAction, downloadAllAction } from './TableActions';
import { normalizedColumns } from './TableUtils';
import withBreadcrumbs from '../../core/BreadcrumbHoc';

interface BreadCrumbChildProps {
    setBreadcrumbData: (data: Record<string, (string | undefined)[]>) => {};
}

const PDF_URL = window.SERVER_CONFIG.REACT_APP_SERVER_PDF_RESOURCE_URL as string;

const content = (
    intent: Intent,
    statusText: string,
    actionText: string,
    link: string
): ContentInterface => ({
    Status: {
        value: statusText,
        displayValue: (
            <Fragment>
                <Icon icon={IconNames.DOT} intent={intent} />
                {statusText}
            </Fragment>
        ),
    },
    Actions: (
        <a rel="noreferrer noopener" target="_blank" href={link}>
            {actionText}
        </a>
    ),
});

interface ContentInterface {
    Status: TCellProps;
    Actions: JSX.Element;
}

const possibleStatus = (link: string): { [key: string]: ContentInterface } => ({
    Completed: content(Intent.SUCCESS, 'Completed', 'Download Responses', link),
    Incomplete: content(Intent.WARNING, 'Incomplete', 'Complete Survey', link),
    Expired: content(Intent.DANGER, 'Expired', '', link),
});

const getStatus = (status: string) => {
    let statusText = '';
    let intent: Intent;
    switch (status) {
        case 'Completed':
            statusText = 'Completed';
            intent = Intent.SUCCESS;
            break;
        case 'Incomplete':
            statusText = 'Incomplete';
            intent = Intent.WARNING;
            break;
        case 'Expired':
            statusText = 'Expired';
            intent = Intent.DANGER;
            break;
        default:
            statusText = '';
            intent = Intent.DANGER;
    }
    if (statusText !== '') {
        return {
            value: statusText,
            displayValue: (
                <Fragment>
                    <Icon icon={IconNames.DOT} intent={intent} />
                    {statusText}
                </Fragment>
            ),
        };
    }
    return <Fragment />;
};

const getActionId = (status: string, surveyUrl: string) => {
    switch (status) {
        case 'Completed':
            return 'download';
        case 'Incomplete':
            if (surveyUrl) return 'edit';
            return 'unknown';
        default:
            return 'unknown';
    }
};

const prepareRows = (rows: any[]) => {
    return rows.map((r, index) => {
        const link = 'https://example.com';
        const status = r.cells.Status && getStatus(r.cells.Status);
        const row: TTableRowProps = {
            id: index.toString(),
            cells: {
                Name: r.cells.Name,
                DateSent: {
                    value: r.cells.DateSent,
                    displayValue: (
                        <span style={{ fontFamily: 'monospace' }}>{r.cells.DateSent}</span>
                    ),
                },
                DateCompleted: {
                    value: r.cells.DateCompleted,
                    displayValue: (
                        <span style={{ fontFamily: 'monospace' }}>{r.cells.DateCompleted}</span>
                    ),
                },
                ExpirationDate: {
                    value: r.cells.ExpirationDate,
                    displayValue: (
                        <span style={{ fontFamily: 'monospace' }}>{r.cells.ExpirationDate}</span>
                    ),
                },
                SurveyUrl: r.cells.SurveyUrl,
                SurveyId: r.cells.SurveyId,
                ResponseId: r.cells.ResponseId,
                ...possibleStatus(link)[r.cells.Status],
                Status: status,
                Actions: r.cells.Actions,
            },
            actionIds: [getActionId(r.cells.Status, r.cells.SurveyUrl)],
            rowDetails: r.description ? <>{r.description}</> : undefined,
        };

        return row;
    });
};

export interface SurveyListTableWithPersonId extends SurveyListTable {
    personId?: string;
    exportFormat?: 'pdf' | 'quexmlpdf';
}
@observer
class SpecificTable extends React.Component<SurveyListTableWithPersonId & BreadCrumbChildProps> {
    @observable
    rows: any[] = [];

    rowActions: TTableRowActionProps[] = [
        {
            id: 'edit',
            label: 'Complete Survey',
            iconName: 'edit',
            showInline: true,
            maxSelection: 1,
            showInSelectionView: true,
            hideIfDisabled: true,
            showLabelInButton: true,
            onTrigger: async (_rowIds: string[], row: any[]) => {
                window.open(row[0].cells.SurveyUrl, '_blank');
            },
        },
        {
            id: 'download',
            label: 'Download Response',
            iconName: 'download',
            showInline: true,
            maxSelection: 1,
            showInSelectionView: true,
            hideIfDisabled: true,
            showLabelInButton: true,
            onTrigger: async (_rowIds: string[], row: any) => {
                const surveyId = parseInt(row[0].cells.SurveyId, 10);
                const responseId = parseInt(row[0].cells.ResponseId, 10);
                const toast = Toaster.create({ position: Position.BOTTOM_RIGHT });

                if (surveyId && responseId) {
                    if (this.props.exportFormat === 'quexmlpdf') {
                        toast.show({
                            timeout: 10000,
                            message: this.props.pleaseWaitToastMessage,
                            intent: Intent.NONE,
                        });
                    }
                    Api.downloadSurvey(
                        surveyId,
                        responseId,
                        this.props.personId,
                        this.props.exportFormat
                    ).then((downloadResponse: any) => {
                        if (!downloadResponse.ok) {
                            toast.show({
                                timeout: 10000,
                                message: this.props.errorToastMessage,
                                intent: Intent.DANGER,
                            });
                            console.error(downloadResponse.data);
                            return;
                        }

                        window.open(`${PDF_URL}/${downloadResponse.data.resourceId}`);
                    });
                }
            },
        },
    ];

    componentDidMount() {
        Api.getSurveyList(this.props.personId).then((response) => {
            if (!response.ok) {
                console.error(response.data);
                return;
            }

            this.setBreadcrumb();
            this.rows = response.data.map((r) => ({ cells: r }));
        });
    }

    componentWillUnmount() {
        this.clearBreadcrumb();
    }

    setBreadcrumb = () => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (this.props.ParentPageLink && this.props.ParentPageLink.breadcrumbTrail) {
            this.props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(this.props.title);
        this.props.setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
    };

    clearBreadcrumb = () => {
        this.props.setBreadcrumbData({
            crumbLabels: [],
            crumbRoutes: [],
        });
    };

    render() {
        const { personId } = this.props;

        const tableActions = [];
        if (this.props.allowExport) {
            tableActions.push(exportToCsvAction);
        }

        if (this.props.allowDownload) {
            tableActions.push(downloadAllAction(personId, PDF_URL, this.props.exportFormat));
        }

        return (
            <SortedFilteredTable
                columns={normalizedColumns(this.props.columns)}
                rows={prepareRows(this.rows.slice())}
                totalRows={this.rows.length}
                title={this.props.title}
                rowActions={this.rowActions}
                tableActions={tableActions}
                itemsPerPage={this.props.itemsPerPage}
                verticallyCenterRowContents
                enableStickyHeader
                showPagination
                showFooter
                disableColumnHoverIndicators
                emptyList={{
                    title: 'No results',
                    description: 'Update the filter',
                    iconName: 'th-list',
                }}
            />
        );
    }
}

export default withBreadcrumbs(SpecificTable);
