import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export function GetAllCodesSchema(title: string): JSONSchema6 {
    return {
        type: 'array',
        title,
        items: {
            type: 'object',
            properties: {
                type: {
                    type: 'array',
                    title: 'Type',
                },
                code: {
                    type: 'string',
                    title: 'Code',
                },
                modifier: {
                    type: 'array',
                    title: 'Modifier',
                },
            },
        },
    };
}

export function GetAllCodesUISchema(): UiSchema {
    const emptyListMessage = {
        title: 'No Codes',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'allcodes',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
            itemsPerPage: 25,
        },
        items: {
            type: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            code: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            modifier: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
        },
    };
}
