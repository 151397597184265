import React from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { Text, Tooltip } from '@blueprintjs/core';

import formatDate from '../../../util/GeneralUtils';

const SimpleTextWidget = (props: WidgetProps) => {
    const { value, schema, options } = props;

    // The value may be a TooltipValue object. When it does contain a tooltip then we will create
    // a tooltip html element to add to the widget.
    let valueToUse = value;
    let tooltip;
    if (value) {
        if (Object.keys(value).includes('value')) {
            valueToUse = value.value;
        }
        if (Object.keys(value).includes('tooltip')) {
            // eslint-disable-next-line prefer-destructuring
            tooltip = value.tooltip;
        }
    }

    let displayValue;
    if (schema.type === 'string') {
        displayValue = valueToUse;
    } else if (schema.type === 'number') {
        if (valueToUse) {
            displayValue = valueToUse;
        } else {
            displayValue = 0;
        }
    } else if (schema.type === 'boolean') {
        if (valueToUse === true) {
            displayValue = 'Active';
        } else {
            displayValue = 'Inactive';
        }
    } else if (valueToUse && valueToUse.length > 0) {
        if (options.renderMode === 'date') {
            displayValue = formatDate(valueToUse);
        } else {
            displayValue = valueToUse;
        }
    } else if (options.value) {
        displayValue = options.value;
    } else {
        displayValue = 'N/A';
    }

    const className: any = options.className ? options.className : '';

    if (tooltip) {
        return (
            <Text className={className} style={options.style as any} ellipsize>
                <Tooltip content={tooltip}>{displayValue}</Tooltip>
            </Text>
        );
    }
    return (
        <Text className={className} style={options.style as any} ellipsize>
            {displayValue}
        </Text>
    );
};

export default SimpleTextWidget;
