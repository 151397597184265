import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { UiSchema } from 'react-jsonschema-form';
import { JSONSchema6 } from 'json-schema';
import { get, invert } from 'lodash';
import { JSONPath } from 'jsonpath-plus';

import { showToast } from '@amxjs/ui';
import { ExtensionUrls } from '@amxjs/fhir-commons';
import { Intent, Icon, Colors, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { getMeasureDenominator, getScoreOrDash } from '../../../../util/utils';
import { OptInOutput, OptInOutputData } from '../../../../server/routes/Mips/OptInInterface';
import {
    MipsMonitorPerformance as MipsMonitorPerformanceInterface,
    Page,
} from '../../../../generator/generated/MeshInterfaces';
import Store from '../../../core/Store';
import { swapDisplayValues, getRoutes } from '../../../util/GeneralUtils';
import { SchemaProvider } from '../../../mesh/Schema';
import { useBreadcrumbContext } from '../../../core/BreadcrumbContext';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../../util/DataDictionary';
import Form from '../../../components/forms/Form';
import FormHeader from '../../../components/forms/components/FormHeader';
import Api from '../../../core/Api';
import {
    columnIsPresentInConfiguration,
    getColumnOrder,
    getSortOrderForColumn,
} from '../../../util/ListUtils';
import {
    MipsPerformanceRecord,
    SubmissionErrorDialogInterface,
} from '../../../../server/routes/Mips/MipsApi';
import MipsModuleConstants from '../MipsModuleConstants';
import SubmissionErrorDialog from '../SubmissionErrorDialog';
import ConfirmMipsSubmissionDialog from './ConfirmMipsSubmissionDialog';
import OptInConfirmationDialog from './OptInConfirmationDialog';
import OptInElectionDialog from './OptInElectionDialog';

interface SubmissionErrorMessageDialogInterface extends SubmissionErrorDialogInterface {
    messages?: string[];
}
interface MipsMonitorPerformanceProps extends MipsMonitorPerformanceInterface {
    /* eslint-disable-next-line react/no-unused-prop-types */
    store: Store;
}

const getIcon = (color = Colors.GREEN3, icon: any, text = '') => {
    return <Icon htmlTitle={text} color={color} icon={icon} style={{ paddingRight: '5px' }} />;
};

function MonitorPerformance(
    props: MipsMonitorPerformanceProps & RouteComponentProps & InjectedDataDictionaryProps
) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [formHasChange, setFormHasChange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [savingRecord, setSavingRecord] = useState(false);
    const [confirmSubmissionDialogOpen, setConfirmSubmissionDialogOpen] = useState(false);
    const [optInConfirmationDialogOpen, setOptInConfirmationDialogOpen] = useState(false);
    const [optInConfirmationData, setOptInConfirmationData] = useState([]);
    const [optInElectionDialogOpen, setOptInElectionDialogOpen] = useState(false);
    const [optInElectionData, setOptInElectionData] = useState<MipsPerformanceRecord['Clinicians']>(
        []
    );
    const [submissionErrorDialogOpen, setSubmissionErrorDialogOpen] = useState(false);
    const [tin, setTin] = useState('');
    const [participationValueSet, setParticipationValueSet] = useState({} as any);
    const [formData, setFormData] = useState<MipsPerformanceRecord>({
        id: '',
        name: '',
        tin: '',
        valueSet: {},
        Clinicians: [],
        submitStatus: { enabled: false },
    });
    const [selectAllForSubmission, setSelectAllForSubmission] = useState<HTMLElement | null>();
    const [selectAllElements, setSelectAllElements] = useState<Record<string, any>>({
        selectedForSubmission: selectAllForSubmission,
    });
    const [selectAllValues, setSelectAllValues] = useState<Record<string, any>>({
        selectedForSubmission: selectAllForSubmission,
    });
    const [descriptionElement, setDescriptionElement] = useState<Element | null>();
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const [isConfirmSubmitDisabled, setIsConfirmSubmitDisabled] = useState<boolean>(false);
    const [optInConfirmationNumofGroups, setOptInConfirmationNumofGroups] = useState<number>(0);
    const [optInConfirmationNumofIndividuals, setOptInConfirmationNumofIndividuals] =
        useState<number>(0);

    const { labels } = props;

    const optInConfirmationDescription =
        labels.optInConfirmDescription || MipsModuleConstants.OptInConfirmDescription;
    const optInElectionDescription =
        labels.optInElectionDescription || MipsModuleConstants.OptInElectionDescription;

    const calculateDescriptionHeight = useCallback(() => {
        if (descriptionElement && descriptionHeight !== descriptionElement.clientHeight) {
            setDescriptionHeight(descriptionElement.clientHeight + 30);
        }
    }, [descriptionElement, descriptionHeight]);

    const searchPlaceholderText = 'Search by Participant Type, Clinician or NPI';
    const [submissionErrors, setSubmissionErrors] = useState<
        SubmissionErrorMessageDialogInterface[]
    >([]);

    const showErrorDialog = (errorsDialog: SubmissionErrorMessageDialogInterface) => {
        setSubmissionErrors([errorsDialog]);
        setSubmissionErrorDialogOpen(true);
    };

    const getSubmissionStatusIcon = useCallback((row: any) => {
        return row.submissionSuccess ? (
            <div>
                {row.hasBeenSubmitted ? getIcon(Colors.GREEN3, IconNames.TICK_CIRCLE) : null}
                <Tooltip
                    content={
                        row.submissionTime &&
                        (row.hasBeenSubmitted
                            ? `Submitted at ${new Date(row.submissionTime)}`
                            : `Prescore Successful at ${new Date(row.submissionTime)}`)
                    }
                >
                    {row.hasBeenSubmitted ? 'Successful' : '-'}
                </Tooltip>
            </div>
        ) : (
            <div
                style={{ color: '#137CBD' }}
                onClick={() => showErrorDialog(row.errorsDialog)}
                aria-hidden="true"
            >
                {getIcon(Colors.RED3, IconNames.ERROR)}
                <Tooltip
                    content={
                        row.submissionTime &&
                        (row.hasBeenSubmitted
                            ? `Submitted at ${new Date(row.submissionTime)}`
                            : `Prescore Failed at ${new Date(row.submissionTime)}`)
                    }
                >
                    {row.hasBeenSubmitted ? 'Failed' : 'Prescore Failed'}
                </Tooltip>
            </div>
        );
    }, []);

    const setPerformanceRecordDetails = useCallback(async () => {
        const { id, year } = queryString.parse(props.location.search);
        const breadcrumbTrail: Record<string, any[]> = getRoutes(
            props.ParentPageLink?.breadcrumbTrail,
            year
        );
        if (id) {
            const onViewClick = (_e: any, _rowData: MipsPerformanceRecord) => {
                const { route } = props.ViewPageLink as Page;
                if (route) {
                    let routePath = route;
                    if (_rowData.id) {
                        routePath += `?id=${encodeURIComponent(_rowData.id)}&type=${
                            _e.participationOption
                        }${
                            _e.participationOption === 'Individual' && _e.fhirRecordId
                                ? `&providerPractitionerFhirId=${
                                      _e.participationOption === 'Individual' && _e.fhirRecordId
                                          ? _e.fhirRecordId
                                          : ''
                                  }`
                                : ''
                        }&year=${year}`;
                    }
                    props.history.push(routePath);
                }
            };

            try {
                const response = await Api.mips.getMipsPerformanceRecordDetails(
                    id as string,
                    year || MipsModuleConstants.SubmissionYear,
                    props.store.email
                );

                const rawData = response.data as MipsPerformanceRecord;
                if (rawData.valueSet) {
                    const participationOptionValueSet = JSONPath({
                        path: `$.compose.include[0].concept`,
                        json: rawData.valueSet,
                    })[0];

                    const valueSet: any = participationOptionValueSet.reduce(
                        (obj: any, set: any) => ({
                            ...obj,
                            [set.code]: set.display,
                        }),
                        {}
                    );
                    setParticipationValueSet(valueSet);

                    rawData.Clinicians = swapDisplayValues(
                        rawData.Clinicians,
                        'participationOption',
                        valueSet
                    );
                }
                rawData.Clinicians = rawData.Clinicians?.map((row) => {
                    const qualityMeasureCategoryScoreDenominator = getScoreOrDash(
                        getMeasureDenominator(
                            row.qppMeasureReports,
                            ExtensionUrls.QUALITY_MEASURE_CATEGORY,
                            year
                        )
                    );
                    return {
                        ...row,
                        submission: !row.cmsInteraction ? '-' : getSubmissionStatusIcon(row),
                        view: (
                            <button
                                type="button"
                                className="bp3-button bp3-intent-primary bp3-minimal"
                                onClick={() => onViewClick(row, rawData)}
                            >
                                View
                            </button>
                        ),
                        qualityMeasures: `${row.qualityMeasures}/${qualityMeasureCategoryScoreDenominator}`,
                        improvementActivities: `${row.improvementActivities}/40`,
                        promotingInteroperability: `${row.promotingInteroperability}/100`,
                    };
                });

                setTin(rawData.tin);

                breadcrumbTrail.labels.push(rawData.tin);

                setBreadcrumbData({
                    crumbLabels: breadcrumbTrail.labels,
                    crumbRoutes: breadcrumbTrail.routes,
                });

                setFormData(Object.assign({}, rawData));
                setLoading(false);
            } catch (error) {
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Error Occurred!</span>
                            <br />
                            <br />
                            <span>
                                Oops! Looks like something went wrong and participants could not be
                                loaded. Please try again later.
                            </span>
                        </p>
                    ),
                    intent: Intent.DANGER,
                    icon: 'error',
                });
                setLoading(false);
            }
        }
    }, [getSubmissionStatusIcon, props, setBreadcrumbData]);

    useEffect(() => {
        setPerformanceRecordDetails();
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    useEffect(() => {
        setDescriptionElement(document.querySelector('#monitor-performance-description'));
    }, []);

    useEffect(() => {
        if (descriptionElement) {
            calculateDescriptionHeight();
            window.onresize = calculateDescriptionHeight;
        }
    }, [descriptionElement, calculateDescriptionHeight]);

    useEffect(() => {
        setSelectAllForSubmission(document.getElementById('check-all-selectedForSubmission'));
    }, []);

    const handleConfirmSubmissionDialogClose = () => {
        setConfirmSubmissionDialogOpen(false);
    };
    const handleOptInConfirmationDialogClose = () => {
        setOptInConfirmationDialogOpen(false);
        setPerformanceRecordDetails();
        setSavingRecord(false);
    };
    const handleOptInElectionDialogClose = () => {
        setOptInElectionDialogOpen(false);
    };

    const submitData = async () => {
        setSavingRecord(true);
        const { year } = queryString.parse(props.location.search);
        const newFormData: MipsPerformanceRecord = {
            ...formData,
            Clinicians: swapDisplayValues(
                formData.Clinicians,
                'participationOption',
                invert(participationValueSet)
            ),
        };
        try {
            const response = await Api.mips.submitMips(
                newFormData,
                year || MipsModuleConstants.SubmissionYear
            );
            setIsConfirmSubmitDisabled(false);
            if (response.ok && response.data.success) {
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Success</span>
                            <br />
                            <br />
                            <span>Submitted successfully!</span>
                        </p>
                    ),
                    intent: Intent.SUCCESS,
                    icon: 'tick-circle',
                });
                setFormHasChange(false);
            } else {
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Error Occurred!</span>
                            <br />
                            <br />
                            <span>
                                Oops! Looks like something went wrong and your changes could not be
                                saved. Please try again later.
                            </span>
                        </p>
                    ),
                    intent: Intent.DANGER,
                    icon: 'error',
                });
            }
            setPerformanceRecordDetails();
        } catch (error) {
            console.error(error);
        } finally {
            setSavingRecord(false);
            setIsConfirmSubmitDisabled(false);
        }
    };

    const submitOptInElectionData = () => {
        const { year } = queryString.parse(props.location.search);
        const { id } = formData;
        const providerPractitionerReferences = optInElectionData
            .filter((record) => !!record.npi)
            .map((row) => `Practitioner/${row.fhirRecordId}`);
        const numOfGroups = formData.Clinicians.filter(
            (row) =>
                row.selectedForSubmission &&
                row.optInElectionStatus === 'eligible' &&
                row.optInElection &&
                row.participationOption === 'Group'
        ).length;
        const numOfIndividuals = formData.Clinicians.filter(
            (row) =>
                row.selectedForSubmission &&
                row.optInElectionStatus === 'eligible' &&
                row.optInElection &&
                row.participationOption === 'Individual'
        ).length;

        const optinSubmitData = {
            organizationReference: `Organization/${id}`,
            reportingYear: Number(year || MipsModuleConstants.SubmissionYear),
            submitOrganization: false,
            providerPractitionerReferences: [] as string[],
        };
        const confirmationData: any = [];
        if (numOfGroups > 0) {
            optinSubmitData.submitOrganization = true;
        }
        if (numOfIndividuals > 0) {
            optinSubmitData.providerPractitionerReferences = providerPractitionerReferences.length
                ? providerPractitionerReferences
                : [];
        }

        Api.mips
            .submitOptInElection(optinSubmitData)
            .then((response) => {
                if (response.ok) {
                    const rawData = response.data as OptInOutput;
                    if (rawData.results) {
                        rawData.results.forEach((outputData: OptInOutputData) => {
                            if (
                                outputData.organizationReference &&
                                !outputData.providerPractitionerReference
                            ) {
                                // If the only error is that an opt-in decision was already made just mark
                                // the record as having been submitted already. MIPS-296
                                if (
                                    outputData.errors &&
                                    outputData.errors.length === 1 &&
                                    outputData.errors.includes('Opt-in decision already made')
                                ) {
                                    setOptInConfirmationNumofGroups(
                                        optInConfirmationNumofGroups + 1
                                    );
                                } else {
                                    confirmationData.push({
                                        participationOption: 'Group',
                                        clinician: '',
                                        errors: outputData.errors,
                                    });
                                    if (outputData.success) {
                                        setOptInConfirmationNumofGroups(
                                            optInConfirmationNumofGroups + 1
                                        );
                                    }
                                }
                                // If the only error is that an opt-in decision was already made just mark
                                // the record as having been submitted already. MIPS-296
                            } else if (
                                outputData.errors &&
                                outputData.errors.length === 1 &&
                                outputData.errors.includes('Opt-in decision already made')
                            ) {
                                setOptInConfirmationNumofIndividuals(
                                    optInConfirmationNumofIndividuals + 1
                                );
                            } else {
                                confirmationData.push({
                                    participationOption: 'Individual',
                                    clinician: optInElectionData.filter(
                                        (optData) =>
                                            optData.participationOption === 'Individual' &&
                                            `Practitioner/${optData.fhirRecordId}` ===
                                                outputData.providerPractitionerReference
                                    )[0].clinician,
                                    errors: outputData.errors,
                                });
                                if (outputData.success) {
                                    setOptInConfirmationNumofIndividuals(
                                        optInConfirmationNumofIndividuals + 1
                                    );
                                }
                            }
                        });
                    }
                }
                setOptInConfirmationData(confirmationData);
                setOptInConfirmationDialogOpen(true);
            })
            .catch((error) => {
                console.log(error);
                setOptInConfirmationDialogOpen(true);
            });
    };

    const handleConfirmSubmissionDialogOkay = async () => {
        setIsConfirmSubmitDisabled(true);
        await submitData();
        setConfirmSubmissionDialogOpen(false);
    };
    const handleOptInElectionDialogOkay = () => {
        // make API call to optIn Election
        submitOptInElectionData();
        setOptInElectionDialogOpen(false);
    };
    const handleOptInConfirmationDialogOkay = () => {
        setOptInConfirmationDialogOpen(false);
        setConfirmSubmissionDialogOpen(true);
    };

    const handleSubmissionErrorDialogOkay = () => {
        setSubmissionErrorDialogOpen(false);
    };

    const handleSubmissionErrorDialogClose = () => {
        setSubmissionErrorDialogOpen(false);
    };

    const buildListDescription = () => {
        const { year } = queryString.parse(props.location.search);
        return (
            <div
                id="monitor-performance-description"
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {tin && (
                        <>
                            <span>{`TIN: ${tin}`}</span>
                            <div
                                style={{
                                    height: '12px',
                                    width: '1px',
                                    margin: '0px 10px',
                                    backgroundColor: '#c9d0d8',
                                }}
                            />
                        </>
                    )}
                    {formData.name && (
                        <>
                            <span>{formData.name}</span>
                            <div
                                style={{
                                    height: '12px',
                                    width: '1px',
                                    margin: '0px 10px',
                                    backgroundColor: '#c9d0d8',
                                }}
                            />
                        </>
                    )}
                    <span>{year || MipsModuleConstants.SubmissionYear}</span>
                </div>
                <span style={{ marginTop: '18px' }}>
                    {MipsModuleConstants.PerformanceDescription}
                </span>
            </div>
        );
    };

    const onChange = (e: any) => {
        // When there is a change on the page check to see if the change caused 0 or more rows to be marked
        // for submission. Only in a case where a row is checked for submission will be want to enabled
        // the submission button. Otherwise we will disable the button.
        if (!e.formData || !e.formData.Clinicians) {
            setFormHasChange(false);
        } else {
            let atLeastOneClinicianMarkedForSubmission = false;
            e.formData.Clinicians.forEach((clinician: any) => {
                if (clinician && clinician.selectedForSubmission) {
                    atLeastOneClinicianMarkedForSubmission = true;
                }
            });
            setFormHasChange(atLeastOneClinicianMarkedForSubmission);
        }

        setFormData(Object.assign({}, e.formData));
    };

    const onColumnSelectAll = (key: string, value: boolean, filteredRows: any[]) => {
        const npis: (string | null)[] = filteredRows
            .map((filteredRow) => {
                const [npi] = JSONPath({
                    path: 'npi.props.children.props.value',
                    json: filteredRow,
                });
                return [...(npi ?? []), ...(filteredRow.npi === '-' ? [null] : [])];
            })
            .flat();
        const listDataClinicians = formData.Clinicians.map((row) => {
            // TODO: fix this in a different ticket. It wasn't working before. Just refactoring here.
            // return { ...row, ...(npis.includes(row.npi) ? { [key]: value } : null) };
            return { ...row, ...(npis.includes(row.npi) ? { [key]: value } : { [key]: value }) };
        });
        const atLeastOneClinicianMarkedForSubmission = listDataClinicians.some(
            (clinician) => clinician?.selectedForSubmission
        );
        setFormHasChange(atLeastOneClinicianMarkedForSubmission);
        setFormData({ ...formData, Clinicians: listDataClinicians });
        setSelectAllValues({
            ...selectAllValues,
            key: value,
        });
    };

    const calculateSelectAllValues = (rows: any[]) => {
        const controlledColumns: Record<string, string[]> = {
            selectedForSubmission: [],
        };

        if (rows) {
            rows.forEach((row: any) => {
                controlledColumns.selectedForSubmission.push(row.selectedForSubmission);
            });

            const values = Object.assign({}, selectAllValues);

            const columnId = 'selectedForSubmission';
            if (selectAllElements[columnId]) {
                const allRowsAreEqual = controlledColumns[columnId].every(
                    (val) => val === controlledColumns[columnId][0]
                );
                setSelectAllElements({
                    ...selectAllElements,
                    [columnId]: {
                        ...selectAllElements[columnId],
                        indeterminate: !allRowsAreEqual,
                    },
                });
                values[columnId] = allRowsAreEqual ? controlledColumns[columnId][0] : false;
            }
            setSelectAllValues(values);
        }
    };

    const onFilterStringChangeForInListFiltering = (renderedRows: any[]) => {
        const formattedFilteredRows = renderedRows.map((row: any) => {
            return {
                selectedForSubmission: get(
                    row,
                    'selectedForSubmission.props.value',
                    row.selectedForSubmission
                ),
            };
        });
        calculateSelectAllValues(formattedFilteredRows);
    };

    useEffect(() => {
        calculateSelectAllValues(formData.Clinicians);
    }, [formData.Clinicians]);

    const getUISchema = (): UiSchema => {
        return {
            Clinicians: {
                'ui:field': 'list',
                'ui:hash': 'clinicians',
                'ui:options': {
                    showSearchBar: true,
                    searchPlaceholderText,
                    showColumnControl: true,
                    selectAllValues,
                    onSelectAllRows: onColumnSelectAll,
                    stickyHeaderOffset: descriptionHeight,
                    allowExport: props.allowExport,
                    itemsPerPage: 200,
                    loading,
                    data: Object.assign({}, formData),
                    onChange: (e: any) => onChange({ formData: { ...formData, Clinicians: e } }),
                    onFilterStringChangeForInListFiltering,
                    columnOrder: getColumnOrder(props.columns),
                },
                items: {
                    selectedForSubmission: {
                        'ui:widget': 'CheckboxWidget',
                        'ui:options': {
                            orderable: false,
                            renderMode: 'checkbox',
                            columnControl: true,
                        },
                        showColumn: columnIsPresentInConfiguration(
                            props.columns,
                            'participationOption'
                        ),
                    },
                    participationOption: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            initialSort: getSortOrderForColumn(
                                props.columns,
                                'participationOption'
                            ),
                            renderMode: 'dash',
                            showColumn: columnIsPresentInConfiguration(
                                props.columns,
                                'participationOption'
                            ),
                        },
                    },
                    clinician: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            renderMode: 'dash',
                            initialSort: getSortOrderForColumn(props.columns, 'clinician'),
                            showColumn: columnIsPresentInConfiguration(props.columns, 'clinician'),
                        },
                    },
                    npi: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            renderMode: 'dash',
                            initialSort: getSortOrderForColumn(props.columns, 'npi'),
                            showColumn: columnIsPresentInConfiguration(props.columns, 'npi'),
                        },
                    },
                    qualityMeasures: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            renderMode: 'dash',
                            initialSort: getSortOrderForColumn(props.columns, 'qualityMeasures'),
                            showColumn: columnIsPresentInConfiguration(
                                props.columns,
                                'qualityMeasures'
                            ),
                        },
                    },
                    improvementActivities: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            renderMode: 'dash',
                            initialSort: getSortOrderForColumn(
                                props.columns,
                                'improvementActivities'
                            ),
                            showColumn: columnIsPresentInConfiguration(
                                props.columns,
                                'improvementActivities'
                            ),
                        },
                    },
                    promotingInteroperability: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            renderMode: 'dash',
                            initialSort: getSortOrderForColumn(
                                props.columns,
                                'promotingInteroperability'
                            ),
                            showColumn: columnIsPresentInConfiguration(
                                props.columns,
                                'promotingInteroperability'
                            ),
                        },
                    },
                    consent: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            renderMode: 'dash',
                            orderable: true,
                            initialSort: getSortOrderForColumn(props.columns, 'consent'),
                            showColumn: columnIsPresentInConfiguration(props.columns, 'consent'),
                        },
                    },
                    submission: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: true,
                            initialSort: getSortOrderForColumn(props.columns, 'submission'),
                            showColumn: columnIsPresentInConfiguration(props.columns, 'submission'),
                        },
                    },
                    view: {
                        'ui:widget': 'SimpleTextWidget',
                        'ui:options': {
                            orderable: false,
                        },
                    },
                },
            },
        };
    };

    const getSchema = (): JSONSchema6 => {
        const columnLabels: any = props.columns.reduce((obj: any, column: any) => {
            const o = obj;
            o[column.id] = column.label;
            return o;
        }, {});

        return {
            type: 'object',
            properties: {
                Clinicians: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            selectedForSubmission: {
                                type: 'boolean',
                                title: ' ',
                            },
                            participationOption: {
                                type: 'string',
                                title: columnLabels.participationOption || 'Participant Type',
                            },
                            clinician: {
                                type: 'string',
                                title: columnLabels.clinician || 'Clinician',
                            },
                            npi: {
                                type: 'string',
                                title: columnLabels.npi || 'NPI',
                            },
                            qualityMeasures: {
                                type: 'string',
                                title: columnLabels.qualityMeasures || 'Quality Measures',
                            },
                            improvementActivities: {
                                type: 'string',
                                title:
                                    columnLabels.improvementActivities || 'Improvement Activities',
                            },
                            promotingInteroperability: {
                                type: 'string',
                                title:
                                    columnLabels.promotingInteroperability ||
                                    'Promoting Interoperability',
                            },
                            consent: {
                                type: 'string',
                                title: columnLabels.consent || 'Consent',
                            },
                            submission: {
                                type: 'string',
                                title: columnLabels.submission || 'Submission',
                            },
                            view: {
                                type: 'string',
                                title: columnLabels.view || ' ',
                            },
                        },
                    },
                },
            },
        };
    };

    const onSubmit = () => {
        const isOptInElectionSelected = !!formData.Clinicians.filter(
            (row) =>
                row.selectedForSubmission &&
                row.optInElectionStatus === 'eligible' &&
                row.optInElection
        ).length;
        setOptInElectionData(
            formData.Clinicians.filter(
                (row) =>
                    row.selectedForSubmission &&
                    row.optInElectionStatus === 'eligible' &&
                    row.optInElection
            )
        );
        if (isOptInElectionSelected) {
            setOptInElectionDialogOpen(true);
        } else {
            setConfirmSubmissionDialogOpen(true);
        }
    };

    const onError = (e: any) => {
        console.log('on error: ', e);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmMipsSubmissionDialog
                isOpen={confirmSubmissionDialogOpen}
                handleClose={handleConfirmSubmissionDialogClose}
                handleOkay={handleConfirmSubmissionDialogOkay}
                isDisabled={isConfirmSubmitDisabled}
                numOfGroups={
                    formData.Clinicians.filter(
                        (row) => row.selectedForSubmission && row.participationOption === 'Group'
                    ).length
                }
                numOfIndividuals={
                    formData.Clinicians.filter(
                        (row) =>
                            row.selectedForSubmission && row.participationOption === 'Individual'
                    ).length
                }
            />
            <OptInElectionDialog
                isOpen={optInElectionDialogOpen}
                handleClose={handleOptInElectionDialogClose}
                handleOkay={handleOptInElectionDialogOkay}
                optinData={optInElectionData}
                numOfGroups={
                    formData.Clinicians.filter(
                        (row) =>
                            row.selectedForSubmission &&
                            row.optInElectionStatus === 'eligible' &&
                            row.optInElection &&
                            row.participationOption === 'Group'
                    ).length
                }
                numOfIndividuals={
                    formData.Clinicians.filter(
                        (row) =>
                            row.selectedForSubmission &&
                            row.optInElectionStatus === 'eligible' &&
                            row.optInElection &&
                            row.participationOption === 'Individual'
                    ).length
                }
                optInElectionDescription={optInElectionDescription}
            />
            <OptInConfirmationDialog
                isOpen={optInConfirmationDialogOpen}
                handleClose={handleOptInConfirmationDialogClose}
                handleOkay={handleOptInConfirmationDialogOkay}
                numOfGroups={optInConfirmationNumofGroups}
                numOfIndividuals={optInConfirmationNumofIndividuals}
                optInConfirmationData={optInConfirmationData}
                optInConfirmationDescription={optInConfirmationDescription}
            />
            <SubmissionErrorDialog
                isOpen={submissionErrorDialogOpen}
                handleClose={handleSubmissionErrorDialogClose}
                handleOkay={handleSubmissionErrorDialogOkay}
                errors={submissionErrors}
            />
            <Form
                recordId="monitor-performance"
                recordName={tin}
                sidebarElements={[]}
                hideSidebar
                primary
                schema={getSchema()}
                uiSchema={getUISchema()}
                validators={[]}
                onChange={(e: any) => {
                    onChange(e);
                }}
                formData={formData}
                onError={onError}
                onSubmit={onSubmit}
            >
                <FormHeader
                    title={props.title || 'Monitor Performance & Submit'}
                    primaryListStyle
                    editMode={false}
                    allowPrinting
                    exportButtonLabel="Export List"
                    allowCancel={formHasChange && !savingRecord}
                    allowSave
                    customButton={{
                        label: 'Submit Data',
                        intent: 'primary',
                        onClick: onSubmit,
                        disabled: !formHasChange || savingRecord || !formData.submitStatus?.enabled,
                        minimal: false,
                        ...(formData.submitStatus?.reason
                            ? { tooltip: formData.submitStatus.reason }
                            : null),
                    }}
                    description={buildListDescription()}
                />
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(withDataDictionary(MonitorPerformance));
