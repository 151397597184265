import React from 'react';

import { DateTimeField } from '@amxjs/ui';
import { runInAction, observable } from 'mobx';
import uuid from 'uuid';

import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';

@observer
class DateTimeWidget extends React.Component<WidgetProps> {
    @observable
    $value: any;

    id: any;

    enumOptions: any;

    constructor(props: any) {
        super(props);

        // const { options, multiple, value } = this.props;  // 2.0.0+
        const { options, value } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        // const emptyValue = multiple ? [] : '';  // 2.0.0+
        const emptyValue = '';

        this.$value = typeof value === 'undefined' ? emptyValue : value;

        this.id = uuid.v4();
    }

    /* eslint-disable class-methods-use-this */
    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: props.options,
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,

            schema: props.schema,

            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
        };
    }

    render() {
        const props = this.convertProps(this.props);

        return (
            <DateTimeField
                label={props.label || props.schema.title}
                showRequiredIndicator={props.required}
                disabled={props.disabled || props.readonly}
                readOnly={props.readonly}
                value={this.$value}
                showTimeInput="12HOUR"
                onChange={(v) => {
                    runInAction(() => {
                        this.$value = v;
                        props.onChange(v);
                    });
                }}
            />
        );
    }
}

export default DateTimeWidget;
