import React from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { Tag } from '@blueprintjs/core';

const TagWidget = (props: WidgetProps) => {
    return (
        <Tag style={{ marginRight: '5px', backgroundColor: '#bfccd6', color: '#182026' }}>
            {props.value}
        </Tag>
    );
};

export default TagWidget;
