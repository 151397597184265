import React from 'react';

// import { ArrayFieldTemplateProps, utils, IdSchema } from '@rjsf/core';
import { ArrayFieldTemplateProps, IdSchema } from 'react-jsonschema-form';
import { getDefaultRegistry, isMultiSelect } from 'react-jsonschema-form/lib/utils';

import { Row, Column } from '@amxjs/ui';
import AddButton from '../components/AddButton';
import IconButton from '../components/IconButton';
import TitleField from './TitleField';

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    // const { schema, registry = utils.getDefaultRegistry() } = props;
    const { schema, registry = getDefaultRegistry() } = props;

    // if (utils.isMultiSelect(schema, registry.definitions)) {
    if (isMultiSelect(schema, registry.definitions)) {
        return <DefaultFixedArrayFieldTemplate {...props} />;
    }
    return <DefaultNormalArrayFieldTemplate {...props} />;
};

interface ArrayFieldTitleProps {
    TitleField: any;
    idSchema: IdSchema;
    title: string;
    required: boolean;
}

class ArrayFieldTitle extends React.Component<ArrayFieldTitleProps> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { idSchema, title, required } = this.props;

        if (!title) {
            return <div />;
        }

        const id = `${idSchema.$id}__title`;
        return <TitleField id={id} title={title} required={required} />;
    }
}

interface ArrayFieldDescriptionProps {
    DescriptionField: any;
    idSchema: IdSchema;
    description: string;
}

class ArrayFieldDescription extends React.Component<ArrayFieldDescriptionProps> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { DescriptionField, idSchema, description } = this.props;

        if (!description) {
            return <div />;
        }

        const id = `${idSchema.$id}__description`;
        return <DescriptionField id={id} description={description} />;
    }
}

const DefaultArrayItem = (props: any) => {
    return (
        <Row key={props.index}>
            <Column md={10}>{props.children}</Column>

            {props.hasToolbar && (
                <Column
                    md={2}
                    style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '8px' }}
                >
                    {(props.hasMoveUp || props.hasMoveDown) && (
                        <IconButton
                            icon="arrow-up"
                            className="array-item-move-up"
                            disabled={props.disabled || props.readonly || !props.hasMoveUp}
                            onClick={props.onReorderClick(props.index, props.index - 1)}
                        />
                    )}

                    {(props.hasMoveUp || props.hasMoveDown) && (
                        <IconButton
                            icon="arrow-down"
                            disabled={props.disabled || props.readonly || !props.hasMoveDown}
                            onClick={props.onReorderClick(props.index, props.index + 1)}
                        />
                    )}

                    {props.hasRemove && (
                        <IconButton
                            icon="remove"
                            disabled={props.disabled || props.readonly}
                            onClick={props.onDropIndexClick(props.index)}
                        />
                    )}
                </Column>
            )}
        </Row>
    );
};

class DefaultFixedArrayFieldTemplate extends React.Component<ArrayFieldTemplateProps> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { props } = this;
        return (
            <fieldset className={props.className}>
                <ArrayFieldTitle
                    key={`array-field-title-${props.idSchema.$id}`}
                    TitleField={props.TitleField}
                    idSchema={props.idSchema}
                    title={props.uiSchema['ui:title'] || props.title}
                    required={props.required}
                />

                {(props.uiSchema['ui:description'] || props.schema.description) && (
                    <div
                        className="field-description"
                        key={`field-description-${props.idSchema.$id}`}
                    >
                        {props.uiSchema['ui:description'] || props.schema.description}
                    </div>
                )}

                <div className="row array-item-list" key={`array-item-list-${props.idSchema.$id}`}>
                    {props.items && props.items.map(DefaultArrayItem)}
                </div>

                {props.canAdd && (
                    <AddButton
                        className="array-item-add"
                        onClick={props.onAddClick}
                        disabled={props.disabled || props.readonly}
                        label={props.uiSchema.buttonLabel}
                    />
                )}
            </fieldset>
        );
    }
}

class DefaultNormalArrayFieldTemplate extends React.Component<ArrayFieldTemplateProps> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { props } = this;

        return (
            <div style={{ marginBottom: '18px' }}>
                <ArrayFieldTitle
                    key={`array-field-title-${props.idSchema.$id}`}
                    TitleField={props.TitleField}
                    idSchema={props.idSchema}
                    title={props.uiSchema['ui:title'] || props.title}
                    required={props.required}
                />
                {(props.uiSchema['ui:description'] || props.schema.description) && (
                    <ArrayFieldDescription
                        key={`array-field-description-${props.idSchema.$id}`}
                        DescriptionField={props.DescriptionField}
                        idSchema={props.idSchema}
                        description={props.uiSchema['ui:description'] || props.schema.description}
                    />
                )}
                <Row>
                    <Column key={`array-item-list-${props.idSchema.$id}`}>
                        {props.items && props.items.map((p) => DefaultArrayItem(p))}
                    </Column>
                </Row>
                {props.canAdd && (
                    <Row style={{ marginTop: '10px' }}>
                        <Column md={8} />
                        <Column md={4}>
                            <AddButton
                                className="array-item-add"
                                onClick={props.onAddClick}
                                disabled={props.disabled || props.readonly}
                                {...(props.uiSchema['ui:options'] && {
                                    label: props.uiSchema['ui:options'].addButtonLabel as string,
                                })}
                            />
                        </Column>
                    </Row>
                )}
            </div>
        );
    }
}

export default ArrayFieldTemplate;
