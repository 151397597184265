import { JSONSchema6 } from 'json-schema';
import { Intent } from '@blueprintjs/core';
import { UiSchema } from 'react-jsonschema-form';
import { TTableEmptyProps } from '@amxjs/ui';
import { RowAction } from '../../components/Tables/List';
import UserModuleConstants from './UserModuleConstants';

export const getAccessUISchema = (
    editMode: boolean,
    createMode: boolean,
    onAddAccessControlClick: (event: any) => void,
    showAccessControlCallout: boolean,
    rowActions: RowAction[],
    addPersonaButtonLabel: string | undefined,
    addAccessTitle: string | undefined
): UiSchema => {
    const showAccessControls = editMode || createMode;

    const noAccessControlCallout = {
        intent: Intent.DANGER,
        icon: null,
        title: 'Please add access control for this user',
    };

    const emptyMessage: TTableEmptyProps = {
        title: `No ${addAccessTitle || UserModuleConstants.AddAccessTitle}`,
        description: `Select '${
            addPersonaButtonLabel || UserModuleConstants.AddPersonaButtonLabel
        }' to get started`,
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'accesscontrol',
        'ui:options': {
            addButtonLabel: addPersonaButtonLabel || UserModuleConstants.AddPersonaButtonLabel,
            addAccessTitle: addAccessTitle || UserModuleConstants.AddAccessTitle,
            addable: showAccessControls,
            removable: showAccessControls,
            editable: showAccessControls,
            onAddItemClick: onAddAccessControlClick,
            callout: showAccessControlCallout ? noAccessControlCallout : null,
            fieldNameForDeleteDialog: 'persona',
            rowActions,
            emptyDataListMessage: emptyMessage,
        },
        items: {
            persona: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            dataAccess: {
                'ui:widget': 'tag',
            },
        },
    };
};

export const getAccessSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title,
        items: {
            type: 'object',
            properties: {
                persona: {
                    type: 'string',
                    title: 'Roles',
                    // enum: ['Registry Administrator', 'Practice Administrator', 'Patient', 'Clinician'],
                },
                dataAccess: {
                    type: 'array',
                    uniqueItems: true,
                    title: 'Data Access',
                    items: {
                        type: 'string',
                        // enum: ['Select All', 'Practice 1', 'Practice 2'],
                    },
                },
            },
        },
    };
};
