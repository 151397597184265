import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getQualityMeasuresStratumSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Strata Details',

        items: {
            type: 'object',
            properties: {
                strataName: {
                    type: 'object',
                    title: 'Strata Name',
                },
                dataCompletenessStrata: {
                    type: 'object',
                    title: 'Data Completeness',
                },
                performanceRateStrata: {
                    type: 'object',
                    title: 'Performance Rate',
                },
                eligiblePopulation: {
                    type: 'number',
                    title: 'Eligible Population',
                },
                performanceMet: {
                    type: 'number',
                    title: 'Performance Met',
                },
                performanceNotMet: {
                    type: 'number',
                    title: 'Performance Not Met',
                },
                eligiblePopulationException: {
                    type: 'number',
                    title: 'Eligible Population Exception',
                },
                eligiblePopulationExclusion: {
                    type: 'number',
                    title: 'Eligible Population Exclusion',
                },
            },
        },
    };
};

export const getQualityMeasuresStratumUISchema = (): UiSchema => {
    const emptyListMessage = {
        title: 'No Quality Measures',
        description: 'Add patient data to see Quality Measures',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'qualitymeasurecategoryscoresstratum',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items: {
            strataName: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            dataCompletenessStrata: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            performanceRateStrata: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            eligiblePopulation: {
                'ui:options': {
                    orderable: true,
                },
            },
            performanceMet: {
                'ui:options': {
                    orderable: true,
                },
            },
            performanceNotMet: {
                'ui:options': {
                    orderable: true,
                },
            },
            eligiblePopulationException: {
                'ui:options': {
                    orderable: true,
                },
            },
            eligiblePopulationExclusion: {
                'ui:options': {
                    orderable: true,
                },
            },
        },
    };
};
