/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */

import React from 'react';
// import { style } from 'typestyle';
import { Button, H4 } from '@blueprintjs/core';

// const titleWrapper = style({
//   marginLeft: '4px',
//   paddingLeft: '3px',
//   // marginBottom: '-10px',
// });

interface ListTitleFieldProps {
    id: string;
    title?: string;
    required: boolean;
    hash?: string;
    addable?: boolean;
    onAddItemClick?: (event: any) => void;
    addButtonLabel?: string;
}

const ListTitleField = (props: ListTitleFieldProps) => {
    return (
        <div className="form-section-header" style={{ marginBottom: '10px' }}>
            <H4 style={{ marginRight: 'auto' }}>
                {props.hash ? (
                    <span
                        id={props.hash}
                        style={{
                            position: 'absolute',
                            top: '-50px',
                        }}
                    />
                ) : null}
                {props.title}
            </H4>
            {props.addable ? (
                <Button icon="add" onClick={props.onAddItemClick} style={{ marginRight: '10px' }}>
                    {props.addButtonLabel ? props.addButtonLabel : `Add ${props.title}`}
                </Button>
            ) : null}
        </div>
    );
};

export default ListTitleField;
