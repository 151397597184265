import React, { FC } from 'react';
import { style } from 'typestyle';

export interface Props {
    value: string;
}

const className = style({
    $nest: {
        ul: {
            listStyle: 'unset',
            listStylePosition: 'inside',
            paddingLeft: '10px',
        },
    },
});

const HTMLRenderer: FC<Props> = (props: Props): JSX.Element => {
    // eslint-disable-next-line react/no-danger
    return <div className={className} dangerouslySetInnerHTML={{ __html: props.value }} />;
};

export default HTMLRenderer;
