import { Path } from 'history';

import { ReviewResult } from '../../../models/DataSpecification';

interface DataSpecificationWithActionLink {
    specType: string;
    releaseDate: string;
    status: string;
    description: string;
    actionLink: { text: string; url?: Path };
}

interface ReviewDataSpecifications {
    description?: string;
    specs?: DataSpecificationWithActionLink[];
}

export interface SFVData {
    reviewDataSpecifications?: ReviewDataSpecifications;
    assembleData?: { description?: string };
    uploadData?: {
        description?: string;
        specType?: { id: string; name: string };
        file?: { disableDropZone?: boolean; file?: File };
        progress?: {
            value?: number;
            description?: string;
            statusUploadDescription?: string;
            showRemovalButton?: boolean;
            showProgressBar?: boolean;
        };
    };
    reviewResults?: ReviewResult & { description?: string };
    nextSteps?: { description?: string };
}

export enum SectionTypesEnum {
    reviewDataSpecifications = 'reviewDataSpecifications',
    assembleData = 'assembleData',
    uploadData = 'uploadData',
    reviewResults = 'reviewResults',
    nextSteps = 'nextSteps',
}
export type SectionTypes = keyof typeof SectionTypesEnum;
