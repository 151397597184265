import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { style } from 'typestyle';

import { IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Colors } from '@blueprintjs/colors';
import { TTableEmptyProps } from '@amxjs/ui';
import { TClientResult } from '@amxjs/api/lib/client';

import {
    Page,
    sfvAssembleData,
    sfvNextSteps,
    sfvReviewDataSpecifications,
    sfvReviewResults,
    sfvUploadData,
    StandardFileValidator as StandardFileValidatorInterface,
} from '../../../generator/generated/MeshInterfaces';
import {
    DataSpecification,
    reviewResultError,
    ReviewResult,
    Status,
    StatusUploadDescription,
    ValidationStatus,
    DialogMode,
} from '../../../models/DataSpecification';
import { getCurrentTimeFromBrowser, getUriFromFile } from '../../../util/utils';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { SchemaProvider } from '../../mesh/Schema';
import FormHeader from '../../components/forms/components/FormHeader';
import HTMLRenderer from '../../components/forms/components/HTMLRenderer';
import Form from '../../components/forms/Form';

import Api from '../../core/Api';
import { downloadFile } from '../../components/utils/FileUtils';

import {
    getSFVAssembleDataSchema,
    getSFVAssembleDataUISchema,
    getSFVNextStepsSchema,
    getSFVNextStepsUISchema,
    getSFVReviewDataSpecificationsSchema,
    getSFVReviewDataSpecificationsUISchema,
    getSFVReviewResultsSchema,
    getSFVReviewResultsUISchema,
    getSFVUploadDataSchema,
    getSFVUploadDataUISchema,
} from './schemas';
import { SectionTypes, SectionTypesEnum, SFVData } from './types';

import ResetFormDialog from './ResetFormDialog';

const PROGRESS_BAR_UPDATE_INTERVAL = 10000;

const headingClassName = style({
    paddingBottom: '30px',
});

const reviewDataSpecActionLinkClassName = style({
    textAlign: 'end',
});

const getSpecs = async (): Promise<DataSpecification[]> => {
    try {
        const result = await Api.dataIngestion.getDataSpecifications();
        if (result.ok) {
            return result.data;
        }
        return [];
    } catch (err) {
        throw err;
    }
};

const getReviewResult = async ({
    specType,
    file,
    fileName,
    uploadTime,
}: {
    specType: { id: string; name: string };
    file: string;
    fileName: string;
    uploadTime: string;
}): Promise<TClientResult<ReviewResult>> => {
    return Api.dataIngestion.getReviewResult({
        specType,
        fileUri: file,
        fileName,
        uploadTime,
    });
};

interface IconAndText {
    iconName?: IconName;
    iconColor?: string;
    tooltipText?: string;
    displayText?: string;
}

const determineIconAndTextForValidationStatus = (errorDetail: string): IconAndText => {
    return {
        ...(errorDetail === ValidationStatus.NO_ERROR
            ? {
                  iconName: IconNames.TICK_CIRCLE,
                  iconColor: '#0F9960',
              }
            : {
                  iconName: IconNames.ERROR,
                  iconColor: '#A82A2A',
              }),
        displayText: errorDetail,
    };
};

const StandardFileValidator: FC<StandardFileValidatorInterface & RouteComponentProps> = (
    props: StandardFileValidatorInterface & RouteComponentProps
) => {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [formData, setFormData] = useState<SFVData>({});
    const [originalFormData, setOriginalFormData] = useState<SFVData>({});
    const [schema, setSchema] = useState<JSONSchema6>({});
    const [uiSchema, setUISchema] = useState<UiSchema>({});
    const [resetFormDialog, setResetFormDialog] = useState({
        open: false,
        mode: DialogMode.RESET_DATA,
    });
    const [fakingProgressActive, setFakingProgressActive] = useState(false);
    const requestAborted = useRef(false);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }
        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [props.title, props.ParentPageLink, setBreadcrumbData]);

    const getSchemaGenerator = (sectionType: SectionTypes): ((data: any) => JSONSchema6) => {
        const generators = {
            reviewDataSpecifications: getSFVReviewDataSpecificationsSchema,
            assembleData: getSFVAssembleDataSchema,
            uploadData: getSFVUploadDataSchema,
            reviewResults: getSFVReviewResultsSchema,
            nextSteps: getSFVNextStepsSchema,
        };
        return generators[sectionType] || null;
    };

    const getUISchemaGenerator = (sectionType: SectionTypes): ((formSection: any) => UiSchema) => {
        const generators: {
            reviewDataSpecifications: (
                data: sfvReviewDataSpecifications & {
                    actionLinkClassName: string;
                    emptyDataListMessage: TTableEmptyProps;
                }
            ) => UiSchema;
            assembleData: () => UiSchema;
            uploadData: (
                data: sfvUploadData & {
                    actionRemovalButtonCallback: any;
                }
            ) => UiSchema;
            reviewResults: (
                data: sfvReviewResults & {
                    determineIconAndTextForValidationStatus?: (errorDetail: string) => IconAndText;
                    exportDataToCsv?: any;
                    disabledButton: boolean;
                }
            ) => UiSchema;
            nextSteps: () => UiSchema;
        } = {
            reviewDataSpecifications: getSFVReviewDataSpecificationsUISchema,
            assembleData: getSFVAssembleDataUISchema,
            uploadData: getSFVUploadDataUISchema,
            reviewResults: getSFVReviewResultsUISchema,
            nextSteps: getSFVNextStepsUISchema,
        };
        return generators[sectionType] || null;
    };

    const exportDataToCsv = useCallback(
        (reviewResult?: ReviewResult) => {
            if (reviewResult) {
                let columnsToDisplay = '';
                const fileName = `${
                    reviewResult.fileUploaded
                        ? reviewResult.fileUploaded.split('.').slice(0, -1).join('.')
                        : ''
                }_${getCurrentTimeFromBrowser()}_${reviewResult.specType}.csv`;

                const rowsToDisplay =
                    reviewResult.errorDetails && reviewResult.errorDetails.length > 0
                        ? reviewResult.errorDetails
                              .map((errorDetail) => {
                                  // work around, using replaceAll meanwhile the new map to pyxfrm is not ready.
                                  return Object.values({
                                      a: errorDetail.fileName,
                                      b: errorDetail.rowNumber,
                                      c: errorDetail.columnName,
                                      d: errorDetail.errorDescription,
                                      e: errorDetail.errorResolution,
                                  }).map((value) => {
                                      if (value) {
                                          const strValue = value.toString();
                                          if (strValue.includes('"')) {
                                              const aux = [...strValue]
                                                  .map((e) => (e === '"' ? '""' : e))
                                                  .join('')
                                                  .replaceAll(/[\n]/g, '');
                                              return `"${aux}"`;
                                          }
                                          return `"${value}"`.replaceAll(/[\n]/g, '');
                                      }
                                      return '-';
                                  });
                              })
                              .join('\n')
                        : '';
                props.sections.forEach((section) => {
                    if (
                        [SectionTypesEnum.reviewResults].includes(
                            SectionTypesEnum[section.sectionType]
                        )
                    ) {
                        const schemaGenerator = getSchemaGenerator(section.sectionType);
                        const reviewResultSchema = schemaGenerator(section);
                        if (reviewResultSchema.properties) {
                            // Typing errorDetails list to fix the error from ts
                            const errorDetailsUiSchema: any =
                                reviewResultSchema.properties?.errorDetails;
                            columnsToDisplay = Object.values(errorDetailsUiSchema.items.properties)
                                .map((value: any) => {
                                    return value.title;
                                })
                                .join(',');
                        }
                    }
                });

                const content = `${columnsToDisplay}\n${rowsToDisplay}`;

                downloadFile(content, fileName, 'text/csv');
            }
        },
        [props.sections]
    );

    const actionRemovalButtonCallback = () => {
        requestAborted.current = true;
        setFormData((current) => ({
            ...current,
            uploadData: {
                ...current.uploadData,
                progress: {
                    ...current.uploadData?.progress,
                    showProgressBar: false,
                },
                file: undefined,
            },
        }));
    };

    useEffect(() => {
        const init = async () => {
            const auxUISchema: Record<string, UiSchema> = {};
            const auxSchema: Record<string, JSONSchema6> = {};
            const auxData: SFVData = {};

            const emptyMessage: TTableEmptyProps = {
                title: 'Error Loading Files',
                description: 'Please try again later',
                iconName: IconNames.ERROR,
            };
            const specs: DataSpecification[] = [];
            try {
                specs.push(...(await getSpecs()));
            } catch (err) {
                console.error(err);
            }
            props.sections.forEach((section) => {
                const { sectionType, description } = section;
                const schemaGenerator = getSchemaGenerator(sectionType);
                const uiSchemaGenerator = getUISchemaGenerator(sectionType);
                auxData[sectionType] = {
                    description: description as string,
                };
                switch (sectionType) {
                    case SectionTypesEnum.reviewDataSpecifications:
                        auxUISchema[sectionType] = uiSchemaGenerator({
                            ...section,
                            emptyDataListMessage: emptyMessage,
                            actionLinkClassName: reviewDataSpecActionLinkClassName,
                        });
                        auxData[sectionType] = {
                            ...auxData[sectionType],
                            specs: specs.map((spec) => ({
                                ...spec,
                                className: style({
                                    color:
                                        spec.status === Status.DEPRECATED
                                            ? Colors.GRAY1
                                            : Colors.BLACK,
                                }),
                                actionLink: {
                                    text: 'Open',
                                    url: spec.dataSpecPDFLink,
                                },
                            })),
                        };
                        break;
                    case SectionTypesEnum.uploadData: {
                        auxUISchema[sectionType] = uiSchemaGenerator({
                            ...section,
                            actionRemovalButtonCallback,
                        });
                        auxSchema[sectionType] = schemaGenerator({
                            ...section,
                            specTypes: specs.map((spec) => ({
                                id: spec.url,
                                name: `${spec.specType} - ${spec.releaseDate}`,
                            })),
                        });
                        auxData[sectionType] = {
                            ...auxData[sectionType],
                            file: {
                                disableDropZone: true,
                            },
                        };
                        break;
                    }
                    case SectionTypesEnum.reviewResults:
                        auxUISchema[sectionType] = uiSchemaGenerator({
                            ...section,
                            determineIconAndTextForValidationStatus,
                            exportDataToCsv,
                            disabledButton: false,
                        });
                        auxSchema[sectionType] = schemaGenerator({
                            ...section,
                            hide: true,
                        });
                        break;
                    case SectionTypesEnum.nextSteps:
                        auxSchema[sectionType] = schemaGenerator({
                            ...section,
                            hide: true,
                        });
                        break;
                    default:
                        break;
                }
                if (!(sectionType in auxUISchema)) {
                    auxUISchema[sectionType] = uiSchemaGenerator(section);
                }
                if (!(sectionType in auxSchema)) {
                    auxSchema[sectionType] = schemaGenerator(section);
                }
            });
            setUISchema(auxUISchema);
            setSchema({
                type: 'object',
                properties: auxSchema,
            });
            setFormData(auxData);
            setOriginalFormData(auxData);
        };
        init();
        return () => {
            setUISchema({});
            setSchema({});
            setFormData({});
            setOriginalFormData({});
        };
    }, [props.sections, exportDataToCsv]);

    const toggleSection = async (
        section:
            | sfvReviewDataSpecifications
            | sfvAssembleData
            | sfvUploadData
            | sfvReviewResults
            | sfvNextSteps,
        hide: boolean,
        disabledButton?: boolean
    ) => {
        const schemaGenerator = getSchemaGenerator(section.sectionType);
        setSchema((prevSchema) => ({
            ...prevSchema,
            properties: {
                ...prevSchema.properties,
                [section.sectionType]: schemaGenerator({
                    ...section,
                    hide,
                }),
            },
        }));
        if ([SectionTypesEnum.reviewResults].includes(SectionTypesEnum[section.sectionType])) {
            const uiSchemaGenerator = getUISchemaGenerator(section.sectionType);
            setUISchema({
                ...uiSchema,
                [section.sectionType]: uiSchemaGenerator({
                    ...section,
                    exportDataToCsv,
                    disabledButton,
                }),
            });
        }
    };

    const hideReviewAndNextSteps = (hide: boolean, disabledButton?: boolean) => {
        props.sections.forEach((section) => {
            if (
                [SectionTypesEnum.reviewResults, SectionTypesEnum.nextSteps].includes(
                    SectionTypesEnum[section.sectionType]
                )
            ) {
                toggleSection(section, hide, disabledButton);
            }
        });
    };

    useEffect(() => {
        const increaseProgress = () => {
            setFormData((current) => ({
                ...current,
                uploadData: {
                    ...current.uploadData,
                    progress: {
                        ...current.uploadData?.progress,
                        value:
                            current.uploadData?.progress?.value !== undefined &&
                            current.uploadData.progress.value >= 0 &&
                            current.uploadData.progress.value < 0.9
                                ? parseFloat((current.uploadData.progress.value + 0.1).toFixed(1))
                                : undefined,
                    },
                },
            }));
        };
        let intervalID: NodeJS.Timer | undefined;
        if (fakingProgressActive) {
            // Increasing progress value 10% initially and every 10 seconds.
            increaseProgress();
            intervalID = setInterval(() => {
                increaseProgress();
                if (
                    formData.uploadData?.progress?.value &&
                    formData.uploadData.progress.value >= 0.9
                ) {
                    setFakingProgressActive(false);
                }
            }, PROGRESS_BAR_UPDATE_INTERVAL);
        } else {
            clearInterval(intervalID);
        }
        return () => clearInterval(intervalID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fakingProgressActive]);

    const uploadFileAndRetrieveReviewResults = async (fileName: string, fileAsParam?: File) => {
        const file = fileAsParam || formData.uploadData?.file?.file;

        // Return if there is no file or specType selected
        if (!(formData.uploadData?.specType && file)) {
            return;
        }
        const fileUri = await getUriFromFile(file);
        setFakingProgressActive(true);
        const result = await getReviewResult({
            specType: formData.uploadData.specType,
            file: fileUri,
            fileName,
            uploadTime: getCurrentTimeFromBrowser(),
        });
        setFakingProgressActive(false);
        // `requestAborted` check status if user clicked removal button
        // if true means user didn't click removal button then we should show review results and next steps
        // otherwise we keep it hidden.
        if (requestAborted.current) {
            requestAborted.current = false;
            return;
        }
        if (result.ok) {
            const progress: { value: number; statusUploadDescription: string } =
                result.data.fileStatus === StatusUploadDescription.UPLOADED
                    ? {
                          value: 1,
                          statusUploadDescription: StatusUploadDescription.UPLOADED,
                      }
                    : {
                          value: 0,
                          statusUploadDescription: StatusUploadDescription.UPLOAD_FAILED,
                      };
            setFormData({
                ...formData,
                uploadData: {
                    ...formData.uploadData,
                    file: {
                        file,
                    },
                    progress: {
                        ...progress,
                        showRemovalButton: false,
                    },
                },
                reviewResults: {
                    ...formData.reviewResults,
                    ...result.data,
                },
            });
            hideReviewAndNextSteps(false, false);
        } else {
            setFormData({
                ...formData,
                uploadData: {
                    ...formData.uploadData,
                    file: {
                        ...formData.uploadData.file,
                        file,
                    },
                    progress: {
                        ...formData.uploadData.progress,
                        value: 0,
                        statusUploadDescription: StatusUploadDescription.UPLOAD_FAILED,
                        showRemovalButton: false,
                    },
                },
                reviewResults: {
                    ...formData.reviewResults,
                    ...reviewResultError,
                },
            });
            hideReviewAndNextSteps(false, true);
        }
    };

    const onChange = async (e: any) => {
        if (e.formData.uploadData) {
            // if there's an specType selected
            if (e.formData.uploadData.specType?.id) {
                // and it's different from the one already set
                if (e.formData.uploadData.specType.id !== formData.uploadData?.specType?.id) {
                    // save current formData state
                    setOriginalFormData(formData);
                    // save that new specType and prompt the user
                    // change disableDropZone to false to let the user drag files.
                    setFormData({
                        ...formData,
                        uploadData: {
                            ...formData.uploadData,
                            specType: e.formData.uploadData.specType,
                            file: {
                                ...formData.uploadData?.file,
                                disableDropZone: false,
                            },
                        },
                    });
                    if (formData.uploadData?.specType?.id !== undefined) {
                        setResetFormDialog({ open: true, mode: DialogMode.RESET_DATA });
                    }
                } else if (e.formData.uploadData.file && formData.uploadData?.specType?.id) {
                    // This is if checking if a file is being dropped and the same specType is selected
                    // Set requestAborted to default behavior `false` to prevent any new request to the flow.
                    requestAborted.current = false;
                    // if there was already a file.
                    if (formData.uploadData?.file?.file) {
                        // save current formData state
                        setOriginalFormData(formData);
                        // save that new file and prompt the user
                        setFormData({
                            ...formData,
                            uploadData: {
                                ...formData.uploadData,
                                file: {
                                    ...formData.uploadData.file,
                                    file: e.formData.uploadData.file,
                                },
                            },
                        });
                        setResetFormDialog({ open: true, mode: DialogMode.RESET_WITH_NEW_DATA });
                    } else {
                        // Show progress bar component.
                        setFormData((prev) => ({
                            ...prev,
                            uploadData: {
                                ...prev.uploadData,
                                progress: {
                                    value: 0,
                                    description: e.formData.uploadData.file.name,
                                    showProgressBar: true,
                                    showRemovalButton: true,
                                },
                            },
                        }));
                        await uploadFileAndRetrieveReviewResults(
                            e.formData.uploadData.file.name,
                            e.formData.uploadData.file
                        );
                    }
                }
            }
        }
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ResetFormDialog
                isOpen={resetFormDialog.open}
                onClose={() => {
                    // restore previous formData state
                    setFormData({ ...originalFormData });
                    setResetFormDialog((prev) => ({ ...prev, open: false }));
                }}
                onSubmit={async () => {
                    hideReviewAndNextSteps(true);
                    switch (resetFormDialog.mode) {
                        case DialogMode.RESET_DATA: {
                            // clear uploaded file and the following steps
                            const newFormData: SFVData = {
                                ...formData,
                                uploadData: {
                                    ...formData.uploadData,
                                    file: undefined,
                                },
                                reviewResults: {
                                    description: formData.reviewResults?.description,
                                },
                            };
                            setFormData(newFormData);
                            setOriginalFormData(newFormData);
                            setResetFormDialog((prev) => ({ ...prev, open: false }));
                            break;
                        }
                        case DialogMode.RESET_WITH_NEW_DATA: {
                            if (formData?.uploadData?.file) {
                                const newFormData: SFVData = {
                                    ...formData,
                                    uploadData: {
                                        ...formData.uploadData,
                                        file: undefined,
                                        progress: {
                                            value: 0,
                                            description: formData.uploadData.file?.file?.name,
                                            statusUploadDescription:
                                                StatusUploadDescription.PROCESSING,
                                            showRemovalButton: true,
                                            showProgressBar: true,
                                        },
                                    },
                                    reviewResults: {
                                        description: formData.reviewResults?.description,
                                    },
                                };
                                setFormData(newFormData);
                                setOriginalFormData(newFormData);
                                setResetFormDialog((prev) => ({ ...prev, open: false }));
                                if (formData.uploadData.file.file) {
                                    await uploadFileAndRetrieveReviewResults(
                                        formData.uploadData.file.file.name,
                                        formData.uploadData.file.file
                                    );
                                }
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }}
            />
            <Form
                recordId=""
                recordName=""
                hideSidebar
                sidebarElements={[]}
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                onChange={(e: any) => {
                    onChange(e);
                }}
            >
                <FormHeader
                    disableSticky
                    title={props.title}
                    description={<HTMLRenderer value={props.description as string} />}
                    editMode={false}
                    headingClassName={headingClassName}
                    primaryListStyle
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(StandardFileValidator);
