import React from 'react';
import { observer } from 'mobx-react';

export interface TextboxProps {
    label: string;
    name: string;
    width?: number;
}

@observer
export default class Textbox extends React.Component<TextboxProps> {
    render() {
        return (
            <div>
                <label htmlFor={this.props.name}>
                    {this.props.label}:&nbsp;&nbsp;&nbsp;
                    <input
                        type="text"
                        name={this.props.name}
                        size={this.props.width ? this.props.width : 50}
                    />
                </label>
            </div>
        );
    }
}
