import React, { FC } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

import HTMLRenderer from '../components/HTMLRenderer';

export interface HTMLWidgetProps extends WidgetProps {
    value: string;
}

const HTMLWidget: FC<HTMLWidgetProps> = (props: HTMLWidgetProps): JSX.Element => {
    return <HTMLRenderer value={props.value} />;
};

export default HTMLWidget;
