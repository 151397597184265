import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getEncountersSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Encounters',
        items: {
            type: 'object',
            properties: {
                encounterId: {
                    type: 'string',
                    title: 'Encounter ID',
                },
                provider: {
                    type: 'string',
                    title: 'Provider',
                },
                startDate: {
                    type: 'string',
                    title: 'Start Date',
                },
                endDate: {
                    type: 'string',
                    title: 'End Date',
                },
                // numOfCodes: {
                //   type: 'string',
                //   title: '# of Codes',
                // },
            },
        },
    };
};

export const getEncountersSectionUISchema = (sectionProps: PatientDetailSection): UiSchema => {
    const emptyListMessage = {
        title: 'No Encounters Found',
        description: '',
    };
    return {
        'ui:field': 'list',
        'ui:hash': 'encounter-results',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
            editable: false,
            rowActions: [
                {
                    id: 'encounter-view',
                    intent: 'primary',
                    label: 'View',
                    behavior: 'Navigate',
                    link: sectionProps.encounterPageLink,
                    idName: 'id',
                },
            ],
            itemsPerPage: sectionProps.itemsPerPage as number,
        },
        items: {
            encounterId: {
                'ui:options': {
                    orderable: true,
                },
            },
            provider: {
                'ui:options': {
                    orderable: true,
                },
            },
            startDate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            endDate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            // numOfCodes: {
            //   'ui:widget': 'SimpleTextWidget',
            //   'ui:options': {
            //     orderable: true,
            //   },
            // },
        },
    };
};
