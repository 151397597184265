import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getLaboratoryResultsSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Laboratory Results',
        items: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                    title: 'Type',
                },
                value: {
                    type: 'string',
                    title: 'Value',
                },
                unit: {
                    type: 'string',
                    title: 'Unit',
                },
                date: {
                    type: 'string',
                    title: 'Date',
                },
                interpretation: {
                    type: 'string',
                    title: 'Interpretation',
                },
            },
        },
    };
};

export const getLaboratoryResultsSectionUISchema = (
    sectionProps: PatientDetailSection
): UiSchema => {
    const emptyListMessage = {
        title: 'No Laboratory Results Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'laboratoryresults',
        'ui:options': {
            itemsPerPage: sectionProps.itemsPerPage as number,
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items: {
            type: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            value: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            unit: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            date: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            interpretation: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
        },
    };
};
