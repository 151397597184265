/* eslint-disable class-methods-use-this */
import React, { useState, useEffect } from 'react';
import { JSONSchema6 } from 'json-schema';
import { RouteComponentProps, withRouter } from 'react-router';
import { Intent, Icon } from '@blueprintjs/core';
import queryString from 'query-string';
import { UiSchema } from 'react-jsonschema-form';
import { IconNames } from '@blueprintjs/icons';
import { isEmpty } from 'lodash';
import { showToast } from '@amxjs/ui';
import { JSONPath } from 'jsonpath-plus';
import Form from '../../components/forms/Form';
import FormHeader from '../../components/forms/components/FormHeader';
import ConfirmExitDialog from '../ConfirmExitDialog';
import Api from '../../core/Api';
import { validateEmail, validatePhone } from '../../components/forms/validation/CustomValidation';
import { validateAffiliatedOrganizations, validateNpi } from './ClinicianValidation';
import { ClinicianDetailsProps } from './ClinicianDetailsInterface';
import FormFooter from '../../components/forms/components/FormFooter';
import { Page } from '../../../generator/generated/MeshInterfaces';
import {
    getAffiliatedOrganizationsSchema,
    getAffiliatedOrganizationsUISchema,
} from './AffiliatedOrganizations';
import { getGeneralSchema, getGeneralUISchema } from './GeneralInformation';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { ClinicianRecord } from '../../../server/routes/ClinicianManagement/ClinicianApi';
import { RowAction } from '../../components/Tables/List';
import { SchemaProvider } from '../../mesh/Schema';
import AffiliatedOrganizationDialog from './AffiliatedOrganizationDialog';
import { OrganizationRecord } from '../../../server/routes/ClinicianManagement/OrganizationApi';
import ClinicianModuleConstants from './ClinicianModuleConstants';

function ClinicianDetails(props: ClinicianDetailsProps & RouteComponentProps) {
    const [savingRecord, setSavingRecord] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [affiliatedPracticeDialogOpen, setAffiliatedPracticeDialogOpen] = useState(false);
    const [activeAffiliatedPractice, setActiveAffiliatedPractice] = useState(null);
    const [showAffiliatedOrganizationCallout, setShowAffiliatedOrganizationCallout] =
        useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [extraErrors, setExtraErrors] = useState<any>();
    const [clinicianTypes, setClinicianTypes] = useState<string[]>([]);
    const [dialogOrgs, setDialogOrgs] = useState<OrganizationRecord[]>([]);
    const [key, setKey] = useState<number>(Date.now());

    const [formData, setFormData] = useState<ClinicianRecord>({
        General: {
            id: '',
            firstName: undefined,
            middleName: '',
            lastName: undefined,
            suffix: '',
            npi: undefined,
            primaryEmail: undefined,
            phoneNumber: '',
            clinicianType: '',
            clinicianStatus: true,
            affiliatedOrganizations: [],
            userHasAccessToAllAffiliatedPractices: true,
        },
        AffiliatedOrganizations: [],
        Organizations: [],
    });
    const [origFormData, setOrigFormData] = useState<ClinicianRecord>({
        General: {
            id: '',
            firstName: undefined,
            middleName: '',
            lastName: undefined,
            suffix: '',
            npi: undefined,
            primaryEmail: undefined,
            phoneNumber: '',
            clinicianType: '',
            clinicianStatus: true,
            affiliatedOrganizations: [],
            userHasAccessToAllAffiliatedPractices: true,
        },
    });
    const [recordInfo, setRecordInfo] = useState({
        id: '',
        name: '',
    });

    let formTitle;
    if (createMode) {
        formTitle = `Add ${props.title}`;
    } else if (editMode) {
        formTitle = `Edit ${props.title}`;
    } else {
        formTitle = props.title || 'Clinician Details';
    }

    const { setBreadcrumbData } = useBreadcrumbContext();

    useEffect(() => {
        const loadData = async () => {
            const { id } = queryString.parse(props.location.search);
            const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
            if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
                props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                    breadcrumbTrail.labels.push(crumb.name);
                    breadcrumbTrail.routes.push(crumb.route || null);
                });
            }

            if (id) {
                const response: any = await Api.clinicianManagement.queryForClinician(id);
                setClinicianTypes(response.data.ClinicianTypeList);
                const rawData = response.data as ClinicianRecord;
                const data = {
                    ...rawData,
                    // set clinicianType to 'None' as it is JSON Schema default value
                    General: {
                        ...rawData.General,
                        clinicianType: rawData.General.clinicianType || 'None',
                    },
                };
                setOrigFormData(data);
                setFormData(data);
                setRecordInfo({
                    id: rawData.General.npi ? rawData.General.npi : '',
                    name:
                        rawData.General.firstName && rawData.General.lastName
                            ? `${rawData.General.lastName}, ${rawData.General.firstName}`
                            : '',
                });
                breadcrumbTrail.labels.push(
                    rawData.General.firstName &&
                        `${rawData.General.lastName}, ${rawData.General.firstName}`
                );
            } else {
                setCreateMode(true);
                const response: any = await Api.clinicianManagement.queryForOrganization();
                setClinicianTypes(response.data.ClinicianTypeList);
                const rawData = response.data.OrganizationList as OrganizationRecord[];
                setFormData({ ...formData, Organizations: rawData });

                breadcrumbTrail.labels.push('Add Clinician');
            }
            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });
        };
        loadData();

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    useEffect(() => {
        const allOrgs: OrganizationRecord[] = Object.assign([], formData.Organizations);
        const affiliatedOrgs: any[] = Object.assign([], formData.AffiliatedOrganizations);

        const availableOrgsForSelection = allOrgs.filter(
            (org) =>
                affiliatedOrgs.filter(
                    (affiliatedOrg) =>
                        affiliatedOrg.organizationReference === org.organizationReference
                ).length === 0
        );

        setDialogOrgs(availableOrgsForSelection);
    }, [formData.AffiliatedOrganizations, formData.Organizations]);

    const onChange = (e: any) => {
        setHasChange(true);
        setFormData(e.formData);
    };

    const getSidebarElements = () => {
        return [
            { title: 'General Information', hash: 'general' },
            {
                title:
                    props.affiliatedOrganizationTitle ||
                    ClinicianModuleConstants.AddaffiliatedOrganizationTitle,
                hash: 'affiliatedorganizations',
            },
        ];
    };

    const processAffiliatedOrganizations = (
        originalForm: ClinicianRecord,
        newForm: ClinicianRecord
    ): ClinicianRecord => {
        const oldAffiliatedOrganizations: OrganizationRecord[] =
            originalForm.AffiliatedOrganizations || [];
        const newAffiliatedOrganizations: OrganizationRecord[] =
            newForm.AffiliatedOrganizations || [];
        const processedAffiliatedOrganizations: OrganizationRecord[] = [];
        const processedFormData: ClinicianRecord = Object.assign({}, formData);

        const originalOrgsRecord: any = oldAffiliatedOrganizations.reduce(
            (prev: any, curr: any) => {
                const p = prev;
                p[curr.id] = curr;
                return p;
            },
            {}
        );

        const newOrgsRecord: any = newAffiliatedOrganizations.reduce((prev: any, curr: any) => {
            const p = prev;
            p[curr.id] = curr;
            return p;
        }, {});

        oldAffiliatedOrganizations.forEach((oldOrg) => {
            if (!newOrgsRecord[oldOrg.id]) {
                // user deleted the affiliated organization
                const deletedOrg: any = oldOrg;
                deletedOrg.organizationRecordDeleted = true;
                processedAffiliatedOrganizations.push(deletedOrg);
            }
        });

        newAffiliatedOrganizations.forEach((newOrg) => {
            if (!originalOrgsRecord[newOrg.id]) {
                // user added a new affiliated organization
                const addedOrg: any = newOrg;
                addedOrg.organizationRecordIsNew = true;
                processedAffiliatedOrganizations.push(addedOrg);
            } else {
                // previously existing or edited affiliated organizations
                processedAffiliatedOrganizations.push(newOrg);
            }
        });

        processedFormData.General.affiliatedOrganizations = processedAffiliatedOrganizations;
        return processedFormData;
    };

    const onSubmit = () => {
        setSavingRecord(true);
        setExtraErrors(undefined);
        const recordData: ClinicianRecord = processAffiliatedOrganizations(origFormData, formData);

        if (isEmpty(formData.AffiliatedOrganizations)) {
            setShowAffiliatedOrganizationCallout(true);
            setSavingRecord(false);
            return;
        }

        setShowAffiliatedOrganizationCallout(false);
        Api.clinicianManagement
            .updateClinician(recordData, createMode)
            .then((response: any) => {
                const successMessage = createMode
                    ? 'New Record Successfully Added '
                    : 'Changes Successfully Saved';
                if (response.ok) {
                    if (response.data.noChangesNeeded) {
                        showToast({
                            message: (
                                <div>
                                    <p style={{ fontWeight: 'bold' }}>
                                        {response.data.noChangesMessageTitle}
                                    </p>
                                    <p>{response.data.noChangesMessage}</p>
                                </div>
                            ),
                            intent: Intent.SUCCESS,
                            icon: 'tick-circle',
                        });
                    } else {
                        showToast({
                            message: (
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>{successMessage}</span>
                                </p>
                            ),
                            intent: Intent.SUCCESS,
                            icon: IconNames.TICK_CIRCLE,
                        });
                        // When a new record is added add the new ID to the URL
                        if (createMode) {
                            props.history.replace(
                                `${props.history.location.pathname}?id=${encodeURIComponent(
                                    response.data.clinicianId
                                )}`
                            );
                        }
                        setOrigFormData(formData);
                    }
                    setHasChange(false);
                    setCreateMode(false);
                    setEditMode(false);
                } else {
                    let errorTitle = 'Error Occurred!';
                    let errorContent =
                        'Oops! Looks like something went wrong and the record could not be saved. Please try again later.';

                    if (
                        response.originalResponseObject &&
                        (response.originalResponseObject.status === 409 ||
                            response.originalResponseObject.status === 403)
                    ) {
                        const messageText = response.data.split('|');
                        if (messageText && messageText.length > 1) {
                            let field;
                            [errorTitle, errorContent, field] = messageText;

                            if (field === 'npi') {
                                setExtraErrors({
                                    General: {
                                        npi: {
                                            __errors: ['Please enter a different NPI'],
                                        },
                                    },
                                });
                            } else if (field === 'email') {
                                setExtraErrors({
                                    General: {
                                        primaryEmail: {
                                            __errors: ['Please enter a different email address'],
                                        },
                                    },
                                });
                            }
                        }
                    }
                    showToast({
                        message: (
                            <p>
                                <span style={{ fontWeight: 'bold' }}>
                                    <Icon icon={IconNames.ERROR} />
                                    &nbsp;
                                    {errorTitle}
                                </span>
                                <br />
                                <br />
                                <span>{errorContent}</span>
                            </p>
                        ),
                        intent: Intent.DANGER,
                    });
                }
                setSavingRecord(false);
            })
            .catch((error: any) => {
                console.error(error);
                setSavingRecord(false);
            });
    };

    const onError = (errors: any) => {
        console.log('errored', errors);
    };

    const setDisplayForFailedAffiliatedOrganizationCheck = () => {
        setShowAffiliatedOrganizationCallout(true);
    };

    const getValidators = () => {
        const validators = [
            {
                field: 'General.phoneNumber',
                validator: validatePhone,
                errorMessage: 'Please specify a valid phone number in the form of (999)999-9999',
            },
            {
                field: 'General.npi',
                validator: validateNpi,
                errorMessage: 'NPI must be 10 digits starting with a 1 or 2',
            },
            {
                field: 'General.primaryEmail',
                validator: validateEmail,
                errorMessage: 'Please specify a valid email address in the form of user@webaddress',
            },
            {
                field: 'AffiliatedOrganizations',
                validator: validateAffiliatedOrganizations,
                errorMessage: '',
                callbackOnFail: () => setDisplayForFailedAffiliatedOrganizationCheck(),
            },
        ];

        return validators;
    };

    const onAddAffiliatedPracticeClick: (event: any) => void = () => {
        setAffiliatedPracticeDialogOpen(!affiliatedPracticeDialogOpen);
    };

    const onDeleteAffiliatedPractice = (e: any) => {
        const displayData: ClinicianRecord = Object.assign({}, formData);

        if (displayData.General.affiliatedOrganizations) {
            const orgs = displayData.General.affiliatedOrganizations.filter(
                (org) => org.organizationReference !== e.organizationReference
            );

            displayData.General.affiliatedOrganizations = orgs;
            displayData.AffiliatedOrganizations = orgs;

            setFormData(displayData);
            setHasChange(true);
        }
    };

    const onEditAffiliatedPractice = (e: any) => {
        setActiveAffiliatedPractice(e);
        setAffiliatedPracticeDialogOpen(true);
    };

    const rowActions: RowAction[] = [
        {
            id: `affiliatedPractice-edit`,
            label: '',
            behavior: 'Custom',
            intent: Intent.PRIMARY,
            link: {},
            icon: 'edit',
            callbackOnCustom: onEditAffiliatedPractice,
        },
        {
            id: `affiliatedPractice-remove`,
            label: '',
            behavior: 'Delete',
            intent: Intent.DANGER,
            link: {},
            icon: 'remove',
            callbackOnDelete: onDeleteAffiliatedPractice,
        },
    ];

    const getSchema = (): JSONSchema6 => {
        return {
            type: 'object',
            properties: {
                General: getGeneralSchema('General Information', clinicianTypes),
                AffiliatedOrganizations: getAffiliatedOrganizationsSchema(
                    props.affiliatedOrganizationTitle,
                    props.columns
                ),
            },
        };
    };

    const getUISchema = (): UiSchema => {
        return {
            General: getGeneralUISchema(
                editMode || createMode,
                formData.General.clinicianType,
                formData.General.userHasAccessToAllAffiliatedPractices
            ),
            AffiliatedOrganizations: getAffiliatedOrganizationsUISchema(
                editMode,
                createMode,
                props.affiliatedOrganizationButtonLabel,
                props.affiliatedOrganizationTitle,
                showAffiliatedOrganizationCallout,
                rowActions,
                onAddAffiliatedPracticeClick,
                props.columns
            ),
        };
    };

    const editToggle = () => {
        const toggleEditMode = () => {
            setEditMode(!editMode);
            setFormData(origFormData);
        };

        if (createMode) {
            if (hasChange) {
                setConfirmationDialogOpen(true);
            } else {
                setShowAffiliatedOrganizationCallout(false);
                props.history.goBack();
            }
        } else if (editMode && hasChange) {
            setConfirmationDialogOpen(true);
        } else {
            toggleEditMode();
        }
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setKey(Date.now());
        setEditMode(!editMode);
        setFormData(origFormData);
        setShowAffiliatedOrganizationCallout(false);
        setHasChange(false);
        setConfirmationDialogOpen(false);
        if (createMode) {
            props.history.goBack();
        }
    };

    const handleAffiliatedPracticeDialogClose = () => {
        setActiveAffiliatedPractice(null);
        setAffiliatedPracticeDialogOpen(false);
    };

    const handleAffiliatedPracticeDialogOkay = (e: any) => {
        let nameToUse = JSONPath({
            path: `$.name.itemName`,
            json: e,
        })[0];
        if (!nameToUse) {
            // eslint-disable-next-line prefer-destructuring
            nameToUse = JSONPath({
                path: `$.name`,
                json: e,
            })[0];
        }
        const formattedAffiliatedOrganization = {
            id: e.id !== e.organizationReference ? e.id : undefined,
            organizationReference: e.organizationReference,
            status: e.status,
            tin: e.tin,
            name: nameToUse,
            dateRange: {
                startDate: e.dateRange && e.dateRange.startDate ? e.dateRange.startDate : undefined,
                endDate: e.dateRange && e.dateRange.endDate ? e.dateRange.endDate : undefined,
            },
            clinicianType: formData.General.clinicianType,
        };
        const displayData: ClinicianRecord = Object.assign({}, formData);
        const affiliated =
            displayData.AffiliatedOrganizations &&
            displayData.AffiliatedOrganizations.filter(
                (org) =>
                    org.organizationReference !==
                        formattedAffiliatedOrganization.organizationReference &&
                    org.organizationReference !== e.originalOrganizationReference
            );
        if (affiliated) {
            affiliated.push(formattedAffiliatedOrganization);
            displayData.General.affiliatedOrganizations = affiliated;
            displayData.AffiliatedOrganizations = affiliated;
        }

        setFormData(displayData);
        setHasChange(true);
        setActiveAffiliatedPractice(null);
        setAffiliatedPracticeDialogOpen(false);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={handleConfirmationDialogClose}
                handleOkay={handleConfirmationDialogOkay}
            />
            <AffiliatedOrganizationDialog
                isOpen={affiliatedPracticeDialogOpen}
                handleClose={handleAffiliatedPracticeDialogClose}
                handleOkay={handleAffiliatedPracticeDialogOkay}
                organizations={dialogOrgs}
                unfilteredOrganizations={formData.Organizations}
                activeAffiliatedPractice={activeAffiliatedPractice}
            />
            <Form
                key={key}
                recordId={recordInfo.id}
                recordName={recordInfo.name}
                sidebarElements={getSidebarElements()}
                schema={getSchema()}
                uiSchema={getUISchema()}
                formData={editMode || createMode ? formData : origFormData}
                onChange={(e: any) => {
                    onChange(e);
                }}
                validators={getValidators()}
                onSubmit={onSubmit}
                onError={onError}
                extraErrors={extraErrors}
            >
                <FormHeader
                    title={formTitle}
                    editMode={editMode || createMode}
                    allowEditing
                    allowCancel={!savingRecord}
                    allowSave={hasChange && !savingRecord}
                    enableEditMode={() => editToggle()}
                    disableEditMode={() => editToggle()}
                />
                {createMode && (
                    <FormFooter
                        title={formTitle}
                        editMode={editMode || createMode}
                        allowEditing
                        allowCancel={!savingRecord}
                        allowSave={hasChange && !savingRecord}
                        enableEditMode={() => editToggle()}
                        disableEditMode={() => editToggle()}
                    />
                )}
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(ClinicianDetails);
