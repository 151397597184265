import React, { useEffect, useState } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { WidgetProps } from 'react-jsonschema-form';
import { Suggest } from '@blueprintjs/select';
import { style } from 'typestyle';
import WidgetTitleField from '../fields/WidgetTitleField';

interface StateItem {
    name: string;
    id: string;
}

const items: StateItem[] = [
    { name: 'Alaska', id: 'AK' },
    { name: 'Alabama', id: 'AL' },
    { name: 'Arkansas', id: 'AR' },
    { name: 'American Samoa', id: 'AS' },
    { name: 'Arizona', id: 'AZ' },
    { name: 'California', id: 'CA' },
    { name: 'Colorado', id: 'CO' },
    { name: 'Connecticut', id: 'CT' },
    { name: 'District of Columbia', id: 'DC' },
    { name: 'Delaware', id: 'DE' },
    { name: 'Florida', id: 'FL' },
    { name: 'Federated States of Micronesia', id: 'FM' },
    { name: 'Georgia', id: 'GA' },
    { name: 'Guam', id: 'GU' },
    { name: 'Hawaii', id: 'HI' },
    { name: 'Iowa', id: 'IA' },
    { name: 'Idaho', id: 'ID' },
    { name: 'Illinois', id: 'IL' },
    { name: 'Indiana', id: 'IN' },
    { name: 'Kansas', id: 'KS' },
    { name: 'Kentucky', id: 'KY' },
    { name: 'Louisiana', id: 'LA' },
    { name: 'Massachusetts', id: 'MA' },
    { name: 'Maryland', id: 'MD' },
    { name: 'Maine', id: 'ME' },
    { name: 'Marshall Islands', id: 'MH' },
    { name: 'Michigan', id: 'MI' },
    { name: 'Minnesota', id: 'MN' },
    { name: 'Missouri', id: 'MO' },
    { name: 'Northern Mariana Islands', id: 'MP' },
    { name: 'Mississippi', id: 'MS' },
    { name: 'Montana', id: 'MT' },
    { name: 'North Carolina', id: 'NC' },
    { name: 'North Dakota', id: 'ND' },
    { name: 'Nebraska', id: 'NE' },
    { name: 'New Hampshire', id: 'NH' },
    { name: 'New Jersey', id: 'NJ' },
    { name: 'New Mexico', id: 'NM' },
    { name: 'Nevada', id: 'NV' },
    { name: 'New York', id: 'NY' },
    { name: 'Ohio', id: 'OH' },
    { name: 'Oklahoma', id: 'OK' },
    { name: 'Oregon', id: 'OR' },
    { name: 'Pennsylvania', id: 'PA' },
    { name: 'Puerto Rico', id: 'PR' },
    { name: 'Palau', id: 'PW' },
    { name: 'Rhode Island', id: 'RI' },
    { name: 'South Carolina', id: 'SC' },
    { name: 'South Dakota', id: 'SD' },
    { name: 'Tennessee', id: 'TN' },
    { name: 'Texas', id: 'TX' },
    { name: 'Utah', id: 'UT' },
    { name: 'Virginia', id: 'VA' },
    { name: 'Virgin Islands of the U.S.', id: 'VI' },
    { name: 'Vermont', id: 'VT' },
    { name: 'Washington', id: 'WA' },
    { name: 'Wisconsin', id: 'WI' },
    { name: 'West Virginia', id: 'WV' },
    { name: 'Wyoming', id: 'WY' },
];

const ItemSuggest = Suggest.ofType<StateItem>();

const suggestStyle = style({
    height: '50px',
});

function StateWidget(props: WidgetProps) {
    const [selectedItem, setSelectedItem] = useState<StateItem>();
    const { required, label, id } = props;
    const titleId = `${id}__title`;

    useEffect(() => {
        items.map((option: any) => {
            if (props.value === option.name) {
                setSelectedItem(option);
            }
        });
    }, [props.value]);

    const renderInputValue = (item: StateItem) => {
        return item.name;
    };

    const handleValueChange = (item: StateItem) => {
        setSelectedItem(item);
        props.onChange(item.name);
    };

    const renderItem = (item: StateItem, { handleClick, modifiers }: any) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item.id}
                onClick={handleClick}
                text={item.name}
            />
        );
    };

    const filterItem = (query: string, item: StateItem) => {
        return `${item.name.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
            {label ? <WidgetTitleField required={required} title={label} id={titleId} /> : null}
            <ItemSuggest
                className={suggestStyle}
                inputProps={{
                    placeholder: '',
                    className: props.rawErrors && props.rawErrors.length > 0 ? 'error-border' : '',
                    autoComplete: 'off-override', // Chrome ignores the default 'off' value. Overriding to any other value to disable autofilling for the State selection.
                }}
                inputValueRenderer={renderInputValue}
                items={items}
                itemPredicate={filterItem}
                itemRenderer={renderItem}
                noResults={<MenuItem disabled text="No results." />}
                onItemSelect={handleValueChange}
                selectedItem={selectedItem}
                popoverProps={{ minimal: true, usePortal: false }}
            />
        </div>
    );
}

export default StateWidget;
