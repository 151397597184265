import { Intent, IconName, Position, Toaster } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CardClickableListCard, { ListItem } from './CardClickableListCard';
import Api from '../../core/Api';

export interface SurveyListItem extends Omit<ListItem, 'listItemIconIntent'> {
    listItemIconIntent: Intent | 'Incomplete' | 'Complete' | 'Expired';
}

const getIcon = (responseState: string): IconName => {
    switch (responseState) {
        case 'Incomplete':
            return 'warning-sign';
        case 'Completed':
            return 'tick-circle';
        case 'Expired':
        default:
            return 'outdated';
    }
};

const getIconIntent = (responseState: string): Intent => {
    switch (responseState) {
        case 'Incomplete':
            return 'warning';
        case 'Completed':
            return 'success';
        case 'Expired':
        default:
            return 'none';
    }
};

const PDF_URL = window.SERVER_CONFIG.REACT_APP_SERVER_PDF_RESOURCE_URL as string;

@observer
class SurveyListCard extends CardClickableListCard {
    componentDidMount() {
        Api.getSurveyList().then((response) => {
            if (!response.ok) {
                console.error(response.data);
                return;
            }

            const { sortingOrder = 'ascending' } = this.props;
            const surveyResponse = response.data;

            const sortedResponse =
                sortingOrder === 'descending'
                    ? surveyResponse.sort((a, b) => (a.DateSent > b.DateSent ? -1 : 1))
                    : surveyResponse.sort((a, b) => (a.DateSent > b.DateSent ? 1 : -1));

            const listItems = sortedResponse.map((survey): ListItem => {
                return {
                    listItemIcon: getIcon(survey.Status),
                    listItemIconIntent: getIconIntent(survey.Status),
                    listItemHeading: survey.Name,
                    listItemText: `[${survey.Status}] ${survey.Description}`,
                    listItemAction: () => {
                        switch (survey.Status) {
                            case 'Incomplete':
                                if (survey.SurveyUrl) window.open(survey.SurveyUrl);
                                break;
                            case 'Completed':
                                if (survey.SurveyId !== null && survey.ResponseId !== null) {
                                    const toast = Toaster.create({
                                        position: Position.BOTTOM_RIGHT,
                                    });
                                    if (this.props.exportFormat === 'quexmlpdf') {
                                        toast.show({
                                            timeout: 10000,
                                            message: this.props.pleaseWaitToastMessage,
                                            intent: Intent.NONE,
                                        });
                                    }
                                    Api.downloadSurvey(
                                        survey.SurveyId,
                                        survey.ResponseId,
                                        undefined,
                                        this.props.exportFormat
                                    ).then((downloadResponse: any) => {
                                        if (!downloadResponse.ok) {
                                            toast.show({
                                                timeout: 10000,
                                                message: this.props.errorToastMessage,
                                                intent: Intent.DANGER,
                                            });
                                            console.error(downloadResponse.data);
                                            return;
                                        }

                                        window.open(
                                            `${PDF_URL}/${downloadResponse.data.resourceId}`
                                        );
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    },
                };
            });

            this.setState({ listItems });
        });
    }
}

export default withRouter(SurveyListCard);
