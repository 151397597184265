import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card } from '@blueprintjs/core';
import { style } from 'typestyle';

// import { JSONSchema7 } from 'json-schema';
// import Form from '@rjsf/core';
import { JSONSchema6 } from 'json-schema';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Row, Column, RadioGroupField, CheckboxGroupField, TextField } from '@amxjs/ui';
import { runInAction, observable } from 'mobx';
import AmxThemedForm from '../../components/forms/AmxThemedForm';
import Store from '../../core/Store';
import { FhirEditorForm as FhirEditorFormInterface } from '../../../generator/generated/MeshInterfaces';
import Api from '../../core/Api';
import testData from './testdata';
import SwitchWidget from '../../components/forms/widgets/SwitchWidget';

export interface FhirEditorPlaygroundProps extends FhirEditorFormInterface {
    store: Store;
}

interface FhirEditorFormState {
    formData: any;
}

@observer
class FhirEditorPlayground extends Component<
    FhirEditorPlaygroundProps & RouteComponentProps,
    FhirEditorFormState
> {
    testData: any = testData;

    @observable
    $val: keyof typeof testData.schema = 'amx';

    @observable
    $preferences: any[] = [];

    @observable
    $schema: string;

    @observable
    $uiSchema: string;

    @observable
    $formdata: string;

    @observable
    $key: string;

    updateMode = false;

    constructor(props: any) {
        super(props);

        this.state = { formData: {} };

        this.$schema = JSON.stringify(this.testData.schema[this.$val], null, 2);
        this.$uiSchema = JSON.stringify(this.testData.uiSchema[this.$val], null, 2);
        this.$formdata = JSON.stringify(this.testData.formData[this.$val], null, 2);

        this.$key = Math.random().toString();

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { id } = queryString.parse(this.props.location.search);

        if (id) {
            this.updateMode = true;
            Api.queryFhirResource(
                this.props.primaryResource,
                id as string,
                this.props.graphQLQuery || ''
            ).then((response) => {
                const rawData = response.data;

                this.setState({ formData: rawData });
                console.log(this.state.formData);
            });
        }
    }

    onChange(formData: any, e: any) {
        if (this.$preferences.includes('Show Custom Data')) {
            this.$formdata = JSON.stringify(formData.formData, null, 2);
        }
        console.log('changed', formData, e);
    }

    static onError(errors: any) {
        console.log('errored', errors);
    }

    static onSubmit(formData: any) {
        console.log('submitted', formData);
    }

    getSchema(): JSONSchema6 {
        return JSON.parse(this.props.jsonSchema);
    }

    getUiSchema(): JSONSchema6 {
        return JSON.parse(this.props.uiSchema ? this.props.uiSchema : '{}');
    }

    static getSchemaFromString(objString: string): JSONSchema6 {
        return JSON.parse(objString);
    }

    static getTestDataKeys() {
        return [
            'amx',
            'layout',
            'nested',
            'arrays',
            'numbers',
            'widgets',
            'ordering',
            'references',
            'custom',
            'errors',
            'examples',
            'large',
            'datetime',
            'validation',
            'files',
            'single',
            'customarray',
            'customobject',
            'alternatives',
            'additionalproperties',
            'anyof',
            'oneof',
            // 'allof',
            'nullfields',
            'defaults',
            // 'simple', 'propertydependencies', 'schemadependencies', 'nullable', 'errorschema',
        ].map((name) => {
            return {
                label: name,
                value: name,
                info: undefined,
            };
        });
    }

    static getCheckboxesList() {
        return ['Live Validation', 'Show Custom Data'].map((name) => {
            return {
                label: name,
                value: name,
                info: undefined,
            };
        });
    }

    getKeyValue =
        <U extends keyof T, T extends object>(key: U) =>
        (obj: T) =>
            obj[key];

    render() {
        const showCustomData = this.$preferences.includes('Show Custom Data');
        const liveValidation = this.$preferences.includes('Live Validation');

        return (
            <div className={style({ display: 'flex' })}>
                <Card
                    className={style({
                        width: '250px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    })}
                >
                    <CheckboxGroupField
                        label="Preferences:"
                        value={this.$preferences}
                        options={FhirEditorPlayground.getCheckboxesList()}
                        onChange={(selectedOptions) =>
                            runInAction(() => {
                                this.$preferences = selectedOptions;
                            })
                        }
                    />
                    <RadioGroupField
                        label="Form Selection"
                        value={this.$val}
                        options={FhirEditorPlayground.getTestDataKeys()}
                        onChange={(value) =>
                            runInAction(() => {
                                this.$val = value;
                                this.$schema = JSON.stringify(
                                    this.testData.schema[this.$val],
                                    null,
                                    2
                                );
                                this.$uiSchema = JSON.stringify(
                                    this.testData.uiSchema[this.$val],
                                    null,
                                    2
                                );
                                this.$formdata = JSON.stringify(
                                    this.testData.formData[this.$val],
                                    null,
                                    2
                                );
                            })
                        }
                    />
                </Card>

                <Card
                    className={style({
                        width: `calc(100vw - 250px)`,
                        flexGrow: 1,
                        position: 'relative',
                    })}
                >
                    {showCustomData && (
                        <Row>
                            <Column lg={4}>
                                <TextField
                                    label="Schema"
                                    multiLine
                                    type="text"
                                    value={this.$schema}
                                    onChange={(v) => {
                                        runInAction(() => {
                                            this.$schema = v;
                                        });
                                    }}
                                />
                            </Column>
                            <Column lg={4}>
                                <TextField
                                    label="uiSchema"
                                    multiLine
                                    type="text"
                                    value={this.$uiSchema}
                                    onChange={(v) => {
                                        runInAction(() => {
                                            this.$uiSchema = v;
                                        });
                                    }}
                                />
                            </Column>
                            <Column lg={4}>
                                <TextField
                                    label="formData"
                                    multiLine
                                    type="text"
                                    value={this.$formdata}
                                    onChange={(v) => {
                                        runInAction(() => {
                                            this.$formdata = v;
                                            this.$key = Math.random().toString();
                                        });
                                    }}
                                />
                            </Column>
                        </Row>
                    )}
                    <AmxThemedForm
                        key={this.$key}
                        schema={FhirEditorPlayground.getSchemaFromString(this.$schema)}
                        formData={JSON.parse(this.$formdata)}
                        uiSchema={FhirEditorPlayground.getSchemaFromString(this.$uiSchema)}
                        widgets={{ switch: SwitchWidget }}
                        liveValidate={liveValidation}
                        onChange={this.onChange}
                        onSubmit={(submitEvent: any) => FhirEditorPlayground.onSubmit(submitEvent)}
                        onError={FhirEditorPlayground.onError}
                    />
                </Card>
            </div>
        );
    }
}

export default withRouter(FhirEditorPlayground);
