import React, { CSSProperties, FC, useMemo, useRef } from 'react';
import { Accept, DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { style } from 'typestyle';
import { Button, Icon, Intent, Text, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { formatBytes } from '../../../util/utils';

const focusedStyle: CSSProperties = {
    borderColor: Colors.BLUE3,
};

const acceptStyle: CSSProperties = {
    borderColor: Colors.GREEN3,
};

const rejectStyle: CSSProperties = {
    borderColor: Colors.RED3,
};

interface FileUploadProps extends DropzoneOptions {
    extraMessages: string[];
    selectFileButtonText: string;
    acceptedFilesText: string;
    disableDropZone: boolean;
}

const baseStyle = (disable: boolean): CSSProperties => {
    return {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        outline: 'none',
        transition: 'border-color .24s ease-in-out',
        ...(disable
            ? {
                  opacity: '0.5',
                  cursor: 'not-allowed',
              }
            : {
                  opacity: '1',
                  cursor: 'default',
              }),
    };
};

const FileUpload: FC<FileUploadProps> = (props: FileUploadProps) => {
    const { acceptedFilesText, extraMessages, selectFileButtonText, disableDropZone, ...other } =
        props;
    const { getInputProps, getRootProps, isDragAccept, isDragReject, isFocused } = useDropzone({
        ...other,
    });

    const inputRef = useRef<HTMLInputElement>(null);

    // Work around for: https://github.com/react-dropzone/react-dropzone/issues/1294
    const handleButtonClick = () => {
        if (!disableDropZone) {
            inputRef.current?.click();
        }
    };

    const styles = useMemo(
        () =>
            style({
                ...baseStyle(disableDropZone),
                ...(isFocused ? focusedStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {}),
            }),
        [isFocused, isDragAccept, isDragReject, disableDropZone]
    );

    return (
        <>
            <div
                className={style({
                    lineHeight: 1.5,
                    backgroundColor: 'rgba(138, 155, 168, 0.15)',
                    borderRadius: '3px',
                    padding: '10px',
                    marginBottom: '15px',
                })}
            >
                <div {...getRootProps({ className: styles })}>
                    <input {...getInputProps()} type="file" title="input" ref={inputRef} />
                    <Icon icon={IconNames.UPLOAD} intent={Intent.PRIMARY} size={30} />
                    <div className={style({ margin: '15px 0', textAlign: 'center' })}>
                        {extraMessages.map((message) => (
                            <Text key={message}>{message}</Text>
                        ))}
                    </div>
                    <Button type="button" onClick={handleButtonClick} disabled={disableDropZone}>
                        {selectFileButtonText}
                    </Button>
                </div>
            </div>

            <div className={style({ display: 'flex', justifyContent: 'space-between' })}>
                <Text>{acceptedFilesText}</Text>
                <Text>Maximum size: {formatBytes(other.maxSize as number) || ''}</Text>
            </div>
        </>
    );
};

export default FileUpload;
export { Accept, DropEvent, FileRejection, FileUpload };
