/* eslint-disable @typescript-eslint/camelcase */
import { isNumber } from 'lodash';

import { IMeasureReport, IMeasureReport_Group } from '@ahryman40k/ts-fhir-types/lib/R4';

const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const getUriFromFile = async (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();

        reader.onload = (event: any) => {
            resolve(event.target?.result);
        };
        reader.readAsDataURL(file);
    });
};

const getCurrentTimeFromBrowser = () => {
    return new Date().toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};

const setQueryParamsInUrl = (existingUrl: string, queryParams: Record<string, string>): string => {
    const existingURLObject = new URL(`${window.location.origin}${existingUrl}`);
    Object.getOwnPropertyNames(queryParams).forEach((paramKey) => {
        if (queryParams[paramKey]) {
            existingURLObject.searchParams.append(paramKey, queryParams[paramKey]);
        }
    });

    return `${existingURLObject.pathname}/${existingURLObject.search}`;
};

const roundToDecimals = (num: number, decimalPoints: number): number => {
    if (!num || !decimalPoints) {
        return 0;
    }
    return +`${Math.round(Number(`${num}e+${decimalPoints}`))}e-${decimalPoints}`;
};

const getScoreOrDash = (score: number | undefined): string => {
    if (isNumber(score)) {
        return roundToDecimals(score, 2).toString();
    }
    return '-';
};

const getMeasureDenominator = (
    measureReports: IMeasureReport[] | undefined,
    measure: string,
    reportingYear: string
): number | undefined => {
    if (!measureReports || !measure || !reportingYear) {
        return undefined;
    }
    const measureId = `${measure}|${reportingYear}`.toLowerCase();
    const measureReport = measureReports.find(
        (mr: IMeasureReport) => mr.measure.toLowerCase() === measureId
    );
    if (!measureReport) {
        return undefined;
    }
    return measureReport.group
        ?.map(
            (group: IMeasureReport_Group) =>
                group.population?.find((p) => p.code?.coding?.find((c) => c.code === 'denominator'))
                    ?.count
        )
        .reduce((prev, curr) => prev ?? curr, undefined);
};

export {
    formatBytes,
    getMeasureDenominator,
    getCurrentTimeFromBrowser,
    getScoreOrDash,
    getUriFromFile,
    roundToDecimals,
    setQueryParamsInUrl,
};
