import Heading from '../pages/heading/Heading';
import CardSet from '../pages/cards/CardSet';
import WelcomeScreen from '../pages/welcome-screen/WelcomeScreen';
// import DataGraph from '../pages/tableau/DataGraph';
import ResourceDetails from '../pages/cards/ResourcesCardSet';
import ResourceCard from '../pages/cards/ResourceCard';
import AffectedTable from '../components/Tables/AffectedIndividualsTable';
import CardWithImage from '../pages/cards/CardWithImage';
import SurveyListTable from '../components/Tables/SurveyListTable';
import Textbox from '../components/Inputs/Textbox';
// import FhirList from '../components/Tables/FhirList';
// import FhirEditorForm from '../pages/fhir/FhirEditorForm';
// import FhirEditorPlayground from '../pages/fhir/FhirEditorPlayground';
import Disclaimer from '../components/text/Disclaimer';

// import UserList from '../pages/users/UserList';

class MeshComponentMapper {
    componentMap: any;

    constructor() {
        this.componentMap = {
            Heading,
            CardSet,
            // needs error boundary
            // tableau: DataGraph,
            ResourcesCardSet: ResourceDetails,
            Resource: ResourceCard,
            welcomeScreen: WelcomeScreen,
            CardWithImage,
            SurveyListTable,
            AffectedIndividualsListTable: AffectedTable,
            Textbox,
            // FhirEditorForm,
            // FhirEditorPlayground,
            Disclaimer,
            // These cause a dependency cycle, so we'll have to figure out a better way for this.
            // FhirList,
            // UserList,
        };
    }

    getComponent(meshSchema: string) {
        return this.componentMap[meshSchema];
    }

    registerComponent<P extends object>(
        meshSchema: string,
        reactComponent: React.ComponentType<P>
    ) {
        this.componentMap[meshSchema] = reactComponent;
    }
}

export default new MeshComponentMapper();
