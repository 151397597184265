import { observer } from 'mobx-react';
import { get } from 'lodash';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import CardClickableListCard, { ListItem } from './CardClickableListCard';
import { Resource } from '../../../generator/generated/MeshInterfaces';
import { openLink } from '../../util/LinkUtils';

interface ResourceItem extends Resource {
    created: string;
}

@observer
class ResourceListCard extends CardClickableListCard {
    componentDidMount() {
        const resourcesList = get(this.props, 'store.siteConfiguration.Resources');
        if (resourcesList) {
            // if Resources exist, make sure to filter them out by the schema with matching name first
            const foundResources = Object.entries(resourcesList).filter(
                ([, value]: [string, any]) => value.schema === 'Resource'
            );
            if (foundResources.length) {
                // take those found resources, and now get rid of the generated name from position 0
                // in the array returned from the filter above and just return the object
                const resourceItems = foundResources.map((found) => found[1]) as ResourceItem[];

                // filter out any that shouldn't be shown on the dashboard
                const filteredResources = resourceItems.filter((item) => item.showOnDashboard);

                // sort them by newest om top--note the .reverse() at the end
                const sortedResourceItems = filteredResources
                    .sort(
                        (a, b) =>
                            Number(moment(a.created).format('YYYYMMDD')) -
                            Number(moment(b.created).format('YYYYMMDD'))
                    )
                    .reverse();

                // lastly, mutate the data and prepare it for CardClickableListCard format
                const listItems = sortedResourceItems.map((resource: Resource): ListItem => {
                    return {
                        listItemIcon:
                            (resource.iconForDashboardCard &&
                                resource.iconForDashboardCard.iconName) ||
                            'bookmark',
                        listItemIconIntent:
                            (resource.iconForDashboardCard &&
                                resource.iconForDashboardCard.iconIntent) ||
                            'none',
                        listItemHeading: resource.title,
                        listItemText: resource.description,
                        listItemAction: () => {
                            openLink(resource.link, this.props.history);
                        },
                    };
                });

                this.setState({ listItems });
            }
        }
    }
}

export default withRouter(ResourceListCard);
