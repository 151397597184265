/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Column, Row } from '@amxjs/ui';
import moment from 'moment';
import {
    Page,
    ResourcesCardSet as CmsResourcesCardSet,
} from '../../../generator/generated/MeshInterfaces';
import ResourceCard from './ResourceCard';
import TagSearchBox from '../../components/Inputs/TagSearchBox';
import Store from '../../core/Store';
import { Tag } from './Tag';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';

interface ResourceCardSetInterface extends CmsResourcesCardSet {
    store: Store;
}

function ResourceDetails(props: ResourceCardSetInterface) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [filterTags, setFilterTags] = useState<any>([]);
    const siteConfig = props.store.siteConfiguration;

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.name);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [siteConfig, setBreadcrumbData]);

    const addTags = (x: string) => {
        setFilterTags([...filterTags, x]);
    };

    const removeTag = (x: string) => {
        setFilterTags(filterTags.filter((t: any) => t !== x));
    };

    const handleClear = () => {
        setFilterTags([]);
    };

    const renderCards = () => {
        if (props.store.siteConfiguration) {
            const resourceConfig = props.store.siteConfiguration.Resources;
            const resources = Object.keys(resourceConfig)
                .filter((key) => {
                    return Object.prototype.hasOwnProperty.call(resourceConfig[key], 'schema');
                })
                .map((key) => {
                    return resourceConfig[key];
                });

            if (props.sort === 'CREATED') {
                resources.sort((a, b) => {
                    const momentA = moment(a.created);
                    const momentB = moment(b.created);

                    if (momentA.isBefore(momentB)) {
                        return 1;
                    }

                    if (momentA.isAfter(momentB)) {
                        return -1;
                    }

                    return 0;
                });
            } else if (props.sort === 'ORDER') {
                resources.sort((a, b) => {
                    if (a.order != null) {
                        if (b.order != null) {
                            return a.order - b.order;
                        }
                        return -1;
                    }
                    return 1;
                });
            }

            if (props.searchTags && filterTags.length !== 0) {
                return resources.map((resource) => {
                    if (resource.tags.find((t: Tag) => filterTags.includes(t.name))) {
                        return (
                            <Column key={resource.title} md={4}>
                                <ResourceCard {...resource} />
                            </Column>
                        );
                    }
                });
            }

            return resources.map((resource) => {
                return (
                    <Column key={resource.title} md={4}>
                        <ResourceCard {...resource} />
                    </Column>
                );
            });
        }

        return <div>Test</div>;
    };

    const resourceConfig = props.store.siteConfiguration && props.store.siteConfiguration.Resources;
    const resources = Object.keys(resourceConfig)
        .filter((key) => {
            return Object.prototype.hasOwnProperty.call(resourceConfig[key], 'schema');
        })
        .map((key) => {
            return resourceConfig[key];
        });

    const resourceTags = resources.map((r) => r.tags).filter((r) => r[0] !== undefined);
    const allTags = resourceTags.filter((y) => y.length > 0);
    const resourceTagNames: string[] = [];
    allTags.forEach((obj) => {
        obj.map((tag: Tag) => {
            tag.name && resourceTagNames.push(tag.name);
        });
    });
    const items = Array.from(new Set(resourceTagNames));

    if (props.searchTags) {
        return (
            <>
                <TagSearchBox
                    items={items}
                    onClear={handleClear}
                    filterTags={filterTags}
                    addToFilteredTag={addTags}
                    removeTag={removeTag}
                />
                <Row style={{ padding: '20px' }}>{renderCards()}</Row>
            </>
        );
    }
    return <Row>{renderCards()}</Row>;
}

export default ResourceDetails;
