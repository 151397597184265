import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { PatientDetailSection } from '../../../generator/generated/MeshInterfaces';

export const getProvidersSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Providers',
        items: {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                    title: 'Name',
                },
                specialty: {
                    type: 'string',
                    title: 'Specialty',
                },
                address: {
                    type: 'string',
                    title: 'Address',
                },
                contactNumber: {
                    type: 'string',
                    title: 'Contact Number',
                },
            },
        },
    };
};

export const getProvidersSectionUISchema = (sectionProps: PatientDetailSection): UiSchema => {
    const emptyListMessage = {
        title: 'No Providers Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'providers',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
            itemsPerPage: sectionProps.itemsPerPage as number,
        },
        items: {
            name: {
                'ui:widget': 'SimpleTextWidget',
            },
            specialty: {
                'ui:widget': 'SimpleTextWidget',
            },
            address: {
                'ui:widget': 'SimpleTextWidget',
            },
            contactNumber: {
                'ui:widget': 'SimpleTextWidget',
            },
        },
    };
};
