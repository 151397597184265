import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CardWithImage as UiCardWithImage } from '@amxjs/ui';

import { CardWithImage as MeshCardWithImage } from '../../../generator/generated/MeshInterfaces';
import { prependAppBaseName, openLink } from '../../util/LinkUtils';

interface CardWithImageProps extends MeshCardWithImage {
    link?: string;
    onClick?: Function;
}

const CardWithImage = (props: CardWithImageProps & RouteComponentProps) => {
    const { history, link } = props;
    return (
        <UiCardWithImage
            {...props}
            pageLink={prependAppBaseName(link)}
            onClick={() => openLink(link, history)}
        />
    );
};

CardWithImage.defaultProps = {
    link: undefined,
    onClick: undefined,
};

export default withRouter(CardWithImage);
