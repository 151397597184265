import { showToast } from '@amxjs/ui';
import { Intent } from '@blueprintjs/core';
import React from 'react';
import _ from 'lodash';
import { ErrorMessage } from '../../../server/utils/edits/Edits';

// This holds some logic for some generic toast messages

export const showToastError = (
    errorTitle?: string,
    errorMessage?: string,
    // Holds any additional information we'd like to print in the toast. This shows as a new line in the toast message.
    additionalContext?: any[]
) => {
    showToast({
        message: (
            <div>
                <p style={{ fontWeight: 'bold' }}>{errorTitle || 'Unknown Error'}</p>
                <p>
                    {errorMessage ||
                        'Oops! Looks like something went wrong and your action(s) could not be completed. Please try again later.'}
                    {_.isArray(additionalContext)
                        ? additionalContext.map((context) => {
                              // Remove the double quotes from the value
                              // eslint-disable-next-line react/jsx-key
                              return <p>{`${JSON.stringify(context)}`.replace(/"/g, '')}</p>;
                          })
                        : undefined}
                </p>
            </div>
        ),
        intent: Intent.DANGER,
        icon: 'error',
    });
};

export const showToastErrors = (errorMessages: ErrorMessage[] | undefined, errorTitle?: string) => {
    if (errorMessages) {
        errorMessages.forEach((errorMessage) => {
            showToastError(errorTitle, `${errorMessage.message}`, errorMessage.additionalContext);
        });
    } else {
        showToastError(errorTitle, undefined);
    }
};

export const showNoChangesToast = (noChangesMessageTitle?: string, noChangesMessage?: string) => {
    showToast({
        message: (
            <div>
                <p style={{ fontWeight: 'bold' }}>
                    {noChangesMessageTitle || 'No changes to save'}
                </p>
                <p>{noChangesMessage || 'There were no changes to save for this record'}</p>
            </div>
        ),
        intent: Intent.SUCCESS,
        icon: 'tick-circle',
    });
};

export const showSuccessfulToast = (successTitle?: string, successBody?: string) => {
    showToast({
        message: (
            <p>
                <span style={{ fontWeight: 'bold' }}>{successTitle || 'Success'}</span>
                <br />
                <br />
                <span>{successBody || 'Action(s) have completed successfully'}</span>
            </p>
        ),
        intent: Intent.SUCCESS,
        icon: 'tick-circle',
    });
};
