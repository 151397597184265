import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import MipsModuleConstants from '../MipsModuleConstants';

export const getScoringDetailsSchema = (
    title: string,
    _labels: any,
    _description?: string
): JSONSchema6 => {
    return {
        type: 'object',
        title: title || 'ScoringDetails',
        properties: {
            detailsTitle: {
                type: 'null',
                description: _description || MipsModuleConstants.ScoringDescription,
            },
            submissionStatus: { type: 'string', title: 'Submission', default: '-' },
            details: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        detailsType: {
                            type: 'string',
                            title: ' ',
                        },
                        qualityMeasures: {
                            type: 'string',
                            title: 'Quality Measures',
                        },
                        improvementActivities: {
                            type: 'string',
                            title: 'Improvement Activities',
                        },
                        promotingInteroperability: {
                            type: 'string',
                            title: 'Promoting Interoperability',
                        },
                        bonus: {
                            type: 'string',
                            title: 'Bonus',
                        },
                        total: {
                            type: 'string',
                            title: 'Total',
                        },
                    },
                },
            },
        },
    };
};

export const getScoringDetailsUISchema = (): UiSchema => {
    const emptyListMessage = {
        title: 'No Scoring Details',
        description: '',
    };

    return {
        'ui:field': 'layout',
        'ui:hash': 'scoringdetails',
        'ui:layout': [
            {
                detailsTitle: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
            {
                submissionStatus: { sm: 6, md: 6, lg: 6, xl: 6 },
            },
            {
                details: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        detailsTitle: {
            'ui:widget': 'null',
            'ui:readonly': 'true',
        },
        submissionStatus: {
            'ui:widget': 'LabelWidget',
            'ui:readonly': 'true',
            'ui:options': { inLine: true },
        },
        details: {
            'ui:field': 'list',
            'ui:options': {
                emptyListMessage,
                emptyDataListMessage: emptyListMessage,
            },
            items: {
                detailsType: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: false,
                        className: 'tableRowHeader',
                    },
                },
                qualityMeasures: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                improvementActivities: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                promotingInteroperability: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                bonus: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                total: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
            },
        },
    };
};
