import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { FieldProps, UiSchema } from 'react-jsonschema-form';
import { JSONSchema6 } from 'json-schema';

import { Intent, Callout } from '@blueprintjs/core';
import { TTableEmptyProps } from '@amxjs/ui';

import List, { ListColumnWithRenderMode, RowAction, TableAction } from '../../Tables/List';
import ListTitleField from './ListTitleField';
import CellField from './CellField';

const ListField = (props: FieldProps) => {
    const title = props.title || props.schema.title;
    const uiOptions = props.uiSchema['ui:options'] || {};
    const [rowsOfData, setRowsOfData] = useState([]);

    const onPropertyChange = useCallback(
        (value: any, name: string, rowNumber: number, onChange?: Function) => {
            const fieldOnChange: any = onChange || uiOptions.onChange;
            const newFormData = Object.assign([], props.formData);
            newFormData[rowNumber][name] = value;

            if (fieldOnChange) {
                fieldOnChange(newFormData, name, rowNumber);
            }
        },
        [props.formData, uiOptions.onChange]
    );

    useEffect(() => {
        if (
            props.formData &&
            props.schema.items &&
            typeof props.schema.items === 'object' &&
            !Array.isArray(props.schema.items) &&
            props.schema.items.properties
        ) {
            const itemProperties = props.schema.items.properties;
            const itemPropertiesKeys = Object.keys(itemProperties);
            const itemUISchemas = props.uiSchema.items || {};
            const itemErrorSchemas = props.errorSchema || {};
            const data = props.formData.map((row: any, rowNumber: number) => {
                const formattedRow = { ...row };
                const rowErrorSchemas = itemErrorSchemas[rowNumber];
                Object.entries(row).forEach(([key, value]) => {
                    if (itemPropertiesKeys.includes(key)) {
                        const itemSchema = (
                            typeof itemProperties[key] === 'boolean' ? {} : itemProperties[key]
                        ) as JSONSchema6;
                        const itemUISchema: UiSchema = itemUISchemas[key];
                        const itemErrorSchema = rowErrorSchemas?.[key];
                        const itemUIOptions = itemUISchema?.['ui:options'];
                        const itemUIReadonly = itemUISchema?.['ui:readonly'];
                        const readonly =
                            typeof itemUIReadonly === 'function'
                                ? itemUIReadonly(row)
                                : itemUIReadonly || false;
                        if (Array.isArray(value)) {
                            formattedRow[key] = value.map((arrayItemVal, index) => {
                                const id =
                                    arrayItemVal.id ||
                                    `${props.idSchema[key].$id}${rowNumber}${index}`;
                                return (
                                    <CellField
                                        {...(props as FieldProps)}
                                        key={id}
                                        id={id}
                                        schema={itemSchema}
                                        options={itemUIOptions}
                                        uiSchema={itemUISchema}
                                        errorSchema={itemErrorSchema}
                                        value={arrayItemVal.name || arrayItemVal}
                                        readonly={readonly}
                                    />
                                );
                            });
                        } else {
                            // Merge UISchema properties and specific field properties
                            let valueObject = value as Record<string, string>;
                            const id = (valueObject && valueObject.id) || props.idSchema[key].$id;

                            let customProps;
                            if (typeof value === 'object' && value !== null) {
                                if ('value' in value) {
                                    const auxVal = value as { value: any; props: any };
                                    valueObject = auxVal.value as Record<string, string>;
                                    if ('props' in auxVal && typeof auxVal.props === 'object') {
                                        customProps = auxVal.props;
                                    }
                                } else if ('name' in value) {
                                    const auxVal = value as { id: any; name: any };
                                    valueObject = (valueObject && auxVal.name) || value;
                                }
                            }
                            const itemOnChange =
                                itemUIOptions &&
                                'onChange' in itemUIOptions &&
                                typeof itemUIOptions.onChange === 'function'
                                    ? itemUIOptions.onChange
                                    : undefined;
                            const properties = {
                                ...(props as FieldProps),
                                ...(customProps as FieldProps),
                            };
                            formattedRow[key] = (
                                <CellField
                                    {...(properties as FieldProps)}
                                    key={id}
                                    id={id}
                                    schema={itemSchema}
                                    uiSchema={itemUISchema}
                                    errorSchema={itemErrorSchema}
                                    onChange={(e: any) => {
                                        onPropertyChange(e, key, rowNumber, itemOnChange);
                                    }}
                                    value={valueObject}
                                    readonly={readonly}
                                />
                            );
                        }
                    }
                });
                return formattedRow;
            });
            setRowsOfData(data);
        } else {
            setRowsOfData(props.formData || []);
        }
    }, [props, onPropertyChange]);

    const getColumns = (): ListColumnWithRenderMode[] => {
        // Right now we are only interested in a particular structure, but this can be expanded to cover other structures (i.e. items as an Array)
        if (
            props.schema.items &&
            typeof props.schema.items === 'object' &&
            !Array.isArray(props.schema.items) &&
            props.schema.items.properties
        ) {
            const itemUISchema = props.uiSchema.items || {};
            return Object.entries(props.schema.items.properties).map(
                ([key, columnSchema]): ListColumnWithRenderMode => {
                    const columnUISchema = itemUISchema[key] || {};
                    const sortable = !!get(columnUISchema, 'ui:options.orderable');
                    const initialSort = get(columnUISchema, 'ui:options.initialSort');
                    const columnControl = get(columnUISchema, 'ui:options.columnControl', false);
                    const renderMode = get(columnUISchema, 'ui:options.renderMode', null);
                    const callbackToDetermineIconAndText = get(
                        columnUISchema,
                        'ui:options.callbackToDetermineIconAndText',
                        null
                    );
                    const valueSet = get(columnUISchema, 'ui:options.valueSet', null);
                    const label =
                        columnUISchema['ui:label'] || (columnSchema as JSONSchema6).title || key;
                    const showColumn = get(columnUISchema, 'ui:options.showColumn');
                    const minWidth = get(columnUISchema, 'ui:options.minWidth');
                    const maxWidth = get(columnUISchema, 'ui:options.maxWidth');
                    const className = get(columnUISchema, 'ui:options.className');
                    const hideLabel = get(columnUISchema, 'ui:options.hideLabel');
                    const hidePopover = get(columnUISchema, 'ui:options.hidePopover');
                    return {
                        label,
                        id: key,
                        sortable: sortable ? initialSort || 'UNSET' : 'OFF',
                        columnControl,
                        renderMode,
                        callbackToDetermineIconAndText,
                        valueSet,
                        showColumn,
                        minWidth,
                        maxWidth,
                        className,
                        hideLabel,
                        hidePopover,
                    };
                }
            );
        }
        return [];
    };

    const getRowActions = (): RowAction[] => {
        return [
            {
                id: `${props.idSchema.$id}-edit`,
                label: '',
                behavior: 'Dialog',
                intent: Intent.PRIMARY,
                link: {},
                icon: 'edit',
                callbackOnDialog: uiOptions.callbackOnDialog as (event: any) => void,
            },
            {
                id: `${props.idSchema.$id}-remove`,
                label: '',
                behavior: 'Delete',
                intent: Intent.DANGER,
                link: {},
                icon: 'remove',
                callbackOnDelete: uiOptions.callbackOnDelete as (event: any) => void,
            },
        ];
    };

    return (
        <div>
            {uiOptions.callout ? (
                <Callout
                    {...(typeof uiOptions.callout === 'object' ? uiOptions.callout : null)}
                    style={{ minHeight: '40px', marginBottom: '24px' }}
                />
            ) : null}
            {title ? (
                <ListTitleField
                    id={props.name}
                    title={title}
                    required={props.required}
                    hash={props.uiSchema['ui:hash'].toLowerCase()}
                    addable={!!uiOptions.addable}
                    onAddItemClick={uiOptions.onAddItemClick as (event: any) => void}
                    addButtonLabel={uiOptions.addButtonLabel as string}
                />
            ) : null}
            <List
                title=""
                itemsPerPage={(uiOptions.itemsPerPage as number) || 10}
                columns={getColumns()}
                editing={!!uiOptions.editable || !!uiOptions.removable}
                allowExport={uiOptions.allowExport as boolean}
                rowActions={(uiOptions.rowActions as RowAction[]) || getRowActions()}
                tableActions={(uiOptions.tableActions as TableAction[]) || undefined}
                rowsOfData={rowsOfData}
                loading={uiOptions.loading as boolean}
                fieldNameForDeleteDialog={(uiOptions.fieldNameForDeleteDialog as string) || ''}
                emptyListMessage={uiOptions.emptyListMessage as TTableEmptyProps}
                emptyDataListMessage={uiOptions.emptyDataListMessage as TTableEmptyProps}
                showSearchBar={uiOptions.showSearchBar as boolean}
                stickyHeaderOffset={uiOptions.stickyHeaderOffset as number}
                unsearchableColumns={uiOptions.unsearchableColumns as string[]}
                searchPlaceholderText={uiOptions.searchPlaceholderText as string}
                showColumnControl={uiOptions.showColumnControl as boolean}
                selectAllValues={uiOptions.selectAllValues}
                onSelectAllRows={uiOptions.onSelectAllRows as () => void}
                onFilterStringChange={uiOptions.onFilterStringChange as () => void}
                columnOrder={
                    uiOptions.columnOrder ? (uiOptions.columnOrder as string[]) : undefined
                }
                showDisabledRowActions={uiOptions.showDisabledRowActions as boolean}
            />
        </div>
    );
};

export default ListField;
