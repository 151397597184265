import React, { FC } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { URL } from 'url';

import { AnchorButton, Intent, Text } from '@blueprintjs/core';

import { showToastError } from '../../../pages/utils/CommonToasts';

export interface URLWidgetProps extends WidgetProps {
    value: { text: string; url: URL };
}

const URLWidget: FC<URLWidgetProps> = (props: URLWidgetProps) => {
    return (
        <AnchorButton
            intent={Intent.PRIMARY}
            minimal
            type="button"
            title={props.value.text}
            onClick={async () => {
                const requestOptions: RequestInit = {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                    },
                };
                const response = await fetch(
                    `${window.location.origin}${props.value.url?.toString()}`,
                    requestOptions
                );
                if (!response.ok) {
                    showToastError(
                        'Error Opening File!',
                        'Looks like something went wrong and the file could not be opened. Please try again later.'
                    );
                } else {
                    window.open(
                        `${window.location.origin}${props.value.url?.toString()}`,
                        '_blank'
                    );
                }
            }}
            className={typeof props.options.className === 'string' ? props.options.className : ''}
        >
            <Text ellipsize>{props.value.text}</Text>
        </AnchorButton>
    );
};

export default URLWidget;
