import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { style } from 'typestyle';

import {
    MipsTINValidation,
    MipsTINValidationColumn,
    MipsTINValidationApproval,
    MipsTINValidationApprovalColumn,
} from '../../../../generator/generated/MeshInterfaces';

const UNSET = 'UNSET';
const ALLOWED_FILE_SIZE = 1048576 * 5; // 5MB

export const getMipsTINValidationUploadDocumentDataSchema = (): JSONSchema6 => {
    return {
        title: 'Upload Documents',
        type: 'object',
        required: [],
        properties: {
            description: {
                type: 'string',
            },
            progress: {
                type: 'string',
            },
            file: {
                type: 'string',
            },
        },
    };
};

export const getMipsTINValidationUploadDocumentUISchema = (): UiSchema => {
    return {
        'ui:field': 'layout',
        'ui:hash': 'uploadData',
        'ui:layout': [
            {
                file: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        file: {
            'ui:readonly': false,
            'ui:widget': 'FileUploadWidget',
            'ui:options': {
                accept: {
                    'all/*': ['.'],
                },
                acceptedFilesText: 'Accepted File Types: .pdf, .doc and .docx',
                maxFileSize: ALLOWED_FILE_SIZE,
            },
        },
    };
};

export const getMipsTINValidationDocumentsSchema = ({
    columns,
}: MipsTINValidation): JSONSchema6 => {
    const properties: { [k: string]: JSONSchema6Definition } = {};
    columns.forEach((column: MipsTINValidationColumn) => {
        if (column.id === 'actions') {
            properties[column.id] = {
                type: 'array',
                title: ' ',
                items: {
                    type: 'object',
                },
            };
        } else {
            properties[column.id] = {
                type: 'string',
                title: column.label,
            };
        }
    });
    return {
        title: 'Documents',
        type: 'array',
        items: {
            type: 'object',
            properties,
        },
    };
};

export const getMipsTINValidationDocumentsUISchema = ({ columns }: MipsTINValidation): UiSchema => {
    const emptyListMessage = {
        title: 'No Documents',
    };
    const getSortOrder = (columnId: string) => {
        if (columns && columns.length > 0) {
            const col = columns.find((column) => column.id === columnId);
            return col ? col.sortable : UNSET;
        }
        return UNSET;
    };
    return {
        'ui:field': 'list',
        'ui:hash': 'mipsTINValidationDocuments',
        'ui:options': {
            emptyListMessage,
        },
        items: {
            documentName: {
                'ui:options': {
                    orderable: true,
                    initialSort: getSortOrder('documentName'),
                },
            },
            practice: {
                'ui:options': {
                    orderable: true,
                    initialSort: getSortOrder('practice'),
                },
            },
            approvedBy: {
                'ui:options': {
                    orderable: true,
                    initialSort: getSortOrder('approvedBy'),
                },
            },
            dateUploaded: {
                'ui:readonly': true,
                'ui:widget': 'DateWidget',
                'ui:options': { orderable: true, renderMode: 'date' },
            },
            approvalStatus: {
                'ui:options': { orderable: true },
            },
            note: {
                'ui:options': { orderable: true },
            },
            actions: {
                'ui:widget': 'ActionButtonWidget',
                'ui:options': { orderable: false },
            },
        },
    };
};

export const getMipsTINValidationApprovalDocumentsSchema = ({
    columns,
}: MipsTINValidationApproval): JSONSchema6 => {
    const properties: { [k: string]: JSONSchema6Definition } = {};
    columns.forEach((column: MipsTINValidationApprovalColumn) => {
        if (column.id === 'actions') {
            properties[column.id] = {
                type: 'array',
                title: ' ',
                items: {
                    type: 'object',
                },
            };
        } else {
            properties[column.id] = {
                type: 'string',
                title: column.label,
            };
        }
    });
    return {
        title: 'Documents',
        type: 'array',
        items: {
            type: 'object',
            properties,
        },
    };
};

export const getMipsTINValidationApprovalDocumentsUISchema = ({
    columns,
}: MipsTINValidationApproval): UiSchema => {
    const emptyListMessage = {
        title: 'No Documents',
    };
    const getUIOptions = (columnId: string) => {
        const defaultResponse = { initialSort: UNSET };
        if (columns && columns.length > 0) {
            const col = columns.find((column) => column.id === columnId);
            return col ? { initialSort: col.sortable, maxWidth: col.maxWidth } : defaultResponse;
        }
        return defaultResponse;
    };
    return {
        'ui:field': 'list',
        'ui:hash': 'mipsTINValidationApprovalDocuments',
        'ui:options': {
            emptyListMessage,
        },
        items: {
            reportingYear: {
                'ui:options': {
                    orderable: true,
                    ...getUIOptions('reportingYear'),
                },
            },
            tin: {
                'ui:options': { orderable: true, ...getUIOptions('tin') },
            },
            practice: {
                'ui:options': {
                    orderable: true,
                    ...getUIOptions('practice'),
                },
            },
            documentName: {
                'ui:options': {
                    orderable: true,
                    ...getUIOptions('documentName'),
                },
            },
            dateUploaded: {
                'ui:readonly': true,
                'ui:widget': 'DateWidget',
                'ui:options': {
                    orderable: true,
                    renderMode: 'date',
                    ...getUIOptions('dateUploaded'),
                },
            },
            approvalStatus: {
                'ui:options': { orderable: true, ...getUIOptions('approvalStatus') },
            },
            actions: {
                'ui:widget': 'ActionButtonWidget',
                'ui:options': {
                    orderable: false,
                    className: style({
                        textAlign: 'right',
                        $nest: {
                            '&>div:first-child': {
                                padding: '0 5px',
                            },
                        },
                    }),
                    ...getUIOptions('actions'),
                },
            },
        },
    };
};
