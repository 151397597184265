import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

// Schema for the 1st IA Copy to Dialog to confirm how many records will be affected
export const getCopyIaDialogSchema = () => {
    const schema: JSONSchema6 = {
        type: 'object',
        properties: {
            copyIaDescription: {
                title: 'Code',
                type: 'string',
            },
        },
    };
    return schema;
};

export const getCopyIaDialogUiSchema = () => {
    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:layout': [
            {
                copyIaDescription: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        copyIaDescription: {
            'ui:widget': 'SimpleTextWidget',
            'ui:options': {
                style: {
                    'word-wrap': 'unset',
                    'white-space': 'normal',
                },
            },
        },
    };
    return uiSchema;
};

// ------

// Schema for the 2nd IA Copy to Dialog 'Copy Confirmation'
export const getCopyIaConfirmationDialogSchema = () => {
    const schema: JSONSchema6 = {
        type: 'object',
        properties: {
            copyIaConfirmationDescription: {
                title: 'Code',
                type: 'string',
            },
        },
    };
    return schema;
};

export const getCopyIaConfirmationDialogUiSchema = () => {
    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:layout': [
            {
                copyIaConfirmationDescription: { sm: 12, md: 12, lg: 12, xl: 12 },
            },
        ],
        copyIaConfirmationDescription: {
            'ui:widget': 'SimpleTextWidget',
            'ui:options': {
                style: {
                    'word-wrap': 'unset',
                    'white-space': 'normal',
                },
            },
        },
    };
    return uiSchema;
};

// ------
