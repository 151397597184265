/* eslint-disable global-require */
import './envVars';
import '@amxjs/ui';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import './polyfills';

if (window.SERVER_CONFIG.REACT_APP_THEME_CSS) {
    try {
        // eslint-disable-next-line import/no-dynamic-require
        require(window.SERVER_CONFIG.REACT_APP_THEME_CSS);
    } catch (ex) {
        console.warn(`Cannot load custom theme: ${window.SERVER_CONFIG.REACT_APP_THEME_CSS}`);
    }
}

const loadScript = (src: string) => {
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName('body')[0].appendChild(tag);
};
loadScript(`${window.SERVER_CONFIG.REACT_APP_TABLEAU_DOMAIN}/javascripts/api/tableau-2.2.2.min.js`);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
