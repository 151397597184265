import React, { PropsWithChildren } from 'react';
import { getDisplayName } from '../util/ReactUtils';

const SchemaContext = React.createContext<string | null | undefined>(null);

interface SchemaProviderProps {
    schemaId?: string;
}

export const SchemaProvider = ({ children, schemaId }: PropsWithChildren<SchemaProviderProps>) => {
    return <SchemaContext.Provider value={schemaId}>{children}</SchemaContext.Provider>;
};

export const useSchema = () => React.useContext(SchemaContext);

/* HOC to inject store to any functional or class component */
export const withSchema = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const ComponentWithSchema = (props: P) => {
        return <WrappedComponent {...props} schemaId={useSchema()} />;
    };

    ComponentWithSchema.displayName = `withSchema(${getDisplayName(WrappedComponent)})`;
    return ComponentWithSchema;
};
