import React, { useState, useEffect } from 'react';
import { JSONSchema6 } from 'json-schema';
import { Dialog } from '@blueprintjs/core';

import DialogForm from '../../components/forms/DialogForm';
import DialogFormActions from '../../components/forms/components/DialogFormActions';
import LabelWidget from '../../components/forms/widgets/LabelWidget';
import { SubmissionErrorDialogInterface } from '../../../server/routes/Mips/MipsApi';
import ErrorLabelWidget from '../../components/forms/widgets/ErrorLabelWidget';
import Heading from '../heading/Heading';

interface SubmissionErrorProps {
    isOpen: boolean;
    handleClose: () => void;
    handleOkay: () => void;
    errors: SubmissionErrorDialogInterface[];
}

export default function SubmissionErrorDialog(props: SubmissionErrorProps) {
    const [formData, setFormData] = useState<SubmissionErrorDialogInterface>();

    useEffect(() => {
        if (props.errors) {
            setFormData(props.errors[0]);
        }
    }, [props.errors]);

    const getSchema = () => {
        const schema: JSONSchema6 = {
            type: 'object',
            properties: {
                clinician: {
                    title: 'Clinician',
                    type: 'string',
                },
                npi: {
                    title: 'NPI',
                    type: 'string',
                },
                errors: {
                    title: 'CMS Message',
                    type: 'string',
                },
            },
        };
        return schema;
    };

    const getUiSchema = () => {
        const uiSchema: any = {
            'ui:field': 'layout',
            'ui:layout': [
                {
                    clinician: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
                {
                    npi: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
                {
                    errors: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
            ],
            'ui:options': {
                maxRowWidth: '624px',
            },
            clinician: {
                'ui:widget': 'ErrorLabelWidget',
            },
            npi: {
                'ui:widget': 'ErrorLabelWidget',
            },
            errors: {
                'ui:widget': 'LabelWidget',
                'ui:options': {
                    overflowBreakWord: true,
                },
            },
        };
        return uiSchema;
    };

    return props.isOpen ? (
        <Dialog onClose={props.handleClose} isOpen={props.isOpen} style={{ padding: '40px' }}>
            <div>
                <Heading alignment="left" text="Submission Errors" />
                <DialogForm
                    schema={getSchema()}
                    formData={formData}
                    uiSchema={getUiSchema()}
                    onSubmit={props.handleOkay}
                    widgets={{
                        LabelWidget,
                        ErrorLabelWidget,
                    }}
                    minHeight="132px"
                    minWidth="624px"
                >
                    <DialogFormActions
                        onCancel={props.handleClose}
                        cancelButtonText="Done"
                        formHasChange={false}
                        disabled={false}
                        isAddButtonDisabled
                        addButtonText=""
                        actionClassStyle
                    />
                </DialogForm>
            </div>
        </Dialog>
    ) : null;
}
