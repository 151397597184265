import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import {
    PatientDetailClinicalIds,
    PatientDetailConditions,
    PatientDetailEncounters,
    PatientDetailLaboratoryResults,
    PatientDetailMedications,
    PatientDetailPharmacies,
    PatientDetailProcedures,
    PatientDetailProviders,
} from '../../../generator/generated/MeshInterfaces';

export const isEncounterSection = (p: any): p is PatientDetailEncounters => !!p.encounterPageLink;

export const getSchemaForSection = (
    section:
        | PatientDetailClinicalIds
        | PatientDetailEncounters
        | PatientDetailConditions
        | PatientDetailMedications
        | PatientDetailLaboratoryResults
        | PatientDetailPharmacies
        | PatientDetailProcedures
        | PatientDetailProviders
): JSONSchema6 => {
    const properties: Record<string, JSONSchema6> = {};

    section.columns.forEach((column: { id: string; label: string }) => {
        properties[column.id] = { type: 'string', title: column.label };
    });

    const sectionSchema: JSONSchema6 = {
        type: 'array',
        title: section.title,
        items: {
            type: 'object',
            properties,
        },
    };

    return sectionSchema;
};

export const getUISchemaForSection = (
    section:
        | PatientDetailClinicalIds
        | PatientDetailEncounters
        | PatientDetailConditions
        | PatientDetailMedications
        | PatientDetailLaboratoryResults
        | PatientDetailPharmacies
        | PatientDetailProcedures
        | PatientDetailProviders
): UiSchema => {
    const items: Record<string, UiSchema> = {};
    const emptyListMessage = {
        title: `No ${section.title} Found`,
        description: '',
    };

    section.columns.forEach(
        (column: { id: string; label: string; sortable?: string; renderMode?: string }) => {
            const dateFields = ['startDate', 'endDate', 'date'];
            items[column.id] = {
                'ui:widget': 'SimpleTextWidget',
                'ui:readonly': true,
                'ui:options': {
                    orderable: !(!column.sortable || column.sortable === 'OFF'),
                    renderMode: dateFields.includes(column.id) ? 'date' : 'default',
                },
            };
        }
    );

    const schema: UiSchema = {
        'ui:field': 'list',
        'ui:hash': section.sectionType.toLowerCase(),
        'ui:options': {
            itemsPerPage: section.itemsPerPage || 5,
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items,
    };

    if (isEncounterSection(section) && schema['ui:options']) {
        schema['ui:options'].editable = true;
        schema['ui:options'].rowActions = [
            {
                id: 'encounter-view',
                label: 'View',
                behavior: 'Navigate',
                intent: 'primary',
                link: section.encounterPageLink,
                idName: 'id',
            },
        ];
    }

    return schema;
};
