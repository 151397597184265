import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getGeneralUISchema = (
    editMode: boolean,
    createMode: boolean,
    isLoggedInUserHasAccessToAllManagingEntities: boolean
): UiSchema => {
    const readOnlyFields = !(editMode || createMode);

    const uiSchema: UiSchema = {
        'ui:field': 'layout',
        'ui:hash': 'general',
        'ui:layout': [
            {
                firstName: { sm: 12, md: 6, lg: 4, xl: 3 },
                middleName: { sm: 12, md: 6, lg: 4, xl: 3 },
                lastName: { sm: 12, md: 6, lg: 4, xl: 3 },
                suffix: { sm: 12, md: 2, lg: 2, xl: 2 },
            },
            {
                userName: { sm: 12, md: 6, lg: 4, xl: 3 },
                primaryEmail: { sm: 12, md: 6, lg: 4, xl: 3 },
                phone: { sm: 12, md: 6, lg: 4, xl: 3 },
            },
        ],
        firstName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        middleName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        lastName: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        suffix: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
        },
        userName: {
            'ui:widget': createMode ? 'text' : 'LabelWidget',
            'ui:options': {
                note: createMode ? 'Username cannot be changed after user record is created' : null,
            },
        },
        phone: {
            'ui:readonly': readOnlyFields,
            'ui:widget': readOnlyFields ? 'LabelWidget' : undefined,
            'ui:options': {
                mask: '(999)999-9999',
            },
        },
        primaryEmail: {
            'ui:widget': readOnlyFields ? 'LabelWidget' : 'email',
            'ui:readonly': readOnlyFields,
        },
    };

    if (!createMode) {
        uiSchema['ui:layout'] = [
            ...uiSchema['ui:layout'],
            {
                userStatus: { md: 4 },
            },
        ];

        uiSchema.userStatus = {
            'ui:readonly': isLoggedInUserHasAccessToAllManagingEntities ? readOnlyFields : true,
            'ui:widget': readOnlyFields ? 'LabelWidget' : 'SwitchWidget',
            'ui:options': {
                tooltip: isLoggedInUserHasAccessToAllManagingEntities
                    ? ''
                    : "You don't have access to all of the organizations this user has. Please reach out to your Registry Administrator to resolve",
            },
        };
    }

    return uiSchema;
};

export const getGeneralSchema = (title: string): JSONSchema6 => {
    const schema: JSONSchema6 = {
        title,
        type: 'object',
        required: ['firstName', 'lastName', 'primaryEmail', 'userName'],
        properties: {
            userStatus: { type: 'boolean', title: 'Status', default: true },
            id: { type: 'string', title: 'ID' },
            firstName: { type: 'string', title: 'First Name' },
            middleName: { type: 'string', title: 'Middle Name' },
            lastName: { type: 'string', title: 'Last Name' },
            userName: { type: 'string', title: 'Username' },
            suffix: { type: 'string', title: 'Suffix' },
            phone: { type: 'string', title: 'Phone Number', maxLength: 13, minLength: 13 },
            primaryEmail: { type: 'string', title: 'Email Address' },
            createdDate: { type: 'string', title: 'Created Date' },
            lastUpdated: { type: 'string', title: 'Last Updated Date' },
        },
    };

    return schema;
};
