import { observable, action, computed, autorun, toJS } from 'mobx';
import React, { PropsWithChildren, ReactElement } from 'react';

import SurveyItemInterface from '../../models/SurveyResponse';
import { PromotingInteroperabilityScore } from '../../models/Mips';
import { getDisplayName } from '../util/ReactUtils';
import { MultiSelectWidgetEnum } from '../components/forms/widgets/MultiSelectWidget';

const UNRESTRICTED_ACCESS_ADMIN_ROLES = ['registry-admin', 'di-specialist'];

interface SiteConfigurationInterface {
    Pages: any;
    Resources: any;
    HeaderConfig: any;
    FooterConfig: any;
    Images: any;
    Configuration: any;
}

export interface TooltipValue {
    value: any;
    tooltip: any;
}

export interface Preferences {
    tin: string;
    tinName: string;
    npi?: string;
    clinicianName?: string;
    participationOption?: string;
    participatingInQualityMeasures: boolean;
    participatingInImprovementActivities: boolean;
    participatingInPromotingInteroperability: boolean;
    optInStatus?: string;
    optInDecision?: string;
    cehrtId: string;
    emailAddress?: string;
    consentGiven: boolean;
    performanceCategoryParticipation?: any;
}
export interface ScoringDetails {
    submissionStatus: any;
    errors?: ScoringDetailsErrors;
    qualityMeasureWeightedScore: string;
    improvementActivityWeightedScore: string;
    promotingInteroperabilityWeightedScore: string;
    bonusScore: string;
    totalScore: string;
    qualityMeasureCategoryScore: string;
    qualityMeasureCategoryScoreDenominator: string;
    improvementActivityCategoryScore: string;
    promotingInteroperabilityCategoryScore: string;
    details?: any[];
    lastSubmissionDate?: string;
}

export interface Error {
    errorMessage?: string;
    diagnosticsInformation?: string;
    severity?: string;
}

export interface ScoringDetailsErrors {
    clinicianName?: string;
    npi?: string;
    errors?: Error[];
}
export interface QualityMeasureScore {
    scoreMeasureReportFhirId: string;
    measureId: string;
    measureTitle: string;
    dataCompleteness: string;
    performanceRate: string;
    score: ReactElement | string;
    decile: ReactElement | string;
    startDate: string;
    endDate: string;
    measureIsExcluded: boolean;
    ecqmMeasureId?: string;
    endToEndFlag: boolean;
    metricType: string;
    measureType: string;
    inverse: boolean;
    dataCompletenessNumerator: number;
    dataCompletenessDenominator: number;
    performanceRateNumerator: number;
    performanceRateDenominator: number;
    eligiblePopulation?: number;
    performanceMet?: number;
    performanceNotMet?: number;
    eligiblePopulationException: number;
    eligiblePopulationExclusion: number;
    view?: any;
    stratum?: Strata[];

    observationInstances?: number;
    description: string;
    overallAlgorithm?: string; // Only for multi performance rates & registry multi performance rates
}

export interface ImprovementActivityScore {
    scoreMeasureReportFhirId: string;
    measureId: string;
    measureTitle: string;
    subcategory: string;
    weight: string;
    score: string;
    startDate: string;
    endDate: string;
    metricType: string;
    measureType: string;
    description: string;
    view?: any;
    copyImprovementActivities?: MultiSelectWidgetEnum[];
}

export interface ImprovementActivityMeasureDefinitions {
    measureId: string; // In the form of ia_be_12
    title: string; // The title of the measure, for example: "Use evidence-based decision aids to support shared decision-making."
    subcategory: string; // Example: "beneficiaryEngagement"
    weight: string; // Example: "medium"
    // The description does not always match, in some cases it does.
    description: string; // Example: "Use evidence-based decision aids to support shared decision-making."
}

export interface PractitionerInformation {
    practitionerName: string;
    practitionerFhirId: string; // In the form of 'Practitioner/{id}'
    iaMeasureIds: string[]; // In the form of IA_BE_12
    preventCopyingIaMeasuresDueToParticipation?: boolean; // True if the participation is not 'participation-both' or 'participation-individual'
}

export interface GroupInformation {
    iaMeasureIds: string[]; // In the form of IA_BE_12
}
export interface MipsManageRecordListOutput {
    organizationFhirId: string;
    reportingYear: string;
    organizationAffiliationFhirId?: string;
    providerPractitionerFhirId?: string;
    mipsPractitionerRoleFhirId?: string;
    consentRecordFhirId?: string;
    preferences: Preferences;
    scoringDetails?: ScoringDetails;
    qualityMeasureCategoryScores?: QualityMeasureScore[];
    improvementActivityScores?: ImprovementActivityScore[];
    promotingInteroperabilityScores?: PromotingInteroperabilityScore[];
    improvementActivityMeasureDefinitions?: ImprovementActivityMeasureDefinitions[];
    practitioners?: PractitionerInformation[];
    groupInformation?: GroupInformation;
}

export interface Strata {
    strataName: TooltipValue;
    dataCompletenessStrata: TooltipValue;
    performanceRateStrata: TooltipValue;
    eligiblePopulation: number;
    performanceMet: number;
    performanceNotMet: number;
    eligiblePopulationException: number;
    eligiblePopulationExclusion: number;
}

function autoSave(store: any, save: any) {
    let firstRun = true;
    autorun(() => {
        // This code will run every time any observable property
        // on the store is updated.
        const json = JSON.stringify(toJS(store));
        if (!firstRun) {
            save(json);
        }
        firstRun = false;
    });
}

class Store {
    siteConfigurations: { [project: string]: any } = {};

    public currentProject: string = '';

    @observable email = '';

    @observable keycloakSessionId = '';

    @observable name = '';

    @observable username = '';

    @observable userid = '';

    @observable siteConfiguration?: SiteConfigurationInterface;

    @observable siteLanguage: string = localStorage.getItem('site-language') || 'en';

    @observable surveyList: SurveyItemInterface[] = [];

    @observable personaList: string[] = [];

    @observable ldapUser: boolean = false;

    @observable mipsYearsList: number[] = [];

    @observable mipsManageRecordList?: MipsManageRecordListOutput;

    @observable connected: {
        tableau: boolean;
        mesh: boolean;
        keycloak: boolean;
        database: boolean;
    } = { tableau: true, mesh: true, keycloak: true, database: true };

    constructor() {
        this.load();
        autoSave(this, this.save.bind(this));
    }

    load() {
        const raw = sessionStorage.getItem('mobx-state');

        if (raw) {
            const data = JSON.parse(raw);

            if (data) {
                // TODO: Make this cover all of the observables.
                this.email = data.email;
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    save(json: string) {
        sessionStorage.setItem('mobx-state', json);
    }

    @computed get language() {
        return this.siteLanguage;
    }

    @action setLanguage = (language: string) => {
        this.siteLanguage = language;
        localStorage.setItem('site-language', language);
    };

    @action addSiteConfiguration(project: string, configuration: any) {
        this.siteConfigurations[project] = configuration;
        this.siteConfiguration = configuration;
        this.currentProject = project;
    }

    @action setSiteConfiguration(project: string) {
        this.siteConfiguration = this.siteConfigurations[project];
        this.currentProject = project;
        this.mipsYearsList =
            this.siteConfiguration?.Pages?.MIPSExplorer?.MIPSExplorer?.Years?.map(
                (y: number) => y
            ) || [];
    }

    @action setLoginEmail(email: string) {
        this.email = email;
    }

    @action setName(name: any) {
        this.name = name;
    }

    @action setUserName(username: string) {
        this.username = username;
    }

    @action setUserId(userid: any) {
        this.userid = userid;
    }

    @computed get surveys() {
        return this.surveyList;
    }

    @action setSurveys(list: SurveyItemInterface[]) {
        this.surveyList = list;
    }

    @action setPersonas(list: string[]) {
        this.personaList = list;
    }

    @action setLdapUser(ldapUser: boolean) {
        this.ldapUser = ldapUser;
    }

    @action setConnected = (status: {
        tableau: boolean;
        mesh: boolean;
        keycloak: boolean;
        database: boolean;
    }) => Object.assign(this.connected, status);

    // This is used for some cursory checks on what APIs to call to retrieve data. This should never be used as the
    // only way to secure data.
    @computed get HasUnrestrictedAccess(): boolean {
        return (
            this.ldapUser ||
            this.personaList.some((x) => UNRESTRICTED_ACCESS_ADMIN_ROLES.includes(x))
        );
    }

    @computed get MipsManageRecordList() {
        return this.mipsManageRecordList;
    }

    @computed get MipsYearList() {
        return this.mipsYearsList;
    }

    @action setMipsManageRecordList(list: MipsManageRecordListOutput) {
        this.mipsManageRecordList = list;
    }
}

export default Store;

const StoreContext = React.createContext(new Store());

interface StoreProviderProps {
    store: Store;
}

export const StoreProvider = ({ children, store }: PropsWithChildren<StoreProviderProps>) => {
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const ComponentWithStore = (props: P) => {
        return <WrappedComponent {...props} store={useStore()} />;
    };

    ComponentWithStore.displayName = `withStore(${getDisplayName(WrappedComponent)})`;
    return ComponentWithStore;
};
