import React, { FC } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { Button } from '@blueprintjs/core';

import { CustomButton } from '../components/FormActions';

export interface ActionButtonWidgetProps extends WidgetProps {
    value: CustomButton;
}

const ActionButtonWidget: FC<ActionButtonWidgetProps> = (
    props: ActionButtonWidgetProps
): JSX.Element => {
    const renderCustomButton = () => {
        const customButton = props.value;
        return (
            customButton && (
                <>
                    <Button
                        icon={customButton.icon}
                        onClick={customButton.onClick}
                        intent={customButton.intent}
                        disabled={customButton.disabled}
                        minimal={customButton.minimal}
                    >
                        {customButton.label}
                    </Button>
                </>
            )
        );
    };
    return renderCustomButton();
};

export default ActionButtonWidget;
