import React from 'react';

import { NumberInput } from '@amxjs/ui';
import { IconName } from '@blueprintjs/core';
import { runInAction, observable } from 'mobx';
import uuid from 'uuid';

import { observer } from 'mobx-react';

// import { WidgetProps } from '@rjsf/core';
import { WidgetProps } from 'react-jsonschema-form';

@observer
class UpDownWidget extends React.Component<WidgetProps> {
    @observable
    $value: number | null = null;

    @observable
    $min = -3;

    @observable
    $max = 19;

    id: any;

    enumOptions: any;

    decimalPlaces: number = 0;

    constructor(props: any) {
        super(props);

        // const { options, multiple, value } = this.props;  // 2.0.0+
        const { options, value } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        // const emptyValue = multiple ? [] : '';  // 2.0.0+
        const emptyValue = '';

        this.$value = typeof value === 'undefined' ? emptyValue : value;

        this.id = uuid.v4();

        const valueString = value.toString();
        if (valueString.indexOf('.') !== -1) {
            this.decimalPlaces = valueString.length - valueString.indexOf('.') - 1;
        }
    }

    convertOptions = (iOptions: any): any => {
        if (iOptions) {
            return iOptions.map((item: any) => {
                return {
                    value: item.value,
                    label: item.label,
                    info: {
                        iconName: 'info-sign' as IconName,
                        message: 'Info',
                    },
                };
            });
        }

        return null;
    };

    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: this.convertOptions(props.options.enumOptions),
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,

            schema: props.schema,

            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
        };
    }

    render() {
        const props = this.convertProps(this.props);

        return (
            <div style={{ margin: '10px' }}>
                <NumberInput
                    value={this.$value}
                    onChange={(v) => {
                        runInAction(() => {
                            this.$value = v;
                            props.onChange(v);
                        });
                    }}
                    placeholder="Enter num"
                    maxDecimalPlaces={this.decimalPlaces}
                />
            </div>
        );
    }
}

export default UpDownWidget;
