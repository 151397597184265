export function validatePersona(persona: { id: string; name: string }) {
    if (!persona.id) return false;
    return true;
}

export function validateDataAccess(dataAccess: string[]) {
    if (!dataAccess || dataAccess.length === 0) {
        return false;
    }
    return true;
}
