import React, { Component, ErrorInfo } from 'react';
import Store from '../../core/Store';
import { DatabaseError, GenericError, KeycloakError, MeshError } from './Errors';

interface ErrorProps {
    store: Store;
    children: JSX.Element;
}
class AppErrorBoundary extends Component<ErrorProps> {
    state: { hasError: boolean } = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.error(error);
        console.info(info);
    }

    render() {
        const { database, keycloak, mesh } = this.props.store.connected;
        if (!keycloak) {
            return <KeycloakError />;
        }
        if (!database) {
            return <DatabaseError />;
        }
        if (!mesh) {
            return <MeshError />;
        }
        if (this.state.hasError) {
            return <GenericError />;
        }
        return this.props.children;
    }
}

export default AppErrorBoundary;
