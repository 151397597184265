import { JSONSchema6 } from 'json-schema';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { UiSchema } from 'react-jsonschema-form';
import { RouteComponentProps, withRouter } from 'react-router';
import { EncounterDetails, Page } from '../../../generator/generated/MeshInterfaces';
import { EncounterDetailInterface } from '../../../server/routes/Encounter/EncounterInterface';
import FormHeader from '../../components/forms/components/FormHeader';
import Form from '../../components/forms/Form';
import Api from '../../core/Api';
import Store from '../../core/Store';
import { SchemaProvider } from '../../mesh/Schema';
import { GetGeneralInfoSchema, GetGeneralInfoUISchema } from './GeneralInfo';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { GetAllCodesSchema, GetAllCodesUISchema } from './AllCodes';

interface RecordInfoType {
    id: string | JSX.Element;
    name: string;
}

interface EncounterDetailProps extends EncounterDetails, RouteComponentProps {
    /* eslint-disable-next-line react/no-unused-prop-types */
    store: Store;
}

function EncounterDetail(props: EncounterDetailProps) {
    const [recordInfo, setRecordInfo] = useState<RecordInfoType>({
        id: '',
        name: '',
    });
    const [formData, setFormData] = useState<EncounterDetailInterface>({});
    const { id } = queryString.parse(props.location.search);
    const { setBreadcrumbData } = useBreadcrumbContext();
    const siteConfig = props.store.siteConfiguration;

    useEffect(() => {
        Api.encounter.getEncounterDetail(id as string).then((response) => {
            const rawData = response.data as EncounterDetailInterface;

            const encounterId = rawData.General ? rawData.General.encounterId : null;
            const numberOfCodes = rawData.AllCodes ? rawData.AllCodes.length : 0;
            const family = rawData.General ? rawData.General.patient.familyName : null;
            const given = rawData.General ? rawData.General.patient.given : null;
            const mrn = rawData.General ? rawData.General.mrn : null;
            const startDate = rawData.General
                ? new Date(rawData.General.startDate).toLocaleDateString('en-US')
                : null;
            const patientId = rawData.General ? rawData.General.patient.id : null;

            setFormData(rawData);
            setRecordInfo({
                name: `${family}, ${given ? given.join(' ') : ''}`,
                id: <div>MRN: {mrn}</div>,
            });

            const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

            if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
                props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                    breadcrumbTrail.labels.push(crumb.name);
                    breadcrumbTrail.routes.push(crumb.route || null);
                });
            }

            breadcrumbTrail.labels.push(`${given}, ${family}`);
            breadcrumbTrail.routes.push(
                `${
                    siteConfig &&
                    siteConfig.Pages.PatientDetails &&
                    siteConfig.Pages.PatientDetails.route
                }?id=${patientId}`
            );
            breadcrumbTrail.labels.push(`${encounterId} (${numberOfCodes}), ${startDate}`);

            setBreadcrumbData({
                crumbLabels: breadcrumbTrail.labels,
                crumbRoutes: breadcrumbTrail.routes,
            });
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    const getSidebarElements = () => {
        return [
            { title: 'Encounter Details', hash: 'general' },
            { title: 'Codes', hash: 'allcodes' },
        ];
    };

    const getSchema = (): JSONSchema6 => {
        return {
            type: 'object',
            properties: {
                General: GetGeneralInfoSchema('Encounter Details'),
                AllCodes: GetAllCodesSchema('Codes'),
            },
        };
    };

    const getUISchema = (): UiSchema => {
        return {
            General: GetGeneralInfoUISchema(),
            AllCodes: GetAllCodesUISchema(),
        };
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <Form
                recordId={recordInfo.id}
                recordName={recordInfo.name}
                sidebarElements={getSidebarElements()}
                schema={getSchema()}
                uiSchema={getUISchema()}
                formData={formData}
            >
                <FormHeader
                    title={props.name || 'Encounter Details'}
                    editMode={false}
                    allowPrinting={!!props.allowExport}
                    exportButtonLabel={props.exportButtonLabel}
                />
            </Form>
        </SchemaProvider>
    );
}

export default withRouter(EncounterDetail);
