import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getConditionsSectionSchema = (title: string): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Conditions',
        items: {
            type: 'object',
            properties: {
                status: {
                    type: 'string',
                    title: 'Status',
                },
                condition: {
                    type: 'string',
                    title: 'Condition',
                },
                severity: {
                    type: 'string',
                    title: 'Severity',
                },
                startDate: {
                    type: 'string',
                    title: 'Start Date',
                },
                endDate: {
                    type: 'string',
                    title: 'End Date',
                },
                notes: {
                    type: 'string',
                    title: 'Notes',
                },
            },
        },
    };
};

export const getConditionsSectionUISchema = (): UiSchema => {
    const emptyListMessage = {
        title: 'No Conditions Found',
        description: '',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'conditions',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items: {
            status: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            condition: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            severity: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            startDate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            endDate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            notes: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
        },
    };
};
