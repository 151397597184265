import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getPromoteInteroperabilitySchema = (
    title: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _labels: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _description?: string
): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'PromoteInteroperability',
        items: {
            type: 'object',
            properties: {
                measureId: {
                    type: 'string',
                    title: 'Measure ID',
                },
                measureTitle: {
                    type: 'string',
                    title: 'Title',
                },
                objective: {
                    type: 'string',
                    title: 'Objective',
                },
                performanceRate: {
                    type: 'string',
                    title: 'Performance Rate',
                },
                score: {
                    type: 'string',
                    title: 'Score',
                },
                startDate: {
                    type: 'string',
                    title: 'Start Date',
                },
                endDate: {
                    type: 'string',
                    title: 'End Date',
                },
                view: {
                    type: 'string',
                    title: ' ',
                },
            },
        },
    };
};

export const getPromoteInteroperabilityUISchema =
    (labels: any, attestCb: () => void) =>
    (editMode: boolean): UiSchema => {
        const emptyListMessage = {
            title: labels?.PromoteInteroperability?.emptyListMessage || 'No Data Available',
        };

        return {
            'ui:field': 'list',
            'ui:hash': 'promotinginteroperabilityscores',
            'ui:options': {
                emptyListMessage,
                emptyDataListMessage: emptyListMessage,
                addable: editMode,
                addButtonLabel: 'Attest to Promoting Interoperability',
                onAddItemClick: () => attestCb(),
            },
            items: {
                measureId: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                measureTitle: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                objective: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                performanceRate: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                score: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                startDate: {
                    'ui:readonly': true,
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                endDate: {
                    'ui:readonly': true,
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: true,
                    },
                },
                view: {
                    'ui:widget': 'SimpleTextWidget',
                    'ui:options': {
                        orderable: false,
                    },
                },
            },
        };
    };
