import React, { Component, ErrorInfo } from 'react';
import { Router } from 'react-router-dom';
import { observer } from 'mobx-react';
import Keycloak from 'keycloak-js';
import { setKeycloak } from '@amxjs/api/client';

import ReactGA from 'react-ga';
import { createBrowserHistory, History } from 'history';
import Main from './pages/Main';
import MeshConfigurationService from './mesh/MeshConfigurationService';
import Store, { StoreProvider } from './core/Store';
import SessionTimer from './pages/utils/SessionTimer';
import AppErrorBoundary from './pages/error/AppErrorBoundary';
import { KeycloakError } from './pages/error/Errors';
import { prependAppBaseName } from './util/LinkUtils';
import getPersonas from '../util/getPersonas';

@observer
class App extends Component {
    appStore: Store;

    state: any;

    meshConfigurationService: MeshConfigurationService;

    history: History;

    constructor(props: any) {
        super(props);

        this.appStore = new Store();
        this.state = { isPublic: false, keycloak: {}, authenticated: true };

        this.meshConfigurationService = new MeshConfigurationService();

        this.history = createBrowserHistory({
            basename: prependAppBaseName(''),
        });
    }

    componentDidMount() {
        const isPublic = window.SERVER_CONFIG.REACT_APP_IS_PUBLIC as string;
        if (isPublic && isPublic === 'true') {
            this.setState({ isPublic: true, keycloak: undefined, authenticated: true });
            this.appStore.setConnected({ ...this.appStore.connected, keycloak: true });

            this.meshConfigurationService.loadPageConfiguration(this.appStore);
        } else {
            // const keycloak = Keycloak('/keycloak.json') as any;

            const config = {
                realm: window.SERVER_CONFIG.REACT_APP_KEYCLOAK_REALM,

                // IMPORTANT:
                // if we load the keycloak config from .env and set it in a javascript object, we have to use 'url' and 'clientId';
                //
                // however, if we want to load it via a static keycloak.json file, we have to use 'auth-server-url' and 'resource' instead.
                //
                url: window.SERVER_CONFIG.REACT_APP_KEYCLOAK_AUTH_URL,
                clientId: window.SERVER_CONFIG.REACT_APP_KEYCLOAK_CLIENT_ID,

                // 'auth-server-url': 'https://arx.dev.k8s.arbormetrix.net/auth',
                // 'resource': 'pdd-app',

                'ssl-required': 'none',
                'public-client': true,
                'confidential-port': 0,
            };
            const keycloak = Keycloak(config) as any;
            keycloak
                .init({ onLoad: 'login-required', promiseType: 'native' })
                .then((authenticated: any) => {
                    this.appStore.setConnected({ ...this.appStore.connected, keycloak: true });
                    this.setState({ keycloak, authenticated });

                    setKeycloak(keycloak);

                    this.appStore.keycloakSessionId = keycloak.sessionId;

                    keycloak
                        .loadUserInfo()
                        .then(
                            (userInfo: {
                                email: string;
                                name: string;
                                username: string;
                                sub: string;
                            }) => {
                                this.appStore.setLoginEmail(userInfo.email);
                                this.appStore.setName(userInfo.name);
                                this.appStore.setUserName(userInfo.name);
                                this.appStore.setUserId(userInfo.sub);
                            }
                        );

                    if (keycloak.realmAccess && keycloak.realmAccess.roles) {
                        this.appStore.setPersonas(getPersonas(keycloak.realmAccess));
                    }

                    this.meshConfigurationService.loadPageConfiguration(this.appStore);
                })
                .catch((error: any) => {
                    console.error(error);
                    this.appStore.setConnected({ ...this.appStore.connected, keycloak: false });
                });
        }

        if (window.SERVER_CONFIG.REACT_APP_ANALYTICS_ENABLED === 'true') {
            ReactGA.initialize(`${window.SERVER_CONFIG.REACT_APP_ANALYTICS_TRACKING_ID}`, {
                debug: window.SERVER_CONFIG.REACT_APP_ANALYTICS_DEBUG as unknown as boolean,
            });
            ReactGA.pageview(
                `${window.SERVER_CONFIG.REACT_APP_BASE_NAME}${this.history.location.pathname}`
            );

            this.history.listen((location) => {
                const locationWithBaseName = `${window.SERVER_CONFIG.REACT_APP_BASE_NAME}${location.pathname}`;
                ReactGA.set({ page: locationWithBaseName }); // Update the user's current page
                ReactGA.pageview(locationWithBaseName); // Record a pageview for the given page
            });
        }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.log(error);
        console.log(info);
    }

    render() {
        if (!this.state.isPublic) {
            if (
                !this.state.keycloak ||
                !this.state.authenticated ||
                !this.appStore.connected.keycloak
            ) {
                return <KeycloakError />;
            }
        }
        return (
            <StoreProvider store={this.appStore}>
                <Router history={this.history}>
                    <AppErrorBoundary store={this.appStore}>
                        <Main store={this.appStore} keycloak={this.state.keycloak} />
                    </AppErrorBoundary>
                </Router>
                <SessionTimer store={this.appStore} keycloak={this.state.keycloak} />
            </StoreProvider>
        );
    }
}

export default App;
