import React, { FC, useCallback, useEffect, useState } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

import { formatBytes } from '../../../../util/utils';
import { showToastError } from '../../../pages/utils/CommonToasts';
import { Accept, FileRejection, FileUpload } from '../../Inputs/FileUpload';

export interface CustomProgressBarProps extends WidgetProps {
    value: {
        disableDropZone?: boolean;
    };
}

const FileUploadWidget: FC<CustomProgressBarProps> = (props: CustomProgressBarProps) => {
    const { accept, acceptedFilesText, maxFileSize } = props.options;
    const { value } = props;
    const [disableDropZone, setDisableDropZone] = useState<boolean>(false);

    useEffect(() => {
        if (typeof value?.disableDropZone === 'boolean') {
            setDisableDropZone(value.disableDropZone);
        }
    }, [value]);

    const onDrop = useCallback(() => {
        // TODO: set progress
    }, []);

    const onDropAccepted = useCallback(
        (acceptedFiles: File[]) => {
            props.onChange(acceptedFiles[0]);
        },
        [props]
    );

    const onDropRejected = useCallback(
        (fileRejections: FileRejection[]): void => {
            if (maxFileSize !== null) {
                const largeFileSize = fileRejections.filter(
                    (file) =>
                        maxFileSize !== null &&
                        typeof maxFileSize === 'number' &&
                        maxFileSize < file.file.size
                );
                if (largeFileSize.length > 0) {
                    showToastError(
                        `File Size too Large.`,
                        `The file you tried to upload exceeds the size limit of ${formatBytes(
                            +maxFileSize
                        )}.`
                    );
                }
            }
        },
        [maxFileSize]
    );

    return (
        <FileUpload
            extraMessages={['Drag and Drop files here', 'or']}
            selectFileButtonText="Select File to Upload"
            acceptedFilesText={acceptedFilesText as string}
            accept={accept as Accept}
            maxFiles={1}
            maxSize={maxFileSize as number}
            multiple={false}
            noClick
            noKeyboard
            onDrop={onDrop}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            disableDropZone={disableDropZone}
        />
    );
};

export default FileUploadWidget;
