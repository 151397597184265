import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CardWithImage from './CardWithImage';
import { Card } from '../../../generator/generated/MeshInterfaces';
import Api from '../../core/Api';

interface TableauCardState {
    imageUrl?: string;
    isLoaded: boolean;
}

@observer
class TableauCard extends Component<Card, TableauCardState> {
    constructor(props: Readonly<Card>) {
        super(props);
        this.state = { imageUrl: props.imageUrl, isLoaded: true };
    }

    componentDidMount() {
        const { siteId, workbookName, sheetName } = this.props;

        if (siteId && workbookName && sheetName) {
            Api.getVizUrl(siteId, workbookName, sheetName, true).then((res: any) => {
                const { url } = res.data as any;

                this.setState({ imageUrl: url, isLoaded: false });
            });
        }
    }

    render() {
        const { description, title, linkedPage } = this.props;
        if (!this.state.isLoaded && this.state.imageUrl === '') {
            throw new Error('The tableau url was invalid or null');
        }
        return (
            <CardWithImage
                {...this.props}
                description={description || ''}
                imageSource={this.state.imageUrl || ''}
                link={linkedPage && linkedPage.route}
                title={title || ''}
                key={title || ''}
            />
        );
    }
}

export default TableauCard;
