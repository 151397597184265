/* eslint-disable */
import React, { Fragment } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { style } from 'typestyle';
import formatDate from '../../../util/GeneralUtils';

const titleWrapper = style({
    marginLeft: '4px',
    paddingLeft: '3px',
    marginBottom: '5px',
  });

interface LabelWidgetProps extends WidgetProps {
  label: string;
}

const fieldClassName = (skipBottomPadding: boolean, skipTopPadding: boolean) =>
  style({
    position: 'relative',
    paddingTop: skipTopPadding ? '0' : '4px',
    paddingBottom: skipBottomPadding ? '0' : '1px',
    display: 'block',
  });

const labelClassName = (grayText: boolean) =>
  style({
    margin: '16px 0 0 4px',
    fontSize: '16px',
    paddingLeft: '3px',
    color: grayText ? '#5C7080' : '#000000',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  });
class ErrorLabelWidget extends React.Component<LabelWidgetProps> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, value, required, id, options, schema } = this.props;

    const titleId = `${id}__title`;

    // Change the passed in value for booleans into an 'Active'/'Inactive' string to display as the label
    // Default no value to 'N/A' and set it to gray
    let displayValue;
    let grayText = false;
    if (schema.type === 'boolean') {
      if (value === true) {
        displayValue = 'Active';
      } else {
        displayValue = 'Inactive';
      }
    } else if (schema.type === 'number') {
        if (value) {
            displayValue = value.toString();
        } else {
            displayValue = 0;
        }
    } else if (options.objectFieldName) {
      if (Array.isArray(value)) {
        displayValue = value
          .map((val: any) => {
            return val[options.objectFieldName as string];
          })
          .join(', ');
      } else {
        displayValue = value[options.objectFieldName as string];
      }
    } else {
      if (value && value.length > 0) {
        if (options.renderMode === 'date') {
          displayValue = formatDate(value);
        } else {
          displayValue = value;
        }
      } else {
        displayValue = 'N/A';
        grayText = true;
      }
    }

    return (
      <div
        className={fieldClassName(
          options.skipBottomPadding as boolean,
          options.skipTopPadding as boolean
        )}
      >
        {label ?       <div id={titleId} className={titleWrapper} >
        <label style={{ fontWeight: 600, marginBottom: 0 }}>
          {required ? `${label} *` : `${label}`} :
        </label> 
        <span
          className={labelClassName(grayText)}
          title={displayValue + (options.unit ? ' ' + options.unit : '')}
        >
          {typeof displayValue === 'object'
            ? displayValue.map((value: string, index: number) => {
                return <div key={index}>{value}</div>;
              })
            : displayValue}
          {options.unit ? <Fragment>&nbsp;{options.unit}</Fragment> : null}
        </span>
      </div> : null}

      </div>
    );
  }
}

export default ErrorLabelWidget;
