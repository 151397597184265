import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TTableEmptyProps } from '@amxjs/ui';
import {
    ClinicianList as ClinicianListInterface,
    FhirListColumn,
    FhirListRowAction,
    FhirListTableAction,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';
import FhirList, { FhirListColumnWithRenderMode } from '../../components/Tables/FhirList';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import filterAndSort, { setSortingValues, sortValues } from '../utils/filterAndSort';

interface ClinicianListProps extends ClinicianListInterface {
    store: Store;
}

function ClinicianList(props: ClinicianListProps & RouteComponentProps) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [paginationOffset, setPaginationOffset] = useState(0);
    const itemsPerPage = props.itemsPerPage || 25;
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const [columnSorting, setColumnSorting] = useState<Record<string, string>>({});
    const emptyMessage: TTableEmptyProps = {
        title: 'No Clinicians',
        description: `Select "${props.addClinicianButton}" to get started`,
    };
    const [filterString, setFilterString] = useState('');
    const [graphQLQuery, setGraphQLQuery] = useState<string>('');
    const [graphQLQueryVersion, setGraphQLQueryVersion] = useState(0);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    useEffect(() => {
        if (props.columns) {
            const sorting: Record<string, string> = {};

            props.columns.map(setSortingValues(sorting));

            setColumnSorting(sorting);
        }
    }, [props.columns]);

    useEffect(() => {
        setGraphQLQuery(
            `
        ClinicianList(searchString: "${filterString}", pageCount: ${itemsPerPage}, offset:${paginationOffset}) {
          meta {
            totalCount
          }
          returnData {
            id
            active (${filterAndSort(columnSorting.clinicianStatus)})
            name {
              given (${filterAndSort(columnSorting.firstName)})
              family (${filterAndSort(columnSorting.lastName)})
            },
            identifier(
              system: "http://hl7.org/fhir/sid/us-npi"
              filterAllFields: false
              npiFilterAndSort:"true|${columnSorting.npi || ''}") {
              system
              value
            },
            PractitionerRoleList {
              organization {
                resource {
                  name (${filterAndSort(columnSorting.affiliatedOrganizations)})
                }
              }
            }
          }
        }
    `
        );
    }, [graphQLQueryVersion]);

    const getBaseColumns = (): FhirListColumn[] => {
        const jsonPaths = {
            id: ['$.id'],
            npi: ['$.identifier[0].value'],
            firstName: ['$.name[0].given[0]'],
            lastName: ['$.name[0].family'],
            clinicianStatus: ['$.active'],
            affiliatedOrganizations: ['$.PractitionerRoleList[*].organization[*].name'],
        };

        return props.columns.map((col) => {
            const fhirColumn: FhirListColumnWithRenderMode = {
                ...col,
                jsonPath: jsonPaths[col.id],
            };

            if (col.id === 'clinicianStatus') {
                fhirColumn.renderMode = 'active';
            } else if (col.id === 'affiliatedOrganizations') {
                fhirColumn.renderMode = 'tag';
            } else {
                fhirColumn.renderMode = 'default';
            }

            return fhirColumn;
        });
    };

    const getRowActions = (): FhirListRowAction[] => {
        return [
            {
                id: 'view',
                label: 'View',
                behavior: 'Navigate',
                intent: 'primary',
                link: props.ViewPageLink,
                idJsonPath: '$.id',
            },
        ];
    };

    const getTableActions = (): FhirListTableAction[] => {
        return [
            {
                id: 'addClinician',
                label: props.addClinicianButton,
                behavior: 'Navigate',
                intent: 'primary',
                link: props.ViewPageLink,
                icon: 'add',
            },
        ];
    };

    const onLoadMore = () => {
        setPaginationOffset(itemsPerPage * pagesLoaded);
        setPagesLoaded(pagesLoaded + 1);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const resetPagination = () => {
        setPaginationOffset(0);
        setPagesLoaded(1);
    };

    const onSortChanged = (sortValue: string, columnId: string) => {
        // When sorting changes:
        // 1. the pagination resets (handled here)
        // 2. the list is cleared (handled in FhirList)
        // 3. the list is populated with the new first data set (handled in FhirList)
        resetPagination();
        setColumnSorting({ ...columnSorting, [columnId]: sortValues[sortValue] });
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const onFilterStringChange = (searchString: string) => {
        resetPagination();
        setFilterString(searchString);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    return (
        <FhirList
            store={props.store}
            showSearchBar
            allowExport={props.allowExport}
            itemsPerPage={itemsPerPage}
            onLoadMore={onLoadMore}
            appendResults
            title={props.title}
            graphQLQuery={graphQLQuery}
            columns={getBaseColumns()}
            rowActions={getRowActions()}
            tableActions={getTableActions()}
            schemaId={props.schemaId}
            emptyDataListMessage={emptyMessage}
            onSortChanged={onSortChanged}
            onFilterStringChange={onFilterStringChange}
            useServerSideSearch
        />
    );
}

export default withRouter(ClinicianList);
