import React, { FC, useEffect, useState } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

import { FractionField } from '@amxjs/ui';

import { NumeratorDenominator } from '../../../../models/Mips';

export interface FractionWidgetProps extends WidgetProps {
    value: NumeratorDenominator;
}

const FractionWidget: FC<WidgetProps> = (props: FractionWidgetProps) => {
    const [state, setState] = useState<NumeratorDenominator>();

    useEffect(() => {
        setState({ numerator: props.value?.numerator, denominator: props.value?.denominator });
    }, [props.value]);

    return props.hidden ? null : (
        <FractionField
            label=""
            value={state}
            onChange={({
                numerator,
                denominator,
            }: {
                numerator?: number;
                denominator?: number;
            }) => {
                const newState = {
                    ...state,
                    numerator: typeof numerator === 'number' ? numerator : undefined,
                    denominator: typeof denominator === 'number' ? denominator : undefined,
                };
                setState(newState);
                props.onChange?.(newState);
            }}
            hideLabel={Boolean(props.options?.hideLabel)}
            disabled={props.disabled}
            buttonPosition="none"
            errors={props.rawErrors}
        />
    );
};

export default FractionWidget;
