/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import FormActions from './FormActions';

interface FormHeaderProps {
    title: string;
    editMode: boolean;
    allowEditing?: boolean;
    allowCancel?: boolean;
    allowPrinting?: boolean;
    allowSave?: boolean;
    exportButtonLabel?: string;
    enableEditMode?: () => void;
    disableEditMode?: () => void;
}

class FormFooter extends Component<FormHeaderProps> {
    render() {
        return (
            <div className="form-section-footer">
                <FormActions
                    editMode={this.props.editMode}
                    allowEditing={this.props.allowEditing}
                    allowPrinting={this.props.allowPrinting}
                    allowSave={this.props.allowSave}
                    exportButtonLabel={this.props.exportButtonLabel}
                    enableEditMode={this.props.enableEditMode}
                    disableEditMode={this.props.disableEditMode}
                />
            </div>
        );
    }
}

export default FormFooter;
