import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TTableEmptyProps } from '@amxjs/ui';
import {
    FhirListColumn,
    FhirListRowAction,
    FhirListTableAction,
    OrganizationList as OrganizationListInterface,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import FhirList, { FhirListColumnWithRenderMode } from '../../components/Tables/FhirList';
import { useStore } from '../../core/Store';
import { SchemaProvider } from '../../mesh/Schema';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import filterAndSort, { setSortingValues, sortValues } from '../utils/filterAndSort';

const tinDefinition = `http://arbormetrix.com/fhir/namingsystem/organization-tin`;

const idDefinition = `http://${window.SERVER_CONFIG.REACT_APP_AMX_CUSTOMER}.arbormetrix.com/fhir/namingsystem/organization-id`;

function OrganizationList(props: OrganizationListInterface & RouteComponentProps) {
    const [paginationOffset, setPaginationOffset] = useState(0);
    const itemsPerPage = props.itemsPerPage || 25;
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const { setBreadcrumbData } = useBreadcrumbContext();
    const store = useStore();
    const [columnSorting, setColumnSorting] = useState<Record<string, string>>({});
    const [filterString, setFilterString] = useState('');
    const [graphQLQuery, setGraphQLQuery] = useState<string>('');
    const [graphQLQueryVersion, setGraphQLQueryVersion] = useState(0);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    useEffect(() => {
        if (props.columns) {
            const sorting: Record<string, string> = {};

            props.columns.map(setSortingValues(sorting));

            setColumnSorting(sorting);
        }
    }, [props.columns]);

    useEffect(() => {
        setGraphQLQuery(
            `
        OrganizationList(searchString: "${filterString}", pageCount: ${itemsPerPage}, offset:${paginationOffset}) {
          meta {
            totalCount
          }
          returnData {
            id
            name (${filterAndSort(columnSorting.name)})
            active (${filterAndSort(columnSorting.status)})
            identifier {
              system
              value
            }
            address {
              line
              city (${filterAndSort(columnSorting.city)})
              state (${filterAndSort(columnSorting.state)})
              postalCode (${filterAndSort(columnSorting.zipCode)})
            }
            BusinessOrganizationList {
              identifier(
                filterAllFields: false
                idFilterAndSort:"true|${columnSorting.id || ''}"
                tinFilterAndSort:"true|${columnSorting.tin || ''}") {
                system
                value
              }
            }
          }
        }
    `
        );
    }, [graphQLQueryVersion]);

    function getBaseColumns(): FhirListColumn[] {
        const jsonPaths = {
            id: [`$.identifier[?(@.system === "${idDefinition}")].value`],
            // todo - Remove temporary code after MIPS-69
            tin: [
                `$.BusinessOrganizationList[*].identifier[?(@.system === "${tinDefinition}")].value`,
                `$.identifier[?(@.system === "${tinDefinition}")].value`,
            ],
            name: ['$.name'],
            city: ['$.address[0].city'],
            state: ['$.address[0].state'],
            zipCode: ['$.address[0].postalCode'],
            status: ['$.active'],
            endDate: [''],
            startDate: [''],
        };

        return props.columns.map((col) => {
            const fhirColumn: FhirListColumnWithRenderMode = {
                ...col,
                jsonPath: jsonPaths[col.id],
            };

            if (col.id === 'status') {
                fhirColumn.renderMode = 'active';
            } else {
                fhirColumn.renderMode = 'default';
            }

            return fhirColumn;
        });
    }

    function getTableActions(): FhirListTableAction[] {
        const actions: FhirListTableAction[] = [];

        if (
            props.organizationDetailPageLink &&
            store.personaList &&
            store.personaList.indexOf('registry-admin') > -1
        ) {
            actions.push({
                id: 'addOrganization',
                label: 'Add Practice',
                intent: 'primary',
                icon: 'add',
                behavior: 'Navigate',
                link: props.organizationDetailPageLink,
            });
        }

        return actions;
    }

    function getRowActions(): FhirListRowAction[] {
        const actions: FhirListRowAction[] = [];

        if (props.organizationDetailPageLink) {
            actions.push({
                id: 'viewOrganization',
                label: 'View',
                intent: 'primary',
                behavior: 'Navigate',
                link: props.organizationDetailPageLink,
                idJsonPath: '$.id',
            });
        }

        return actions;
    }

    const tableActions = getTableActions();
    const addOrganizationAction = tableActions.find(
        (tableAction) => tableAction.id === 'addOrganization'
    );
    const emptyMessage: TTableEmptyProps = {
        title: 'No Practices',
        description: `Select "${
            addOrganizationAction ? addOrganizationAction.label : null
        }" to get started`,
    };

    const onLoadMore = () => {
        setPaginationOffset(itemsPerPage * pagesLoaded);
        setPagesLoaded(pagesLoaded + 1);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const resetPagination = () => {
        setPaginationOffset(0);
        setPagesLoaded(1);
    };

    const onSortChanged = (sortValue: string, columnId: string) => {
        // When sorting changes:
        // 1. the pagination resets (handled here)
        // 2. the list is cleared (handled in FhirList)
        // 3. the list is populated with the new first data set (handled in FhirList)
        resetPagination();
        setColumnSorting({ ...columnSorting, [columnId]: sortValues[sortValue] });
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const onFilterStringChange = (searchString: string) => {
        resetPagination();
        setFilterString(searchString);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <FhirList
                showSearchBar
                store={store}
                allowExport={props.allowExport}
                itemsPerPage={itemsPerPage}
                onLoadMore={onLoadMore}
                appendResults
                title={props.title}
                graphQLQuery={graphQLQuery}
                columns={getBaseColumns()}
                rowActions={getRowActions()}
                tableActions={tableActions}
                schemaId={props.schemaId}
                emptyDataListMessage={emptyMessage}
                onSortChanged={onSortChanged}
                onFilterStringChange={onFilterStringChange}
                useServerSideSearch
            />
        </SchemaProvider>
    );
}

export default withRouter(OrganizationList);
