import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import moment from 'moment';

interface OptInConfirmationDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    handleOkay: () => void;
    numOfGroups: number;
    numOfIndividuals: number;

    optInConfirmationData: any;
    optInConfirmationDescription: string;
}

const textStyle = {
    paddingBottom: '10px',
};
const rowData = {
    padding: '5px',
};

const OptInConfirmationDialog = (props: OptInConfirmationDialogProps) =>
    props.isOpen ? (
        <Dialog onClose={props.handleClose} isOpen={props.isOpen} title="Opt-In Confirmation">
            <div className={Classes.DIALOG_BODY} style={{ padding: '10px', width: '450px' }}>
                <p style={textStyle}>
                    {`You successfully opted in ${props.numOfGroups} ${
                        props.numOfGroups === 1 ? 'group' : 'groups'
                    } and ${props.numOfIndividuals} ${
                        props.numOfIndividuals === 1 ? 'clinician' : 'clinicians'
                    } to participate in MIPS on `}
                    <span style={{ color: '#137CBD' }}>
                        {moment().format('l')} at {moment().format('LT')}.
                    </span>
                </p>
                {props.optInConfirmationData &&
                props.optInConfirmationData.length &&
                props.optInConfirmationData.filter((row: any) => {
                    return !row.success;
                }).length ? (
                    <>
                        <p style={textStyle}>The participants below could not be opted in:</p>
                        <p style={textStyle}>
                            <table style={{ width: '100%' }}>
                                <tr className="bp3-heading">
                                    <th>Participant Type</th>
                                    <th>Clinician</th>
                                    <th>Error</th>
                                </tr>
                                {props.optInConfirmationData
                                    .filter((row: any) => {
                                        return !row.success;
                                    })
                                    .map((row: any) => (
                                        <tr key={row.clinician} style={{ textAlign: 'center' }}>
                                            <td style={rowData}>{row.participationOption}</td>
                                            <td style={rowData}>
                                                {row.clinician ? row.clinician : '-'}
                                            </td>
                                            <td style={rowData}>{row.errors}</td>
                                        </tr>
                                    ))}
                            </table>
                        </p>
                    </>
                ) : null}
                <p>{props.optInConfirmationDescription}</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        text="Done"
                        onClick={props.handleClose}
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                    />
                    <Button
                        text="Continue to Submission"
                        intent={Intent.PRIMARY}
                        onClick={props.handleOkay}
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                    />
                </div>
            </div>
        </Dialog>
    ) : null;

export default OptInConfirmationDialog;
