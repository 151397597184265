const downloadFile = (content: string | Blob, fileName: string, type: string) => {
    const a = document.createElement('a');
    if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
            new Blob([content], {
                type: `${type};encoding:utf-8`,
            }),
            fileName
        );
    } else if (URL && 'download' in a) {
        // html5 A[download]
        a.href = URL.createObjectURL(
            new Blob([content], {
                type: `${type};encoding:utf-8`,
            })
        );
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else if (typeof content === 'string') {
        window.location.href = `data:application/octet-stream,${encodeURIComponent(content)}`; // only this mime type is supported
    }
};

export { downloadFile };
