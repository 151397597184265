import React, { FC } from 'react';
import { style } from 'typestyle';
import { Button, Classes, Dialog, H5, Intent } from '@blueprintjs/core';

interface ResetDialogProps {
    isOpen: boolean;
    onClose: (event?: React.SyntheticEvent<HTMLElement>) => void;
    onSubmit: (event?: React.SyntheticEvent<HTMLElement>) => void;
}

const titleText = 'Are you sure you want to continue?';
const subText =
    'If you continue, you will remove your file upload and lose validation errors. To save, please export list.';
const cancelText = 'No, go back';
const submitText = 'Yes, continue';

const containerClassName = style({ padding: '15px', backgroundColor: 'white', width: '360px' });
const contentClassName = style({ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' });

const ResetFormDialog: FC<ResetDialogProps> = (props: ResetDialogProps) => {
    return props.isOpen ? (
        <Dialog
            onClose={props.onClose}
            isOpen={props.isOpen}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
        >
            <div className={containerClassName}>
                <H5>{titleText}</H5>
                <p>{subText}</p>
                <div className={contentClassName}>
                    <Button
                        className={Classes.POPOVER_DISMISS}
                        style={{ marginRight: 10 }}
                        onClick={props.onClose}
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                    >
                        {cancelText}
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={props.onSubmit}
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                    >
                        {submitText}
                    </Button>
                </div>
            </div>
        </Dialog>
    ) : null;
};

export default ResetFormDialog;
