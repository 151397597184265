/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Subtract } from 'utility-types';
import { useStore } from '../core/Store';
import { useSchema } from '../mesh/Schema';
import { getDisplayName } from './ReactUtils';

export interface InjectedDataDictionaryProps {
    labels: { [key: string]: string };
}

export interface WithDataDictionaryProps {
    schemaId?: string;
}

const withDataDictionary = <P extends InjectedDataDictionaryProps>(
    WrappedComponent: React.ComponentType<P>
) => {
    const DataDictionary = (
        props: Subtract<P, InjectedDataDictionaryProps> & WithDataDictionaryProps
    ) => {
        const store = useStore();
        const schemaId = useSchema() || props.schemaId;
        let labels = {};
        if (store.siteConfiguration && schemaId) {
            const dataDictionaryNodePair: [any, any] | undefined = Object.entries(
                store.siteConfiguration.Configuration
            ).find(([, value]) => {
                return (value as any).schema === 'DataDictionary';
            });

            if (
                dataDictionaryNodePair &&
                dataDictionaryNodePair[1].dataDictionaryConfig[schemaId]
            ) {
                labels = dataDictionaryNodePair[1].dataDictionaryConfig[schemaId];
            }
        }

        return <WrappedComponent {...(props as unknown as P)} labels={labels} />;
    };

    DataDictionary.displayName = `WithDataDictionary(${getDisplayName(WrappedComponent)})`;
    return DataDictionary;
};

export default withDataDictionary;
