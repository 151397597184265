import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

export const getQualityMeasuresSchema = (
    title: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _labels: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _description?: string
): JSONSchema6 => {
    return {
        type: 'array',
        title: title || 'Quality Measures',
        items: {
            type: 'object',
            properties: {
                measureId: {
                    type: 'string',
                    title: 'Measure ID',
                },
                measureTitle: {
                    type: 'string',
                    title: 'Title',
                },
                dataCompleteness: {
                    type: 'string',
                    title: 'Data Completeness',
                },
                performanceRate: {
                    type: 'string',
                    title: 'Performance Rate',
                },
                score: {
                    type: 'string',
                    title: 'Score',
                },
                decile: {
                    type: 'string',
                    title: 'Decile',
                },
                startDate: {
                    type: 'string',
                    title: 'Start Date',
                },
                endDate: {
                    type: 'string',
                    title: 'End Date',
                },
                view: {
                    type: 'string',
                    title: ' ',
                },
            },
        },
    };
};

export const getQualityMeasuresUISchema = (): UiSchema => {
    const emptyListMessage = {
        title: 'No Quality Measures',
        description: 'Add patient data to see Quality Measures',
    };

    return {
        'ui:field': 'list',
        'ui:hash': 'qualitymeasurecategoryscores',
        'ui:options': {
            emptyListMessage,
            emptyDataListMessage: emptyListMessage,
        },
        items: {
            measureId: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            measureTitle: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            dataCompleteness: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            startDate: {
                'ui:readonly': true,
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            endDate: {
                'ui:readonly': true,
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            performanceRate: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            score: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            decile: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: true,
                },
            },
            view: {
                'ui:widget': 'SimpleTextWidget',
                'ui:options': {
                    orderable: false,
                },
            },
        },
    };
};
