import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorSchema, FieldProps, Widget } from 'react-jsonschema-form';
import { JSONSchema6 } from 'json-schema';
import uuid from 'uuid';

const CellField = (props: FieldProps) => {
    const [parsedErrors, setParsedErrors] = useState<string[]>();

    const CustomWidget = useMemo(() => {
        const widgetObjectOrName = props.uiSchema?.['ui:widget'];
        let widget: Widget | undefined;
        switch (typeof widgetObjectOrName) {
            case 'string':
                widget = props.registry.widgets[widgetObjectOrName];
                break;
            case 'object':
                widget = widgetObjectOrName;
                break;
            default:
                widget = props.registry.widgets.SimpleTextWidget;
        }
        return widget;
    }, [props.registry?.widgets, props.uiSchema]);

    const toErrorList = useCallback((errorSchema: ErrorSchema) => {
        if (!errorSchema) {
            return [];
        }
        let errorList: string[] = [];
        const { __errors: errors } = errorSchema;
        if (errors && Array.isArray(errors)) {
            errorList = errorList.concat(errors);
        }
        return Object.keys(errorSchema).reduce((acc: string[], key) => {
            let auxAcc = acc;
            if (key !== '__errors') {
                auxAcc = auxAcc.concat(toErrorList(errorSchema[key]));
            }
            return auxAcc;
        }, errorList);
    }, []);

    useEffect(() => {
        const errorList = toErrorList(props.errorSchema);
        const uniqueErrors = errorList.reduce((acc: string[], current) => {
            let auxAcc = acc;
            if (!acc.includes(current)) {
                auxAcc = acc.concat(current);
            }
            return auxAcc;
        }, []);
        setParsedErrors(uniqueErrors);
    }, [props.errorSchema, toErrorList]);

    return CustomWidget !== undefined ? (
        <CustomWidget
            {...props}
            label=""
            id={props.id || uuid.v4()}
            schema={props.schema as JSONSchema6}
            options={props.uiSchema?.['ui:options'] || {}}
            value={props.value}
            rawErrors={props.hideErrors ? [] : parsedErrors || []}
            required={false}
            autofocus={false}
            onBlur={() => {}}
            onFocus={() => {}}
        />
    ) : null;
};

export default CellField;
