import React, { Component } from 'react';
import { FormProps } from 'react-jsonschema-form';
import AmxThemedForm from './AmxThemedForm';

/* eslint-disable react/prefer-stateless-function */
interface DialogFormProps {
    minHeight: string;
    minWidth: string;
    validators?: {
        field: string;
        validator: Function;
        errorMessage: string;
        callbackOnFail?: () => void;
    }[];
    // If set this will allow the enter key to submitting the form data during normal form input
    // This still allows the submit/save button to be focused via tab and enter to submit the form
    // By default the enter key will now allow the form to be submitted when not focused on the submit button
    allowEnterToSubmit?: boolean;
}

interface DialogState {
    id: string;
}

function preventEnterFromSubmitting(e: any) {
    if (e && e.keyCode === 13) {
        e.preventDefault();
    }
}

class DialogForm<T> extends Component<DialogFormProps & FormProps<T>, DialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: (this.props.id ? this.props.id : Math.random()) as string,
        };
    }

    componentDidMount() {
        if (document && !this.props.allowEnterToSubmit && this.state.id) {
            const dialog = document.getElementById(this.state.id);
            if (dialog) {
                dialog.onkeypress = preventEnterFromSubmitting;
            }
        }
    }

    render() {
        return (
            <div
                style={{
                    width: this.props.minWidth,
                    minHeight: this.props.minHeight,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 'fit-content',
                }}
            >
                <AmxThemedForm
                    {...this.props}
                    id={this.state.id}
                    className="amx-form-height-expand"
                >
                    {this.props.children}
                </AmxThemedForm>
            </div>
        );
    }
}

export default DialogForm;
