/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Dialog, Classes } from '@blueprintjs/core';
// import Store from '../core/Store';
import { MeshNode } from '../../generator/generated/MeshInterfaces';
import MeshComponentMapper from '../mesh/MeshComponentMapper';

interface DialogHandlerProps {
    // store: Store;
    dialogChild: MeshNode;
    isOpen: boolean;
    canEscapeKeyClose?: boolean;
    canOutsideClickClose?: boolean;
    onClose: (event?: React.SyntheticEvent<HTMLElement>) => void;

    // Maps the name of a schema to a react component class. ClinicianDetails.tsx is an example of this.
    // Used to prevent circular dependencies from MeshComponentMapper.ts
    schemaToMeshType?: Map<string, any>;
}

class DialogHandler extends Component<DialogHandlerProps> {
    // meshMapper: MeshComponentMapper;

    constructor(props: DialogHandlerProps) {
        super(props);

        // this.meshMapper = new MeshComponentMapper();
    }

    renderChild() {
        if (this.props.dialogChild.schema) {
            const childType = this.props.schemaToMeshType
                ? this.props.schemaToMeshType.get(this.props.dialogChild.schema)
                : MeshComponentMapper.getComponent(this.props.dialogChild.schema);
            return React.createElement(childType, {
                ...this.props.dialogChild,
                onDialogClose: this.props.onClose,
            });
        }

        return null;
    }

    render() {
        return (
            <Dialog
                onClose={this.props.onClose}
                isOpen={this.props.isOpen}
                canEscapeKeyClose={!!this.props.canEscapeKeyClose}
                canOutsideClickClose={!!this.props.canOutsideClickClose}
            >
                <div className={Classes.DIALOG_BODY}>{this.renderChild()}</div>
            </Dialog>
        );
    }
}

export default DialogHandler;
