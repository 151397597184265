/* eslint-disable no-restricted-globals */
import React from 'react';
import { observer } from 'mobx-react';
import { Button, Navbar } from '@blueprintjs/core';
import { Footer as MeshFooter } from '../../../generator/generated/MeshInterfaces';
import Store from '../../core/Store';

interface MeshFooterProps extends MeshFooter {
    store: Store;
}

const meshLanguageMap = {
    es: 'Español',
    en: 'English',
};

function Footer(props: MeshFooterProps) {
    const {
        active,
        availableLanguages = ['en'],
        copyrightYear,
        copyrightCompanyName,
        poweredByCompanyName,
        poweredByCompanyLink,
        store,
    } = props;

    const { setLanguage } = store;

    const setMeshLanguage = (language: any) => {
        setLanguage(language);
        window.location.reload(true);
    };

    if (!active) return null;

    return (
        <footer id="page-footer">
            <Navbar style={{ padding: '0 24px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        &copy; {copyrightYear} {copyrightCompanyName}. All Rights Reserved.
                    </div>
                    {availableLanguages.map((language) => {
                        return (
                            <Button
                                className="bp3-minimal bp3-intent-primary"
                                onClick={() => setMeshLanguage(language)}
                                key={language}
                            >
                                {meshLanguageMap[language]}
                            </Button>
                        );
                    })}
                    <div style={{ flex: 1, textAlign: 'right' }}>
                        Powered by
                        <strong>
                            <a
                                href={poweredByCompanyLink}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {` ${poweredByCompanyName}`}
                            </a>
                        </strong>
                        .
                    </div>
                </div>
            </Navbar>
        </footer>
    );
}

export default observer(Footer);
