import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    FhirListColumn,
    FhirListRowAction,
    Page,
    PatientList as PatientListInterface,
} from '../../../generator/generated/MeshInterfaces';
import FhirList, { FhirListColumnWithRenderMode } from '../../components/Tables/FhirList';
import { useStore } from '../../core/Store';
import { SchemaProvider } from '../../mesh/Schema';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { setSortingValues, sortValues } from '../utils/filterAndSort';

function PatientList(props: PatientListInterface & RouteComponentProps) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const store = useStore();
    const [paginationOffset, setPaginationOffset] = useState(0);
    const itemsPerPage = props.itemsPerPage || 25;
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const [columnSorting, setColumnSorting] = useState<Record<string, string>>({});
    const [filterString, setFilterString] = useState('');
    const [graphQLQuery, setGraphQLQuery] = useState<string>('');
    const [graphQLQueryForTotalCount, setGraphQLQueryForTotalCount] = useState<string>('');
    const [graphQLQueryVersion, setGraphQLQueryVersion] = useState(0);
    const [skipTotalCount, setSkipTotalCount] = useState(false);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    useEffect(() => {
        if (props.columns) {
            const sorting: Record<string, string> = {};

            props.columns.map(setSortingValues(sorting));

            setColumnSorting(sorting);
        }
    }, [props.columns]);

    useEffect(() => {
        setGraphQLQuery(
            `
        PatientListOverview(pageCount: ${itemsPerPage}, 
                            offset:${paginationOffset}, 
                            searchString: "${filterString}", 
                            skipTotalCount: true, 
                            searchByManagingEntity: ${!store.HasUnrestrictedAccess})  {
            meta {
                totalCount
            }
            returnData {
                id
                mrn (includeInSearchString: true sortDirection: "${columnSorting.mrn || ''}")
                dob (includeInSearchString: true sortDirection: "${columnSorting.dob || ''}")
                firstName (includeInSearchString: true sortDirection: "${
                    columnSorting.firstName || ''
                }")
                lastName (includeInSearchString: true sortDirection: "${
                    columnSorting.lastName || ''
                }")
                practice (includeInSearchString: true sortDirection: "${
                    columnSorting.practice || ''
                }")
            }
        }
    `
        );
    }, [graphQLQueryVersion]);

    useEffect(() => {
        setGraphQLQueryForTotalCount(
            `
        PatientListOverview(pageCount: ${itemsPerPage}, 
                            offset:${paginationOffset}, 
                            searchString: "${filterString}", 
                            skipTotalCount: ${skipTotalCount}, 
                            searchByManagingEntity: ${!store.HasUnrestrictedAccess}, 
                            totalCountOnly: true)  {
            meta {
                totalCount
            }
            returnData {
                id
                mrn (includeInSearchString: true sortDirection: "${columnSorting.mrn || ''}")
                dob (includeInSearchString: true sortDirection: "${columnSorting.dob || ''}")
                firstName (includeInSearchString: true sortDirection: "${
                    columnSorting.firstName || ''
                }")
                lastName (includeInSearchString: true sortDirection: "${
                    columnSorting.lastName || ''
                }")
                practice (includeInSearchString: true sortDirection: "${
                    columnSorting.practice || ''
                }")
            }
        }
    `
        );
    }, [graphQLQueryVersion]);

    function getBaseColumns(): FhirListColumn[] {
        const jsonPaths = {
            mrn: [`$.mrn`],
            firstName: ['$.firstName'],
            lastName: ['$.lastName'],
            dob: ['$.dob'],
            practice: ['$.practice'],
        };

        return props.columns.map((col) => {
            const fhirColumn: FhirListColumnWithRenderMode = {
                ...col,
                jsonPath: jsonPaths[col.id],
            };

            if (col.id === 'dob') {
                fhirColumn.renderMode = 'date';
            }

            return fhirColumn;
        });
    }

    function getRowActions(): FhirListRowAction[] {
        const actions: FhirListRowAction[] = [];

        if (props.patientDetailPageLink) {
            actions.push({
                id: 'viewPatient',
                label: 'View',
                intent: 'primary',
                behavior: 'Navigate',
                link: props.patientDetailPageLink,
                idJsonPath: '$.id',
            });
        }

        return actions;
    }

    const emptyListMessage = {
        title: 'No Patients',
        description: '',
    };

    const onLoadMore = () => {
        setSkipTotalCount(true);
        setPaginationOffset(itemsPerPage * pagesLoaded);
        setPagesLoaded(pagesLoaded + 1);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const resetPagination = () => {
        setSkipTotalCount(false);
        setPaginationOffset(0);
        setPagesLoaded(1);
    };

    const onSortChanged = (sortValue: string, columnId: string) => {
        // When sorting changes:
        // 1. the pagination resets (handled here)
        // 2. the list is cleared (handled in FhirList)
        // 3. the list is populated with the new first data set (handled in FhirList)
        resetPagination();
        setColumnSorting({ ...columnSorting, [columnId]: sortValues[sortValue] });
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const onFilterStringChange = (searchString: string) => {
        resetPagination();
        setFilterString(searchString);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <FhirList
                showSearchBar
                store={store}
                allowExport={props.allowExport}
                itemsPerPage={itemsPerPage}
                onLoadMore={onLoadMore}
                appendResults
                title={props.title}
                graphQLQuery={graphQLQuery}
                columns={getBaseColumns()}
                rowActions={getRowActions()}
                schemaId={props.schemaId}
                emptyDataListMessage={emptyListMessage}
                onSortChanged={onSortChanged}
                onFilterStringChange={onFilterStringChange}
                useServerSideSearch
                skipTotalCount={skipTotalCount}
                graphQLQueryForTotalCount={graphQLQueryForTotalCount}
                separateCallForTotalCount
            />
        </SchemaProvider>
    );
}

export default withRouter(PatientList);
