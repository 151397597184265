/* eslint-disable react/prop-types */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { style } from 'typestyle';
import { Popover, PopoverInteractionKind, Text } from '@blueprintjs/core';

const popoverContentStyle = style({
    padding: '10px',
});

interface ListPopoverProps {
    content: string;
    textClassName?: string;
}

export const ListPopover: FC<ListPopoverProps> = ({ content, textClassName }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [hoverActive, setHoverActive] = useState(false);

    let timer: NodeJS.Timeout | undefined;
    const compareSize = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (ref.current && ref.current.lastElementChild) {
                setHoverActive(
                    ref.current.lastElementChild.scrollWidth >
                        ref.current.lastElementChild.clientWidth
                );
            }
        }, 500);
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        return () => {
            // remove resize listener again on "componentWillUnmount"
            window.removeEventListener('resize', compareSize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const memoizedContent = useMemo(
        () => (
            <div ref={ref}>
                <Text ellipsize title="" className={textClassName}>
                    {content}
                </Text>
            </div>
        ),
        [textClassName, content]
    );

    return hoverActive ? (
        <Popover
            usePortal
            interactionKind={PopoverInteractionKind.HOVER}
            content={<div className={popoverContentStyle}>{content}</div>}
        >
            {memoizedContent}
        </Popover>
    ) : (
        memoizedContent
    );
};
