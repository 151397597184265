import React, { useEffect, useState } from 'react';
import { JSONPath } from 'jsonpath-plus';
import { Dialog, Intent } from '@blueprintjs/core';
import { JSONSchema6 } from 'json-schema';
import { cloneDeep, isEqual } from 'lodash';
import { showToast } from '@amxjs/ui';

import { MipsTinRecord } from '../../../server/routes/Mips/RecordInterface';
import DialogForm from '../../components/forms/DialogForm';
import LabelWidget from '../../components/forms/widgets/LabelWidget';
import TextWidget from '../../components/forms/widgets/TextWidget';
import RadioWidget from '../../components/forms/widgets/RadioWidget';
import DialogFormActions from '../../components/forms/components/DialogFormActions';
import withDataDictionary from '../../util/DataDictionary';
import Api from '../../core/Api';
import Heading from '../heading/Heading';
import MipsModuleConstants from './MipsModuleConstants';
import { validateParticipationOption, validateCehrtId } from './MipsValidation';

function SetUpTinDialog(props: any) {
    const { isOpen, handleClose, handleOkay, activeTin, labels, participationOptions } = props;
    const [hasChange, setHasChange] = useState(false);
    const [savingRecord, setSavingRecord] = useState(false);
    const initialData: MipsTinRecord = {
        id: '',
        tin: '',
        tinName: '',
        participationOption: '',
        cehrtId: '',
        businessOrganizationId: '',
        organizationAffiliationId: '',
        submissionYear: '',
        providerPractitionerIds: [],
    };
    const [originalFormData, setOriginalFormData] = useState<MipsTinRecord>({
        id: '',
        tin: '',
        participationOption: '',
        tinName: '',
        cehrtId: '',
        businessOrganizationId: '',
        organizationAffiliationId: '',
        submissionYear: '',
        providerPractitionerIds: [],
    });
    const [formData, setFormData] = useState<MipsTinRecord>({
        id: '',
        tin: '',
        participationOption: '',
        tinName: '',
        cehrtId: '',
        businessOrganizationId: '',
        organizationAffiliationId: '',
        submissionYear: '',
        providerPractitionerIds: [],
    });
    const resetToBlankState = () => {
        setHasChange(false);
        setOriginalFormData(initialData);
        setFormData(initialData);
    };
    const closeAction = () => {
        resetToBlankState();
        handleClose();
    };
    const okayAction = (updatedRowId: any, existingOrg: any, updatedRecord: MipsTinRecord) => {
        resetToBlankState();
        handleOkay(updatedRowId, existingOrg, updatedRecord);
    };
    useEffect(() => {
        if (activeTin && activeTin.activeOrg) {
            const submissionYear =
                props.selectedYear.toString() || MipsModuleConstants.SubmissionYear;
            const cehrtId = JSONPath({
                path: `$.cehrtId`,
                json: activeTin.activeOrg,
            })[0];

            const businessOrganizationId = JSONPath({
                path: `$.businessOrganizationId`,
                json: activeTin.activeOrg,
            })[0];

            const organizationAffiliationId = JSONPath({
                path: `$.mipsOrganizationAffiliationId`,
                json: activeTin.activeOrg,
            })[0];

            const ogFormData: MipsTinRecord = {
                id: props.activeTin.idFieldForLink || '',
                tin: props.activeTin.tin || 0,
                participationOption: props.activeTin.participationOption || '',
                tinName: props.activeTin.tinName || '',
                cehrtId,
                businessOrganizationId,
                organizationAffiliationId,
                submissionYear,
                providerPractitionerIds: activeTin.activeOrg.providerPractitionerIds,
            };

            setOriginalFormData(ogFormData);
            setFormData(ogFormData);
        } else {
            setOriginalFormData(initialData);
            setFormData(initialData);
        }
    }, [activeTin]);

    const onChange = (e: any) => {
        setFormData(cloneDeep(e.formData));
        setHasChange(true);
    };

    const getSchema = () => {
        const schema: JSONSchema6 = {
            type: 'object',
            properties: {
                tin: {
                    title: labels.tin || 'TIN',
                    type: 'string',
                },
                tinName: {
                    title: labels.tinName || 'TIN Name',
                    type: 'string',
                },
                participationOption: {
                    title: labels.participationOption || 'Participation Option',
                    type: 'string',
                    enum: participationOptions,
                },
                cehrtId: {
                    title: labels.cehrtId || 'CEHRT ID',
                    type: 'string',
                },
            },
        };
        return schema;
    };

    const getUiSchema = () => {
        const uiSchema: any = {
            'ui:field': 'layout',
            'ui:layout': [
                {
                    tin: { sm: 4, md: 4, lg: 4, xl: 4 },
                    tinName: { sm: 6, md: 6, lg: 6, xl: 6 },
                },
                {
                    participationOption: { sm: 12, md: 12, lg: 12, xl: 12 },
                },
                {
                    cehrtId: { sm: 8, md: 8, lg: 8, xl: 8 },
                },
            ],
            tin: {
                'ui:widget': 'LabelWidget',
            },
            tinName: {
                'ui:widget': 'LabelWidget',
            },
            participationOption: {
                'ui:widget': 'RadioWidget',
                'ui:options': {
                    selectedOption: formData.participationOption,
                    helpText:
                        'Participation options refers to the levels at which data can be collected and submitted to CMS. If your TIN is not participating in MIPS, do not select a participation option.',
                },
            },
            cehrtId: {
                'ui:widget': 'TextWidget',
                'ui:options': {
                    mask: '***************',
                    helpText:
                        'Certified Electronic Health Record Technology ID: A unique identifier based on your EHR vendor, product, and version that is required by CMS if submitting for the Promoting Interoperability category.',
                },
            },
        };
        return uiSchema;
    };

    const getValidators = () => {
        const validators = [
            {
                field: 'participationOption',
                validator: validateParticipationOption,
                errorMessage:
                    labels.participationOptionError || MipsModuleConstants.ParticipationOptionError,
            },
            {
                field: 'cehrtId',
                validator: validateCehrtId,
                errorMessage: labels.cehrtIdError || MipsModuleConstants.CehrtIdError,
            },
        ];
        return validators;
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };

    const onSubmit = () => {
        if (!isEqual(originalFormData, formData)) {
            setSavingRecord(true);
            console.info('submitted ', formData);
            Api.mips
                .updateTin(formData)
                .then((response) => {
                    if (response.ok) {
                        if (response.data.noChangesNeeded) {
                            showToast({
                                message: (
                                    <div>
                                        <p style={{ fontWeight: 'bold' }}>
                                            {response.data.noChangesMessageTitle}
                                        </p>
                                        <p>{response.data.noChangesMessage}</p>
                                    </div>
                                ),
                                intent: Intent.SUCCESS,
                                icon: 'tick-circle',
                            });
                            setHasChange(false);
                            closeAction();
                        } else if (response.data.success) {
                            showToast({
                                message: (
                                    <p>
                                        <span style={{ fontWeight: 'bold' }}>Success</span>
                                        <br />
                                        <br />
                                        <span>Updated TIN successfully!</span>
                                    </p>
                                ),
                                intent: Intent.SUCCESS,
                                icon: 'tick-circle',
                            });
                            setHasChange(false);
                            okayAction(response.data.diagnostics, activeTin.activeOrg, formData);
                        } else {
                            showToast({
                                message: (
                                    <p>
                                        <span style={{ fontWeight: 'bold' }}>Error Occured!</span>
                                        <br />
                                        <br />
                                        <span>
                                            Oops! Looks like something went wrong and your changes
                                            could not be saved. Please try again later.
                                        </span>
                                    </p>
                                ),
                                intent: Intent.DANGER,
                                icon: 'error',
                            });
                        }
                    } else {
                        showToast({
                            message: (
                                <p>
                                    <span style={{ fontWeight: 'bold' }}>Error Occured!</span>
                                    <br />
                                    <br />
                                    <span>
                                        Oops! Looks like something went wrong and your changes could
                                        not be saved. Please try again later.
                                    </span>
                                </p>
                            ),
                            intent: Intent.DANGER,
                            icon: 'error',
                        });
                    }
                    setSavingRecord(false);
                })
                .catch((error: any) => {
                    console.error(error);
                    setSavingRecord(false);
                });
        } else {
            closeAction();
        }
    };

    return isOpen ? (
        <Dialog onClose={closeAction} isOpen={isOpen} style={{ padding: '40px' }}>
            <div>
                <Heading alignment="left" text="Set Up TIN" />
                <DialogForm
                    schema={getSchema()}
                    formData={formData}
                    uiSchema={getUiSchema()}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onError={(e) => onError(e)}
                    widgets={{
                        LabelWidget,
                        TextWidget,
                        RadioWidget,
                    }}
                    validators={getValidators()}
                    minHeight="132px"
                    minWidth="624px"
                >
                    <DialogFormActions
                        addButtonIcon="floppy-disk"
                        onCancel={closeAction}
                        formHasChange={hasChange}
                        disabled={savingRecord}
                        addButtonText="Save"
                    />
                </DialogForm>
            </div>
        </Dialog>
    ) : null;
}

export default withDataDictionary(SetUpTinDialog);
