import React from 'react';

import { Button, IButtonProps } from '@blueprintjs/core';

interface IconButtonProps extends IButtonProps {
    icon: any;
}

class IconButton extends React.Component<IconButtonProps> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { icon, ...otherProps } = this.props;

        return <Button icon={icon} {...otherProps} small />;
    }
}

export default IconButton;
