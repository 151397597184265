/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
import React, { Fragment } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import {
    Navbar,
    Button,
    Popover,
    Menu,
    Position,
    MenuItem,
    IconName,
    OverflowList,
} from '@blueprintjs/core';
import { style } from 'typestyle';
import { Link as MeshLink, NavLevel, Header } from '../../../generator/generated/MeshInterfaces';
import { prependAppBaseName, openLink } from '../../util/LinkUtils';

interface HeaderProp {
    username: string;
    profileDropdown?: { label: any; link: string | Function }[];
}

const TempHeader = (props: HeaderProp & Header & RouteComponentProps) => {
    const { username, logo, logoLink, profileDropdown, navLinks } = props;
    const profileButtons = (i: number) => {
        if (profileDropdown && profileDropdown[i].link) {
            if (typeof profileDropdown[i].link === 'string') {
                location.href = profileDropdown[i].link as string;
            } else {
                (profileDropdown[i].link as Function)();
            }
        }
    };

    const EXTERNAL_LINK_REGEX = /^https?:\/\//;

    const renderProfile = () => {
        if (profileDropdown) {
            return (
                <Popover
                    usePortal={false}
                    key="Profile"
                    minimal
                    content={
                        <Menu>
                            {/* eslint-disable-next-line react/prop-types */}
                            {profileDropdown.map((menuItem, i) => (
                                <MenuItem
                                    key={menuItem.label}
                                    text={menuItem.label}
                                    onClick={() => profileButtons(i)}
                                />
                            ))}
                        </Menu>
                    }
                    position={Position.BOTTOM_RIGHT}
                >
                    <Button
                        className="bp3-minimal"
                        rightIcon="caret-down"
                        icon="user"
                        text={username}
                    />
                </Popover>
            );
        }
        return null;
    };

    const renderSecondaryNav = (link: MeshLink) => {
        return (
            // setting popoverProps is to fix this bug: https://github.com/palantir/blueprint/issues/2863
            <Popover
                usePortal={false}
                key={`Seconday-Nav-${link.text}`}
                minimal
                content={
                    <Menu>
                        {link.navLevel &&
                            link.navLevel.map((level2: NavLevel) => {
                                if (!level2.active) return null;
                                return (
                                    <MenuItem
                                        popoverProps={{ openOnTargetFocus: false }}
                                        onClick={() => openLink(level2.link, props.history)}
                                        key={level2.name}
                                        text={level2.name}
                                    >
                                        {level2.children &&
                                        level2.children.some((child) => child.active)
                                            ? level2.children.map((level3: NavLevel) => {
                                                  if (!level3.active) return null;
                                                  return (
                                                      <MenuItem
                                                          popoverProps={{
                                                              openOnTargetFocus: false,
                                                          }}
                                                          onClick={() =>
                                                              openLink(level3.link, props.history)
                                                          }
                                                          key={level3.name}
                                                          text={level3.name}
                                                      >
                                                          {level3.children &&
                                                          level3.children.some(
                                                              (child) => child.active
                                                          )
                                                              ? level3.children.map(
                                                                    (level4: NavLevel) => {
                                                                        if (!level4.active)
                                                                            return null;
                                                                        return (
                                                                            <MenuItem
                                                                                popoverProps={{
                                                                                    openOnTargetFocus:
                                                                                        false,
                                                                                }}
                                                                                onClick={() =>
                                                                                    openLink(
                                                                                        level4.link,
                                                                                        props.history
                                                                                    )
                                                                                }
                                                                                key={level4.name}
                                                                                text={level4.name}
                                                                            />
                                                                        );
                                                                    }
                                                                )
                                                              : null}
                                                      </MenuItem>
                                                  );
                                              })
                                            : null}
                                    </MenuItem>
                                );
                            })}
                    </Menu>
                }
                position={Position.BOTTOM_LEFT}
            >
                <Button
                    className="bp3-minimal"
                    rightIcon="caret-down"
                    icon={link.icon as IconName}
                    text={link.text}
                    // Allow overflow list to handle sizing, prevent breaking on spaces.
                    style={{ whiteSpace: 'nowrap' }}
                />
            </Popover>
        );
    };

    const renderLinkButton = (link: MeshLink) => {
        const { pageLink: { route = '' } = {} } = link;
        const isActive = location.pathname === prependAppBaseName(route);
        return (
            <Button
                className={isActive ? 'bp3-minimal bp3-intent-primary' : 'bp3-minimal'}
                icon={link.icon as IconName}
                text={link.text}
                // Allow overflow list to handle sizing, prevent breaking on spaces.
                style={{ whiteSpace: 'nowrap' }}
            />
        );
    };

    const renderRouterLinkOrExternalLink = (route: string, children: JSX.Element, text: string) => {
        // React-Router Link can't handle external links, so render an <a> if the link.route is a full URL
        // https://github.com/ReactTraining/react-router/issues/1147
        const key = `${text}-${route}`;
        return EXTERNAL_LINK_REGEX.test(route) ? (
            <a href={route} key={key} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        ) : (
            <Link to={route} key={key}>
                {children}
            </Link>
        );
    };

    const renderNavLinks = () => {
        if (navLinks) {
            return navLinks.map((link: MeshLink) => {
                if (!link.active) return undefined;
                if (
                    link.navLevel &&
                    link.navLevel.length &&
                    link.navLevel.some((nav) => nav.active)
                ) {
                    return renderSecondaryNav(link);
                }
                return renderRouterLinkOrExternalLink(
                    (link.pageLink && link.pageLink.route) || '',
                    renderLinkButton(link),
                    link.text
                );
            });
        }
        return [];
    };

    const navBarClass = style({
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 12px',
    });

    return (
        // Give navbar flex to allow the OverflowList to calculate what link elements to display.
        <Navbar className={navBarClass}>
            <Navbar.Group>
                {renderRouterLinkOrExternalLink(
                    logoLink || '/',
                    <img
                        alt=""
                        className="header-logo" // used in pcha theme
                        src={
                            logo &&
                            `${window.SERVER_CONFIG.REACT_APP_MESH_API_URL}/${window.SERVER_CONFIG.REACT_APP_MESH_PROJECTS}/nodes/${logo.uuid}/binary/image`
                        }
                        style={{ maxHeight: '40px', verticalAlign: 'bottom' }}
                    />,
                    'header-logo'
                )}
            </Navbar.Group>
            {/* Give all remaining space to the nav links. Hide overflow to force space calculation. */}
            <Navbar.Group
                id="header-menu-group"
                style={{ flex: '1', justifyContent: 'flex-end', overflow: 'hidden' }}
            >
                {navLinks && navLinks.length > 0 && (
                    <Fragment>
                        <OverflowList
                            observeParents
                            overflowRenderer={(navItems) => (
                                <Popover
                                    minimal
                                    content={
                                        <Menu>
                                            {navItems.map((navItem) => (
                                                <li key={(navItem && navItem.key) || ''}>
                                                    {navItem}
                                                </li>
                                            ))}
                                        </Menu>
                                    }
                                    position={Position.BOTTOM_LEFT}
                                >
                                    <Button className="bp3-minimal" icon="more" />
                                </Popover>
                            )}
                            visibleItemRenderer={(item: any) => item}
                            items={renderNavLinks()}
                        />
                        <Navbar.Divider />
                    </Fragment>
                )}
                {renderProfile()}
            </Navbar.Group>
        </Navbar>
    );
};

TempHeader.defaultProps = {
    profileDropdown: undefined,
};

export default withRouter(TempHeader);
