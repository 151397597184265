import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';

import { MipsAttestToPromotingInteroperability } from '../../../generator/generated/MeshInterfaces';
import { MipsPIAttestationMeasure } from '../../../models/PIAttestation';
import { RowAction } from '../../components/Tables/List';

export const getMipsAttestToPromotingInteroperabilitySchema = ({
    columns,
}: MipsAttestToPromotingInteroperability): JSONSchema6 => {
    const properties: { [k: string]: JSONSchema6Definition } = {};
    columns.forEach((column) => {
        switch (column.id) {
            case 'attested':
                properties[column.id] = {
                    type: ['object', 'null'],
                    title: column.label,
                    properties: {
                        value: {
                            type: 'boolean',
                        },
                    },
                };
                break;
            case 'startDate':
            case 'endDate':
                properties[column.id] = {
                    type: 'object',
                    title: column.label,
                    properties: {
                        value: {
                            type: 'object',
                            format: 'date-time',
                        },
                    },
                };
                break;
            case 'performanceRate':
                properties[column.id] = {
                    type: 'object',
                    properties: {
                        value: {
                            properties: {
                                numerator: {
                                    type: ['number'],
                                },
                                denominator: {
                                    type: ['number'],
                                },
                            },
                        },
                    },
                    title: column.label,
                };
                break;
            case 'actions':
                properties[column.id] = {
                    title: ' ',
                };
                break;
            default:
                properties[column.id] = {
                    type: 'string',
                    title: column.label,
                };
        }
    });
    return {
        type: 'array',
        items: {
            type: 'object',
            properties,
        },
    };
};

export const getAttestToPromotingInteroperabilityUISchema = (
    rowActions: RowAction[],
    totalRows: number,
    itemsPerPage: number,
    loading: boolean,
    onChange: (
        value: MipsPIAttestationMeasure[],
        field: keyof MipsPIAttestationMeasure,
        rowNumber: number
    ) => void
): UiSchema => {
    const emptyListMessage = {
        title: 'No Measures',
    };
    return {
        'ui:field': 'list',
        'ui:hash': 'mipsAttestToPromotingInteroperability',
        'ui:options': {
            emptyListMessage,
            rowActions,
            removable: true,
            showInSelectionView: false,
            loading,
            showSearchBar: true,
            searchPlaceholderText: ' ',
            editing: true,
            totalRows,
            itemsPerPage,
        },
        items: {
            shortMeasureId: {
                'ui:options': {
                    orderable: true,
                },
            },
            title: {
                'ui:options': { orderable: true },
            },
            objective: {
                'ui:options': {
                    orderable: true,
                },
            },
            attested: {
                'ui:widget': 'CheckboxWidget',
                'ui:options': {
                    orderable: true,
                    hideFieldMessageArea: true,
                    onChange,
                },
            },
            startDate: {
                'ui:readonly': false,
                'ui:widget': 'DateWidget',
                'ui:options': {
                    orderable: true,
                    hideLabel: true,
                    hidePopover: true,
                    onChange,
                },
            },
            endDate: {
                'ui:readonly': false,
                'ui:widget': 'DateWidget',
                'ui:options': {
                    hideLabel: true,
                    hidePopover: true,
                    orderable: true,
                    onChange,
                },
            },
            performanceRate: {
                'ui:readonly': false,
                'ui:widget': 'FractionWidget',
                'ui:options': {
                    hideLabel: true,
                    hidePopover: true,
                    orderable: false,
                    onChange,
                },
            },
        },
    };
};
