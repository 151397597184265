import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TTableEmptyProps } from '@amxjs/ui';
import {
    UserList as UserListInterface,
    FhirListColumn,
    FhirListRowAction,
    FhirListTableAction,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import FhirList, { FhirListColumnWithRenderMode } from '../../components/Tables/FhirList';
import Store from '../../core/Store';
import { SchemaProvider } from '../../mesh/Schema';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import filterAndSort, { setSortingValues, sortValues } from '../utils/filterAndSort';

interface UserListProps extends UserListInterface {
    store: Store;
}

function UserList(props: UserListProps & RouteComponentProps) {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [paginationOffset, setPaginationOffset] = useState(0);
    const itemsPerPage = props.itemsPerPage || 25;
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const [columnSorting, setColumnSorting] = useState<Record<string, string>>({});
    const [filterString, setFilterString] = useState('');
    const [graphQLQuery, setGraphQLQuery] = useState<string>('');
    const [graphQLQueryVersion, setGraphQLQueryVersion] = useState(0);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.title);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    useEffect(() => {
        if (props.columns) {
            const sorting: Record<string, string> = {};

            props.columns.map(setSortingValues(sorting));

            setColumnSorting(sorting);
        }
    }, [props.columns]);

    useEffect(() => {
        setGraphQLQuery(
            `
        UserList(searchString: "${filterString}", pageCount: ${itemsPerPage}, offset:${paginationOffset}) {
          meta {
            totalCount
          }
          returnData {
            active (${filterAndSort(columnSorting.Status)})
            username (${filterAndSort(columnSorting.Username)})
            email (${filterAndSort(columnSorting.EmailAddress)})
            emailVerified
            name {
              family (${filterAndSort(columnSorting.LastName)})
              given (${filterAndSort(columnSorting.FirstName)})
            }
            address {
              city
              state
              country
            }
            access {
              persona (${filterAndSort(columnSorting.Persona)})
              personaDisplay
              managingEntity (${filterAndSort(columnSorting.Access)})
            }
            fhirPractitionerId
            fhirPersonId
            identifier {
              system
              value
            },
          }
        }
    `
        );
    }, [graphQLQueryVersion]);

    const getBaseColumns = (): FhirListColumn[] => {
        const jsonPaths = {
            ID: ['$.fhirPractitionerId'],
            Status: ['$.active'],
            FirstName: ['$.name.given[0]'],
            LastName: ['$.name.family'],
            Persona: ['$.access[*].personaDisplay'],
            EmailAddress: ['$.email'],
            Access: ['$.access[*].managingEntity'],
            Username: ['$.username'],
        };

        return props.columns.map((col) => {
            const fhirColumn: FhirListColumnWithRenderMode = {
                ...col,
                // label: this.props.labels[col.id] || col.label,
                jsonPath: jsonPaths[col.id],
            };

            if (col.id === 'Status') {
                fhirColumn.renderMode = 'active';
            } else if (col.id === 'Persona' || col.id === 'Access') {
                fhirColumn.renderMode = 'tag';
            } else {
                fhirColumn.renderMode = 'default';
            }

            return fhirColumn;
        });
    };

    const getRowActions = (): FhirListRowAction[] => {
        return [
            {
                id: 'view',
                label: 'View',
                behavior: 'Navigate',
                intent: 'primary',
                link: props.ViewPageLink,
                idJsonPath: '$.username',
            },
        ];
    };

    const getTableActions = (): FhirListTableAction[] => {
        return [
            {
                id: 'addUser',
                label: 'Add User',
                intent: 'primary',
                icon: 'add',
                behavior: 'Navigate',
                link: props.ViewPageLink,
            },
        ];
    };

    const onLoadMore = () => {
        setPaginationOffset(itemsPerPage * pagesLoaded);
        setPagesLoaded(pagesLoaded + 1);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const tableActions = getTableActions();
    const addUserAction = tableActions.find((tableAction) => tableAction.id === 'addUser');
    const emptyMessage: TTableEmptyProps = {
        title: 'No Users',
        description: `Select "${addUserAction ? addUserAction.label : null}" to get started`,
    };

    const resetPagination = () => {
        setPaginationOffset(0);
        setPagesLoaded(1);
    };

    const onSortChanged = (sortValue: string, columnId: string) => {
        // When sorting changes:
        // 1. the pagination resets (handled here)
        // 2. the list is cleared (handled in FhirList)
        // 3. the list is populated with the new first data set (handled in FhirList)
        resetPagination();
        setColumnSorting({ ...columnSorting, [columnId]: sortValues[sortValue] });
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    const onFilterStringChange = (searchString: string) => {
        resetPagination();
        setFilterString(searchString);
        setGraphQLQueryVersion(graphQLQueryVersion + 1);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <FhirList
                showSearchBar
                store={props.store}
                allowExport={props.allowExport}
                itemsPerPage={itemsPerPage}
                onLoadMore={onLoadMore}
                appendResults
                title={props.title}
                graphQLQuery={graphQLQuery}
                columns={getBaseColumns()}
                rowActions={getRowActions()}
                tableActions={tableActions}
                schemaId={props.schemaId}
                emptyDataListMessage={emptyMessage}
                onSortChanged={onSortChanged}
                onFilterStringChange={onFilterStringChange}
                useServerSideSearch
            />
        </SchemaProvider>
    );
}

// export default withRouter(withDataDictionary(UserList));
export default withRouter(UserList);
