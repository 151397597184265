import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isObjectLike } from 'lodash';
import { style } from 'typestyle';

import { ItemRenderer, Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';

import WidgetTitleField from '../fields/WidgetTitleField';

const ItemSelect = Select.ofType<string>();

const containerClassName = style({ position: 'relative', paddingTop: '4px' });

const SelectWidget = (props: any) => {
    const [selectedItem, setSelectedItem] = useState<any | null>(
        (props.options.selectedItem as any) || null
    );
    const { label, required, id, value } = props;

    const items: any[] = useMemo(
        () => (props.options.enumOptions as { value: any }[]).map((option) => option.value),
        [props.options.enumOptions]
    );

    const findAndSetItem = useCallback(
        (item?: any) => {
            if (item) {
                let auxItem = item;
                const isObject = isObjectLike(item);
                if (isObject) {
                    auxItem = item?.props ? { id: item.props.id, name: item.props.value } : item;
                }
                const opt = items.find((o) => (isObject ? o.id === auxItem.id : o === auxItem));
                setSelectedItem(opt);
            }
        },
        [items]
    );

    useEffect(() => {
        if (value) {
            findAndSetItem(value);
        }
    }, [value, findAndSetItem]);

    const renderItem: ItemRenderer<any> = (item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        return (
            <MenuItem
                key={item.id || item}
                active={modifiers.active}
                disabled={modifiers.disabled}
                onClick={handleClick}
                text={item.name || item}
            />
        );
    };

    return (
        <div className={containerClassName}>
            {label ? <WidgetTitleField required={required} title={label} id={id} /> : null}
            <ItemSelect
                activeItem={selectedItem}
                matchTargetWidth={false}
                items={items}
                itemRenderer={renderItem}
                filterable={false}
                onItemSelect={(item) => {
                    setSelectedItem(item);
                    props.onChange(item);
                }}
                inputProps={{
                    className: props.rawErrors && props.rawErrors.length > 0 ? 'error-border' : '',
                }}
                popoverProps={{ minimal: true, fill: true }}
            >
                <Button
                    text={selectedItem ? selectedItem.name || selectedItem : ' '}
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        marginTop: '5px',
                        whiteSpace: 'normal',
                    }}
                    rightIcon="double-caret-vertical"
                />
            </ItemSelect>
        </div>
    );
};

export default SelectWidget;
