export function getColumnOrder(columns: { id: string }[]): string[] | undefined {
    if (!columns || !columns.length) return undefined;
    return columns.map((column) => column.id);
}

export function columnIsPresentInConfiguration(
    columns: { id: string }[],
    columnName: string
): boolean {
    if (!columns || !columns.length || !columnName) return false;
    const filteredColumns = columns.filter(
        (column) => column && column.id && column.id === columnName
    );
    return filteredColumns !== undefined && filteredColumns.length > 0;
}

// Handle for some undefined/null safe checks of what the sort order for a specific column should be.
// By default, if there is no sort order it will be 'UNSET'.
// Allows specifying what the default sort should be, as in some cases it will be 'OFF'.
const UNSET = 'UNSET';
export function getSortOrderForColumn(
    columns: { id: string; sortable?: string }[],
    columnName: string,
    defaultSort?: 'OFF' | 'UNSET' | 'ASCENDING' | 'DESCENDING'
): string {
    if (!columns || !columns.length || !columnName) return UNSET;
    const filteredColumns = columns.filter(
        (column) => column && column.id && column.id === columnName
    );
    return filteredColumns &&
        filteredColumns.length &&
        filteredColumns[0] &&
        filteredColumns[0].sortable
        ? filteredColumns[0].sortable
        : defaultSort || UNSET;
}
