/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compact, get, omit } from 'lodash';
import { isObservableArray } from 'mobx';
import {
    SortedFilteredTable,
    TTableActionProps,
    TTableRowActionProps,
    TTableRowProps,
    TTableEmptyProps,
} from '@amxjs/ui';
import { Icon, IconName, Intent, Tag, Tooltip } from '@blueprintjs/core';
import {
    FhirListColumn,
    List as ListProps,
    ListColumn,
    ListRowAction,
    ListTableAction,
    Page,
} from '../../../generator/generated/MeshInterfaces';
import { normalizedColumns } from './TableUtils';
import formatDate from '../../util/GeneralUtils';
import { exportToCsvAction } from './TableActions';
import { ListPopover } from './ListPopover';
import DialogHandler from '../../pages/DialogHandler';
import DeleteDialog from '../../pages/DeleteDialog';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../util/DataDictionary';
import CheckboxWidget from '../forms/widgets/CheckboxWidget';
import SelectWidget from '../forms/widgets/SelectWidget';
import DateWidget from '../forms/widgets/DateWidget';
import FractionWidget from '../forms/widgets/FractionWidget';

export interface ListColumnWithRenderMode extends ListColumn {
    renderMode?:
        | 'active'
        | 'checkbox'
        | 'dash'
        | 'date'
        | 'default'
        | 'fraction'
        | 'iconAndText'
        | 'select'
        | 'tag';
    hideLabel?: boolean;
    hidePopover?: boolean;
    columnControl?: boolean;
    valueSet?: Record<string, string>;
    callbackToDetermineIconAndText?: (jsonData: any) => void; // If the renderMode is of type 'iconAndText' this needs to be passed in
    showColumn?: boolean;
}

export interface TableAction extends ListTableAction {
    callbackOnDialog?: () => void;
    callbackOnNavigation?: () => void;
    callbackOnTriggerAction?: () => void;
}

export interface RowAction extends ListRowAction {
    callbackOnDialog?: (rowData: any) => void;
    callbackOnNavigation?: (rowData: any) => void;
    callbackOnDelete?: (rowData: any, id: number) => void;
    callbackOnCustom?: (rowData: any, id: number) => void;
    disabledActions?: string[];
    setQueryParametersOnNavigate?: (routePath: string) => string;
}

export interface ListInterfaceProps extends ListProps {
    columns: ListColumnWithRenderMode[];
    onFilterStringChange?: any;
    onFilterStringChangeForInListFiltering?: any;
    showSearchBar?: boolean;
    showDisabledRowActions?: boolean;
    stickyHeaderOffset?: number;
    showColumnControl?: boolean;
    selectAllValues?: any;
    onSelectAllRows?(key?: string, value?: boolean, filteredRows?: any[]): void;
    searchPlaceholderText?: string;
    useServerSideSearch?: boolean;
    unsearchableColumns?: string[];
    tableActions?: TableAction[];
    rowActions?: RowAction[];
    description?: JSX.Element;
    loading?: boolean;
    totalCountLoading?: boolean;
    onLoadMore?: () => void;
    onSortChanged?: (value: string, columnId: string) => void;

    // Maps the name of a schema to a react component class. ClinicianDetails.tsx is an example of this.
    // Used to prevent circular dependencies from MeshComponentMapper.ts
    schemaToMeshType?: Map<string, any>;

    rowsOfData: any[];
    totalRows?: number;
    editing?: boolean;
    fieldNameForDeleteDialog?: string;
    callout?: any;
    emptyListMessage?: TTableEmptyProps; // Messages to be shown on the empty list when the search yields no results
    emptyDataListMessage?: TTableEmptyProps; // Messages to be shown on the empty list when there are no rows of data
    enableDropdownActionMenu?: boolean;
    dropdownActionMenuParent?: Record<string, any>;
    selectedYear?: any;
    columnOrder?: string[];
}

interface ListState {
    dialogs: { [key: string]: boolean };
    tableActions: TTableActionProps[];
    rowActions: TTableRowActionProps[];
    filterString: string;
    filteredRows: any[];
    searchable: boolean;
    rowDataToDelete: any;
    rowId: number;
    recordNameBeingDeleted: string;
    sizeOfList: number;
    filteredRowData: any[];
    useInListFilter: boolean;
    timer: any;
    windowWidth: number;
}

const tagStyle = {
    marginRight: '5px',
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: '#D9E1E8',
    color: '#182026',
};

const hiddenTagStyle = {
    display: 'none',
};

class List extends Component<
    ListInterfaceProps & RouteComponentProps & InjectedDataDictionaryProps,
    ListState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            dialogs: {},
            filterString: '',
            searchable: false,
            rowDataToDelete: '',
            rowId: 0,
            recordNameBeingDeleted: '',
            sizeOfList: props.itemsPerPage,
            filteredRowData: props.rowsOfData,
            useInListFilter: !props.useServerSideSearch,
            timer: null,
            windowWidth: 0,
            filteredRows: [],
            tableActions: [],
            rowActions: [],
        };
    }

    componentDidMount = () => {
        window.addEventListener('resize', () => {
            if (this.state.windowWidth !== window.innerWidth)
                this.setState({ windowWidth: window.innerWidth });
        });
        this.buildTableAndRowActions();
    };

    componentDidUpdate = (
        prevProps: Readonly<ListInterfaceProps & RouteComponentProps & InjectedDataDictionaryProps>
    ): void => {
        if (
            this.props.tableActions !== prevProps.tableActions ||
            this.props.rowActions !== prevProps.rowActions
        ) {
            this.buildTableAndRowActions();
        }
    };

    onFilterStringChange = (event: any) => {
        const { useInListFilter } = this.state;
        const searchString = (event.target.value as string).toLowerCase();
        this.setState({
            filterString: searchString,
            searchable: false,
        });
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({
                timer: null,
            });
        }
        this.setState({
            timer: setTimeout(() => {
                const searchStr = this.state.filterString;
                this.setState({
                    searchable: searchStr === '' || searchStr.length > 1,
                });
                if (!useInListFilter) {
                    this.setState({
                        sizeOfList: this.props.itemsPerPage || 25,
                    });
                    if (
                        this.props.onFilterStringChange &&
                        (searchStr === '' || searchStr.length > 1)
                    ) {
                        this.props.onFilterStringChange(searchStr);
                    }
                }
            }, 750),
        });
    };

    onSortChanged = (sortValue: string, columnId: string) => {
        this.setState({
            sizeOfList: this.props.itemsPerPage || 25,
        });
        if (this.props.onSortChanged) {
            this.props.onSortChanged(sortValue, columnId);
        }
    };

    buildTableAndRowActions = () => {
        const dialogs: { [key: string]: boolean } = {};

        const tableActions: TTableActionProps[] = [];
        if (this.props.allowExport) {
            tableActions.push(exportToCsvAction);
        }
        this.props.tableActions?.map((action: TableAction) => {
            if (action.behavior === 'Dialog') {
                dialogs[`table-${action.id}`] = false;
            }
            tableActions.push({
                disabled: action.disabled,
                label: action.label,
                intent: action.intent ? (action.intent as Intent) : undefined,
                iconName: action.icon ? (action.icon as IconName) : undefined,
                onTrigger: async (_rowIds: string[], _row: any[]) => {
                    switch (action.behavior) {
                        case 'Navigate': {
                            const { route } = action.link as Page;
                            if (route) {
                                this.props.history.push(route);
                            } else {
                                console.error('Linked Component is not a Page to route to.');
                            }
                            if (action.callbackOnNavigation) {
                                action.callbackOnNavigation();
                            }
                            break;
                        }
                        case 'Dialog': {
                            this.setState({
                                dialogs: {
                                    [`table-${action.id}`]: true,
                                },
                            });
                            if (action.callbackOnDialog) {
                                action.callbackOnDialog();
                            }
                            break;
                        }
                        case 'TriggerAction': {
                            if (action.callbackOnTriggerAction) {
                                action.callbackOnTriggerAction();
                            }
                            break;
                        }
                        default:
                            break;
                    }
                },
            });
        });

        const rowActions: TTableRowActionProps[] = [];
        this.props.rowActions?.map((action: RowAction) => {
            if (action.behavior === 'Dialog' || action.behavior === 'Delete') {
                dialogs[`row-${action.id}`] = false;
            }
            rowActions.push({
                id: action.id,
                label: action.label,
                intent: action.intent ? (action.intent as Intent) : undefined,
                iconName: action.icon ? (action.icon as IconName) : undefined,
                rightIcon: action.rightIcon ? (action.rightIcon as IconName) : undefined,
                showInline: true,
                maxSelection: 1,
                showInSelectionView: true,
                hideIfDisabled: !this.props.showDisabledRowActions,
                showLabelInButton: true,
                size: action.size,
                disabledActions: action.disabledActions,
                onTrigger: async (rowIds: string[], _row: any[]) => {
                    if (action.behavior === 'Navigate') {
                        const { route } = action.link as Page;

                        let id;

                        if (action.idName) {
                            id = this.state.filteredRowData[parseInt(rowIds[0], 10)][action.idName];
                        }

                        if (route) {
                            let routePath = route;
                            if (id) {
                                routePath += `?id=${encodeURIComponent(id)}`;
                            }

                            if (typeof action.setQueryParametersOnNavigate === 'function') {
                                routePath = action.setQueryParametersOnNavigate(routePath);
                            }

                            this.props.history.push(routePath);
                        }

                        if (action.callbackOnNavigation) {
                            action.callbackOnNavigation(
                                this.state.filteredRowData[parseInt(rowIds[0], 10)]
                            );
                        }
                    } else if (action.behavior === 'Dialog') {
                        if (action.callbackOnDialog) {
                            action.callbackOnDialog(
                                this.state.filteredRowData[parseInt(rowIds[0], 10)]
                            );
                        }

                        this.setState({
                            dialogs: {
                                [`row-${action.id}`]: true,
                            },
                        });
                    } else if (action.behavior === 'Delete') {
                        const arrayIndex = parseInt(rowIds[0], 10);
                        let deleteField = '';
                        if (this.props.fieldNameForDeleteDialog) {
                            deleteField = this.state.filteredRowData[arrayIndex][
                                this.props.fieldNameForDeleteDialog
                            ].props
                                ? this.state.filteredRowData[arrayIndex][
                                      this.props.fieldNameForDeleteDialog
                                  ].props.value
                                : this.state.filteredRowData[arrayIndex][
                                      this.props.fieldNameForDeleteDialog
                                  ];
                        }

                        this.setState({
                            dialogs: {
                                [`row-${action.id}`]: true,
                            },
                            rowDataToDelete: this.state.filteredRowData[arrayIndex],
                            rowId: arrayIndex,
                            recordNameBeingDeleted: deleteField,
                        });
                    } else if (action.behavior === 'Custom') {
                        const arrayIndex = parseInt(rowIds[0], 10);
                        if (action.callbackOnCustom) {
                            action.callbackOnCustom(
                                this.state.filteredRowData[parseInt(rowIds[0], 10)],
                                arrayIndex
                            );
                        }
                    }
                },
            });
        });

        this.setState({
            dialogs,
            filterString: '',
            searchable: false,
            rowDataToDelete: '',
            rowId: 0,
            recordNameBeingDeleted: '',
            filteredRowData: this.props.rowsOfData,
            useInListFilter: !this.props.useServerSideSearch,
            timer: null,
            windowWidth: 0,
            filteredRows: [],
            tableActions,
            rowActions,
        });
    };

    buildTagValue = (value: any) => {
        if (!Array.isArray(value) && !isObservableArray(value)) {
            return (
                <Tag key={value} style={tagStyle} htmlTitle="">
                    <ListPopover content={value} />
                </Tag>
            );
        }

        const uniqueValues = [...new Set(value.reduce((acc, val) => acc.concat(val), []) as any[])];

        let contentLength = 0;
        const valueLength = uniqueValues.length;
        const noNullValues = compact(uniqueValues);
        return noNullValues.map((data: string, index: number) => {
            // Returns a hidden tag - not to be displayed - but in order for the Export List functionality to work correctly
            // Because the export looks at the current data of the row, not the source of where the data came from
            if (contentLength > 35) {
                return (
                    <Tag key={data} style={hiddenTagStyle}>
                        {data}
                    </Tag>
                );
            }
            if (data && data.length) {
                contentLength += data.length;
            }
            if (contentLength > 35) {
                // Prevents creating the '+ More' text if this is the last item in the list
                const plusMoreTag = valueLength - 1 !== index ? '+ More' : null;
                return (
                    <div key={data}>
                        <Tag style={tagStyle} htmlTitle="">
                            <ListPopover content={data} />
                        </Tag>
                        {plusMoreTag}
                    </div>
                );
            }
            return (
                <Tag key={data} style={tagStyle} htmlTitle="">
                    <ListPopover content={data} />
                </Tag>
            );
        });
    };

    buildFractionValue = (value: any, className: string) => {
        return <FractionWidget {...value?.props} className={className} />;
    };

    buildDefaultValue = (
        defaultValue: any,
        className: string,
        columnOptions: ListColumnWithRenderMode | null
    ) => {
        let displayValue = defaultValue;
        if (columnOptions) {
            if (columnOptions.valueSet && Object.keys(columnOptions.valueSet).length) {
                if (Array.isArray(columnOptions.valueSet)) {
                    const replacement = columnOptions.valueSet.filter(
                        (v: any) =>
                            v.value === defaultValue ||
                            v.value === (defaultValue.props && defaultValue.props.value)
                    )[0];
                    if (replacement) {
                        displayValue = replacement.label;
                    }
                }
            }
            if (columnOptions.hidePopover) {
                return displayValue;
            }
        }
        return (
            <ListPopover
                key={defaultValue}
                content={displayValue}
                textClassName={`${defaultValue}_${this.state.windowWidth} ${className}`}
            />
        );
    };

    buildDateValue = (value: any, className: string) => {
        return <DateWidget {...value?.props} className={className} />;
    };

    buildCheckboxValue = (value: any) => {
        return <CheckboxWidget {...value.props} label="" required={false} readonly={false} />;
    };

    buildSelectValue = (value: any) => {
        return <SelectWidget {...value.props} />;
    };

    flattenObject = (obj: any, index: number) => {
        const flattened: any = {};
        Object.keys(obj).forEach((key) => {
            let currentColumn: ListColumnWithRenderMode | null = null;
            this.props.columns.forEach((column) => {
                if (column.id === key && !currentColumn) {
                    currentColumn = column as ListColumnWithRenderMode;
                }
            });

            if (!currentColumn && typeof obj[key] === 'object' && obj[key] !== null) {
                Object.assign(flattened, this.flattenObject(obj[key], index));
            } else {
                const value = obj[key];
                const columnOptions = currentColumn
                    ? (currentColumn as ListColumnWithRenderMode)
                    : null;
                const options = get(value, 'props.options', {});

                if (columnOptions && columnOptions.renderMode === 'tag' && value) {
                    flattened[key] = this.buildTagValue(value);
                } else if (columnOptions && columnOptions.renderMode === 'fraction') {
                    flattened[key] = this.buildFractionValue(value, obj.className);
                } else if (columnOptions && columnOptions.renderMode === 'active') {
                    flattened[key] = value ? 'Active' : 'Inactive';
                } else if (columnOptions && columnOptions.renderMode === 'date') {
                    if (value?.props?.readonly === false) {
                        flattened[key] = this.buildDateValue(value, obj.className);
                    } else {
                        const date = get(value, 'props.value', value);
                        flattened[key] = this.buildDefaultValue(
                            formatDate(date),
                            obj.className,
                            columnOptions
                        );
                    }
                } else if (
                    (columnOptions && columnOptions.renderMode === 'dash') ||
                    options.renderMode === 'dash'
                ) {
                    if (!value) {
                        flattened[key] = '-';
                    } else if (value.props && !value.props.value) {
                        flattened[key] = '-';
                    } else {
                        flattened[key] = this.buildDefaultValue(
                            value,
                            obj.className,
                            columnOptions
                        );
                    }
                } else if (
                    options.renderMode === 'checkbox' ||
                    columnOptions?.renderMode === 'checkbox'
                ) {
                    flattened[key] = this.buildCheckboxValue(value);
                } else if (options.renderMode === 'select') {
                    flattened[key] = this.buildSelectValue(value);
                    // Handle building out a cell with an optional icon, optional text, and optional tooltip
                } else if (
                    columnOptions &&
                    columnOptions.renderMode === 'iconAndText' &&
                    columnOptions.callbackToDetermineIconAndText
                ) {
                    // @ts-ignore
                    const iconAndTextContent: {
                        iconName?: IconName;
                        iconColor?: string;
                        tooltipText?: string;
                        displayText?: string;
                    } = columnOptions.callbackToDetermineIconAndText(value);
                    flattened[key] = (
                        <div style={{ display: 'flex' }} key={value}>
                            {iconAndTextContent.iconName ? (
                                <Icon
                                    color={iconAndTextContent.iconColor}
                                    icon={iconAndTextContent.iconName}
                                    style={{ paddingRight: '5px' }}
                                />
                            ) : undefined}
                            {iconAndTextContent.tooltipText ? (
                                <Tooltip content={iconAndTextContent.tooltipText}>
                                    {iconAndTextContent.displayText}
                                </Tooltip>
                            ) : (
                                <div>{iconAndTextContent.displayText}</div>
                            )}
                        </div>
                    );
                } else if (options.dependency && obj[options.dependency.field]) {
                    const dependentValue = obj[options.dependency.field];
                    const mode = options.dependency.modes[dependentValue];
                    if (mode === 'dash') {
                        flattened[key] = '-';
                    } else if (mode === 'select') {
                        flattened[key] = this.buildSelectValue(value);
                    } else {
                        this.buildDefaultValue(value, obj.className, columnOptions);
                    }
                } else {
                    flattened[key] = this.buildDefaultValue(value, obj.className, columnOptions);
                }
            }
        });

        flattened.disabledActions = obj.disabledActions;
        return flattened;
    };

    onLoadMore = () => {
        this.setState((prevState) => ({
            sizeOfList: prevState.sizeOfList + (this.props.itemsPerPage || 25),
        }));
        if (this.props.onLoadMore) {
            this.props.onLoadMore();
        }
    };

    prepareColumns(
        columns: ListColumnWithRenderMode[],
        columnOrder: string[] | undefined
    ): FhirListColumn[] {
        return columns
            .filter((col) => {
                // Filter out any columns that should not be showing in the table
                return col.showColumn === undefined || col.showColumn;
            })
            .sort((columnA, columnB) => {
                if (!columnOrder || !columnOrder.length) return 0;
                const indexColumnA = columnOrder.indexOf(columnA.id);
                const indexColumnB = columnOrder.indexOf(columnB.id);
                // Sort the order of the columns if a sorting order is present.
                // If the column is not present in mesh
                // configuration, then push the column to the far right
                return indexColumnA !== -1 && indexColumnB !== -1 ? indexColumnA - indexColumnB : 0;
            })
            .map((col) => ({
                ...col,
                label: this.props.labels[col.id] || col.label,
            }));
    }

    // Flattens each row to allow filtering by list display values.
    // Returns filtered arrays for both the flattened objects and original row objects
    // because the state relies on the raw data format
    filterRows(rawRows: any[], filterString: string) {
        const lowerCaseFilter = filterString.toLowerCase();
        const { unsearchableColumns } = this.props;

        const rows: any[] = [];
        const flattenedRows: any[] = [];
        rawRows.filter((row, index) => {
            // Flatten the row to allow filtering on all fields by string
            const flattenedRow = this.flattenObject(row, index);

            // Remove the idFieldForLink since it may contain the filter term
            // and any provided unsearchable columns
            const omissions = unsearchableColumns
                ? unsearchableColumns.concat('idFieldForLink')
                : 'idFieldForLink';

            const searchBy = omit(flattenedRow, omissions);
            const rowValues = Object.values(searchBy);

            rowValues.forEach((val: any) => {
                let matches = false;

                if (val && typeof val === 'string') {
                    if (val.toLowerCase().includes(lowerCaseFilter)) {
                        matches = true;
                    }
                }

                if (val && val.key && typeof val.key === 'string') {
                    if (val.key.toLowerCase().includes(lowerCaseFilter)) {
                        matches = true;
                    }
                }

                if ((Array.isArray(val) || isObservableArray(val)) && val.length) {
                    val.forEach((v) => {
                        if (typeof v === 'string') {
                            if (v.toLowerCase().includes(lowerCaseFilter)) {
                                matches = true;
                            }
                        } else if (v.key && typeof v.key === 'string') {
                            if (v.key.toLowerCase().includes(lowerCaseFilter)) {
                                matches = true;
                            }
                        }
                    });
                }

                if (val && typeof val === 'object') {
                    // This is a bit of an awkward check however it is needed in some cases where the actual value of the cell
                    // is nested in a few different spots depending on how the content of the table was populated.
                    const v =
                        get(val, 'props.children.props.value', null) ||
                        get(val, 'props.value', null) ||
                        get(val, 'props.content.props.value', null);
                    if (v && v.toString().toLowerCase().includes(lowerCaseFilter)) {
                        matches = true;
                    }
                }

                if (matches && !flattenedRows.includes(flattenedRow)) {
                    rows.push(row);
                    flattenedRows.push(flattenedRow);
                }
            });
        });

        return { rows, flattenedRows };
    }

    prepareRows(rawRows: any[]) {
        const { useInListFilter, searchable, filterString, sizeOfList } = this.state;

        let rows = [];
        let flattenedRows = [];

        if (useInListFilter && searchable) {
            ({ rows, flattenedRows } = this.filterRows(rawRows, filterString));
        }

        // Keep track of the original rows since the state relies on that format
        const totalRows = useInListFilter && searchable ? rows : rawRows;
        const totalFlattenedRows =
            useInListFilter && searchable
                ? flattenedRows
                : rawRows.map((row, index) => this.flattenObject(row, index));

        const showLoadMoreButton = totalRows.length > this.state.sizeOfList;

        const renderedRows = showLoadMoreButton ? totalRows.slice(0, sizeOfList) : totalRows;
        const flattenedRenderedRows = showLoadMoreButton
            ? totalFlattenedRows.slice(0, sizeOfList)
            : totalFlattenedRows;

        // Makes sure that the rowData in the state has the correct filtered data for what is rendered on the page
        // This is important when filtering or searching data since the order on the page changes
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = { ...this.state, filteredRowData: renderedRows };

        const filteredRows = [
            ...flattenedRenderedRows.map((dataRow, index) => {
                const row: TTableRowProps = {
                    id: index.toString(),
                    cells: dataRow,
                    actionIds: this.state.rowActions.map((a: any) => {
                        if (
                            dataRow.disableAllRowActions &&
                            dataRow.disableAllRowActions.key === 'true'
                        ) {
                            return null;
                        }
                        if (dataRow.disabledActions) {
                            if (dataRow.disabledActions.indexOf(a.id) === -1) {
                                return a.id;
                            }
                            return null;
                        }
                        return a.id;
                    }),
                };
                return row;
            }),
        ];

        if (useInListFilter) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state = { ...this.state, filteredRows: flattenedRenderedRows };

            // NOTE / TODO: we need to find another way to notify the outside world for the flattenedRenderedRows with onFilterStringChange is NOT a good approach.
            //  For now, we changed the function name to differentiate with normal onFilterStringChange()
            if (this.props.onFilterStringChangeForInListFiltering) {
                this.props.onFilterStringChangeForInListFiltering(flattenedRenderedRows);
            }
        }
        return filteredRows;
    }

    renderDialogs(): JSX.Element[] {
        const dialogs: JSX.Element[] = [];

        const closeDialog = (id: string) => {
            return () => {
                this.setState({
                    dialogs: {
                        [id]: false,
                    },
                });
            };
        };

        const onSubmitDelete = (
            id: string,
            callbackOnDelete?: (rowData: any, id: number) => void
        ) => {
            return () => {
                if (callbackOnDelete) {
                    callbackOnDelete(this.state.rowDataToDelete, this.state.rowId);
                }
                this.setState({
                    dialogs: {
                        [id]: false,
                    },
                    rowDataToDelete: '',
                });
            };
        };

        if (this.props.tableActions) {
            this.props.tableActions.map((action) => {
                if (action.behavior === 'Dialog') {
                    const id = `table-${action.id}`;
                    dialogs.push(
                        <DialogHandler
                            key={id}
                            // store={this.props.store}
                            dialogChild={action.link}
                            onClose={closeDialog(id)}
                            isOpen={this.state.dialogs[id]}
                            canEscapeKeyClose={action.canEscapeKeyClose}
                            canOutsideClickClose={action.canOutsideClickClose}
                            schemaToMeshType={this.props.schemaToMeshType}
                        />
                    );
                }
            });
        }

        if (this.props.rowActions) {
            this.props.rowActions.map((action) => {
                if (action.behavior === 'Dialog') {
                    const id = `row-${action.id}`;
                    dialogs.push(
                        <DialogHandler
                            key={id}
                            // store={this.props.store}
                            dialogChild={action.link}
                            onClose={closeDialog(id)}
                            isOpen={this.state.dialogs[id]}
                            canEscapeKeyClose={action.canEscapeKeyClose}
                            canOutsideClickClose={action.canOutsideClickClose}
                            schemaToMeshType={this.props.schemaToMeshType}
                        />
                    );
                } else if (action.behavior === 'Delete') {
                    const id = `row-${action.id}`;
                    dialogs.push(
                        <DeleteDialog
                            key={id}
                            onClose={closeDialog(id)}
                            onSubmit={onSubmitDelete(id, action.callbackOnDelete)}
                            isOpen={this.state.dialogs[id]}
                            recordNameBeingDelete={this.state.recordNameBeingDeleted}
                        />
                    );
                }
            });
        }
        return dialogs;
    }

    render() {
        const {
            editing,
            loading,
            emptyListMessage,
            emptyDataListMessage,
            rowsOfData,
            showSearchBar,
            showColumnControl,
            selectAllValues,
            onSelectAllRows,
            totalRows,
        } = this.props;
        // TODO: Remove editing check here. Should be controlled by consumers of List.
        const tableActions = editing ? this.state.tableActions : [];
        const rowActions = editing ? this.state.rowActions : [];
        const dataLength = totalRows || rowsOfData.length;
        const showLoadMoreButton =
            (totalRows && totalRows > this.state.sizeOfList) ||
            rowsOfData.length > this.state.sizeOfList;
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = { ...this.state, filteredRowData: rowsOfData };
        let emptyMessage: TTableEmptyProps | undefined = {
            title: 'No Results Found',
            description: 'Please try another search',
        };

        if (loading) {
            // Prevents empty message from displaying while loading
            emptyMessage = undefined;
        } else {
            if (dataLength && dataLength > 0) {
                if (emptyListMessage) {
                    emptyMessage = emptyListMessage;
                }
            } else if (emptyDataListMessage) {
                emptyMessage = emptyDataListMessage;
            }

            if (!emptyMessage.iconName) {
                emptyMessage.iconName = 'th-list';
            }
        }

        return (
            <>
                {...this.renderDialogs()}
                <SortedFilteredTable
                    onFilterStringChange={this.onFilterStringChange}
                    columns={normalizedColumns(
                        this.prepareColumns(this.props.columns, this.props.columnOrder)
                    )}
                    rows={this.prepareRows(rowsOfData)}
                    totalRows={this.props.totalRows || this.props.rowsOfData.length}
                    dataLength={dataLength}
                    title={this.props.title}
                    description={this.props.description}
                    itemsPerPage={this.state.sizeOfList}
                    rowActions={rowActions}
                    tableActions={tableActions}
                    verticallyCenterRowContents
                    enableStickyHeader
                    stickyHeaderOffset={this.props.stickyHeaderOffset}
                    showFooter
                    disableColumnHoverIndicators
                    showSearchBar={showSearchBar}
                    showColumnControl={showColumnControl}
                    selectAllValues={selectAllValues}
                    onSelectAllRows={(columnId, value) =>
                        onSelectAllRows && onSelectAllRows(columnId, value, this.state.filteredRows)
                    }
                    searchPlaceholderText={this.props.searchPlaceholderText}
                    showLoadMoreButton={showLoadMoreButton}
                    onLoadMore={this.onLoadMore}
                    emptyList={emptyMessage}
                    loading={this.props.loading}
                    callout={this.props.callout}
                    enableDropdownActionMenu={this.props.enableDropdownActionMenu}
                    dropdownActionMenuParent={this.props.dropdownActionMenuParent}
                    onSortChanged={this.onSortChanged}
                    serverSideSorting={!this.state.useInListFilter}
                    totalCountLoading={this.props.totalCountLoading}
                />
            </>
        );
    }
}

export default withRouter(withDataDictionary(List));
