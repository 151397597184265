import React, { useEffect, useState } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { WidgetProps } from 'react-jsonschema-form';
import { Suggest } from '@blueprintjs/select';
import { style } from 'typestyle';
import WidgetTitleField from '../fields/WidgetTitleField';

const ItemSuggest = Suggest.ofType<Item>();

const suggestStyle = style({
    height: '50px',
});

export interface Item {
    itemName: string;
    itemID: string;
}

function SuggestWidget(props: WidgetProps) {
    const [selectedItem, setSelectedItem] = useState<Item>();
    const [items, setItems] = useState<Item[]>([]);
    const { required, label, id } = props;
    const titleId = `${id}__title`;

    useEffect(() => {
        const tempItems: Item[] = [];
        if (props.schema.enum) {
            props.schema.enum.map((option: any) => {
                tempItems.push(option);
                if (props.value === option.itemName) {
                    setSelectedItem(option);
                }
            });
            setItems(tempItems);
        }
    }, [props.schema.enum, props.value]);

    const renderInputValue = (item: Item) => {
        return item.itemName;
    };

    const handleValueChange = (item: Item) => {
        setSelectedItem(item);
        props.onChange(item);
    };

    const renderItem = (item: Item, { handleClick, modifiers }: any) => {
        if (!modifiers.matchesPredicate || !item.itemID || !item.itemName) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item.itemID}
                onClick={handleClick}
                text={item.itemName}
            />
        );
    };

    const filterItem = (query: string, item: Item) => {
        return `${item.itemName && item.itemName.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
            {label ? <WidgetTitleField required={required} title={label} id={titleId} /> : null}
            <ItemSuggest
                className={suggestStyle}
                inputProps={{ placeholder: '' }}
                inputValueRenderer={renderInputValue}
                items={items}
                itemPredicate={filterItem}
                itemRenderer={renderItem}
                noResults={<MenuItem disabled text="No results." />}
                onItemSelect={handleValueChange}
                selectedItem={selectedItem}
                popoverProps={{ minimal: true }}
            />
        </div>
    );
}

export default SuggestWidget;
