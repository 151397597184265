import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import dateFormat from 'dateformat';

import { MipsImprovementActivitiesDetails as MipsImprovementActivitiesDetailsInterface } from '../../../generator/generated/MeshInterfaces';
import { EditOutput as ImprovementActivityOutput } from '../../../server/routes/Mips/EditImprovementActivityInterface';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../util/DataDictionary';
import Store, { MipsManageRecordListOutput } from '../../core/Store';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import { SchemaProvider } from '../../mesh/Schema';
import Form from '../../components/forms/Form';
import FormHeader from '../../components/forms/components/FormHeader';
import Api from '../../core/Api';
import SelectWidget from '../../components/forms/widgets/SelectWidget';
import { MultiSelectWidgetEnum } from '../../components/forms/widgets/MultiSelectWidget';
import GenericDialog from '../../components/forms/GenericDialog';
import {
    showNoChangesToast,
    showSuccessfulToast,
    showToastError,
    showToastErrors,
} from '../utils/CommonToasts';
import ConfirmExitDialog from '../ConfirmExitDialog';
import { buildBreadcrumbTrail } from './utils';
import MipsModuleConstants from './MipsModuleConstants';
import {
    getImprovementActivitiesDetailsUISchema,
    getImprovementActivitiesSchema,
} from './MipsImprovementActivitiesDetailsSchema';
import {
    getCopyIaConfirmationDialogSchema,
    getCopyIaConfirmationDialogUiSchema,
    getCopyIaDialogSchema,
    getCopyIaDialogUiSchema,
} from './CopyImprovementActivityDialog';

interface MipsImprovementActivitiesDetailsProps extends MipsImprovementActivitiesDetailsInterface {
    // eslint-disable-next-line react/no-unused-prop-types
    store: Store;
}

const belongsToPeriod = (formDate?: string, reportingYear?: string): boolean => {
    if (!formDate || !reportingYear) {
        return false;
    }
    const parsedFormDate = Date.parse(formDate);
    if (Number.isNaN(parsedFormDate)) {
        return false;
    }
    return Number.parseInt(reportingYear, 10) === new Date(parsedFormDate).getUTCFullYear();
};

const MipsImprovementActivitiesDetails = (
    props: MipsImprovementActivitiesDetailsProps & RouteComponentProps & InjectedDataDictionaryProps
) => {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [preferences, setPreferences] = useState(props.store?.MipsManageRecordList?.preferences);
    const [reportingYear, setReportingYear] = useState(
        props.store?.MipsManageRecordList?.reportingYear
    );
    const { measureId, editState } = queryString.parse(props.location.search);
    const [editMode, setEditMode] = useState(editState === 'Edit' || editState === 'Add');
    const [formData, setFormData] = useState(
        props.store?.MipsManageRecordList?.improvementActivityScores?.filter(
            (row) =>
                row.measureId &&
                measureId &&
                row.measureId.toLowerCase() === measureId.toLowerCase()
        )[0]
    );
    const [originalFormData, setOriginalFormData] = useState(
        props.store?.MipsManageRecordList?.improvementActivityScores?.filter(
            (row) =>
                row.measureId &&
                measureId &&
                row.measureId.toLowerCase() === measureId.toLowerCase()
        )[0]
    );
    const [saving, setSaving] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const [confirmCopyIADialogOpen, setConfirmCopyIADialogOpen] = useState(false);
    const [copyResultsIADialogOpen, setCopyResultsIADialogOpen] = useState(false);

    const [numberOfPractitionersCopyToAppliedTo, setNumberOfPractitionersCopyToAppliedTo] =
        useState(0);
    const [copyToAppliedGroup, setCopyToAppliedGroup] = useState(false);

    const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };
    function buildTrailData(record: MipsManageRecordListOutput | undefined) {
        breadcrumbTrail.labels = [];
        breadcrumbTrail.routes = [];
        setBreadcrumbData({
            crumbLabels: [],
            crumbRoutes: [],
        });
        const newTrail = buildBreadcrumbTrail([props.ParentPageLink], record);
        breadcrumbTrail.labels = newTrail.labels;
        breadcrumbTrail.routes = newTrail.routes;
        if (breadcrumbTrail.labels) breadcrumbTrail.labels.reverse();
        if (breadcrumbTrail.routes) breadcrumbTrail.routes.reverse();
        breadcrumbTrail.labels.push(`Improvement Activity Details`);

        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });
    }
    // If the store was not already loaded up with the required data to load this page then go ahead and make the API
    // call to get data. This is used in cases where the user reloads the page or they start at the measure details
    // page without having gone to the main record page.
    useEffect(() => {
        const { id, providerPractitionerFhirId, year } = queryString.parse(props.location.search);
        if (
            !props.store.mipsManageRecordList ||
            props.store.mipsManageRecordList.organizationFhirId !== id ||
            (!providerPractitionerFhirId &&
                props.store.mipsManageRecordList.providerPractitionerFhirId) ||
            (providerPractitionerFhirId &&
                props.store.mipsManageRecordList.providerPractitionerFhirId !==
                    providerPractitionerFhirId)
        ) {
            Api.mips
                .readForRecordDetails({
                    organizationFhirId: id,
                    providerPractitionerFhirId,
                    reportingYear:
                        year || props.labels.submissionYear || MipsModuleConstants.SubmissionYear,
                })
                .then(async (response) => {
                    const rawData = response.data as MipsManageRecordListOutput;
                    props.store.setMipsManageRecordList(rawData);
                    setPreferences(props.store?.MipsManageRecordList?.preferences);
                    setReportingYear(props.store?.MipsManageRecordList?.reportingYear);

                    const newFormData =
                        props.store?.MipsManageRecordList?.improvementActivityScores?.filter(
                            (row) =>
                                row.measureId &&
                                measureId &&
                                row.measureId.toLowerCase() === measureId.toLowerCase()
                        )[0];
                    setFormData(newFormData);
                    setOriginalFormData(newFormData);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    buildTrailData(rawData);
                });
        }
    }, []);

    useEffect(() => {
        buildTrailData(props.store?.MipsManageRecordList);

        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    const onChange = (e: any) => {
        // If the Measure ID changed then I need to populate all other values here
        if (
            formData &&
            e.formData.measureId &&
            // For the `SuggestWidget` the value coming back will be the 'itemID'
            ((Object.keys(e.formData.measureId).includes('itemID') &&
                e.formData.measureId.itemID !== formData.measureId) ||
                (!Object.keys(e.formData.measureId).includes('itemID') &&
                    e.formData.measureId !== formData.measureId))
        ) {
            e.formData.copyImprovementActivities = [];
            // Find the definition here for the measure being selected
            const newMeasureDefinition =
                props.store?.MipsManageRecordList?.improvementActivityMeasureDefinitions?.filter(
                    (row) =>
                        row.measureId &&
                        row.measureId.toLowerCase() === e.formData.measureId.itemID.toLowerCase()
                )[0];
            if (newMeasureDefinition) {
                setFormData({
                    ...e.formData,
                    measureId: e.formData.measureId.itemID,
                    measureTitle: newMeasureDefinition.title,
                    subcategory: newMeasureDefinition.subcategory,
                    weight: newMeasureDefinition.weight,
                    description: newMeasureDefinition.description,
                    ...{
                        startDate:
                            e.formData.startDate && e.formData.startDate !== '-'
                                ? new Date(e.formData.startDate).toUTCString()
                                : undefined,
                        endDate:
                            e.formData.endDate && e.formData.startDate !== '-'
                                ? new Date(e.formData.endDate).toUTCString()
                                : undefined,
                    },
                });
            } else {
                console.error(
                    `Failed to find matching measure definition: [MeasureID: ${JSON.stringify(
                        e.formData.measureId
                    )}]`
                );
            }
        } else {
            setFormData({
                ...e.formData,
                ...{
                    startDate:
                        e.formData.startDate && e.formData.startDate !== '-'
                            ? new Date(e.formData.startDate).toUTCString()
                            : undefined,
                    endDate:
                        e.formData.endDate && e.formData.endDate !== '-'
                            ? new Date(e.formData.endDate).toUTCString()
                            : undefined,
                },
            });
        }

        if (!hasChange && formData && e.formData) {
            setHasChange(JSON.stringify(e.formData) !== JSON.stringify(formData));
        }
    };

    // Get the list of Practitioner records that we are submitting the change to. This include the current record and any
    // records in the copy to box.
    const getPractitionersToSubmit = (): string[] | undefined => {
        if (!props.store || !props.store.MipsManageRecordList || !formData) return undefined;
        const practitionersFromCopyTo = formData.copyImprovementActivities
            ? formData.copyImprovementActivities
                  .filter((copyItem) => {
                      return copyItem.id && copyItem.id !== 'group';
                  })
                  .map((copyItem) => {
                      return copyItem.id;
                  })
            : [];

        const result = props.store.MipsManageRecordList.providerPractitionerFhirId
            ? [
                  ...practitionersFromCopyTo,
                  `Practitioner/${props.store.MipsManageRecordList.providerPractitionerFhirId}`,
              ]
            : practitionersFromCopyTo;
        setNumberOfPractitionersCopyToAppliedTo(result.length);
        return result;
    };

    // Determines if we are applying this IA change to the group level too.
    const shouldApplyChangeToGroupLevel = () => {
        if (props.store && props.store.MipsManageRecordList && formData) {
            const result =
                props.store.MipsManageRecordList.providerPractitionerFhirId === undefined ||
                (formData.copyImprovementActivities !== undefined &&
                    formData.copyImprovementActivities.find((copyItem: MultiSelectWidgetEnum) => {
                        return copyItem.id === 'group';
                    }) !== undefined);
            setCopyToAppliedGroup(result);
            return result;
        }
        setCopyToAppliedGroup(false);
        return false;
    };

    // When saving the page we need to make sure to update the store with the new measure ID we just added
    // This is because the store is what the `copy to` is using to determine which entries to show in the copy to field.
    // The business rules state that if a record already has the IA measure it should not show up in the copy to field.
    const updateStoreWithNewMeasureID = (
        practitionersToApplyChangeTo: string[] | undefined,
        applyChangeToGroupLevel: boolean
    ) => {
        if (formData && props.store.MipsManageRecordList) {
            if (practitionersToApplyChangeTo) {
                props.store.MipsManageRecordList.practitioners?.forEach((practitioner) => {
                    if (practitionersToApplyChangeTo.includes(practitioner.practitionerFhirId)) {
                        if (practitioner.iaMeasureIds === undefined) {
                            // eslint-disable-next-line no-param-reassign
                            practitioner.iaMeasureIds = [];
                        }
                        if (!practitioner.iaMeasureIds.includes(formData.measureId)) {
                            practitioner.iaMeasureIds.push(formData.measureId);
                        }
                    }
                });
            }
            if (
                applyChangeToGroupLevel &&
                props.store.MipsManageRecordList.groupInformation &&
                (!props.store.MipsManageRecordList.groupInformation.iaMeasureIds ||
                    !props.store.MipsManageRecordList.groupInformation.iaMeasureIds.includes(
                        formData.measureId
                    ))
            ) {
                props.store.MipsManageRecordList.groupInformation.iaMeasureIds.push(
                    formData.measureId
                );
            }
        }
    };

    // Format all the data for submission to the BFF and handle any of the returned information
    const doSubmission = () => {
        if (props.store && props.store.MipsManageRecordList && formData && !saving) {
            const practitionersToApplyChangeTo = getPractitionersToSubmit();
            const applyChangeToGroupLevel = shouldApplyChangeToGroupLevel();
            Api.mips
                .editImprovementActivities({
                    organizationFhirId: props.store.MipsManageRecordList.organizationFhirId,
                    reportingYear: props.store.MipsManageRecordList.reportingYear,
                    applyChangeToGroupLevel,
                    practitionersToApplyChangeTo,
                    measureId: formData.measureId,
                    dateRange:
                        formData.startDate || formData.endDate
                            ? {
                                  start:
                                      formData.startDate && formData.startDate !== '-'
                                          ? dateFormat(formData.startDate, 'UTC:mm/dd/yyyy')
                                          : undefined,
                                  end:
                                      formData.endDate && formData.endDate !== '-'
                                          ? dateFormat(formData.endDate, 'UTC:mm/dd/yyyy')
                                          : undefined,
                              }
                            : undefined,
                })
                .then((response) => {
                    setSaving(false);
                    const copyConfirmDialogWasOpen = confirmCopyIADialogOpen;
                    setConfirmCopyIADialogOpen(false);
                    if (response.ok && response.data) {
                        setCopyResultsIADialogOpen(copyConfirmDialogWasOpen);
                        setEditMode(!editMode);
                        setOriginalFormData({ ...formData, copyImprovementActivities: undefined });
                        setFormData({ ...formData, copyImprovementActivities: [] });
                        updateStoreWithNewMeasureID(
                            practitionersToApplyChangeTo,
                            applyChangeToGroupLevel
                        );

                        if (response.data.noChangesNeeded) {
                            showNoChangesToast();
                        } else {
                            showSuccessfulToast(undefined, 'Save Successful!');
                            if (response.data?.newMeasureScore) {
                                let newScore = '-';
                                if (props.store.MipsManageRecordList) {
                                    // The change is being applied at the group level if the provider practitioner is undefined
                                    if (
                                        props.store.MipsManageRecordList
                                            .providerPractitionerFhirId === undefined
                                    ) {
                                        newScore = String(
                                            response.data?.newMeasureScore[
                                                `Organization/${props.store.MipsManageRecordList.organizationFhirId}`
                                            ] || '-'
                                        );
                                    } else {
                                        newScore = String(
                                            response.data?.newMeasureScore[
                                                `Practitioner/${props.store.MipsManageRecordList.providerPractitionerFhirId}`
                                            ] || '-'
                                        );
                                    }
                                }
                                setFormData({
                                    ...formData,
                                    score: newScore,
                                    copyImprovementActivities: undefined,
                                });
                                setOriginalFormData({
                                    ...formData,
                                    score: newScore,
                                    copyImprovementActivities: undefined,
                                });
                            }
                        }

                        // When we save the page while in 'Add' mode then swap it back to 'View' so we show the correct
                        // react components
                        if (editState === 'Add') {
                            props.history.replace(
                                `${
                                    props.history.location.pathname
                                }${props.history.location.search.replace(
                                    'editState=Add',
                                    'editState=View'
                                )}&measureId=${formData.measureId}`
                            );
                        }
                        // If we got any errors back try to show that specific error or a generic toast.
                    } else if (
                        response.data &&
                        (response.data as ImprovementActivityOutput).errors
                    ) {
                        showToastErrors(
                            (response.data as ImprovementActivityOutput).errors,
                            'Error saving Improvement Activity Details'
                        );
                    } else {
                        showToastError();
                    }
                });
        }
        // Disable the save button until changes are made to the page
        setSaving(true);
    };

    // Handle either submitting the data right away or prompting the user through a second confirmation screen
    const onSubmit = () => {
        if (
            !formData?.startDate ||
            !belongsToPeriod(formData.startDate, reportingYear) ||
            (formData?.endDate && !belongsToPeriod(formData?.endDate, reportingYear))
        ) {
            showToastError(
                'Invalid dates',
                'Start date must be before end date and within performance year'
            );
            return;
        }
        if (formData?.copyImprovementActivities && formData?.copyImprovementActivities.length) {
            // Update the state with the count and boolean so that messages display properly
            getPractitionersToSubmit();
            shouldApplyChangeToGroupLevel();
            setConfirmCopyIADialogOpen(true);
        } else {
            doSubmission();
        }
    };

    const onError = (e: any) => {
        console.log('errored', e);
    };
    const getSidebarElements = () => {
        return [
            {
                title: 'Improvement Activities Details',
                hash: 'ImprovementActivitiesDetails',
            },
        ];
    };
    const groupCategorization = preferences?.npi ? `TIN: ${preferences?.tin}` : '';

    const handleEditModeExit = () => {
        // Go back to the record page if it was an add
        if (editState === 'Add') {
            props.history.goBack();
        }
    };

    const editToggle = () => {
        if (editMode && hasChange) {
            setConfirmationDialogOpen(true);
        } else {
            setFormData({ ...(formData as any), copyImprovementActivities: [] });
            setEditMode(!editMode);
            setHasChange(false);
            handleEditModeExit();
        }
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setEditMode(!editMode);

        setFormData({ ...(originalFormData as any), copyImprovementActivities: [] });

        handleEditModeExit();
        setHasChange(false);

        setConfirmationDialogOpen(false);
    };

    // Converts the IA Measure definitions to something that the suggestWidget can use
    const getIaMeasureDefinitions = (): { itemName: string; itemID: string }[] | undefined => {
        if (
            props.store?.MipsManageRecordList?.improvementActivityMeasureDefinitions &&
            props.store?.MipsManageRecordList?.improvementActivityMeasureDefinitions.length
        ) {
            // Grab out all the currently selected IA measures
            const selectedImprovementActivities: string[] =
                props.store?.MipsManageRecordList?.improvementActivityScores
                    ?.filter((score) => {
                        return score.measureId;
                    })
                    .map((score) => {
                        return score.measureId.toLowerCase();
                    }) || [];
            const returnData: { itemName: string; itemID: string }[] =
                props.store?.MipsManageRecordList?.improvementActivityMeasureDefinitions
                    .filter((definition) => {
                        // Verify that the measure id has a value, is not a dash (-) and it is not already selected for the current record
                        return (
                            definition.measureId &&
                            definition.measureId !== '-' &&
                            !selectedImprovementActivities.includes(
                                definition.measureId.toLowerCase()
                            )
                        );
                    })
                    .map((definition) => {
                        return {
                            itemName: `${definition.measureId} - ${definition.title}`,
                            itemID: definition.measureId,
                        };
                    });

            return returnData.sort(
                (
                    a: {
                        itemName: string;
                        itemID: string;
                    },
                    b: {
                        itemName: string;
                        itemID: string;
                    }
                ) => {
                    return a.itemName.localeCompare(b.itemName, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
            );
        }
        return undefined;
    };

    // Runs through the logic needed to populate the copy to list. Only those Practitioners/Group that do not have the IA in question are eligible to be copied to.
    const getImprovementActivityCopyToList = (): MultiSelectWidgetEnum[] | undefined => {
        const enums: MultiSelectWidgetEnum[] = [];
        if (props.store?.MipsManageRecordList) {
            if (
                props.store.MipsManageRecordList.providerPractitionerFhirId !== undefined &&
                (!formData ||
                    !formData.measureId ||
                    !props.store.MipsManageRecordList.groupInformation?.iaMeasureIds ||
                    !props.store.MipsManageRecordList.groupInformation?.iaMeasureIds?.includes(
                        formData.measureId
                    ))
            ) {
                enums.push({
                    id: 'group',
                    name: 'Group',
                });
            }
            props.store.MipsManageRecordList.practitioners
                ?.filter((practitioner) => {
                    return (
                        (!practitioner.iaMeasureIds ||
                            !formData ||
                            !practitioner.iaMeasureIds.includes(formData.measureId)) &&
                        !practitioner.preventCopyingIaMeasuresDueToParticipation
                    );
                })
                .forEach((practitioner) => {
                    enums.push({
                        id: practitioner.practitionerFhirId,
                        name: practitioner.practitionerName,
                    });
                });
        }
        return enums;
    };

    const copyIaDialogOkAction = () => {
        doSubmission();
    };

    return (
        <SchemaProvider schemaId={props.ParentPageLink?.route}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={() => handleConfirmationDialogClose()}
                handleOkay={() => handleConfirmationDialogOkay()}
            />
            <GenericDialog
                isOpen={confirmCopyIADialogOpen}
                handleClose={() => setConfirmCopyIADialogOpen(false)}
                handleOkay={() => copyIaDialogOkAction()}
                schema={getCopyIaDialogSchema()}
                uiSchema={getCopyIaDialogUiSchema()}
                headingTitle="Are you sure you want to copy improvement activity?"
                cancelButtonText="No, go back"
                addButtonText="Yes, continue"
                addButtonDisabled={false}
                disabled={saving}
                widgets={undefined}
                formData={{
                    copyIaDescription: `You are copying this ${
                        formData?.measureId
                    } to ${numberOfPractitionersCopyToAppliedTo} clinicians${
                        copyToAppliedGroup ? ' and your group' : ''
                    }. If you continue, any additional changes will need to be done in each individual record.`,
                }}
            />

            <GenericDialog
                isOpen={copyResultsIADialogOpen}
                handleClose={() => setCopyResultsIADialogOpen(false)}
                handleOkay={() => copyIaDialogOkAction()}
                schema={getCopyIaConfirmationDialogSchema()}
                uiSchema={getCopyIaConfirmationDialogUiSchema()}
                headingTitle="Copy Confirmation"
                cancelButtonText="Done"
                addButtonText=""
                addButtonDisabled
                disabled
                widgets={undefined}
                formData={{
                    copyIaConfirmationDescription: `You've successfully copied ${
                        formData?.measureId
                    } to ${numberOfPractitionersCopyToAppliedTo} clinicians${
                        copyToAppliedGroup ? ' and your group' : ''
                    }.`,
                }}
            />
            <Form
                recordId={
                    preferences?.npi ? `${preferences?.clinicianName}` : `${preferences?.tinName}`
                }
                recordName={
                    preferences?.npi ? `NPI: ${preferences?.npi}` : `TIN: ${preferences?.tin}`
                }
                recordText={[groupCategorization, reportingYear]}
                sidebarElements={getSidebarElements()}
                schema={getImprovementActivitiesSchema(
                    getIaMeasureDefinitions(),
                    getImprovementActivityCopyToList()
                )}
                uiSchema={getImprovementActivitiesDetailsUISchema(editMode, editState === 'Add')}
                widgets={{ SelectWidget }}
                formData={formData}
                onChange={(e: any) => onChange(e)}
                onSubmit={onSubmit}
                onError={onError}
                noValidate
            >
                <FormHeader
                    title="Improvement Activities Details"
                    editMode={editMode}
                    allowEditing
                    allowCancel={!saving}
                    allowSave={hasChange && !saving && formData?.measureId !== undefined}
                    allowPrinting={!editMode}
                    editButtonText="Edit Measure"
                    exportButtonLabel="Export Measure"
                    buttonOrder
                    enableEditMode={() => editToggle()}
                    disableEditMode={() => editToggle()}
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(withDataDictionary(MipsImprovementActivitiesDetails));
