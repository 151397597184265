import React, { useEffect, useMemo, useState } from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { JSONSchema6Object } from 'json-schema';

import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { Button, MenuItem, ITagProps } from '@blueprintjs/core';

import WidgetTitleField from '../fields/WidgetTitleField';

// The way we are building the enum for the multiselect isn't following the rjsf standard,
//  and that is why we can't add the subText field here
// See more: https://react-jsonschema-form.readthedocs.io/en/latest/usage/single/#custom-labels-for-enum-fields
export interface MultiSelectWidgetEnum extends JSONSchema6Object {
    id: string; // The ID of the entry
    name: string; // The display name of the entry
}

interface MultiSelectWidgetOptions {
    id?: string;
    name: string;
    subText?: string;
}

interface MultiSelectJsonSchemaEnumOption {
    label?: string;
    value: MultiSelectWidgetEnum;
}

const MultiItemSelect = MultiSelect.ofType<MultiSelectWidgetOptions>();

function MultiSelectWidget(props: WidgetProps) {
    const { value } = props;
    const enumOptions = props.options.enumOptions as MultiSelectJsonSchemaEnumOption[];
    const [selected, setSelected] = useState<MultiSelectWidgetOptions[]>([]);
    const { label, required, id } = props;
    const [selectAllActive, setSelectAllActive] = useState(false);

    const items: MultiSelectWidgetOptions[] = useMemo(() => {
        const options: MultiSelectWidgetOptions[] =
            enumOptions && enumOptions.length > 0 ? [{ name: 'Select All' }] : [];

        enumOptions?.forEach((option: MultiSelectJsonSchemaEnumOption) => {
            options.push(option.value);
        });

        return options;
    }, [enumOptions]);

    useEffect(() => {
        if (value && Array.isArray(value)) {
            const selectedFromData: MultiSelectWidgetOptions[] = [];
            value.forEach((item) => selectedFromData.push(item));

            setSelected(selectedFromData);
        }
    }, [value]);

    const isItemSelected = (item: MultiSelectWidgetOptions) => {
        if (selected.find((selectedItem) => selectedItem.id === item.id)) {
            return true;
        }
        return false;
    };

    const deselectItem = (index: number) => {
        const selectedItems = Object.assign([], selected);
        selectedItems.splice(index, 1);

        setSelected(selectedItems);
        props.onChange(selectedItems);
    };

    const selectItem = (item: MultiSelectWidgetOptions) => {
        const selectedItems = Object.assign([], selected);
        selectedItems.push(item);

        setSelected(selectedItems);
        props.onChange(selectedItems);
    };

    const getSelectedItemIndex = (item: MultiSelectWidgetOptions) => {
        const index = selected.findIndex((element) => {
            return element.id === item.id;
        });
        return index;
    };

    const handleSelectAll = () => {
        const allItems = Object.assign([], items);
        allItems.shift();

        setSelected(allItems);
        setSelectAllActive(true);
        props.onChange(allItems);
    };

    const handleItemSelect = (item: MultiSelectWidgetOptions) => {
        if (!isItemSelected(item)) {
            selectItem(item);
        } else {
            deselectItem(getSelectedItemIndex(item));
        }
        setSelectAllActive(false);
    };

    const handleClear = () => {
        setSelected([]);
        props.onChange([]);
        setSelectAllActive(false);
    };

    const handleTagRemove = (_tag: React.ReactNode, index: number) => {
        deselectItem(index);
        setSelectAllActive(false);
    };

    const clearButton =
        selected.length > 0 ? <Button icon="cross" minimal onClick={handleClear} /> : undefined;

    const getTagProps = (): ITagProps => ({
        minimal: true,
    });

    const renderItem: ItemRenderer<MultiSelectWidgetOptions> = (
        item,
        { modifiers, handleClick }
    ) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        let itemText;

        if (item.subText) {
            itemText = (
                <React.Fragment>
                    {item.name}
                    <br />
                    <span className="multi-select-subtext">{item.subText}</span>
                </React.Fragment>
            );
        } else {
            itemText = item.name;
        }

        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                onClick={handleClick}
                key={item.name + item.subText}
                icon={
                    selected.find((selectedItem) => selectedItem.name === item.name) ||
                    (item.name === 'Select All' && selectAllActive)
                        ? 'small-tick'
                        : 'blank'
                }
                text={itemText}
            />
        );
    };

    const renderTag = (item: MultiSelectWidgetOptions) => item.name;

    const filterItem: ItemPredicate<MultiSelectWidgetOptions> = (
        query,
        item,
        _index,
        exactMatch
    ) => {
        const normalizedTitle = item.name ? item.name.toLowerCase() : '';
        const normalizedSubtext = item.subText ? item.subText.toLowerCase() : '';
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        }
        return (
            `${item}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0 ||
            normalizedSubtext.indexOf(normalizedQuery) >= 0
        );
    };
    const { options } = props;
    if (options && options.renderMode === 'none') {
        return null;
    }

    return (
        <div style={{ position: 'relative', paddingTop: '4px' }}>
            {label ? <WidgetTitleField required={required} title={label} id={id} /> : null}
            <div style={{ paddingTop: '5px' }}>
                <MultiItemSelect
                    items={items}
                    itemPredicate={filterItem}
                    tagRenderer={renderTag}
                    resetOnSelect
                    itemRenderer={renderItem}
                    className={props.rawErrors && props.rawErrors.length > 0 ? 'error-border' : ''}
                    onItemSelect={(item) =>
                        item.name === 'Select All' ? handleSelectAll() : handleItemSelect(item)
                    }
                    selectedItems={selected}
                    noResults={<MenuItem disabled text="No results." />}
                    popoverProps={{ minimal: true, fill: true, usePortal: false }}
                    fill
                    tagInputProps={{
                        tagProps: getTagProps,
                        onRemove: handleTagRemove,
                        rightElement: clearButton,
                        placeholder: '',
                    }}
                >
                    <Button fill rightIcon="double-caret-vertical" />
                </MultiItemSelect>
            </div>
        </div>
    );
}

export default MultiSelectWidget;
