import React, { useState } from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { MipsPerformanceRecord } from '../../../../server/routes/Mips/MipsApi';

interface OptInElectionDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    handleOkay: () => void;
    numOfGroups: number;
    optinData: MipsPerformanceRecord['Clinicians'];
    numOfIndividuals: number;
    optInElectionDescription: string;
}

const textStyle = {
    paddingBottom: '20px',
};
const rowData = {
    padding: '5px',
};

const OptInElectionDialog = (props: OptInElectionDialogProps) => {
    const [optIn, setOptIn] = useState(false);
    return props.isOpen ? (
        <Dialog onClose={props.handleClose} isOpen={props.isOpen} title="Opt-In Election">
            <div className={Classes.DIALOG_BODY} style={{ padding: '10px', width: '550px' }}>
                <p style={textStyle}>
                    {`To submit data for groups and individuals who are opt-in eligible, 
        you need to opt them in first.You are opting in  ${props.numOfGroups} ${
                        props.numOfGroups === 1 ? 'group' : 'groups'
                    } and ${props.numOfIndividuals} ${
                        props.numOfIndividuals === 1 ? 'individual' : 'individuals'
                    } to participate in MIPS for this reporting year. `}
                </p>
                <p style={textStyle}>
                    <table style={{ width: '100%' }}>
                        <tr className="bp3-heading">
                            <th>Participant Type</th>
                            <th>Clinician</th>
                            <th>Opt-In Election</th>
                        </tr>
                        {props.optinData.map((row, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <tr key={i} style={{ textAlign: 'center' }}>
                                <td style={rowData}>{row.participationOption}</td>
                                <td style={rowData}>{row.clinician ? row.clinician : '-'}</td>
                                <td style={rowData}>
                                    {row.optInElection && row.optInElection.length
                                        ? row.optInElection
                                              .split('-')
                                              .map(
                                                  (word) =>
                                                      word[0].toUpperCase() +
                                                      word.slice(1, word.length)
                                              )
                                              .join(' ')
                                        : ''}
                                </td>
                            </tr>
                        ))}
                    </table>
                </p>
                <p>
                    <label className="bp3-control bp3-checkbox" htmlFor="optInCheckbox">
                        <input
                            id="optInCheckbox"
                            type="checkbox"
                            checked={optIn}
                            onChange={() => setOptIn(!optIn)}
                        />
                        <span className="bp3-control-indicator" />
                        <span>{props.optInElectionDescription}</span>
                    </label>
                </p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        text="No, go back"
                        onClick={() => {
                            setOptIn(false);
                            props.handleClose();
                        }}
                        data-automation-hook="(ActionBar)-dialog-action-cancel"
                    />
                    <Button
                        disabled={!optIn}
                        text="Yes, Opt-In"
                        intent={Intent.PRIMARY}
                        onClick={() => {
                            setOptIn(false);
                            props.handleOkay();
                        }}
                        data-automation-hook="(ActionBar)-dialog-action-submit"
                    />
                </div>
            </div>
        </Dialog>
    ) : null;
};

export default OptInElectionDialog;
