enum MipsModuleConstants {
    ExplorerDescription = 'The TINs associated to your user account are listed here. Click the dropdown arrow on a row to access actions currently available for that TIN.',
    SubmissionYear = '2022',
    ExplorerEmptyListTitle = 'No TINs',
    ExplorerEmptyListDescription = 'Your user account does not have access to any TINs',
    ParticipationOptionError = 'Please select a participation option',
    GroupPlusIndividualOptionInfo = 'Both Group and Individual performance data will be sent to CMS. Payment adjustment is based on the highest available score.',
    IndividualOptionInfo = 'Report measures and activities based on Individual performance. CMS will assess performance across all categories at the Individual level.',
    GroupOptionInfo = 'Report measures and activities based on the aggregated performance of the clinicians billing under the TIN. CMS will assess performance across all categories at the Group level.',
    CehrtIdError = 'Cehrt ID should be 15 digits',
    ParticipationDescription = 'Select category participation and set opt-in elections for opt-in eligible participants at the Group or Individual level. You can change the category participation at any time. Opt-in elections may be changed up until you submit them to CMS.',
    ParticipationDescriptionCategoryTarget = 'category participation',
    ParticipationDescriptionCategoryDestination = 'https://www.arbormetrix.com/',
    ParticipationDescriptionOptInElectionTarget = 'opt-in elections',
    ParticipationDescriptionOptInElectionDestination = 'https://www.arbormetrix.com/',
    PerformanceDescription = 'Select group and individuals to submit opt-in elections and to submit performance data to CMS.  Click “View” on a row to see detailed settings, reporting data, and scores. Please note, you’re seeing participating entities (eligible, opted-in groups or individuals).',
    CostDescription = 'Only CMS can calculate your cost score. Because of this, cost is excluded from your estimated score. Cost is an important part of MIPS because it measures resources that clinicians use to care for patients and the Medicare payments for care (items and services) given to a beneficiary during an episode of care. Cost accounts for 20% of the total MIPS Score. CMS will calculate your cost score based on the Medicare Spending per Beneficiary (MSPB) and total per capital cost measures. No additional data submission is required. CMS already has this data.',
    ScoringDescription = 'View MIPS performance in all participating categories. Please note, when there is no submission status, your data has not been submitted to CMS. You’re seeing CMS provided scores based on your data and CMS reserves the right to change your score based on periodic policy updates, eligibility reviews, and technical integration developments.',
    PreferenceDescription = 'View or edit reporting preferences for this TIN. To edit, click "Edit Record" in the top right corner.',
    OptInConfirmDescription = 'You have not submited data at this point. Please continue to submit your data to CMS. If you don’t want to submit data at this time, select Done to go back to the list. Note that the opt-in election has been made and cannot be changed.',
    OptInElectionDescription = 'I understand that I cannot change this election for the current reporting year once selected "Yes, Opt-In"',
    PerformanceCategoryParticipationError = '',
    OptInFullyParticipatingDescription = 'Elect to report data, receive performance feedback, receive a MIPS payment adjustment, and be eligible to have data published on Doctors & Clinicians on Medicare Care Compare. Payment adjustment is based on MIPS performance score and could be positive, negative, or neutral.',
    OptInVoluntarilyReportingDescription = 'Elect to receive performance feedback and be eligible to have data published on Doctors & Clinicians on Medicare Care Compare. You will not receive a MIPS payment adjustment or be included in the calculation of MIPS measure benchmarks.',
    QualityMeasuresDescription = 'This performance category assesses the quality of the care you deliver, based on performance measures created by CMS, as well as medical professional and stakeholder groups. You pick the 6 measures of performance that best fit your practice.',
    ImprovementActivitiesDescription = 'This performance category assesses how you improve your care processes, enhance patient engagement in care, and increase access to care. You choose the activities appropriate to your practice.',
    PromotingInteroperabilityDescription = 'This performance category promotes patient engagement and the electronic exchange of health information using certified electronic health record technology (CEHRT). You report a single set of Promoting Interoperability objectives and measures.',
    ParticipationGroupDescription = 'Only the group report will be sent to and scored by CMS. Clinicians will receive a payment adjustment based on the group score.',
    ParticipationIndividualDescription = 'Only individual reports will be sent to and scored by CMS. Clinicians will receive a payment adjustment based on their individual report.',
    ParticipationBothDescription = 'Group and individual reports will be sent to and scored by CMS. Clinicians will receive a payment adjustment based on the higher of the two scores.',
}

export default MipsModuleConstants;
