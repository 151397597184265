/* eslint-disable no-restricted-globals */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import Store from '../../core/Store';
import TempHeader from './TempHeader';
import { Header as MeshHeader } from '../../../generator/generated/MeshInterfaces';

interface HeaderProps extends RouteComponentProps {
    store: Store;
    keycloak: any;
    Header: MeshHeader;
}
// const Header = observer((props: HeaderProps) => {
function Header(props: HeaderProps) {
    const { language, setLanguage, username } = props.store;

    const logout = () => {
        props.history.push('/');
        if (props.keycloak) {
            (props.keycloak as any).logout();
        }
    };

    const gotoAccount = () => {
        if (props.keycloak) {
            const accountUrl: string = props.keycloak.createAccountUrl();
            if (typeof accountUrl !== 'undefined') {
                window.location.href = accountUrl.replace('/account', '/account/password');
            }
        }
    };

    const headerProps = {
        ...props,
        ...props.Header,
        language,
        setLanguage,
        username,
        profileDropdown: props.keycloak
            ? [
                  { label: 'Change Password', link: gotoAccount },
                  { label: 'Log Out', link: logout },
              ]
            : undefined,
    };

    return <TempHeader {...headerProps} />;
}

export default withRouter(observer(Header));
