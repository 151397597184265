import React from 'react';

import { ToggleField } from '@amxjs/ui';
import { IconName } from '@blueprintjs/core';
import { runInAction } from 'mobx';
import uuid from 'uuid';

import { observer } from 'mobx-react';

import { WidgetProps } from 'react-jsonschema-form';
import WidgetTitleField from '../fields/WidgetTitleField';

@observer
class SwitchWidget extends React.Component<WidgetProps> {
    id: any;

    enumOptions: any;

    constructor(props: any) {
        super(props);

        // const { options, multiple, value } = this.props;  // 2.0.0+
        const { options } = this.props;

        const { enumOptions } = options;
        this.enumOptions = enumOptions;

        this.id = uuid.v4();
    }

    convertOptions = (iOptions: any) => {
        return iOptions.map((item: any) => {
            return {
                value: item.value,
                label: item.label,
                info: {
                    iconName: 'info-sign' as IconName,
                    message: 'Info',
                },
            };
        });
    };

    convertProps(props: WidgetProps) {
        return {
            id: props.id !== undefined ? props.id : '',
            label: props.label,
            intent: undefined,
            options: this.convertOptions(props.options.enumOptions),
            value: props.value,
            note: '1',
            required: props.required,
            disabled: props.disabled,
            readonly: props.readonly,
            autofocus: props.autofocus,
            tooltip: props.options.tooltip,

            schema: props.schema,

            onChange: props.onChange,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
            labelTrueValue: props.schema.enum ? props.schema.enum[0] : 'Active',
            labelFalseValue: props.schema.enum ? props.schema.enum[1] : 'Inactive',
        };
    }

    render() {
        const props = this.convertProps(this.props);

        const valueOfWidget = props.value === undefined ? false : props.value;

        return (
            <div style={{ paddingTop: '4px' }}>
                {props.label ? (
                    <WidgetTitleField
                        required={props.required}
                        title={props.label}
                        id={`${props.id}__title`}
                        help={this.props.options.helpText}
                    />
                ) : null}
                <ToggleField
                    onChange={(value) =>
                        runInAction(() => {
                            props.onChange(value);
                        })
                    }
                    label={valueOfWidget ? props.labelTrueValue : props.labelFalseValue}
                    indicator="switch"
                    showRequiredIndicator={props.required}
                    disabled={props.disabled || props.readonly}
                    readOnly={props.readonly}
                    value={valueOfWidget}
                    // This is needed in order for the slider to change depending on the value being used
                    // If this is not set it will only set the switch once on creation of the component
                    columnControl
                />
            </div>
        );
    }
}

export default SwitchWidget;
