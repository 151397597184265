/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import Sticky from 'react-sticky-el';
import { MenuDivider, H3, Spinner } from '@blueprintjs/core';
import { style } from 'typestyle';
import { important } from 'csx';
import FormActions, { CustomButton } from './FormActions';

export interface FormHeaderProps {
    title: string;
    editButtonText?: string;
    buttonOrder?: boolean;
    editMode: boolean;
    allowEditing?: boolean;
    allowCancel?: boolean;
    allowPrinting?: boolean;
    allowSave?: boolean;
    showSpinner?: boolean;
    hideCancel?: boolean;
    exportButtonLabel?: string;
    headingClassName?: string;
    description?: JSX.Element;
    primaryListStyle?: boolean;
    customButton?: CustomButton;
    disableSticky?: boolean;
    enableEditMode?: () => void;
    disableEditMode?: () => void;
}
const padding10 = style({
    padding: '10px',
});

const primaryStyle = style({
    color: important('#000000'),
    fontSize: important('22px'),
    fontWeight: important(600),
});

function FormHeader(props: FormHeaderProps) {
    return (
        <Sticky stickyStyle={{ zIndex: 1010 }} disabled={props.disableSticky}>
            <div
                className={
                    props.headingClassName
                        ? `${props.headingClassName} form-heading`
                        : 'form-heading'
                }
                style={{ backgroundColor: 'white' }}
            >
                <div className="form-section-header">
                    <H3
                        style={{
                            fontWeight: 'bold',
                            marginRight: 'auto',
                            paddingTop: '5px',
                            color: '#5C7080',
                        }}
                        className={props.primaryListStyle ? primaryStyle : ''}
                    >
                        {props.title}
                    </H3>
                    {props.showSpinner && <Spinner className={padding10} size={20} />}
                    <FormActions
                        editMode={props.editMode}
                        allowEditing={props.allowEditing}
                        allowCancel={props.allowCancel}
                        allowPrinting={props.allowPrinting}
                        allowSave={props.allowSave}
                        hideCancel={props.hideCancel}
                        exportButtonLabel={props.exportButtonLabel}
                        enableEditMode={props.enableEditMode}
                        disableEditMode={props.disableEditMode}
                        customButton={props.customButton}
                        editButtonText={props.editButtonText}
                        buttonOrder={props.buttonOrder}
                    />
                </div>
                {props.description && props.description}
                {!props.primaryListStyle && <MenuDivider />}
            </div>
        </Sticky>
    );
}

export default FormHeader;
