import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Page, tableau } from '../../../generator/generated/MeshInterfaces';
import Api from '../../core/Api';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';

function DataGraph(props: tableau & RouteComponentProps) {
    const [tableauError, setTableauError] = useState(false);
    const { setBreadcrumbData } = useBreadcrumbContext();

    useEffect(() => {
        Api.getVizUrl(props.siteId, props.workbookName, props.sheetName).then((res) => {
            const { url, fhirId } = res.data as any;
            const { width, height, showToolbar, showTabs } = props;

            if (url == null) {
                setTableauError(true);
            }

            const vizOptions: any = {
                hideTabs: !showTabs,
                hideToolbar: !showToolbar,
                width: `${width}px`,
                height: `${height}px`,
                onFirstInteractive() {
                    /* getWorkbook() can only return after the viz is loaded.
        Once it does so, we can set the parameters within it.

        ignore this -- just a copy/paste from what im working on,
        but it shows how to use the javascript api.

        currentViz.getWorkbook().getParametersAsync().then(function(paramObjs) {
            console.log("PARAMS: ", paramObjs);
            console.log("hasAttributes?", paramObjs.has("inputAttributeIds"));
        });

        currentViz.getWorkbook().changeParameterValueAsync("inputAttributeIds", "101");
        currentViz.getWorkbook().changeParameterValueAsync("inputAgentIds", "301");
        currentViz.getWorkbook().changeParameterValueAsync("inputClinicalIds", "201");
        currentViz.getWorkbook().changeParameterValueAsync("inputPeriodIds", "401");
        currentViz.getWorkbook().changeParameterValueAsync("inputHospitalIds", "501");
        */
                },
            };

            if (fhirId) vizOptions.fhirId = fhirId;

            if (!tableauError) {
                const vizContainer = document.getElementById('tableau-viz');
                return new window.tableau.Viz(vizContainer, url, vizOptions);
            }

            return null;
        });
    }, [props, tableauError]);

    useEffect(() => {
        const breadcrumbTrail: Record<string, any[]> = { labels: [], routes: [] };

        if (props.ParentPageLink && props.ParentPageLink.breadcrumbTrail) {
            props.ParentPageLink.breadcrumbTrail.forEach((crumb: Page) => {
                breadcrumbTrail.labels.push(crumb.name);
                breadcrumbTrail.routes.push(crumb.route || null);
            });
        }

        breadcrumbTrail.labels.push(props.name);
        setBreadcrumbData({
            crumbLabels: breadcrumbTrail.labels,
            crumbRoutes: breadcrumbTrail.routes,
        });

        return function cleanup() {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, []);

    if (tableauError) {
        throw new Error('The tableau url was invalid or null');
    }

    return <div id="tableau-viz" style={{ display: 'flex', justifyContent: 'center' }} />;
}

export default withRouter(DataGraph);
