import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { UiSchema } from 'react-jsonschema-form';
import queryString from 'query-string';
import { JSONSchema6 } from 'json-schema';
import { JSONPath } from 'jsonpath-plus';

import { Intent } from '@blueprintjs/core';
import { showToast } from '@amxjs/ui';

import { MipsManageParticipation as MipsManageParticipationInterface } from '../../../generator/generated/MeshInterfaces';
import {
    MipsParticipationRecord,
    MipsParticipationSelections,
} from '../../../server/routes/Mips/RecordInterface';
import Store from '../../core/Store';
import { SchemaProvider } from '../../mesh/Schema';
import { useBreadcrumbContext } from '../../core/BreadcrumbContext';
import withDataDictionary, { InjectedDataDictionaryProps } from '../../util/DataDictionary';
import Form from '../../components/forms/Form';
import FormHeader from '../../components/forms/components/FormHeader';
import Api from '../../core/Api';
import {
    columnIsPresentInConfiguration,
    getColumnOrder,
    getSortOrderForColumn,
} from '../../util/ListUtils';
import { swapDisplayValues, getRoutes } from '../../util/GeneralUtils';
import ConfirmExitDialog from '../ConfirmExitDialog';
import MipsModuleConstants from './MipsModuleConstants';

interface MipsManageParticipationProps extends MipsManageParticipationInterface {
    /* eslint-disable-next-line react/no-unused-prop-types */
    store: Store;
}

type MipsParticipationRecordWithouValueSet = Omit<MipsParticipationRecord, 'valueSet'>;

const showErrorSaving = () =>
    showToast({
        message: (
            <p>
                <span style={{ fontWeight: 'bold' }}>Error Occured!</span>
                <br />
                <br />
                <span>
                    Oops! Looks like something went wrong and your changes could not be saved.
                    Please try again later.
                </span>
            </p>
        ),
        intent: Intent.DANGER,
        icon: 'error',
    });

const filterProps = ({
    id,
    name,
    tin,
    submissionYear,
    Clinicians,
    submitStatus,
}: MipsParticipationRecord): MipsParticipationRecordWithouValueSet => ({
    id,
    name,
    tin,
    submissionYear,
    Clinicians,
    submitStatus,
});

const searchPlaceholderText = 'Search by Participation Type, Clinician, NPI, or Opt-In Election';
const unsearchableColumns = [
    'qualityMeasures',
    'improvementActivities',
    'promotingInteroperability',
    'consent',
];

const defaultDescription = {
    body: MipsModuleConstants.ParticipationDescription,
    links: [
        {
            target: MipsModuleConstants.ParticipationDescriptionCategoryTarget,
            destination: MipsModuleConstants.ParticipationDescriptionCategoryDestination,
        },
        {
            target: MipsModuleConstants.ParticipationDescriptionOptInElectionTarget,
            destination: MipsModuleConstants.ParticipationDescriptionOptInElectionDestination,
        },
    ],
};
const optInOptions = [
    { value: 'Fully Participating', id: 'fully-participating' },
    {
        value: 'Voluntary Reporting',
        id: 'voluntarily-participating',
        aliasedValues: ['voluntary-reporting'],
    },
    { value: 'Not Participating', id: 'not-participating' },
];

const ManageParticipation = (
    props: MipsManageParticipationProps & RouteComponentProps & InjectedDataDictionaryProps
) => {
    const { setBreadcrumbData } = useBreadcrumbContext();
    const [formHasChange, setFormHasChange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [savingRecord, setSavingRecord] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [tin, setTin] = useState('');
    const [key, setKey] = useState<number>(Date.now());
    const [originalData, setOriginalData] = useState<MipsParticipationRecord>({
        id: '',
        name: '',
        tin: '',
        submissionYear: '',
        valueSet: {},
        Clinicians: [],
    });
    const [formData, setFormData] = useState<MipsParticipationRecord>({
        id: '',
        name: '',
        tin: '',
        submissionYear: '',
        valueSet: {},
        Clinicians: [],
    });
    const { labels } = props;
    const [selectAllQualityMeasures, setSelectAllQualityMeasures] = useState<HTMLElement | null>();
    const [selectAllImprovementActivities, setSelectAllImprovementActivities] =
        useState<HTMLElement | null>();
    const [selectAllPromotingInteroperability, setSelectAllPromotingInteroperability] =
        useState<HTMLElement | null>();
    const [selectAllConsent, setSelectAllConsent] = useState<HTMLElement | null>();
    const selectAllElements: Record<string, any> = useMemo(
        () => ({
            qualityMeasures: selectAllQualityMeasures,
            improvementActivities: selectAllImprovementActivities,
            promotingInteroperability: selectAllPromotingInteroperability,
            consent: selectAllConsent,
        }),
        [
            selectAllConsent,
            selectAllImprovementActivities,
            selectAllPromotingInteroperability,
            selectAllQualityMeasures,
        ]
    );
    const [selectAllValues, setSelectAllValues] = useState<Record<string, any>>({
        qualityMeasures: null,
        improvementActivities: null,
        promotingInteroperability: null,
        consent: null,
    });
    const [descriptionElement, setDescriptionElement] = useState<Element | null>();
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const calculateDescriptionHeight = useCallback(() => {
        if (descriptionElement && descriptionHeight !== descriptionElement.clientHeight) {
            setDescriptionHeight(descriptionElement.clientHeight + 30);
        }
    }, [descriptionElement, descriptionHeight]);

    const getOptinElection = (
        data: MipsParticipationSelections[]
    ): MipsParticipationSelections[] => {
        return data.map((value: MipsParticipationSelections) => {
            // Temporary backwards compatability. See MIPS-255 for context.
            const optInEl = optInOptions.find(
                (option) =>
                    option.id === value.optInElectionDecision ||
                    (option.aliasedValues &&
                        option.aliasedValues.includes(value.optInElectionDecision))
            )?.value;
            return {
                ...value,
                ...(['eligible', 'submitted'].includes(value.optInElectionStatus)
                    ? {
                          optInElection: optInEl ?? ' ',
                      }
                    : { optInElection: '-' }),
            };
        });
    };

    useEffect(() => {
        (async () => {
            const { id, year } = queryString.parse(props.location.search);
            if (!id) {
                return;
            }
            const breadcrumbTrail: Record<string, any[]> = getRoutes(
                props.ParentPageLink?.breadcrumbTrail,
                year
            );
            try {
                const response = await Api.mips.getMipsParticipationRecordDetails(
                    id as string,
                    year || MipsModuleConstants.SubmissionYear
                );
                const rawData = response.data as MipsParticipationRecord;

                setTin(rawData.tin);

                breadcrumbTrail.labels.push(rawData.tin);

                setBreadcrumbData({
                    crumbLabels: breadcrumbTrail.labels,
                    crumbRoutes: breadcrumbTrail.routes,
                });

                const valueSet = rawData.valueSet
                    ? JSONPath({
                          path: `$.compose.include[0].concept`,
                          json: rawData.valueSet,
                      })[0].reduce(
                          (obj: any, set: any) => ({
                              ...obj,
                              [set.code]: set.display,
                          }),
                          {}
                      )
                    : undefined;
                const newData = {
                    ...rawData,
                    ...(rawData.valueSet
                        ? {
                              Clinicians: getOptinElection(
                                  swapDisplayValues(
                                      rawData.Clinicians,
                                      'participationOption',
                                      valueSet
                                  )
                              ),
                          }
                        : { Clinicians: getOptinElection(rawData.Clinicians) }),
                };
                setOriginalData(newData);
                setFormData(newData);
                setLoading(false);
            } catch (e) {
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Error Occurred!</span>
                            <br />
                            <br />
                            <span>
                                Oops! Looks like something went wrong and participants could not be
                                loaded. Please try again later.
                            </span>
                        </p>
                    ),
                    intent: Intent.DANGER,
                    icon: 'error',
                });
                setLoading(false);
            }
        })();
        return () => {
            setBreadcrumbData({
                crumbLabels: [],
                crumbRoutes: [],
            });
        };
    }, [props.ParentPageLink, props.location.search, setBreadcrumbData]);

    useEffect(() => {
        setSelectAllQualityMeasures(document.getElementById('check-all-qualityMeasures'));
        setSelectAllImprovementActivities(
            document.getElementById('check-all-improvementActivities')
        );
        setSelectAllPromotingInteroperability(
            document.getElementById('check-all-promotingInteroperability')
        );
        setSelectAllConsent(document.getElementById('check-all-consent'));
        setDescriptionElement(document.querySelector('#manage-participation-description'));
    }, []);

    useEffect(() => {
        if (descriptionElement) {
            calculateDescriptionHeight();
            window.onresize = calculateDescriptionHeight;
        }
    }, [descriptionElement, calculateDescriptionHeight]);

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogOkay = () => {
        setKey(Date.now());

        const newData: MipsParticipationRecord = {
            ...originalData,
            Clinicians: getOptinElection(originalData.Clinicians),
        };
        setFormData(newData);
        setFormHasChange(false);
        setConfirmationDialogOpen(false);
    };

    const formatDescription = (description: any) => {
        const { body, links } = description;

        if (description.links.length < 1) {
            return <span>{description.body}</span>;
        }

        let taggedBody = body;
        const taggedLinks: any = {};
        links.forEach((link: any) => {
            taggedBody = taggedBody.replace(link.target, `LINKED_TEXT_${link.target}_LINKED_TEXT`);
            taggedLinks[`LINKED_TEXT_${link.target}_LINKED_TEXT`] = link.destination;
        });

        const formatted = reactStringReplace(
            taggedBody,
            /(LINKED_TEXT_.*?_LINKED_TEXT)/g,
            (match, i) => (
                <a key={`link_${i}`} href={taggedLinks[match]} target="_blank" rel="noreferrer">
                    {match.replace('LINKED_TEXT_', '').replace('_LINKED_TEXT', '')}
                </a>
            )
        );
        return formatted;
    };

    const buildListDescription = () => {
        const { year } = queryString.parse(props.location.search);
        return (
            <div
                id="manage-participation-description"
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {tin && (
                        <>
                            <span>{`TIN: ${tin}`}</span>
                            <div
                                style={{
                                    height: '12px',
                                    width: '1px',
                                    margin: '0px 10px',
                                    backgroundColor: '#c9d0d8',
                                }}
                            />
                        </>
                    )}
                    {formData.name && (
                        <>
                            <span>{formData.name}</span>
                            <div
                                style={{
                                    height: '12px',
                                    width: '1px',
                                    margin: '0px 10px',
                                    backgroundColor: '#c9d0d8',
                                }}
                            />
                        </>
                    )}
                    <span>{year || MipsModuleConstants.SubmissionYear}</span>
                </div>
                <span style={{ marginTop: '18px' }}>
                    {formatDescription(labels.manageParticipationDescription || defaultDescription)}
                </span>
            </div>
        );
    };

    const onChange = (e: any) => {
        setFormHasChange(true);
        setFormData({ ...e.formData });
    };

    const onColumnSelectAll = (colKey: string, value: boolean, filteredRows: any[]) => {
        const listData = { ...formData };
        listData.Clinicians.map((row: any) => {
            const updated = row;
            const npis: string | null[] = [];
            filteredRows.forEach((filteredRow: any) => {
                const npi = JSONPath({
                    path: 'npi.props.children.props.value',
                    json: filteredRow,
                })[0];

                if (npi) {
                    npis.push(npi);
                }
                if (filteredRow.npi === '-') {
                    npis.push(null);
                }
            });
            updated[colKey] = value;
            return updated;
        });
        setFormHasChange(true);
        setFormData({ ...formData, Clinicians: listData.Clinicians });
        setSelectAllValues({
            ...selectAllValues,
            [colKey]: value,
        });
    };

    const calculateSelectAllValues = useCallback(
        (rows: any[]) => {
            if (!rows) {
                return;
            }
            const controlledColumns: Record<string, string[]> = rows.reduce(
                (obj, row) => ({
                    ...obj,
                    qualityMeasures: [...obj.qualityMeasures, row.qualityMeasures],
                    improvementActivities: [
                        ...obj.improvementActivities,
                        row.improvementActivities,
                    ],
                    promotingInteroperability: [
                        ...obj.promotingInteroperability,
                        row.promotingInteroperability,
                    ],
                    consent: [...obj.consent, row.consent],
                }),
                {
                    qualityMeasures: [],
                    improvementActivities: [],
                    promotingInteroperability: [],
                    consent: [],
                }
            );
            const measures = [
                'qualityMeasures',
                'improvementActivities',
                'promotingInteroperability',
                'consent',
            ];
            setSelectAllValues((values) => {
                const selectedValues = measures
                    .map((columnId: string) => {
                        if (selectAllElements[columnId]) {
                            const firstElement = controlledColumns[columnId][0];
                            const allRowsAreEqual = controlledColumns[columnId].every(
                                (val) => val === firstElement
                            );
                            selectAllElements[columnId].indeterminate = !allRowsAreEqual;
                            return { [columnId]: allRowsAreEqual ? firstElement : false };
                        }
                        return {};
                    })
                    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
                return { ...values, ...selectedValues };
            });
        },
        [selectAllElements]
    );

    useEffect(() => {
        calculateSelectAllValues(formData.Clinicians);
    }, [formData.Clinicians, calculateSelectAllValues]);

    const onFilterStringChangeForInListFiltering = (renderedRows: any[]) => {
        const formattedFilteredRows = renderedRows.map((row: any) => {
            return {
                qualityMeasures: row.qualityMeasures?.props?.value || row.qualityMeasures,
                improvementActivities:
                    row.improvementActivities?.props?.value || row.improvementActivities,
                promotingInteroperability:
                    row.promotingInteroperability?.props?.value || row.promotingInteroperability,
                consent: row.consent?.props?.value || row.consent,
            };
        });
        calculateSelectAllValues(formattedFilteredRows);
    };

    const buildColumn = (widget: string, order: string, renderMode = 'dash') => {
        return {
            'ui:widget': widget,
            'ui:options': {
                orderable: true,
                initialSort: getSortOrderForColumn(props.columns, order),
                renderMode,
                showColumn: columnIsPresentInConfiguration(props.columns, order),
            },
        };
    };

    const getUISchema = (): UiSchema => {
        return {
            Clinicians: {
                'ui:field': 'list',
                'ui:hash': 'clinicians',
                'ui:options': {
                    showSearchBar: true,
                    stickyHeaderOffset: descriptionHeight,
                    searchPlaceholderText,
                    unsearchableColumns,
                    showColumnControl: true,
                    selectAllValues,
                    onSelectAllRows: onColumnSelectAll,
                    itemsPerPage: 200,
                    loading,
                    data: Object.assign({}, formData),
                    onChange: (e: any) => onChange({ formData: { ...formData, Clinicians: e } }),
                    onFilterStringChangeForInListFiltering,
                    columnOrder: getColumnOrder(props.columns),
                },
                items: {
                    participationOption: buildColumn('SimpleTextWidget', 'participationOption'),
                    clinician: buildColumn('SimpleTextWidget', 'clinician'),
                    npi: buildColumn('SimpleTextWidget', 'npi'),
                    optInElection: {
                        'ui:widget': 'OptInSelectionWidget',
                        'ui:readonly': (data: MipsParticipationSelections) =>
                            data.optInElectionStatus !== 'eligible',
                        'ui:options': {
                            orderable: true,
                            enumOptions: optInOptions,
                            initialSort: getSortOrderForColumn(props.columns, 'optInElection'),
                            renderMode: 'dash',
                            showColumn: columnIsPresentInConfiguration(
                                props.columns,
                                'optInElection'
                            ),
                        },
                    },
                    qualityMeasures: buildColumn('CheckboxWidget', 'qualityMeasures', 'checkbox'),
                    improvementActivities: buildColumn(
                        'CheckboxWidget',
                        'improvementActivities',
                        'checkbox'
                    ),
                    promotingInteroperability: buildColumn(
                        'CheckboxWidget',
                        'promotingInteroperability',
                        'checkbox'
                    ),
                    consent: buildColumn('CheckboxWidget', 'consent', 'checkbox'),
                },
            },
        };
    };

    const getSchema = (): JSONSchema6 => {
        const columnLabels: any = props.columns.reduce(
            (obj: any, column: any) => ({ ...obj, [column.id]: column.label }),
            {}
        );
        return {
            type: 'object',
            properties: {
                Clinicians: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            participationOption: {
                                type: 'string',
                                title: columnLabels.participationOption || 'Participant Type',
                            },
                            clinician: {
                                type: 'string',
                                title: columnLabels.clinician || 'Clinician',
                            },
                            npi: {
                                type: 'string',
                                title: columnLabels.npi || 'NPI',
                            },
                            optInElection: {
                                type: 'string',
                                title: columnLabels.optInElection || 'Opt-In Election',
                            },
                            qualityMeasures: {
                                type: 'boolean',
                                title: columnLabels.qualityMeasures || 'Quality Measures',
                            },
                            improvementActivities: {
                                type: 'boolean',
                                title:
                                    columnLabels.improvementActivities || 'Improvement Activities',
                            },
                            promotingInteroperability: {
                                type: 'boolean',
                                title:
                                    columnLabels.promotingInteroperability ||
                                    'Promoting Interoperability',
                            },
                            consent: {
                                type: 'boolean',
                                title: columnLabels.consent || 'Consent',
                            },
                        },
                    },
                },
            },
        };
    };

    const onSubmit = async () => {
        setSavingRecord(true);
        const filteredData = filterProps({ ...formData });
        const newData: MipsParticipationRecordWithouValueSet = {
            ...filteredData,
            Clinicians: filteredData.Clinicians.map((value: MipsParticipationSelections) => {
                const { optInElection, ...rest } = value;
                if (optInElection === '-') {
                    return rest;
                }
                const optin = optInOptions.find((o) => o.value === value.optInElection);
                return {
                    ...rest,
                    ...(optin
                        ? { optInElection: optin.value, optInElectionDecision: optin.id }
                        : {}),
                };
            }),
        };
        try {
            const response = await Api.mips.updateMipsParticipation(newData);
            if (!response.ok) {
                showErrorSaving();
                return;
            }

            if (response.data.noChangesNeeded) {
                showToast({
                    message: (
                        <div>
                            <p style={{ fontWeight: 'bold' }}>
                                {response.data.noChangesMessageTitle}
                            </p>
                            <p>{response.data.noChangesMessage}</p>
                        </div>
                    ),
                    intent: Intent.SUCCESS,
                    icon: 'tick-circle',
                });
                setFormHasChange(false);
                return;
            }
            if (response.data.success) {
                showToast({
                    message: (
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Success</span>
                            <br />
                            <br />
                            <span>Updated participation successfully!</span>
                        </p>
                    ),
                    intent: Intent.SUCCESS,
                    icon: 'tick-circle',
                });
                setFormHasChange(false);
                // Update the state of the page to hold the new IDs of the resources that were created.
                const { newlyCreatePractitionerRoleIds, newlyCreateConsentIds } = response.data;
                const clinicians = newData.Clinicians.map((clinician) => {
                    const newlyCreatePractitionerRoleId = newlyCreatePractitionerRoleIds?.find(
                        (n) => clinician.fhirResourceId === n.practitionerRecordId
                    );
                    const newlyCreateConsentId = newlyCreateConsentIds?.find(
                        (n) => clinician.fhirResourceId === n.practitionerRecordId
                    );
                    return {
                        ...clinician,
                        ...(newlyCreatePractitionerRoleId
                            ? {
                                  mipsFhirResourceId:
                                      newlyCreatePractitionerRoleId.practitionerRoleRecordId,
                              }
                            : null),
                        ...(newlyCreateConsentId
                            ? {
                                  consentResourceId: newlyCreateConsentId.consentRecordId,
                              }
                            : null),
                    };
                });
                const newFormData = {
                    ...formData,
                    Clinicians: getOptinElection(clinicians),
                };
                setFormData(newFormData);
                setOriginalData(newFormData);
                return;
            }
            showErrorSaving();
        } catch (error) {
            console.error(error);
        } finally {
            setSavingRecord(false);
        }
    };

    const onCancel = () => {
        if (formHasChange) {
            setConfirmationDialogOpen(true);
        }
    };

    const onError = (e: any) => {
        console.log('on error: ', e);
    };

    return (
        <SchemaProvider schemaId={props.schemaId}>
            <ConfirmExitDialog
                isOpen={confirmationDialogOpen}
                handleClose={handleConfirmationDialogClose}
                handleOkay={handleConfirmationDialogOkay}
            />
            <Form
                key={key}
                recordId="manage-participation"
                recordName={tin}
                sidebarElements={[]}
                hideSidebar
                primary
                schema={getSchema()}
                uiSchema={getUISchema()}
                validators={[]}
                onChange={(e: any) => {
                    onChange(e);
                }}
                formData={formData}
                onError={onError}
                onSubmit={onSubmit}
            >
                <FormHeader
                    title="Manage Participation"
                    primaryListStyle
                    editMode
                    allowCancel={formHasChange && !savingRecord}
                    allowEditing
                    showSpinner={savingRecord}
                    allowSave={formHasChange && !savingRecord}
                    description={buildListDescription()}
                    disableEditMode={() => onCancel()}
                />
            </Form>
        </SchemaProvider>
    );
};

export default withRouter(withDataDictionary(ManageParticipation));
